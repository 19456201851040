import React, { useState, useContext, useEffect } from 'react'
import { WizardContext, WizardService } from '@commons/steppers/wizard/'
import { ContributorBackgroundContext } from '@components/contributor-background'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import { StepperStepsService, StepperStepsContext } from '@commons/steppers/steps'
import ContributorDataManager from '@components/contributor-background/elements/contributor-data-manager'
import ContributorOtherData from '@components/contributor-background/elements/contributor-other-data'
import ErrorMessage from '@commons/messages/error/error-message'
import Button from '@commons/buttons/button'
import { format } from 'rut.js'
import moment from 'moment'
import _ from 'lodash'
import SimpleModal from '@commons/messages/simple-modal-oblig/modalObli'
import Checkbox from '@commons/inputs/checkbox'



const ContributorBackground = () => {
  const { wizard } = useContext(ContributorBackgroundContext)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const { contributor } = wizardData
  const [pensionValidation, setPensionValidation] = useState(true)
  const [dataValidation, setDataValidation] = useState(true)
  const [isEditing, setIsEditing] = useState(false)
  const [loading, setLoading] = useState(true)
  const [contributorData, setContributorData] = useState(contributor)
  const [message, setMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [btnDisabled, setBtnDisabled] = useState(false)
  const [editableData, setEditableData] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [disabledButtonsCircular, setDisabledButtonCircular] = useState(true)


  useEffect(()=> { 
    if(!dataValidation || !pensionValidation || loading || isEditing){
      setBtnDisabled(true)
    }else{
      if(_.size(contributorData)){
        if(contributorData.pension && pensionValidation){
          setBtnDisabled(false)
        }
        if(contributorData.pension && !pensionValidation){
          setBtnDisabled(true)
        }
        if(contributorData.pension === false){
          setBtnDisabled(false)
        }
        if(!_.size(contributorData.weight) || !_.size(contributorData.height)){
          setBtnDisabled(true)
        }
      }
    }
  },[contributorData,dataValidation,pensionValidation,loading,isEditing])
  /**
   * Side Effects
   */
  useEffect(()=>{

    const bodyConyugue = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: null,
    }

    WizardService.obtenerConyugeCircular(bodyConyugue).then( responseO => {
        
      if (responseO.httpCode === 200) {
          if(!responseO.data.firma){
            setShowModal(true);
          }
        } else {
          setShowErrorMessage(true)
          setMessage('No se ha podido cargar informacion de la firma de la circular')
        }
      }).catch(() => {
        setShowErrorMessage(true)
        setMessage('No se ha podido cargar informacion de la firma de la circular')
      })


    setLoading(true)
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut
    }
    WizardService.getContributorBackground(body).then((response) => {
      if(response.httpCode === 200){
        const contributorAux = {
          rut: format(response.data.rut),
          names: response.data.nombres,
          paternalSurname: response.data.apellidoPaterno,
          maternalSurname: response.data.apellidoMaterno,
          email: response.data.correo,
          birthdate: response.data.fechaNacimiento ? compareDates(response.data.fechaNacimiento) : '' ,
          isapre: response.data.descripcionSalud,
          isapreId: response.data.idSalud,
          weight: String(response.data.peso),
          height: response.data.altura,
          pension: response.data.pension,
          pensionCause: response.data.causal,
          pensionDiagnostic: response.data.diagnostico
        }
        WizardDispatch(WizardActions.setContributor(contributorAux))
        setContributorData(contributorAux)
        setLoading(false)
      }else{
        setLoading(false)
        setShowErrorMessage(true)
        setMessage('No se han podido obtener datos de antecedentes del cotizante')
      }
    }).catch(e => {
      setLoading(false)
      setShowErrorMessage(true)
      setMessage('No se han podido obtener datos de antecedentes del cotizante')
    })
  },[stepperStepsData.userInformation,WizardActions, WizardDispatch])
  
  function compareDates(date) {
    if (date) {
      if (date !== '01-01-0001') {
        var isafter = moment('01-01-1900', 'DD-MM-YYYY').isAfter(moment(date,'DD-MM-YYYY')); // true
        if(isafter){
            setEditableData(true)
            return ''
        } else {
          setEditableData(false)
            return date
        }
      } else {
        setEditableData(true)
        return ''
      }
      
    } else {
      setEditableData(true)
      return ''
    }
    
  }

  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  function handleNextStep(){

    if (dataValidation && pensionValidation) {
      const contributorAux = {
        identificador: stepperStepsData.userInformation.identificador,
        nombres: contributor.names,
        apellidoPaterno: contributor.paternalSurname,
        apellidoMaterno: contributor.maternalSurname,
        rut: contributor.rut,
        fechaNacimiento: contributorData.birthdate,
        correo: contributor.email,
        descripcionSalud: contributor.isapre,
        idSalud: contributor.isapreId,
        peso: contributorData.weight,
        altura: contributorData.height,
        pension: contributorData.pension,
        causal :contributorData.pensionCause,
        diagnostico: contributorData.pensionDiagnostic
      }
      setLoading(true)
      WizardService.saveContributorBackground(contributorAux).then((response) => {
        if(response.httpCode === 200){
          const body = {
            identificador: stepperStepsData.userInformation.identificador,
            rut: stepperStepsData.userInformation.rut,
            etapa: 'cotizante',
            subetapa: '',
            fechaRegistro: moment().format('DD-MM-YYYY')
          }
          StepperStepsService.sendStep(body).then((response) => {
            if(response.httpCode === 200){
              WizardDispatch(WizardActions.setCompleteSubStep({ name: 'cotizante' }))
              wizard.next()
            }else{
              setLoading(false)
              setShowErrorMessage(true)
              setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
            }
          }).catch(() => {
            setLoading(false)
            setShowErrorMessage(true)
            setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
          })
        }else{
          setLoading(false)
          setShowErrorMessage(true)
          setMessage('No se han podido enviar los antecedentes del cotizante')
        }
      }).catch(e => {
        setLoading(false)
        setShowErrorMessage(true)
        setMessage('No se han podido enviar los antecedentes del cotizante')
      })
    }
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

  const handleConfirmCircular = (e) => {
    setDisabledButtonCircular(e)
  }

  const submitCircular = () =>{

    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      firma: true 
    }
    
    WizardService.confirmarConyugeCircular(body).then( response => {
      if (response.httpCode === 200) {
        setShowModal(false);
      } else {
        setShowErrorMessage(true)
        setMessage('No se ha podido guardar la firma de la circular')
      }
    }).catch(() => {
        setShowErrorMessage(true)
        setMessage('No se ha podido guardar la firma de la circular')
    })

  }

  return (
    <div className="contributor-bg">
      <h2 className="contributor-bg__title">Antecedentes del Cotizante</h2>
      <div className="contributor-bg__message">
        A continuación debes revisar tus antecedentes y completar la información
        solicitada antes de continuar. Puedes editar también si ves algún error.
      </div>
      <div className="text-center">
        <SpinnerLoader show={!contributor} />
      </div>
      { _.size(contributorData) ? 
        <>
          <ContributorDataManager 
            isEditing={(e) => setIsEditing(e)}
            setValidData={(e) => setDataValidation(e)}
            setContributor={(e) => setContributorData(e)}
            editableData={editableData}
          >
             {contributorData}
            </ContributorDataManager>
          <ContributorOtherData 
            setValidPension={e => setPensionValidation(e)} 
            setValidData={(e) => setDataValidation(e)}
            setContributor={(e) => setContributorData(e)}
          >
            {contributorData}
          </ContributorOtherData>
        </>
       : null}
      <div className="contributor-other-data__button">
        <Button
          id="continue"
          text="Continuar"
          action={handleNextStep}
          cssClass="button__continue"
          disabled={btnDisabled}
        />
      </div>
      <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
        {message}
      </ErrorMessage>
      <SimpleModal isShowing={showModal} action={() => setShowModal(false)}>
        <div className="contributor-bg-container">
          <div className="container contributor-bg">
              <div className="contributor-bg__title">COMPROBANTE DE ENTREGA DE INFORMACIÓN</div>
                <div className="contributor-bg__subtitle_p">
                      La ley 21.337 establece que los cónyuges de matrimonio celebrado en la forma establecida en la
                      ley, podrán ser cargas legales el uno del otro, cuando se cumpla con los requisitos para ser
                      causante de asignación familiar, es decir, debe vivir a expensas del beneficiario y no debe
                      disponer de una renta igual o superior a la mitad del ingreso mínimo mensual, exceptuando lo
                      recibido por pensión de orfandad.
                  </div>
                  <div className="contributor-bg__subtitle_p">
                      Es obligatorio declarar a él o la cónyuge como carga legal cuando cumpla con los requisitos
                      señalados.
                  </div>
                  <div className="contributor-bg__subtitle_p">
                      La Isapre podrá poner término al contrato de salud, cuando se omita a algún beneficiario que
                      cumpla con los requisitos legales para ser carga legal, dentro de los cuales se encuentran él o la
                      cónyuge.
                  </div>
                  <div className="contributor-bg__subtitle_p">
                      El postulante toma conocimiento de lo informado, y en comprobante firma.
                  </div>
                   
                    <div className="contributor-bg__margin-title">
                        <div className="contributor-bg__margin-title_lefth">
                        <Checkbox
                              name="test"
                              text={"Acepto"}
                              clickMethod={() => {handleConfirmCircular(!disabledButtonsCircular)}}
                          />
                        </div>
                        <div>
                          <Button
                            text="Firmar Circular 383"
                            action={() => submitCircular()}
                            disabled={disabledButtonsCircular}
                          />
                      </div>
                    </div>


            </div>
          </div>
        </SimpleModal>
    </div>
  )
}

export default ContributorBackground
