const SET_COMPLETED_STEP = 'SET_COMPLETED_STEP'
const LOADING_DATA = 'LOADING_DATA'
const SET_CONFIRMED_QUESTIONS = 'SET_CONFIRMED_QUIESTIONS'
const SET_SHOW_CONFIRMATION = 'SET_SHOW_CONFIRMATION'

function setCompletedStep() {
    return { type: SET_COMPLETED_STEP, flag: true }
}

function setLoading(flag) {
    return {type: LOADING_DATA,flag}
}

function setConfirmedQuestions(flag) {
    return {type: SET_CONFIRMED_QUESTIONS,flag}
}

function setShowConfirmation(flag) {
    return {type: SET_SHOW_CONFIRMATION,flag}
}

export {
    SET_COMPLETED_STEP,
    LOADING_DATA,
    SET_CONFIRMED_QUESTIONS,
    SET_SHOW_CONFIRMATION,
    setCompletedStep,
    setLoading,
    setConfirmedQuestions,
    setShowConfirmation
}

  