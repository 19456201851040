/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ValidateIdentityContext, ValidateIdentityService } from "@components/validate-identity/";
import { WizardContext } from "@commons/steppers/wizard";
import "./authentication-page.scss";
import WindowOpen from "@commons/window-open/window-open";
import SpinnerLoader from '@commons/loaders/spinner-loader'
import { StepperStepsContext, StepperStepsService } from '@commons/steppers/steps'
import { format } from 'rut.js'
import moment from 'moment'
import ErrorMessage from '@commons/messages/error/error-message'

const AuthenticationPage = () => {

  const _raw = require('./../../../claveunica.txt')
  const [claveUnica, setClaveUnica] = useState(true)
  const { wizard, startSubscriptionData, ValidateIdentityDispatch, ValidateIdentityActions } = useContext(ValidateIdentityContext)

  const { stepperStepsData } = useContext(StepperStepsContext)
  const { wizardData, WizardActions, WizardDispatch } = useContext(WizardContext);
  const { urlIsapre } = wizardData;
  const [showError, setShowError] = useState(false)
  const [showloading, setShowLoading] = useState(false)
  const [preLoadRetryCount, setPreLoadRetryCount] = useState(3)
  const [cluniCount, setCluniCount] = useState(4)

  /** SideEffects */
  useEffect(() => {
    ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(4));
  }, []);

  useEffect(() => {

    fetch(_raw)
      .then((resp) => resp.text())
      .then(data => {
        setClaveUnica(data === 'true' ? true : false)
      })

  }, [claveUnica])

  const handleCustomOpenWindow = () => {
    ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(6));
  };

  const handleCustomCloseWindow = (state) => {
    if (state) {
      setShowLoading(true)
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
      }
      ValidateIdentityService.preloadInformation(body).then((response) => {
        if (response.httpCode === 200) {
          if (typeof (response.data) === "string") {
            ValidateIdentityDispatch(
              ValidateIdentityActions.setNextStepMessage(2)
            )
            ValidateIdentityDispatch(
              ValidateIdentityActions.setStateAuthentication(true)
            )
          } else if (response.data.codigoError === "400" || response.data.codigoError === "500") {
            mangePreLoadRetryCount()
          }
        } else {
          mangePreLoadRetryCount()
        }
      }).catch(() => {
        setShowLoading(false)
        ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(4));
      })
    } else {
      ValidateIdentityDispatch(
        ValidateIdentityActions.setNextStepMessage(5)
      )

    }
  };

  const mangePreLoadRetryCount = () => {
    setPreLoadRetryCount(preLoadRetryCount - 1)
    setShowError(true)
    setShowLoading(false)
    ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(4));
  }

  const handleOtheFlux = () => {

    //if (claveUnica) {

      loginClaveUnica()

    //} else {

     // WizardDispatch(WizardActions.setAuthenticationPage('callcenter'))

    //}

  }

  const loginClaveUnica = () => {

    const body = {
      "rut": format(stepperStepsData.userInformation.rut).split('.').join('').trim(),
      "idFlujo": process.env.REACT_APP_API_ID_FLUJO, // prod 5, qa 8, local 9
      "idExterno": stepperStepsData.userInformation.identificador
    }


    ValidateIdentityService.authClaveUnica(body).then((response) => {

      if (response.httpCode === 200 && response.data.CodError === 0 && cluniCount>0) {
        setCluniCount(cluniCount-1);

        const popupTick = window.open(response.data.Valor.UrlRedirigir, 'Clave Unica')


        const timer = setInterval(() => {

          if (popupTick.closed) {

            clearInterval(timer)

            const bodyEstado = {
              "idTrxClaveUnica": response.data.Valor.trxClaveUnica
            }

            ValidateIdentityService.estadoLogin(bodyEstado).then((response) => {

              if (response.data.Valor.EstadoLogin === 'OK') {

                const bodyStep = {
                  identificador: stepperStepsData.userInformation.identificador,
                  rut: stepperStepsData.userInformation.rut,
                  etapa: 'validacion',
                  subetapa: 'autenticacionPreguntas',
                  fechaRegistro: moment().format('DD-MM-YYYY')
                }

                return StepperStepsService.sendStep(bodyStep).then((response) => {
                  if (response.httpCode === 200) {
                    WizardDispatch(WizardActions.setMixedData(startSubscriptionData))
                    WizardDispatch(WizardActions.setCompleteSubStep({ name: 'validacion' }))
                    wizard.push('declaracion-renta')
                  } else {
                    throw new Error('Error al invocar el servicio sendStep');
                  }
                }).catch(() => {
                  setShowError({ show: true, msg: 'No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.' })
                  // if (!showError) {
                  //     window.location.assign("https://www.nuevamasvida.cl/")
                  // }
                  // setLoading(false)
                })

              } else {
                setShowError({ show: true, msg: `No se ha podido validar, vuelve a intentarlo más tarde. Quedan ${cluniCount} intentos.` })

                //throw new Error('Error al invocar el servicio estadoLogin');

              }

            }).catch(() => {

              setShowError({ show: true, msg: 'No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.' })
              // if (!showError) {
              //     window.location.assign("https://www.nuevamasvida.cl/")
              // }
              // setLoading(false)
            })

          }

        }, 500)

      } else {
        WizardDispatch(WizardActions.setAuthenticationPage('callcenter'));
        //setShowError({ show: true, msg: 'No se han podido enviar los documentos. Inténtalo nuevamente.' })
        // setLoading(false)

      }
    })
  }

  return (
    <div className="authentication-page">
      {showloading ?
        <SpinnerLoader loading={true} text="Verificando Autenticación" />
        :
        <WindowOpen
          textButton="Autenticación"
          customActionOpen={handleCustomOpenWindow}
          customActionClose={handleCustomCloseWindow}
          urlIsapre={urlIsapre}
          proceso="R"
          actionOtherFlux={handleOtheFlux}
          messageOtherFlux={<div>No se ha podido autenticar con tu isapre. Procederemos a la autenticación de tus datos a través de tu Rut.</div>}
          textOtherFlux="Continuar"
          preLoadRetryCount={preLoadRetryCount}
          preLoadError={showError}
        />}

      <ErrorMessage isShowing={showError.show} action={() => setShowError({ show: false, msg: '' })}>
        {showError.msg}
      </ErrorMessage>
    </div>
  );
};

export default AuthenticationPage;
