import React, { useCallback, useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  StepperStepsContext,
  StepperStepsService,
} from '@commons/steppers/steps'
import InputPassword from '@commons/inputs/input-password'
import Button from '@commons/buttons/button'
import ErrorMessage from '@commons/messages/error/error-message'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import Stages from '@utils/stages'

const Login = ({ location }) => {
  const params = new URLSearchParams(location.search)
  const history = useHistory()
  const [password, setPassword] = useState('')
  const [loading, setLoading] = useState(false)
  const {
    stepperStepsDispatch,
    StepperStepsActions,
  } = useContext(StepperStepsContext)
  const [showError, setShowError] = useState(false)
  const [blockedError, setBlockedError] = useState(false)
  const [cancelInvitationError, setCancelInvitationError] = useState(false)
  
  const validarProceso = useCallback(async () => {
    let ident = typeof(params.get('codigo'))
    if(ident === 'string'){
      await StepperStepsService.validarCargaATitular({identificador: params.get('codigo').replace(/ /g, '+')}).then((responseCarga) => {
        if (responseCarga.httpCode === 200) {
          localStorage.setItem('esCargaTitular', JSON.stringify(responseCarga.data.esCargaTitular))
          localStorage.setItem('tieneCargas', JSON.stringify(responseCarga.data.tieneCargas))
        }
      })
      .catch(() => {})
    }
 }, [params])


  /** SideEffects */
  useEffect(() => {
    stepperStepsDispatch(StepperStepsActions.startProcess({}))
    localStorage.removeItem('isAsisted')
    localStorage.removeItem('userInformation')
    localStorage.removeItem('originInvitation')
    localStorage.removeItem('esCargaTitular')
    localStorage.removeItem('tieneCargas')
    validarProceso()
  },[stepperStepsDispatch,StepperStepsActions])


  /**
   * Form Schema Validator
   */
  const schema = yup.object().shape({
    password: yup.string().required('Debe ingresar su clave'),
  })
  const { getValues, register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  })


  function loginUser() {
    setLoading(true)
    const body = {
      identificador: params.get('codigo').replace(/ /g, '+'),
      clave: password,
    }
    StepperStepsService.startProcess(body)
      .then((response) => {
        if (response.httpCode === 200) {
          const userData = {
            apellidoMaterno: response.data.apellidoMaterno,
            apellidoMaternoEjecutivo: response.data.apellidoMaternoEjecutivo,
            apellidoPaterno: response.data.apellidoPaterno,
            apellidoPaternoEjecutivo: response.data.apellidoPaternoEjecutivo,
            correo: response.data.correo,
            identificador: response.data.identificador.replace(/ /g, '+'),
            nombres: response.data.nombres,
            nombresEjecutivo: response.data.nombresEjecutivo,
            rut: response.data.rut.trim(),
            celular: response.data.celular,
          }
          stepperStepsDispatch(StepperStepsActions.startProcess(userData))
     
          const body2 = {
            identificador: params.get('codigo').replace(/ /g, '+'),
            rut: userData.rut,
          }
          StepperStepsService.getFluxOrigin(body2).then((response) => {
            if (response.httpCode === 200) {
              localStorage.setItem('isAsisted', JSON.stringify(response.data.codTipoFlujo === 0))
              stepperStepsDispatch(StepperStepsActions.setFluxOrigin(response.data.codTipoFlujo === 0))
              localStorage.setItem('originInvitation', JSON.stringify(response.data.detalleFlujoId))
              stepperStepsDispatch(StepperStepsActions.setOriginInvitation(response.data.detalleFlujoId))
                  const body3 = {
                    identificador: params.get('codigo').replace(/ /g, '+'),
                    rut: userData.rut,
                  }
                  StepperStepsService.getStep(body3).then((stageResponse) => {
                    if (stageResponse.httpCode === 200) {
                      if(stageResponse.data.etapa === 'contactarEjecutivo'){
                        setBlockedError(true)
                        setLoading(false)
                      } else if (stageResponse.data.etapa === 'noExisteEmpleadoresNoAsistido') {
                        setBlockedError(true)
                        setLoading(false)
                      } else if (stageResponse.data.etapa === 'invitacionAnulada') {
                        setCancelInvitationError(true)
                        setLoading(false)
                      } else{
                        if (stageResponse.data.etapa === 'dsFinalizada') {
                          if (JSON.parse(localStorage.getItem('isAsisted'))) {
                            history.push(Stages[stageResponse.data.etapa]['asistida'].url)
                          } else {
                            history.push(Stages[stageResponse.data.etapa]['noAsistida'].url)
                          }
                        } else {
                          history.push(Stages[stageResponse.data.etapa].url)
                        }
                        history.push(Stages[stageResponse.data.etapa].url)
                      }
                    } else {
                      setShowError(true)
                      setLoading(false)
                    }
                  }).catch(() => {
                    setShowError(true)
                    setLoading(false)
                  })   
            } else {
              setShowError(true)
              setLoading(false)
            }
          }).catch(() => {
            setShowError(true)
            setLoading(false)
          })
        } else {
          setShowError(true)
          setLoading(false)
        }
      })
      .catch(() => {
        setShowError(true)
        setLoading(false)
      })
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowError(false)
  }

  return (
    <div className="login">
      <ErrorMessage isShowing={showError} action={closeMsg}>
        Te informamos que no hemos podido autenticar tu identidad. Te agradeceremos tomar contacto con el ejecutivo que te està asesorando.
      </ErrorMessage>
      <ErrorMessage isShowing={blockedError} action={()=> setBlockedError(false)}>
        Te informamos que no podrás continuar con este proceso de venta electrónica. Un asesor de nuestra Isapre se pondrá en contacto contigo para iniciar un nuevo proceso.
      </ErrorMessage>
      <ErrorMessage isShowing={cancelInvitationError} action={()=> setCancelInvitationError(false)}>
      Te informamos que no podrás continuar con este proceso de venta electrónica, ya que esta invitación se encuentra anulada. Ingresa a tu bandeja de entrada de tu correo electrónico y revisa si existe una invitación más reciente.
      </ErrorMessage>
      <h2 className="login__title">Ingreso a la plataforma</h2>
      <div className="login__message">
        Te damos la bienvenida a la Plataforma de Suscripción Electrónica de
        Isapre Nueva Masvida. Para iniciar al proceso debes ingresar la clave de
        acceso que enviamos por <span>SMS</span> a tu celular y a tu Correo Electrónico
      </div>
      <div className="login__spinner">
        <SpinnerLoader show={loading} />
      </div>
      {!loading && (
        <div className="login__form-wrapper">
          <div className="form-group">
            <InputPassword
              name="password"
              register={register}
              error={errors.password}
              value={getValues().password}
              setPassword={setPassword}
            />
          </div>
          <div className="actions">
            <Button
              text="Ingresar a la plataforma"
              action={handleSubmit(loginUser)}
            />
          </div>
        </div>
      )}
    </div>
  )
}

Login.propTypes = {
  location: PropTypes.shape({ search: PropTypes.string }).isRequired,
}

export default Login
