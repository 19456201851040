import './table.scss'
import React from 'react'
import PropTypes from 'prop-types'

export const Table = ({ children, responsive, cssClass }) => {
  const table = <table className={`table ${cssClass}`.trim()}>{children}</table>
  return (
    <div className="table-wrapper">
      {responsive && <div className="table-responsive">{table}</div>}
      {!responsive && table}
    </div>
  )
}
Table.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
  responsive: PropTypes.bool,
  cssClass: PropTypes.string,
}
Table.defaultProps = {
  responsive: false,
  cssClass: '',
}

export const Td = ({ children }) => (
  <td>
    <span>{children}</span>
  </td>
)
Td.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element])
    .isRequired,
}

export const Th = ({ children }) => (
  <th>
    <span>{children}</span>
  </th>
)
Th.propTypes = {
  children: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.element])
    .isRequired,
}
