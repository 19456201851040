import './documents-list.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Downloader from '@commons/downloader/downloader'

const DocumentsList = ({ data, action }) => {
  return (
    <div className="documents-list">
      {data.map((e) => (
        <Downloader text={e.nombre} key={e.id} fileUrl={e.archivo} />
      ))}
    </div>
  )
}

DocumentsList.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      number: PropTypes.number,
      document: PropTypes.string,
      url: PropTypes.string,
    })
  ).isRequired,
  action: PropTypes.func,
}
DocumentsList.defaultProps = {
  action: () => null,
}

export default DocumentsList
