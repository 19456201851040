import RequestService from '@core/request-service'

export default class RentStatementService extends RequestService {

  //Obtener datos prospecto
  static getProspectData(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        ? '/api/v2/obtenerDatosProspectoV2'
        : 'https://run.mocky.io/v3/6ddb9158-39e6-468d-8913-43dbba2de748',
      payload
    )
  }


  //Obtener datos DS
  static getDs(payload) {
    const codigoInvitacion = JSON.parse(localStorage.getItem("userInformation")).identificador;
    const params = `?codigo=${encodeURIComponent(codigoInvitacion)}`;

    return super.put(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        ? '/api/v2/dsWeb' + params
        : ''
      , payload
    )
  }

  static getDsV2(payload, codigoInvitacion) {
    const params = `?codigo=${encodeURIComponent(codigoInvitacion)}`;

    return super.put(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        ? '/api/v2/dsWeb' + params
        : ''
      , payload
    )
  }

  // api/obtenerentidadespagadoras
  static getEmployerData(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        // ? '/api/v1/Obtenerentidadespagadoras'
        ? '/api/v3/Obtenerentidadespagadoras'
        : 'https://run.mocky.io/v3/336d6135-6db0-46e9-ab81-2fb13748a4f4',
      payload
    )
  }

  static validarEntidadesPagadoras(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        ? '/api/v1/validarEntidadesPagadoras'
        : 'https://run.mocky.io/v3/e9785faf-cd14-4edf-abea-6e9e7fdf5586',
      payload
    )
  }

  static loadDocument(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        ? '/api/v1/CargaArchivo'
        : 'https://run.mocky.io/v3/f938978e-4b15-4b8a-97b2-2479d767f018',
      payload
    )
  }

  // api/obtenercotizaciones
  static getQuotationList(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        ? '/api/v1/Obtenercotizaciones'
        : 'https://run.mocky.io/v3/952416bc-2c11-4e76-b3b1-358b0ee8dc1d',
      payload
    )
  }

  // api/guardarentidadpagadora
  static saveEmployerData(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        // ? '/api/v1/guardarentidadpagadora'
        // : 'https://www.mocky.io/v2/5eda7795330000181a79ec46',
        ? '/api/v3/guardarentidadpagadora'
        : 'https://run.mocky.io/v3/346ac3f7-33ff-4612-8196-904f989f8427',
      payload
    )
  }

  static anularProspecto(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API)
        ? '/api/v4/Anular'
        : '/api/v4/Anular',
      payload
    )
  }

}
