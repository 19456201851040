import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import ContributorBackgroundReducer from './contributor-background-reducer'
import * as ContributorBackgroundActions from './contributor-background-actions'

export const ContributorBackgroundContext = React.createContext()

export const ContributorBackgroundContextProvider = ({ children, wizard }) => {
  const [contributorBackgroundData, contributorBackgroundDispatch] = useReducer(
    ContributorBackgroundReducer,
    {
    }
  )
  return (
    <ContributorBackgroundContext.Provider
      value={{
        wizard,
        contributorBackgroundData,
        contributorBackgroundDispatch,
        ContributorBackgroundActions
      }}
    >
      {children}
    </ContributorBackgroundContext.Provider>
  )
}

ContributorBackgroundContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired
}
