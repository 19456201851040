import React, {useEffect, useState, useContext} from "react";
import Separator from "@commons/separator/separator";
import "./paid-entity.scss";
import _ from "lodash";
import { WizardContext } from "@commons/steppers/wizard";
import FormatNum from '@utils/tools/number'
import { format } from 'rut.js'

const PaidEntity = ({ entity }) => {
  const { wizardData } = useContext(WizardContext)
  const { regions, cities,communes,} = wizardData
  const [regionsValues, setRegionsValue] = useState([])
  const [citiesValues, setCitiesValues] = useState([])
  const [communesValues, setCommunesValues] = useState([])

  const {
    codigoRegion,
    codigoCiudad,
    codigoComuna,
    correo,
    domicilio,
    razonSocial, 
    rut, 
    telefono,
    ultimaRenta,
  } = entity;

  useEffect(() => {
    if (_.size(regions)) {
      const listRegions = regions.map((itemRegion) => {
        return {
          label: itemRegion.descripcion,
          value: itemRegion.codigo,
        };
      });
      setRegionsValue(listRegions);
    }

    if (_.size(cities)) {
      let listCities = cities.filter(
        (itemCity) => itemCity.codigoRegion === codigoRegion
      );
      listCities = listCities.map((itemCity) => {
        return {
          label: itemCity.descripcion,
          value: itemCity.codigo,
        };
      });
      setCitiesValues(listCities);
    }

    if (_.size(communes)) {
      let listCommunes = communes.filter(
        (itemCommune) => itemCommune.codigoCiudad === codigoCiudad
      );

      listCommunes = listCommunes.map((itemCommune) => {
        return {
          label: itemCommune.descripcion,
          value: itemCommune.codigo,
        };
      });
      setCommunesValues(listCommunes);
    }
  }, [regions, cities, communes, codigoCiudad, codigoComuna, codigoRegion]);


  return (
    <div className="paid-entity">
      <div className="paid-entity__box">
        <div className="form-row">
          <div className="form-group form-group--large">
            <label className="form-label">Rut Empleador</label>
            <p className="value-data">{format(rut.toString())}</p>
          </div>
          <Separator />
          <div className="form-group form-group--large">
            <label className="form-label">Razón Social o Nombre</label>
            <p className="value-data">{razonSocial}</p>
          </div>
          <Separator />
          <div className="form-group">
            <label className="form-label">Última Renta</label>
            <p className="value-data">{FormatNum(ultimaRenta)}</p>
          </div>
        </div>
        <Separator isVertical={true} />
        <div className="form-row">
          <div className="form-group">
            <label className="form-label">Domicilio Empleador</label>
            <p className="value-data">{domicilio}</p>
          </div>
        </div>
        <Separator isVertical={true} />
        <div className="form-row">
          <div className="form-group form-group--middle">
            <label className="form-label">Teléfono o Celular</label>
            <p className="value-data">{telefono}</p>
          </div>
          <Separator />
          <div className="form-group form-group--middle">
            <label className="form-label">Correo electrónico</label>
            <p className="value-data">{correo}</p>
          </div>
        </div>
        <Separator isVertical={true} />
        <div className="form-row">
          <div className="form-group form-group--large">
            <label className="form-label">Region</label>
            <p className="value-data">{regionsValues.find((op) => op.value === codigoRegion) !== undefined ? regionsValues.find((op) => op.value === codigoRegion).label : ''}</p>
          </div>
          <Separator />       
          <div className="form-group form-group--large">
            <label className="form-label">Ciudad</label>
            <p className="value-data">{citiesValues.find((op) => op.value === codigoCiudad) !== undefined ? citiesValues.find((op) => op.value === codigoCiudad).label : ''}</p>
          </div>
          <Separator />
            <div className="form-group form-group--last">
            <label className="form-label">Comuna</label>
            <p className="value-data">{communesValues.find((op) => op.value === codigoComuna) !== undefined ? communesValues.find((op) => op.value === codigoComuna).label : ''}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaidEntity;
