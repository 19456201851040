import React, { useState } from 'react'
import './change-email.scss'
import TextGroup from '@commons/inputs/text-group'
import Button from '@commons/buttons/button'
import PropTypes from 'prop-types'
import ConfirmMessage from '@commons/messages/confirm/confirm'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'

const ChangeEmail = ({ action, emailCustom }) => {
  const [email, setEmail] = useState(emailCustom)
  const [repeatEmail, setRepeatEmail] = useState(emailCustom)
  const [showMessage, setShowMessage] = useState('')

  /**
   * @description Handle validation changed email
   */
  function handleChangeEmail() {
    if (email === repeatEmail) {
      action(true, email)
    } else {
      action(false)
      setShowMessage(true)
    }
  }

  /**
   * Form Schema Validator
   */
  const schema = yup.object().shape({
    email: yup.string().matches(/([a-zA-Z0-9]+)([_.\-{1}])?([a-zA-Z0-9]+)@([a-zA-Z0-9.]+)([.])(cl|org|es|net|com|CL|ORG|NET|ES|COM+)$/g, { message: 'Formato inválido (Dominios aceptados: .cl .com .org .es .net)', excludeEmptyString: true }).required('Debe ingresar su correo electrónico'),
    repeatEmail: yup.string().matches(/([a-zA-Z0-9]+)([_.\-{1}])?([a-zA-Z0-9]+)@([a-zA-Z0-9.]+)([.])(cl|org|es|net|com|CL|ORG|NET|ES|COM+)$/g, { message: 'Formato inválido (Dominios aceptados: .cl .com .org .es .net)', excludeEmptyString: true }).required('Debe reingresar su correo electrónico'),
  })
   const { register, handleSubmit,errors } = useForm({
    validationSchema: schema,
  })

  /**
   * @description Handle close message
   */
  function handleCloseMessage() {
    setShowMessage(false)
  }

  return (
    <div className="change-email">
      <TextGroup
        name="email"
        register={register}
        label="Ingresa nuevo Correo Electrónico"
        setText={setEmail}
        error={errors.email}
        value={email}
      />
      <TextGroup
        name="repeatEmail"
        register={register}
        label="Reingresa nuevo Correo Electrónico"
        setText={setRepeatEmail}
        error={errors.repeatEmail}
        value={repeatEmail}
      />
      <Button
        text="Confirmar Correo Electrónico"
        action={handleSubmit(handleChangeEmail)}
      />

      <ConfirmMessage
        cancel="Cerrar"
        isShowing={showMessage}
        cancelAction={handleCloseMessage}
      >
        <div className="change-email__alert">Los correos no coinciden</div>
      </ConfirmMessage>
    </div>
  )
}

ChangeEmail.propTypes = {
  action: PropTypes.func.isRequired,
  emailCustom: PropTypes.string,
}

ChangeEmail.defaultProps = {
  emailCustom: '',
}

export default ChangeEmail
