import React, { useContext, useState } from "react";
import "./contributor-personal-data.scss";
import Separator from "@commons/separator/separator";
import { WizardContext } from "@commons/steppers/wizard";
import { useEffect } from "react";
import _ from "lodash";
import Select from "react-select";
// import Radio from "@commons/inputs/radio";
import { ErrorMessage } from 'react-hook-form'
import Tooltip from '@commons/tooltip/tooltip'
import { Controller } from 'react-hook-form'

const ContributorPersonalData = ({register,errors,control}) => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const {
    backgroudContractualDocuments,
    regions,
    cities,
    communes,
    afps,
  } = wizardData


  const [afpsValues, setAfpsValues] = useState([])
  const [showTooltip, setShowTooltip] = useState(false)
  const [regionsValues, setRegionsValue] = useState([])
  const [citiesValues, setCitiesValues] = useState([])
  const [communesValues, setCommunesValues] = useState([])

  const {
    codigoAfp,
    codigoRegion,
    codigoCiudad,
    codigoComuna,
    domicilio,
    // extranjero,
  } = backgroudContractualDocuments

  useEffect(() => {
    if (_.size(regions)) {
      const listRegions = regions.map((itemRegion) => {
        return {
          label: itemRegion.descripcion,
          value: itemRegion.codigo,
        };
      });
      setRegionsValue(listRegions);
    }

    if (_.size(cities)) {
      let listCities = cities.filter(
        (itemCity) => itemCity.codigoRegion === codigoRegion
      );
      listCities = listCities.map((itemCity) => {
        return {
          label: itemCity.descripcion,
          value: itemCity.codigo,
        };
      });
      setCitiesValues(listCities);
    }

    if (_.size(communes)) {
      let listCommunes = communes.filter(
        (itemCommune) => itemCommune.codigoCiudad === codigoCiudad
      );

      listCommunes = listCommunes.map((itemCommune) => {
        return {
          label: itemCommune.descripcion,
          value: itemCommune.codigo,
        };
      });
      setCommunesValues(listCommunes);
    }
  }, [regions, cities, communes, codigoCiudad, codigoComuna, codigoRegion]);

  useEffect(() => {
    if (_.size(afps)) {
      const listAfps = afps.map((itemAfp) => {
        return {
          label: itemAfp.descripcion,
          value: itemAfp.codigo,
        };
      });
      setAfpsValues(listAfps);
    }
  }, [afps]);

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 5,
    }),
    control: (provided) => ({
      ...provided,
      height: 40,
      minHeight: 0,
      padding: "auto",
    }),
    singleValue: (provided) => ({
      ...provided,
      height: 40,
      paddingTop: 10,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      height: 40,
    }),
    menu: (provided) => ({
      ...provided,
      top: 40,
      height: "auto",
      padding: 0,
    }),
    valueContainer: () => ({
      height: 40,
    }),
    input: (provided) => ({
      ...provided,
      height: 40,
    }),
  };

  const customOnChange = (value) => {
    WizardDispatch(WizardActions.setDataBackgroudContractualDocuments(value))
  }

  /**
   * @description MouseLeave Event for tooltipComponent
   * @param {boolean} flag
   */
  function handlerTooltipOnHover(flag) {
    setShowTooltip(flag)
  }

  const validateError = () => {
    if (errors.hasOwnProperty('domicilio') || errors.hasOwnProperty('afps') || errors.hasOwnProperty('region') || errors.hasOwnProperty('ciudad') || errors.hasOwnProperty('comuna') ) {
      return true
    }else {
      return false
    }
  }

  return (
    <div className="contributor-personal-data">
      <div className="contributor-personal-data__box">
        <div className="form-row">
          <div className="form-group form-group--large">
            <label className="form-label">Region</label>
            {_.size(regionsValues) ? <Controller
              name="region"
              control={control}
              as={Select}
              options={regionsValues}
              rules={{ required: true }}
              className="ns-select contributor-personal-data__select"
              classNamePrefix="ns-select"
              styles={customStyles}
              isSearchable={false}
              isDisabled={!_.size(regionsValues)}
              placeholder="Selecciona Region"
              defaultValue={regionsValues.find(
                (op) => op.value === codigoRegion
              )}
              onChange={(e) => {
                customOnChange({name:'codigoRegion', value:e[0].value})
                return e[0]
              }}
              value={regionsValues.find(
                (op) => op.value === codigoRegion
              )}
            />:null}
            <ErrorMessage name="region" errors={errors}>
                {({ message }) => <div className="invalid-feedback">{message}</div>}
              </ErrorMessage>
          </div>
          <Separator />
          <div className="form-group form-group--large">
            <label className="form-label">Ciudad</label>
            {_.size(citiesValues) ? <Controller 
              as={Select}
              name="ciudad"
              rules={{ required: true }}
              control={control}
              options={citiesValues}
              className="ns-select contributor-personal-data__select"
              classNamePrefix="ns-select"
              styles={customStyles}
              isSearchable={false}
              isDisabled={!_.size(citiesValues)}
              placeholder="Selecciona Ciudad"
              defaultValue={citiesValues.find(
                (op) => op.value === codigoCiudad
              )}
              onChange={(e) => {
                customOnChange({name:'codigoCiudad', value:e[0].value})
                return e[0]
              }}
              value={citiesValues.find(
                (op) => op.value === codigoCiudad
              )}
            />:null}
            <ErrorMessage name="ciudad" errors={errors}>
                {({ message }) => <div className="invalid-feedback">{message}</div>}
              </ErrorMessage>
          </div>
          <Separator />
          <div className="form-group form-group--large">
            <label className="form-label">Comuna</label>
            {_.size(communesValues) ? <Controller 
              as={Select}
              name="comuna"
              rules={{ required: true }}
              control={control}
              options={communesValues}
              className="ns-select contributor-personal-data__select"
              classNamePrefix="ns-select"
              isDisabled={!_.size(communesValues)}
              styles={customStyles}
              isSearchable={false}
              placeholder="Selecciona Comuna"
              defaultValue={communesValues.find(
                (op) => op.value === codigoComuna
              )}
              onChange={(e) => {
                customOnChange({name:'codigoComuna', value:e[0].value})
                return e[0]
            }}
              value={communesValues.find(
                (op) => op.value === codigoComuna
              )}
            />:null}
             <ErrorMessage name="comuna" errors={errors}>
                {({ message }) => <div className="invalid-feedback">{message}</div>}
              </ErrorMessage>
          </div>
        </div>
        <Separator isVertical={true} />
        <div className="form-row">
          <div className="form-group form-group--xlarge">
            <div className="text-group">
              <input
                className="form-control noborder"
                type="text"
                placeholder="&nbsp;"
                onChange={(e) => customOnChange({name:e.target.name,value:e.target.value})}
                name="domicilio"
                value={domicilio}
                ref={register}
              />
              <label htmlFor="" className="form-label">
                Domicilio
              </label>
              <ErrorMessage name="domicilio" errors={errors}>
                {({ message }) => <div className="invalid-feedback">{message}</div>}
              </ErrorMessage>
             </div>
          </div>
        </div>
        <Separator isVertical={true} />
        <div className="form-row">
          <div className="form-group form-group--xlarge">
          <label className="form-label">AFP</label>
            {_.size(afpsValues) ? <Controller 
              name="afps"
              control={control}
              as={Select}
              options={afpsValues}
              className="ns-select contributor-personal-data__select"
              classNamePrefix="ns-select"
              styles={customStyles}
              isDisabled={!_.size(afpsValues)}
              placeholder="Selecciona AFP"
              isSearchable={false}
              value={afpsValues.find(
                (op) => op.value === codigoAfp
              )}
              rules={{ required: true }}
              defaultValue={afpsValues.find(
                (op) => op.value === codigoAfp
              )}
              onChange={(e) => {
                customOnChange({name:'codigoAfp',value:e[0].value})
                return e[0]
              }}
            />:null}
            <ErrorMessage name="afps" errors={errors}>
                {({ message }) => <div className="invalid-feedback">{message}</div>}
              </ErrorMessage>
          </div>

          {/* <Separator />
          <div className="row form-group__extranjero form-group">
            <span className="form-group__label">Extranjero:</span>
            <div className="form-group__radio-options">
              <Radio
                text="NO"
                name="extranjero"
                value="false"
                isChecked={!extranjero}
                onChangeEvent={() => customOnChange({name:'extranjero',value:false})}

              />
              <Radio
                text="SI"
                name="extranjero"
                value="true"
                isChecked={extranjero}
                onChangeEvent={() => customOnChange({name:'extranjero',value:true})}
              />
            </div>
          </div> */}
        </div>
        <div className="contributor-personal-data__action">
          <div className={`contributor-personal-data__alert-toogle ${validateError() ? 'contributor-personal-data__alert-toogle--error':'' }`}>
            <Tooltip visible={showTooltip} align="left">
            Para continuar debes agregar tu domicilio, AFP 
           {/*  y confirmar si eres extranjero. */}
            </Tooltip>
            <em 
              className="icon-alerta" 
              onMouseOver={() => handlerTooltipOnHover(true)}
              onMouseOut={() => handlerTooltipOnHover(false)}
              onBlur={() => handlerTooltipOnHover(false)}
              onFocus={() => handlerTooltipOnHover(true)}
            />
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default ContributorPersonalData;
