import React, { useContext } from 'react'
import './result-health-statement.scss'
import ResultAuthorized from '@components/health-statement-sent/elements/result-authorized'
import ResultEvaluation from '@components/health-statement-sent/elements/result-evaluation'
import ResultRejected from '@components/health-statement-sent/elements/result-rejected'
import ResultRevision from '@components/health-statement-sent/elements/result-revision'
import ResultRestricted from '@components/health-statement-sent/elements/result-restricted'
import ResultCanceled from '@components/health-statement-sent/elements/result-canceled'
import ResultFinantialEvaluation from '@components/health-statement-sent/elements/result-finantial-evaluation'
import ResultFinantialRevision from '@components/health-statement-sent/elements/result-finantial-revision'
import ResultInsufficientIncome from '@components/health-statement-sent/elements/result-insufficient-income'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import { StepperStepsContext } from '@commons/steppers/steps'
import { HealthStatementSentContext } from '@components/health-statement-sent'

const ResultHealthStatement = () => {

  const {
    stepperStepsData: { result },
  } = useContext(StepperStepsContext)

  const {
    healthStatementSentData: { loading, error },
  } = useContext(HealthStatementSentContext)

  return (
    <div className="result-health-statement">
      {
        {
          authorized: <ResultAuthorized />,
          evaluation: <ResultEvaluation />,
          rejected: <ResultRejected />,
          revision: <ResultRevision />,
          restricted: <ResultRestricted />,
          canceled: <ResultCanceled />,
          financialEvaluation: <ResultFinantialEvaluation/>,
          financialRevision: <ResultFinantialRevision/>,
          insufficientIncome: <ResultInsufficientIncome/>,
        }[result]
      }
      <SpinnerLoader show={loading} />
      {error &&
        <div className="result-health-statement__error">
          No se ha podido cargar correctamente el resultado de su Declaración de
          Salud, intentelo más tarde.
        </div>
      }
    </div>
  )
}

export default ResultHealthStatement
