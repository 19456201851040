import React, { useContext, useState } from 'react'
import './sign-form.scss'
import ErrorMessage from '@commons/messages/error/error-message'
import {
  SignHealthStatementContext,
  SignHealthStatementService,
} from '@components/sign-health-statement'

import { WizardContext } from '@commons/steppers/wizard'
import { StepperStepsContext } from '@commons/steppers/steps'
import Sign from '@commons/sign/sign'
import { useHistory } from 'react-router-dom'
import SpinnerLoader from '@commons/loaders/spinner-loader'

const SignForm = () => {
  const history = useHistory()
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const [signReady, setSignReady] = useState(true)
  const { wizard } = useContext(SignHealthStatementContext)
  const [tooglePin, setTooglePin] = useState(false)
  const [loadingSaveHealthStatement, setLoadingSaveHealthStatement] = useState(false)
  const [showErroMessageSaveDS, setShowErroMessageSaveDS] = useState(false)
  const [loading, setLoading] = useState(false)
  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))
  const [loadView, setLoadView] = useState(true)
  const [textLoadForm, setTextLoadForm] = useState('Guardando Declaración de Salud')
  const [textTypeDeclare, setTextTypeDeclare] = useState('Declaración de Salud')
  const [textBtnFirmar, setTextBtnFirmar] = useState('Firmar Declaracion de Salud')

  if((esCargaTitular) && (!tieneCargas) && (loadView)){
    setTextLoadForm('Guardando Declaración de Renta')
    setTextTypeDeclare('Declaración de Renta')
    setTextBtnFirmar('Firmar Declaracion de Renta')
    setLoadView(false)   
  }

  /**
   * @description Handle onClick validation pin
   */
  const handlePinValidation = (pin) => {
    setLoading(true)
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      pin,
    }
    SignHealthStatementService.signHealthStatement(body).then((response) => {
      if (response.httpCode === 200) {
        WizardDispatch(WizardActions.setStatus('sent'))
        setSignReady(true)
        WizardDispatch(WizardActions.setCompleteSubStep({ name: 'firma' }))
        wizard.next()
      } else {
        setShowErrorMessage(true)
        setLoading(false)
      }
    }).catch(() => {
      setShowErrorMessage(true)
      setLoading(false)
    })
  }

  /**
   * @description Handle close error message
   */
  function handleCloseErrorMessage() {
    setShowErrorMessage(false)
  }

  
  /**
   * @description Handle close error message
   */
  function handleCloseErrorMessageSaveDS() {
    setShowErroMessageSaveDS(false)
  }

    /**
   * @description Cancel Action handler
   */
  function cancelAction() {
    history.push('/declaracion-salud/resumen-preguntas')
  }

  function handleSignBtn(){
    setLoadingSaveHealthStatement(true)
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
    }
    SignHealthStatementService.saveHealthStatement(body).then((response) =>{
      if(response.httpCode === 200){
        setLoadingSaveHealthStatement(false)
        setTooglePin(true)
      } else {
        setShowErroMessageSaveDS(true)
        setLoadingSaveHealthStatement(false)
      }
    }).catch(() => {
      setShowErroMessageSaveDS(true)
      setLoadingSaveHealthStatement(false)
    })
  }

  return (
    <div className="sign-form">
      <Sign
            signBtnAction={handleSignBtn}
            onSign={handlePinValidation}
            signButtonText={textBtnFirmar}
            pinDescription={
              <div className="sign-form__message">
                Hemos enviado una clave segura por SMS a tu celular y a tu Correo.
                Ingrésala a continuación para firmar tu{' '}
                <span className="sign-form__message--bold">{textTypeDeclare}</span> y
                continuar al siguiente paso.
              </div>
            }
            onCancel={cancelAction}
            isSigned={signReady}
            tooglePin={tooglePin}
            showCancel={false}
            loading={loading}
            setLoading={setLoading}
          />
        <div className="sign-form__loading">
          <SpinnerLoader show={loadingSaveHealthStatement} text={textLoadForm} />
        </div>
      <ErrorMessage
        isShowing={showErrorMessage}
        action={handleCloseErrorMessage}
      >
        <div className="pin-validation__alert">
          No se ha podido firmar la declaración de salud, vuelva a intentarlo
          más tarde.
        </div>
      </ErrorMessage>
      <ErrorMessage
      isShowing={showErroMessageSaveDS} action={handleCloseErrorMessageSaveDS}>
        <div className="pin-validation__alert">
          No se ha podido guardar la declaración de salud, vuelva a intentarlo
          más tarde.
        </div>
      </ErrorMessage>
    </div>
  )
}

export default SignForm
