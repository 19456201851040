import {
  FILL_ISAPRES_LIST,
  SET_ISAPRE_ACTIVE,
  SET_TYPE_HEALTH_STATEMENT,
  SET_READY_TO_GO,
  SET_TYPE_USER,
  SET_COMPLETED_STEP,
  SET_NEXT_STEP_MESSAGE,
  REFRESH_DATA,
} from './start-subscription-actions'

export default function StartSubscriptionReducer(state, action) {
  switch (action.type) {
    case FILL_ISAPRES_LIST:
      return {
        ...state,
        isapresList: action.collection,
      }
    case SET_ISAPRE_ACTIVE:
      return {
        ...state,
        selectedIsapre: action.isapre,
      }
    case SET_TYPE_HEALTH_STATEMENT:
      return {
        ...state,
        selectedTypeHealthStatement: action.typeHealthStatement,
      }
    case SET_READY_TO_GO:
      return {
        ...state,
        readyToGo: action.flag,
      }
    case SET_TYPE_USER:
      return {
        ...state,
        typeUser: action.typeUser,
      }
    case SET_COMPLETED_STEP:
      return {
        ...state,
        completedStep: action.flag,
      }
    case SET_NEXT_STEP_MESSAGE:
      return {
        ...state,
        currentMessage: action.currentMessage,
        message: state.messages[action.currentMessage],
      }
    case REFRESH_DATA:
      return {
        currentMessage: 0,
        messages: state.messages,
        message: state.messages[0],
        isapresList: state.isapresList,
      }
    default:
      return state
  }
}
