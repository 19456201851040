/* eslint-disable react/forbid-prop-types */
import React from 'react'
import './message-manager.scss'
import InfoMessage from '@commons/messages/info/info-message'
import Button from '@commons/buttons/button'
import PropTypes from 'prop-types'

const MessageError = ({ messages, textButton, isShowing, action, iconColor }) => {
  /**
   * @description Handler onClick mesage
   */
  const handleOnclick = () => {
    action()
  }
  return (
    <div className="message-manager-error">
      <div>
        <InfoMessage isShowing={isShowing} iconColor={iconColor} floating={false} locked={false}>
          <div>
            {Array.isArray(messages) ? messages.map((message) => (
              <div>{message}</div>
            )):messages}
          </div>
        </InfoMessage>
        <div className="message-manager-error__button">
          <Button text={textButton} action={handleOnclick} />
        </div>
      </div>
    </div>
  )
}

MessageError.propTypes = {
  textButton: PropTypes.string.isRequired,
  isShowing: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
}

MessageError.defaultProps = {
  iconColor: 'blue',
}

export default MessageError
