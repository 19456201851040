import React, { useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import { StepperStepsContext, StepperStepsService } from '@commons/steppers/steps'
import _ from 'lodash'
import { useHistory } from 'react-router-dom'
import Stages from '@utils/stages'
import { WizardContext } from '@commons/steppers/wizard'

export const ManagerRouteContext = React.createContext()

export const ManagerRouteContextProvider = ({ children }) => {
    const history = useHistory()

    const { WizardDispatch, WizardActions } = useContext(WizardContext)

    const { stepperStepsData: {userInformation, isAsisted}, stepperStepsDispatch, StepperStepsActions } = useContext(StepperStepsContext)

    useEffect(() => {
        if (! _.size(userInformation) || isAsisted === '') {
            history.push('/')
        } else {
          const body = {
            identificador: userInformation.identificador,
            rut: userInformation.rut,
          }
          StepperStepsService.getFluxOrigin(body).then((response) => {
            if (response.httpCode === 200) {
              stepperStepsDispatch(StepperStepsActions.setFluxOrigin(response.data.codTipoFlujo === 0))
              localStorage.setItem('isAsisted', JSON.stringify(response.data.codTipoFlujo === 0))
              const body2 = {
                identificador: userInformation.identificador,
                rut: userInformation.rut,
              }
              StepperStepsService.getStep(body2).then((stageResponse) => {
                if (stageResponse.httpCode === 200) {
                  let currentStage = stageResponse.data.etapa
                  let currentSubStep = stageResponse.data.subetapa
                  WizardDispatch(WizardActions.setSubStep(currentSubStep))
                  // currentStage = 'cotizante'
                  if (currentStage !== 'noExisteEmpleadoresNoAsistido' && currentStage !== 'contactarEjecutivo') {
                      stepperStepsDispatch(StepperStepsActions.setStage(currentStage))
                    if (currentStage !== 'inicio') {
                      if (window.location.pathname === "/firmar-documentos/done") {
                        WizardDispatch(WizardActions.setCompleteSubStep({ name: 'documentos' }))
                      } else {
                        WizardDispatch(WizardActions.setCompleteSubStep({ name: Stages[currentStage].substep }))
                        if (currentStage === 'dsFinalizada') {
                          if (JSON.parse(localStorage.getItem('isAsisted'))) {
                            history.push(Stages[currentStage]['asistida'].url)
                          } else {
                            history.push(Stages[currentStage]['noAsistida'].url)
                          }
                        } else {
                          history.push(Stages[currentStage].url)
                        }
                      }
                    }
                    if (currentStage === 'inicio') {
                      if (window.location.pathname !== '/informacion-usuario/verificacion-identidad') {
                        if (currentStage === 'dsFinalizada') {
                          if (JSON.parse(localStorage.getItem('isAsisted'))) {
                            history.push(Stages[currentStage]['asistida'].url)
                          } else {
                            history.push(Stages[currentStage]['noAsistida'].url)
                          }
                        } else {
                          history.push(Stages[currentStage].url)
                        }
                      } 
                    }
                    if (window.location.pathname === "/informacion-usuario/inicio-suscripcion") {
                      WizardDispatch(WizardActions.setCompleteSubStep({ name: 'inicio' }))
                    }
                    WizardDispatch(WizardActions.refreshProgress())
                  } else {
                    history.push('/')
                  }
                  
                } 
              }).catch(() => {})
            }
          }).catch(() => {})
        }
  }, [WizardActions, WizardDispatch, history, userInformation, StepperStepsActions, stepperStepsDispatch,isAsisted])

  return (
    <ManagerRouteContext.Provider>
      {children}
    </ManagerRouteContext.Provider>
  )
}

ManagerRouteContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}
export default ManagerRouteContextProvider