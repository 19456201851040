import "./health-statement-questionnaire.scss";
import React, { useContext, useState } from "react";
import PropTypes from "prop-types";
import { WizardContext } from "@commons/steppers/wizard";
import Separator from "@commons/separator/separator";
import HealthStatementQuestion from "./health-statement-question";
import HealthStatementUneditable from "./heath-statement-uneditable";

const HealthStatementQuestionnaire = ({ question, index, indexBall }) => {
  const { wizardData } = useContext(WizardContext);
  const [openQuestion, setOpenQuestion] = useState(false);
  const { editable } = question;
  const handleClickQuestion = (num) => {
    if (
      num <= wizardData.nextQuestionToAnswer ||
      wizardData.nextQuestionToAnswer === 0
    )
      setOpenQuestion(!openQuestion);
  };

  return (
    <div key={question.number} className={`health-questionnaire__container ${question.editable === false && "d-none"}`.trim()}>
      <div className="col-12" key={question.number}>
        <div
          className="health-questionnaire__title-box"
          onClick={() => handleClickQuestion(question.number)}
          role="button"
          onKeyPress={() => handleClickQuestion(question.number)}
          tabIndex="0"
        >
          <div
            className={`health-questionnaire__number ${(question.number > wizardData.nextQuestionToAnswer && question.answer === null) &&
              "health-questionnaire__number--disabled"
              }`.trim()}
          >
            {indexBall}
          </div>
          <div
            className={`health-questionnaire__title ${question.number > wizardData.nextQuestionToAnswer &&
              "health-questionnaire__title--disabled"
              } ${wizardData.answered.includes(question.number) &&
              "health-questionnaire__title--answered"
              }`.trim()}
          >
            <span>{question.question}</span>
          </div>
          <Separator />
          <div
            className={`health-questionnaire__control ${question.number > wizardData.nextQuestionToAnswer &&
              "health-questionnaire__control--disabled"
              }`.trim()}
          >
            <svg
              height="20"
              width="20"
              viewBox="0 0 20 20"
              aria-hidden="true"
              focusable="false"
              className={`health-questionnaire__svg ${question.number > wizardData.nextQuestionToAnswer &&
                "health-questionnaire__svg--disabled"
                } ${(openQuestion ||
                  wizardData.nextQuestionToAnswer === question.number) &&
                "health-questionnaire__svg--rotate"
                }`.trim()}
            >
              <path d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z" />
            </svg>
          </div>
          {wizardData.answered.includes(question.number) ? (
            <div
              className={`health-questionnaire__check ${wizardData.questions[question.number - 1].answer === true &&
                "health-questionnaire__check--yes"
                }`}
            >
              <em className="icon-check" />
            </div>
          ) : null}
        </div>
      </div>

      {editable ? (
        <HealthStatementQuestion
          num={question.number}
          question={question.question}
          example={question.example}
          openQuestion={openQuestion}
          isSummary={false}
          isEditing={false}
          setOpenQuestion={setOpenQuestion}
        />
      ) : (
        <HealthStatementUneditable
          setOpenQuestion={setOpenQuestion}
          openQuestion={openQuestion}
          num={question.number}
          isSummary={false}
        />
      )}
    </div>
  );
};

HealthStatementQuestionnaire.propTypes = {
  question: PropTypes.shape({
    number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    question: PropTypes.string,
    example: PropTypes.string,
    answer: PropTypes.bool,
    diagnostics: PropTypes.array,
  }).isRequired,
};

export default HealthStatementQuestionnaire;
