import React, { useState, useContext, useEffect, useCallback } from 'react'
import DocumentList from '@components/sign-documents/elements/documents-list'
import {
  SignDocumentsContext,
  SignDocumentsService,
} from '@components/sign-documents'
import { StepperStepsContext } from '@commons/steppers/steps'
import { WizardContext } from '@commons/steppers/wizard'
import Button from '@commons/buttons/button'
import Checkbox from '@commons/inputs/checkbox'
import Sign from '@commons/sign/sign'
import SpinnerLoading from '@commons/loaders/spinner-loader'
import ErrorMessage from '@commons/messages/error/error-message'
import InfoMessage from '@commons/messages/info/info-message'

const SignDocuments = () => {
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [read, setRead] = useState(false)
  const [showSign, setShowSign] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingCustom, setLoadingCustom] = useState(false)
  const [doContinue, setDoContinue] = useState(false)
  const [signReady, setSignReady] = useState(true)
  const [showSaveError, setShowSaveError] = useState(false)
  const [showGetError, setShowGetError] = useState(false)
  const [tooglePin, setTooglePin] = useState(false)
  const [showInfoMessage, setShowInfoMessage] = useState(false)
  const [showInfoMessageCompensado, setShowInfoMessageCompensado] = useState(false)
  const [mensajeCompensado, setMensajeCompensado] = useState('')
  const {
    wizard,
    signDocumentsData,
    SignDocumentsDispatch,
    SignDocumentsActions,
  } = useContext(SignDocumentsContext)

  /**
   * @description handle onClick for next step
   */
  function handleNextStep() {
    WizardDispatch(WizardActions.setCompleteSubStep({ name: 'documentos' }))
    wizard.next()
  }

  /**
   * @description Validate PIN & Save data
   * @param {string} pin Secure password
   */
  function handlePinValidation(pin) {
    setLoadingCustom(true)
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      pin,
    }

    const bodyValidate = {
      "identificador": stepperStepsData.userInformation.identificador
    }

    let listCompensados = []

    SignDocumentsService.validateCompensated(bodyValidate).then((responseValidate) => {
      listCompensados = responseValidate.data.lista
      if (responseValidate.httpCode === 200 && responseValidate.data.esCompensado) {
        SignDocumentsService.signDocumentsCompensated(body)
        .then((response) => {
          if (response.httpCode === 200) {
            /*setSignReady(true)
            WizardDispatch(
              WizardActions.setPatPacSign(response.data.firmarMandatoPacPat)
            )
            handleNextStep()*/
            
            if(response.data === 1){
              setShowInfoMessageCompensado(true)
              setMensajeCompensado("El documento quedará a la espera de la firma del otro compensado.")
            } else if(response.data === 2) {
              setShowInfoMessageCompensado(true)
              setMensajeCompensado("Ya solicitó la firma del documento, está pendiente a ejecutar al firmar su otro compensado.")   
            } else if(response.data === 4){
              setShowInfoMessageCompensado(true)
              setMensajeCompensado("El identificador no corresponde a un compensado.")
              //usuario esta listo 
            } else if(response.data === 0 || response.data === 3){
              setSignReady(true)
              WizardDispatch(
                WizardActions.setPatPacSign(response.data.firmarMandatoPacPat)
              )
              handleNextStep()
            }
          } else {
            setShowSaveError(true)
            setLoadingCustom(false)
          }
        })
        .catch(() => {
          setShowSaveError(true)
          setLoadingCustom(false)
        })
      } else {
        SignDocumentsService.signDocuments(body)
        .then((response) => {
          if (response.httpCode === 200) {
            setSignReady(true)
            WizardDispatch(
              WizardActions.setPatPacSign(response.data.firmarMandatoPacPat)
            )
            handleNextStep()
          } else {
            setShowSaveError(true)
            setLoadingCustom(false)
          }
        })
        .catch(() => {
          setShowSaveError(true)
          setLoadingCustom(false)
        })
      }
    })
    .catch(() => {
      setShowSaveError(true)
      setLoadingCustom(false)
    })
  }

  /**
   * @description CheckBox handler click
   */
  function handleConfirm() {
    SignDocumentsDispatch(
      SignDocumentsActions.setReadAll(signDocumentsData.allRead)
    )
    setShowSign(true)
  }

  /**
   * @description Cancel Action handler
   */
  function cancelAction() {
    setShowSign(false)
  }

  /**
   * @description close error messages
   */
  function closeMsg() {
    setShowSaveError(false)
    setShowGetError(false)
  }

  function handleSignBtn(){
    // Do a sendStep if necesary
    setTooglePin(true)
  }

  const getDocumentsList = useCallback(() => {
    setLoading(true)
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
    }
    SignDocumentsService.getDocuments(body)
      .then((response) => {
        if (response.httpCode === 200) {
          SignDocumentsDispatch(
            SignDocumentsActions.setDocumentList(response.data)
          )
          setLoading(false)
        } else if (response.httpCode === 404) {
          setShowInfoMessage(true)
          setLoading(false)
        } else {
          setLoading(false)
          setShowGetError(true)
        }
      })
      .catch(() => {
        setLoading(false)
        setShowGetError(true)
      })
  }, [
    SignDocumentsActions,
    SignDocumentsDispatch,
    stepperStepsData.userInformation.rut,
    stepperStepsData.userInformation.identificador,
  ])

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  useEffect(() => {
    getDocumentsList()
  }, [getDocumentsList])

  return (
    <div className="sign-docs">
      <h2 className="sign-docs__title">Firma de Documentos</h2>
      <SpinnerLoading show={loading} text="Cargando documentos" />
      {!showSign && !loading && (
        <>
          {signDocumentsData.documents.length !== 0 ? <div className="sign-docs__message">{signDocumentsData.message}</div>:null}
          <div className="sign-docs__form-wrapper">
            <DocumentList data={signDocumentsData.documents} />
            {signDocumentsData.documents.length === 0 && (
              <div className="sign-docs__statement">Aún no se encuentran listos tus documentos. Te enviaremos un correo electrónico avisando cuando estén disponibles para ser firmados.</div>
            )}
          </div>
          <div className="sign-docs__confirm">
            {signDocumentsData.documents.length !== 0 && (
              <Checkbox
                name="confirm"
                text="He leído completamente todos los documentos aquí presentados"
                clickMethod={() => {
                  setDoContinue(!doContinue)
                  setRead(!read)
                }}
              />
            )}
          </div>
          <div className="sign-docs__button">
            {signDocumentsData.documents.length === 0 ? (
              <Button
                text="Reintentar"
                action={getDocumentsList}
                cssClass="button__continue"
              />
            ) : (
              <Button
                text="Continuar"
                action={handleConfirm}
                cssClass="button__continue"
                disabled={!doContinue}
              />
            )}
          </div>
        </>
      )}

      {showSign && (
        <div className="sign-docs__sign-wrapper">
          <Sign
            signBtnAction={handleSignBtn}
            onSign={handlePinValidation}
            onCancel={cancelAction}
            signButtonText="Firmar Documentos"
            pinDescription={
              <div>
                Para firmar tus <strong>Documentos</strong> y continuar al
                siguiente paso, debes ingresar la clave segura enviada
                previamente por SMS a tu celular y a tu Correo.
              </div>
            }

             isSigned={signReady}
             tooglePin={tooglePin}
             showCancel={false}
             loading={loadingCustom}
             setLoading={setLoadingCustom}
          />
        </div>
      )}
      <InfoMessage
        buttonText="Cerrar"
        isShowing={showInfoMessage}
        iconColor="orange"
        action={() => setShowInfoMessage(false)}
      >
        <div className="pin-validation__alert">
          Aún no se encuentran listos tus documentos. Te enviaremos un correo electrónico avisando cuando estén disponibles para ser firmados.
        </div>
      </InfoMessage>
      <InfoMessage
        buttonText="Cerrar"
        isShowing={showInfoMessageCompensado}
        iconColor="orange"
        action={() => setShowInfoMessageCompensado(false)}
      >
        <div className="pin-validation__alert">
          {mensajeCompensado}
        </div>
      </InfoMessage>
      <ErrorMessage isShowing={showGetError} action={closeMsg}>
        No se han podido obtener los documentos
      </ErrorMessage>
      <ErrorMessage isShowing={showSaveError} action={closeMsg}>
        No se han podido firmar los documentos
      </ErrorMessage>
    </div>
  )
}

export default SignDocuments
