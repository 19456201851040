import * as yup from 'yup'
import { format, validate } from 'rut.js'

/**
 * @description Function get rut in schema and return if is valid.
 */
const rutSchema = yup
  .string()
  .default('Ingrese valor')
  .test('is-rut', 'Rut no válido', (value) => validate(format(value)))
export default rutSchema
