/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable  consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState,useContext, useEffect } from 'react'
import { WizardContext, WizardService } from '@commons/steppers/wizard'
import { SummaryBackgroundStatementContext } from '@components/summary-background-statement'
import Button from '@commons/buttons/button'
import { StepperStepsContext, StepperStepsService } from '@commons/steppers/steps'
import Checkbox from '@commons/inputs/checkbox'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import ErrorMessage from '@commons/messages/error/error-message'
import { clean, format } from 'rut.js'
import _ from 'lodash'
import ContributorData from '@components/summary-background-statement/elements/contributor-data'
import BeneficiaryData from '@components/summary-background-statement/elements/beneficiary-data'
import moment from 'moment'
import AddScriptRut from '@utils/tools/convertRut'

const SummaryBackgroundStatement = () => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const { contributor, beneficiary } = wizardData
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [pensionValidation, setPensionValidation] = useState(true)
  const [dataValidation, setDataValidation] = useState(true)
  const [loading, setIsLoading] = useState(true)
  const [showSaveError, setShowSaveError] = useState(false)
  const [showGetError, setShowGetError] = useState(false)
  const [errorMsg, setErrorMsg] = useState('No se han podido obtener datos de antecedentes del cotizante')
  const {
    wizard,
    summaryBackgroundStatementData,
    SummaryBackgroundStatementDispatch,
    SummaryBackgroundStatementActions,
  } = useContext(SummaryBackgroundStatementContext)
  const { confirm } = summaryBackgroundStatementData

  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))
  const [showCotizante, setShowCotizante] = useState(true)
  const [check, setCheck] = useState(false)

  
  useEffect(()=>{
      const fetch = async () => {
        await fetchContributor()
        await fetchBeneficiary()
      }
      fetch().then( () => setTimeout(() => setIsLoading(false),1000))
  },[])

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  async function fetchContributor(){
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut
    }
    WizardService.getContributorBackground(body).then((response) => {
      if(response.httpCode === 200){
        const contributorAux = {
          rut: format(response.data.rut),
          names: response.data.nombres,
          paternalSurname: response.data.apellidoPaterno,
          maternalSurname: response.data.apellidoMaterno,
          email: response.data.correo,
          birthdate: response.data.fechaNacimiento,
          isapre: response.data.descripcionSalud,
          isapreId: response.data.idSalud,
          weight: String(response.data.peso),
          height: response.data.altura,
          pension: response.data.pension,
          pensionCause: response.data.causal,
          pensionDiagnostic: response.data.diagnostico
        }
        WizardDispatch(WizardActions.setContributor(contributorAux))
      }else{
        setShowGetError(true)
      } 
    }).catch(() => setShowGetError(true))
}

  async function fetchBeneficiary(){
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut
    }
    await WizardService.getBeneficiaries(body).then(async (response) => {  
      let benefList = []
      if(response.httpCode === 200){
        if(_.size(response.data)){
          benefList = response.data.map(b => ({
              id: b.id,
              names: b.nombres,
              paternalSurname: b.apellidoPaterno,
              maternalSurname: b.apellidoMaterno,
              rut: b.rutBeneficiario,
              relation: b.relacionFamiliar,
              birthdate: b.fechaNacimiento,
              sex: b.sexo,
              type: b.tipoBeneficiario,
              weight: String(b.peso),
              height: b.altura,
              order: b.orden,
              other: b.descripcionOtro,
          }))
          await WizardService.obtenerDocsCargasMedicas({identificador: stepperStepsData.userInformation.identificador}).then((res) => {
            if(res.httpCode === 200){
              if(Array.isArray(res.data)){
                benefList = benefList.map(x => {
                  let currentCargaMedica = res.data.find(y => clean(y.rut) === clean(x.rut)) || null
                  if(currentCargaMedica){
                    return {...x, ...currentCargaMedica}
                  }else{
                    return x
                  }
                })
              }
            }
          }).catch(() => setShowGetError(true))   
        }
        if((esCargaTitular) && (tieneCargas)){
          setShowCotizante(false)
        }

        WizardDispatch(WizardActions.setBeneficiary(benefList))
      }else{
        setShowGetError(true)
      }
    }).catch(() => 
      setShowGetError(true))
  }


  function handleConfirm(){
    setCheck(!check)
    SummaryBackgroundStatementDispatch(
      SummaryBackgroundStatementActions.setConfirmedBackground(!confirm)
    )
  }

  function handleNextStep() {
    if (dataValidation && pensionValidation && (confirm || check)) {
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: AddScriptRut(stepperStepsData.userInformation.rut),
        etapa: 'resumenAntecedentes',
        subetapa: '',
        fechaRegistro: moment().format('DD-MM-YYYY')
      }
      StepperStepsService.sendStep(body).then((response) => {
        if (response.httpCode === 200) {
          WizardDispatch(WizardActions.setCompleteSubStep({ name: 'resumenAntecedentes' }))
          wizard.next()
        } else {

        }
      }).catch(() => {

      })
     
    }
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowGetError(false)
    setShowSaveError(false)
  }

  return (
    
    <div className="sum-background-statement">
      <h2 className="sum-background-statement__title">Resumen Declaración de Salud</h2>
      <div className="sum-background-statement__message">
        Este es el resumen de tu información en la Declaración de Salud,
        <br/>puedes revisar y confirmar.
      </div>

      { loading ? <div className="w-100 text-center"><SpinnerLoader show={loading}/></div> 
      :
      <>
          {showCotizante ? 
          <>
            { _.size(contributor) ?
              <ContributorData contributor={contributor} />
              : null
            }
          </>:null}

        { _.size(beneficiary) ? 
          <BeneficiaryData beneficiary={beneficiary} setBeneficiary={(data)=>WizardDispatch(WizardActions.setBeneficiary(data))} />
          :null
        }
        {
          !showGetError && _.size(beneficiary) && _.size(contributor) ?
          <>
            <div className="sum-background-statement__confirm">
              <Checkbox
                name="test"
                text="Confirmo que la información de antecedentes y beneficiarios es correcta"
                clickMethod={handleConfirm}
              />
            </div>
            <div className="sum-background-statement__button">
                <Button
                text="Continuar"
                action={handleNextStep}
                disabled={ beneficiary.filter(z=>z.type === 1).length ? 
                           (beneficiary.filter(x=>x.type === 1).every(y=> y.accepted) ? (check ? false : true) : true)
                           : (check ? false : true)}
                />
            </div>
          </>
          :
            null
        }
      </>
      }
      <ErrorMessage isShowing={showGetError} action={closeMsg}>
        No se han podido obtener datos de antecedentes del cotizante y sus beneficiarios
      </ErrorMessage>
      <ErrorMessage isShowing={showSaveError} action={closeMsg}>
        {errorMsg}
      </ErrorMessage>
    </div>

  )
}

export default SummaryBackgroundStatement
