import React, { useState, useEffect, useContext, useCallback } from "react";
import { WizardContext } from "@commons/steppers/wizard";
import Button from "@commons/buttons/button";
import InputFile from "@commons/inputs/input-file";
import Tooltip from "@commons/tooltip/tooltip";
import { validateFile } from "@utils/fields-validators";
import IconButton from "@commons/buttons/icon-button";
import InfoMessage from "@commons/messages/info/info-message";
import ErrorMessage from '@commons/messages/error/error-message'
import { fileToBase64 } from '@utils/fields-validators'
import { ProcessFinalizationIsapreService } from "@components/process-finalization-isapre";
import { StepperStepsContext } from '@commons/steppers/steps'
import { ProcessFinalizationIsapreContext } from "@components/process-finalization-isapre";

const Release2 = () => {
  const { WizardDispatch, WizardActions } = useContext(WizardContext);
  const [showTooltip, setShowTooltip] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [showInvalidFileMessage, setShowInvalidFileMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState({show: false, msg: ''})
  const [loading, setLoading] = useState(false)
  const [disableButton, setDisableButton] = useState(true)
  const [fun, setFun] = useState('')
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { wizard } = useContext(ProcessFinalizationIsapreContext);

  const fetchFun = useCallback(() => {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut
    }
    ProcessFinalizationIsapreService.getFun(body)
    .then((response) => {
      if(response.httpCode === 200){
        setFun(response.data.folio)
      }
    }).catch(e => console.log(e))
  },[stepperStepsData])

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress());
  }, [WizardActions, WizardDispatch]);

  useEffect(() => {
    if (attachedFiles && attachedFiles.length !== 0) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [attachedFiles])

  useEffect(()=>{
    fetchFun()
  },[fetchFun])

  /**
   * @description Add file to attachedFiles
   * @param {int} index
   */
  function attachedFilesOnChangeHandler(e) {
    const { files } = e.target;
    const validFiles = Object.keys(files).filter((item) =>
      validateFile(files[item])
    );
    const invalidFiles = Object.keys(files).filter(
      (item) => !validateFile(files[item])
    );
    setShowInvalidFileMessage(invalidFiles.length > 0)

    const allFiles = [
      ...attachedFiles,
      ...validFiles.map((item) => {
        files[item].new = true
        return files[item]
      }),
    ];
    setAttachedFiles(allFiles);
  }

  /**
   * @description Remove an item from FilesList
   * @param {int} index
   */
  function removeFileFromList(index) {
    const auxList = attachedFiles.filter((item, i) => i !== index);
    setAttachedFiles(auxList);
  }

  /**
   * @description Send the attached documents and advance to next screen
   * @param {int} index
   */
  async function handleSendDocuments() {
    setLoading(true)
    const archives = await fileToBase64(attachedFiles)
    let filesId = []

    await archives.map(async (file) => {
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        nombreArchivo: file.nombreArchivo,
        binario: file.binario,
        tipoMIME: file.tipoMIME,
        tipoAdjunto: 1
      }  
      const result = await ProcessFinalizationIsapreService.sendDisaffiliationDocuments(body).then((response) => {
        if (response.httpCode === 200) {
          const file = { id:response.data.adj_Correl.toString(), nombre: response.data.nombreArchivo }
          filesId.push(file)
        } else {
          return setShowErrorMessage({show:true, msg:`El archivo ${body.nombreArchivo} ha fallado en enviarse`})
        }
      }).catch((e) => {
        setShowErrorMessage({show:true, msg:`El archivo ${body.nombreArchivo} ha fallado en enviarse`})
      })

      if(filesId.length >= 1){
        const body = {
          identificador: stepperStepsData.userInformation.identificador,
          rut: stepperStepsData.userInformation.rut,
          folio: fun,
          archivos: filesId
        }
        ProcessFinalizationIsapreService.disaffiliate(body).then((response) => {
          if (response.httpCode === 200) {
            WizardDispatch(WizardActions.setCompleteSubStep({ name: 'desafiliacion' }))
            wizard.next()
          } else {
            setLoading(false)
            setShowErrorMessage({show: true, msg:'No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde'})
          }
        }).catch(() => {
          setLoading(false)
          setShowErrorMessage({show: true, msg:'No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde'})
        })
      }

      return result
    })
  }

  return (
    <>
      <div className="process-fin-isapre__text">
        <p>
          Antes de finalizar el Proceso de Suscripción <br/>
          Electrónica debes adjuntar la <span className="process-fin-isapre__highlight">Carta de Desafiliación</span><br/>
          firmada y autorizada por tu Isapre actual, además de <br/>
          tu cédula de identidad por ambos lados.
        </p>
        <p>
          Este es el Folio de tu <span className="process-fin-isapre__highlight">FUN</span> para que puedas gestionar<br/>
          la Carta de Desafiliación:
        </p>
        <p className="process-fin-isapre__fun-number">
          {fun}
        </p>
      </div>

      <div className="process-fin-isapre__files-wrapper">
        <div className="process-fin-isapre__background">
          <div className="process-fin-isapre__antecedent">• Carta de Desafiliación Isapre Actual</div>
          <div className="process-fin-isapre__antecedent">• Cédula de Identidad Anverso</div>
          <div className="process-fin-isapre__antecedent">• Cédula de Identidad Reverso</div>
        </div>
        <div className="process-fin-isapre__attachment">
          <div className="attachment__list">
            {attachedFiles.map((item, i) => (
              <div  key={i} className="attachment__list-item">
                <em className="icon-archivo" />
                <span>{item.name.split(".")[0]}</span>
                <IconButton
                  text=""
                  icon="eliminar"
                  action={() => removeFileFromList(i)}
                />
              </div>
            ))}
          </div>
          <div className="attachment__actions">
            <InputFile
              onChange={(e) => attachedFilesOnChangeHandler(e)}
              mimetypes={[".jpg", ".jpeg", "application/pdf"]}
              value={attachedFiles && attachedFiles.map((item) => item).join(",")}
            />
            <div className="attachment__tooltip">
              <Tooltip visible={showTooltip} align="left">
                El formato debe ser JPG, GIF o PDF, con un tamaño máximo de 2MB.
              </Tooltip>
              <em
                className="icon-info-3"
                onMouseOver={() => setShowTooltip(true)}
                onMouseOut={() => setShowTooltip(false)}
                onBlur={() => setShowTooltip(false)}
                onFocus={() => setShowTooltip(true)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="process-fin-isapre__buttons">
        <Button
          text="Enviar Documentos"
          action={handleSendDocuments}
          disabled={loading || disableButton}
        />
      </div>

      <ErrorMessage isShowing={showErrorMessage.show} action={() => setShowErrorMessage({show: false, msg: ''})}>
        {showErrorMessage.msg}
      </ErrorMessage>
      <InfoMessage
            buttonText="Volver a intentar"
            action={() => setShowInvalidFileMessage(false)}
            isShowing={showInvalidFileMessage}
          >
            Uno o más de los archivos adjuntados tienen un formato inválido, los
            formatos permitidos son JPG, GIF o PDF, con un tamaño máximo de 2MB.
      </InfoMessage>
    </>
  );
};

export default Release2;
