import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
// import SummaryBackgroundStatementService from './summary-background-statement-service'
import SummaryBackgroundStatementReducer from './summary-background-statement-reducer'
import * as SummaryBackgroundStatementActions from './summary-background-statement-actions'

export const SummaryBackgroundStatementContext = React.createContext()

export const SummaryBackgroundStatementContextProvider = ({ children, wizard }) => {
  const [
    summaryBackgroundStatementData,
    SummaryBackgroundStatementDispatch,
  ] = useReducer(
    SummaryBackgroundStatementReducer, 
    {
      loading:false,
      confirm: false,
    }
  )

  return (
    <SummaryBackgroundStatementContext.Provider
      value={{
        wizard,
        summaryBackgroundStatementData,
        SummaryBackgroundStatementDispatch,
        SummaryBackgroundStatementActions,
      }}
    >
      {children}
    </SummaryBackgroundStatementContext.Provider>
  )
}

SummaryBackgroundStatementContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
