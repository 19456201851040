import * as yup from 'yup'

/**
 * @description Function get surnames in schema and return if is valid.
 */
const surnamesSchema = yup
  .string()
  .required('Debe ingresar sus apellidos')
  .test(
    'two-surnames',
    'Debes ingresar al menos dos apellidos',
    value => (value.toString()).match(/(\w.+\s).+/),
  )
export default surnamesSchema


