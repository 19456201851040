import React from 'react';

import './call-center-message-reject.scss'

import MessageManager from '@commons/message-manager/elements/message-manager'

const CallCenterMessageReject = () => {
    
    const handleRedirectPage = () => {
        window.location = 'https://www.nuevamasvida.cl/'
    }

    return (
        <MessageManager
            action={() => handleRedirectPage()}
            messages="No se ha podido validar tu identidad."
            textButton="Ir a Nueva Masvida"
            isShowing={true}
            iconColor="orange"
        />
    );
};

export default CallCenterMessageReject;