import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import WizardReducer from './wizard-reducer'
import * as WizardActions from './wizard-actions'

export const WizardContext = React.createContext()

export const WizardContextProvider = ({ children, wizard }) => {
  const [wizardData, WizardDispatch] = useReducer(WizardReducer, {
    progress: 0,
    contributor: null,
    beneficiary: [],
    questions: [],
    backupQuestions: [],
    answered: [],
    nextQuestionToAnswer: 1,
    diagnosticsQuestion: [],
    steps: [],
    mixed: [],
    signPatPac: null,
    status: 'respond',
    actualStep: '',
    backgroudContractualDocuments: null,
    regions: [],
    cities: [],
    communes: [],
    afps: [],
    urlIsapre: '',
    authenticationPage: '',
    subStep: null,
    isTimeOut: false,
  })


  return (
    <WizardContext.Provider
      value={{
        wizard,
        wizardData,
        WizardDispatch,
        WizardActions,
      }}
    >
      {children}
    </WizardContext.Provider>
  )
}

WizardContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}