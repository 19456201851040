import {
  SET_COMPLETED_STEP,
  LOADING_DATA,
  SET_CONFIRMED_BACKGROUND,
} from './summary-background-statement-actions'

export default function SummaryBackgorundStatementReducer(state, action) {
  switch (action.type) {
    case SET_COMPLETED_STEP:
      return {
        ...state,
        completedStep: action.flag,
      }
    case LOADING_DATA:
      return {
        ...state,
        loading: action.flag,
      }
    case SET_CONFIRMED_BACKGROUND:
      return {
        ...state,
        confirm: action.flag,
      }
    default:
      return state
  }
}
