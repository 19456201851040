import React, { useState, useEffect, useContext } from 'react';

import './call-center-auth.scss';
import { format } from 'rut.js'
import moment from 'moment'

// Context
import { ValidateIdentityContext, ValidateIdentityService } from "@components/validate-identity/";
import { StepperStepsContext, StepperStepsService } from '@commons/steppers/steps'
import { WizardContext } from '@commons/steppers/wizard'

// Components

import Button from '@commons/buttons/button';
import Tooltip from "@commons/tooltip/tooltip";
import InputFile from "@commons/inputs/input-file";
import IconButton from "@commons/buttons/icon-button";
import SpinnerLoader from '@commons/loaders/spinner-loader';
import InfoMessage from "@commons/messages/info/info-message";
import ErrorMessage from '@commons/messages/error/error-message';

// Utils
import { validateFile } from "@utils/fields-validators";
import { fileToBase64 } from '@utils/fields-validators'

const CallCenterAuth = () => {

    const _raw = require('./../../../claveunica.txt')
    const [claveUnica, setClaveUnica] = useState(true)
    
    const { wizard, ValidateIdentityDispatch, ValidateIdentityActions } = useContext(ValidateIdentityContext)

    // Contexts
    const { stepperStepsData } = useContext(StepperStepsContext)
    const { userInformation: { correo, celular, identificador, rut } } = stepperStepsData;
    const { WizardDispatch, WizardActions } = useContext(WizardContext)

    // Variables locales
    const [loading, setLoading] = useState(false)
    const [showTooltipAFP, setShowTooltipAFP] = useState(false);
    const [showTooltipIdentify, setShowTooltipIdentify] = useState(false);
    const [showTooltipPhoto, setShowTooltipPhoto] = useState(false);
    const [attachedFilesAFP, setAttachedFilesAFP] = useState([]);
    const [attachedFilesIdentify, setAttachedFilesIdentify] = useState([]);
    const [attachedFilesPhoto, setAttachedFilesPhoto] = useState([]);
    const [showExceedFilesMessage, setShowExceedFilesMessage] = useState(false)

    const [disabledButton, setDisabledButton] = useState(true);
    const [showError, setShowError] = useState(false)
    const [showInvalidFileMessage, setShowInvalidFileMessage] = useState(false)

    useEffect(() => {

        fetch(_raw)
            .then((resp) => resp.text())
            .then(data => {
                setClaveUnica(data === 'true' ? true : false)
            })

    }, [claveUnica])

    useEffect(() => {

        if (claveUnica) {

            ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(10))

        } else {

            ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(9))

        }

        // if (attachedFilesAFP.length === 1 && attachedFilesIdentify.length === 1 && attachedFilesPhoto.length === 1 && !loading) {
        //     setDisabledButton(false)
        // } else if (attachedFilesAFP.length === 1 && !loading && claveUnica) {
        //     setDisabledButton(false)
        // } else {
        //     setDisabledButton(true)
        // }

        if (attachedFilesIdentify.length === 1 && attachedFilesPhoto.length === 1 && !loading) {
            setDisabledButton(false)
        // } else if (attachedFilesAFP.length === 1 && !loading && claveUnica) {
        //     setDisabledButton(false)
        } else {
            setDisabledButton(true)
        }

    }, [attachedFilesAFP, attachedFilesIdentify, attachedFilesPhoto, loading])


    // Functions

    const handlerTooltipAFPOnHover = (flag) => {
        setShowTooltipAFP(flag);
    }

    const handlerTooltipIdentifyOnHover = (flag) => {
        setShowTooltipIdentify(flag);
    }

    const handlerTooltipPhotoOnHover = (flag) => {
        setShowTooltipPhoto(flag);
    }

    const attachedFilesAFPOnChangeHandler = (e) => {

        const { files } = e.target;

        if (files.length !== 1) {
            setShowExceedFilesMessage(true)
            return false;
        }

        const validFiles = Object.keys(files).filter((item) => validateFile(files[item]));
        const invalidFiles = Object.keys(files).filter((item) => !validateFile(files[item]));

        setShowInvalidFileMessage(invalidFiles.length > 0)

        const entityFiles = [
            ...attachedFilesAFP,
            ...validFiles.map((item) => {
                files[item].new = true
                return files[item]
            }).slice(0, 10),
        ];

        setAttachedFilesAFP(entityFiles);
    }

    const removeFileAFPFromList = (index) => {
        const auxList = attachedFilesAFP.filter((item, i) => i !== index);
        setAttachedFilesAFP(auxList);
    }

    const attachedFilesIdentifyOnChangeHandler = (e) => {

        const { files } = e.target;

        if (files.length !== 1) {
            setShowExceedFilesMessage(true)
            return false;
        }

        const validFiles = Object.keys(files).filter((item) => validateFile(files[item]));
        const invalidFiles = Object.keys(files).filter((item) => !validateFile(files[item]));

        setShowInvalidFileMessage(invalidFiles.length > 0)

        const entityFiles = [
            ...attachedFilesIdentify,
            ...validFiles.map((item) => {
                files[item].new = true
                return files[item]
            }).slice(0, 10),
        ];

        setAttachedFilesIdentify(entityFiles);
    }

    const removeFileIdentifyFromList = (index) => {
        const auxList = attachedFilesIdentify.filter((item, i) => i !== index);
        setAttachedFilesIdentify(auxList);
    }

    const attachedFilesPhotoOnChangeHandler = (e) => {

        const { files } = e.target;

        if (files.length !== 1) {
            setShowExceedFilesMessage(true)
            return false;
        }

        const validFiles = Object.keys(files).filter((item) => validateFile(files[item]));
        const invalidFiles = Object.keys(files).filter((item) => !validateFile(files[item]));

        setShowInvalidFileMessage(invalidFiles.length > 0)

        const entityFiles = [
            ...attachedFilesPhoto,
            ...validFiles.map((item) => {
                files[item].new = true
                return files[item]
            }).slice(0, 10),
        ];

        setAttachedFilesPhoto(entityFiles);
    }

    const removeFilePhotoFromList = (index) => {
        const auxList = attachedFilesPhoto.filter((item, i) => i !== index);
        setAttachedFilesPhoto(auxList);
    }

    const handleSubmitForm = async () => {
        setLoading(true)

        // if (claveUnica) {

        //     const archivo = await fileToBase64([...attachedFilesAFP])
        //     const archivoEnviado = await archivo.map(async (file) => {

        //         const body = {
        //             identificador,
        //             rut,
        //             nombreArchivo: file.nombreArchivo,
        //             binario: file.binario,
        //             tipoMIME: file.tipoMIME,
        //             tipoAdjunto: 40,
        //         }

        //         const result = await ValidateIdentityService.saveDocument(body).then((response) => {
        //             if (response.httpCode === 200) {
        //                 return true;
        //             } else {
        //                 return false;
        //             }

        //         }).catch(() => {
        //             return false;
        //         })

        //         return result
        //     })

        //     const archivoRespuesta = await Promise.all(archivoEnviado)

        //     let archivoSuccess = true
        //     archivoRespuesta.map((archivo) => {
        //         if (!archivo) {
        //             archivoSuccess = false
        //         }
        //     })

        //     // Si el archivo carga exitosamente pasamos al servicio de clave ùnica
        //     if (archivoSuccess) {

        //         try {
        //             const body = {
        //                 "rut": format(rut).split('.').join('').trim(),
        //                 "idFlujo": process.env.REACT_APP_API_ID_FLUJO, // prod 5, qa 8, local 9
        //                 "idExterno": identificador
        //             }

        //             const authClaveUnica = await ValidateIdentityService.authClaveUnica(body)

        //             if (authClaveUnica.httpCode === 200 && authClaveUnica.data.CodError === 0 && cluniCount>0) {
                       
        //                 setCluniCount(cluniCount-1);

        //                 const popupTick = window.open(authClaveUnica.data.Valor.UrlRedirigir, 'Clave Unica')

        //                 const timer = setInterval(() => {

        //                     if (popupTick.closed) {

        //                         clearInterval(timer)

        //                         const bodyEstado = {
        //                             "idTrxClaveUnica": authClaveUnica.data.Valor.trxClaveUnica
        //                         }

        //                         ValidateIdentityService.estadoLogin(bodyEstado).then((response) => {

        //                             if (response.data.Valor.EstadoLogin === 'OK') {

        //                                 const bodyStep = {
        //                                     identificador,
        //                                     rut,
        //                                     etapa: 'validacion',
        //                                     subetapa: '',
        //                                     fechaRegistro: moment().format('DD-MM-YYYY')
        //                                 }

        //                                 return StepperStepsService.sendStep(bodyStep).then((response) => {

        //                                     if (response.httpCode === 200) {
        //                                         WizardDispatch(WizardActions.setCompleteSubStep({ name: 'validacion' }))
        //                                         wizard.push('declaracion-renta')

        //                                     } else {

        //                                         throw new Error('Error al invocar el servicio sendStep');
                                                
        //                                     }
        //                                 }).catch(() => {
        //                                     setShowError({ show: true, msg: 'No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.' })
        //                                     if (!showError) {
        //                                         window.location.assign("https://www.nuevamasvida.cl/")
        //                                     }
        //                                     setLoading(false)
        //                                   })

        //                             } else {

        //                                 throw new Error('Error al invocar el servicio estadoLogin');

        //                             }

        //                         }).catch(() => {
        //                             setShowError({ show: true, msg: 'No se ha podido validar el inicio de sesion, vuelve a intentarlo más tarde.' })
        //                             if (!showError) {
        //                                 window.location.assign("https://www.nuevamasvida.cl/")
        //                             }
        //                             setLoading(false)
        //                         })

        //                     }

        //                 }, 500)


        //             } else {

        //                 setShowError({ show: true, msg: 'No se han podido enviar los documentos. Inténtalo nuevamente.' })
        //                 setLoading(false)

        //             }

        //         } catch (error) {

        //             setShowError({ show: true, msg: 'No se han podido autenticar. Inténtalo nuevamente.' })
        //             if (!showError) {
        //                 window.location.assign("https://www.nuevamasvida.cl/")
        //             }

        //             setLoading(false)
        //         }

        //     } else {

        //         setShowError({ show: true, msg: 'No se han podido enviar los documentos. Inténtalo nuevamente.' })
        //         if (!showError) {
        //             window.location.assign("https://www.nuevamasvida.cl/")
        //         }

        //         setLoading(false)
        //     }

        // } else {
            //const archives = await fileToBase64([...attachedFilesAFP, ...attachedFilesIdentify, ...attachedFilesPhoto])
            const archives = await fileToBase64([ ...attachedFilesIdentify, ...attachedFilesPhoto])
            const archivesSent = await archives.map(async (file) => {

                const body = {
                    identificador,
                    rut,
                    nombreArchivo: file.nombreArchivo,
                    binario: file.binario,
                    tipoMIME: file.tipoMIME,
                    tipoAdjunto: 40,
                }

                const result = await ValidateIdentityService.saveDocument(body).then((response) => {

                    if (response.httpCode === 200) {
                        return true;
                    } else {
                        return false;
                    }

                }).catch(() => {
                    return false;
                })

                return result;
            })

            const archivesResponse = await Promise.all(archivesSent)

            let archivesSuccess = true
            archivesResponse.map((archive) => {
                if (!archive) {
                    archivesSuccess = false
                }
            })

            if (archivesSuccess) {

                const body = {
                    identificador,
                }

                ValidateIdentityService.authCallCenter(body).then((response) => {
                    if (response.httpCode === 200) {

                        WizardDispatch(WizardActions.setAuthenticationPage('callcenter-message'))
                        ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(5))

                    } else {
                        throw new Error();
                    }
                }).catch(() => {
                    setShowError({ show: true, msg: 'No se han podido autenticar. Inténtalo nuevamente.' })
                    setLoading(false)
                })

            } else {
                setShowError({ show: true, msg: 'No se han podido enviar los documentos. Inténtalo nuevamente.' })
                setLoading(false)
            }


      //  }
    }

    return (
        <div className="call-center-auth">

            <div className="call-center-auth__email">

                <div className="call-center-auth__label">
                    Correo Electrónico
                </div>

                <div className="call-center-auth__data">
                    {correo}
                </div>

            </div>

            <div className="call-center-auth__contact">

                <div className="call-center-auth__label">
                    Número de Contacto
                </div>

                <div className="call-center-auth__data">
                    {celular}
                </div>

            </div>

            {/* <div className="call-center-auth__section-attachment">

                <div className="call-center-auth__attachment">

                    <div className="attachment__list">
                        {attachedFilesAFP.map((item, i) => (
                            <div key={i} className="attachment__list-item">
                                <em className="icon-archivo" />
                                <span>{item.name.split(".")[0]}</span>
                                <IconButton
                                    text=""
                                    icon="eliminar"
                                    action={() => removeFileAFPFromList(i)}
                                />
                            </div>
                        ))}
                    </div>

                    <div className="attachment__actions">

                        <InputFile
                            onChange={(e) => attachedFilesAFPOnChangeHandler(e)}
                            mimetypes={[".jpg", ".jpeg", "application/pdf"]}
                            textInputFile="Adjuntar 24 Cotizaciones AFP"
                            value={attachedFilesAFP && attachedFilesAFP.map((item) => item).join(",")}

                        />

                        <div className="attachment__tooltip">

                            <Tooltip visible={showTooltipAFP} align="left">
                                Debes adjuntar un único documento con las 24 cotizaciones de tu AFP. El formato debe ser JPG, GIF o PDF, con un tamaño máximo de 2MB.
                            </Tooltip>

                            <em
                                className="icon-info-3"
                                onMouseOver={() => handlerTooltipAFPOnHover(true)}
                                onMouseOut={() => handlerTooltipAFPOnHover(false)}
                                onBlur={() => handlerTooltipAFPOnHover(false)}
                                onFocus={() => handlerTooltipAFPOnHover(true)}
                            />

                        </div>

                    </div>

                </div>
            </div> */}
            {!claveUnica && (
                <div className="call-center-auth__section-attachment">

                    <div className="call-center-auth__attachment">

                        <div className="attachment__list">
                            {attachedFilesIdentify.map((item, i) => (
                                <div key={i} className="attachment__list-item">
                                    <em className="icon-archivo" />
                                    <span>{item.name.split(".")[0]}</span>
                                    <IconButton
                                        text=""
                                        icon="eliminar"
                                        action={() => removeFileIdentifyFromList(i)}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="attachment__actions">

                            <InputFile
                                onChange={(e) => attachedFilesIdentifyOnChangeHandler(e)}
                                mimetypes={[".jpg", ".jpeg", "application/pdf"]}
                                textInputFile="Adjuntar Foto Cédula Identidad"
                                value={attachedFilesIdentify && attachedFilesIdentify.map((item) => item).join(",")}
                            />

                            <div className="attachment__tooltip">

                                <Tooltip visible={showTooltipIdentify} align="left">
                                    Debes adjuntar un único documento con la cédula de identidad por ambos lados. El formato debe ser JPG, GIF o PDF, con un tamaño máximo de 2MB.
                                </Tooltip>

                                <em
                                    className="icon-info-3"
                                    onMouseOver={() => handlerTooltipIdentifyOnHover(true)}
                                    onMouseOut={() => handlerTooltipIdentifyOnHover(false)}
                                    onBlur={() => handlerTooltipIdentifyOnHover(false)}
                                    onFocus={() => handlerTooltipIdentifyOnHover(true)}
                                />

                            </div>

                        </div>

                    </div>
                </div>
            )}
            {!claveUnica && (
                <div className="call-center-auth__section-attachment">

                    <div className="call-center-auth__attachment">

                        <div className="attachment__list">
                            {attachedFilesPhoto.map((item, i) => (
                                <div key={i} className="attachment__list-item">
                                    <em className="icon-archivo" />
                                    <span>{item.name.split(".")[0]}</span>
                                    <IconButton
                                        text=""
                                        icon="eliminar"
                                        action={() => removeFilePhotoFromList(i)}
                                    />
                                </div>
                            ))}
                        </div>

                        <div className="attachment__actions">

                            <InputFile
                                onChange={(e) => attachedFilesPhotoOnChangeHandler(e)}
                                mimetypes={[".jpg", ".jpeg", "application/pdf"]}
                                textInputFile="Adjuntar Foto de Persona + Cédula Identidad en Mano"
                                value={attachedFilesPhoto && attachedFilesPhoto.map((item) => item).join(",")}
                            />

                            <div className="attachment__tooltip">

                                <Tooltip visible={showTooltipPhoto} align="left">
                                    Debes adjuntar una foto tuya sosteniendo en mano la cédula de identidad. El formato debe ser JPG, GIF o PDF, con un tamaño máximo de 2MB.
                                </Tooltip>

                                <em
                                    className="icon-info-3"
                                    onMouseOver={() => handlerTooltipPhotoOnHover(true)}
                                    onMouseOut={() => handlerTooltipPhotoOnHover(false)}
                                    onBlur={() => handlerTooltipPhotoOnHover(false)}
                                    onFocus={() => handlerTooltipPhotoOnHover(true)}
                                />

                            </div>

                        </div>

                    </div>

                </div>
            )}

            <div className="call-center-auth__button">
                <Button disabled={disabledButton} action={handleSubmitForm} text="Enviar"></Button>
            </div>

            <div className="call-center-auth__loading">
                <SpinnerLoader text="Enviando documentos" show={loading} />
            </div>

            <InfoMessage
                buttonText="Volver a intentar"
                action={() => setShowInvalidFileMessage(false)}
                isShowing={showInvalidFileMessage}
            >
                Uno o más de los archivos adjuntados tienen un formato inválido, los
                formatos permitidos son JPG, GIF o PDF, con un tamaño máximo de 2MB.
            </InfoMessage>

            <InfoMessage
                buttonText="Volver a intentar"
                action={() => setShowExceedFilesMessage(false)}
                isShowing={showExceedFilesMessage}
            >
                Solo se puede subir un archivo por campo. Inténtalo nuevamente.
            </InfoMessage>

            <ErrorMessage isShowing={showError.show} action={() => setShowError({ show: false, msg: '' })}>
                {showError.msg}
            </ErrorMessage>
        </div>
    );
};

export default CallCenterAuth;