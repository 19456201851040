import {
  SET_VALID_USER,
  SET_QUESTIONNAIRE,
  LOADING_DATA,
  SET_URL_ISAPRE,
  SET_STATE_AUTHENTICATION,
  SET_NEXT_STEP_MESSAGE,
} from './validate-identity-actions'

export default function validateQuestionReducer(state, action) {
  switch (action.type) {
    case SET_VALID_USER:
      return {
        ...state,
        isapresList: action.collection,
      }
    case SET_QUESTIONNAIRE:
      return {
        ...state,
        questions: action.questionnaire.question.length > 0 ? action.questionnaire.question.slice(0, 3): action.questionnaire.question,
        interactiveQueryId: action.questionnaire.interactiveQueryId,
        transactionKey: action.questionnaire.transactionKey,
      }
    case LOADING_DATA:
      return {
        ...state,
        loading: action.flag,
      }
    case SET_URL_ISAPRE:
      return {
        ...state,
        urlIsapre: action.flag,
      }
    case SET_STATE_AUTHENTICATION:
      return {
        ...state,
        isOkAuthentication: action.stateAuth,
      }
    case SET_NEXT_STEP_MESSAGE:
      return {
        ...state,
        message: state.messages[action.currentMessage],
      }
    default:
      return state
  }
}
