import './benef-ident-unalterable.scss'
import React, { useState, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import Separator from '@commons/separator/separator'
import MessageDeclaration from '@commons/message-manager/elements/message-declaration'
import Tooltip from '@commons/tooltip/tooltip'
import { format } from 'rut.js'
import { getAge } from '@utils/fields-validators'
import { ErrorMessage } from 'react-hook-form'
import { StepperStepsContext } from '@commons/steppers/steps'
import ErrorMsg from '@commons/messages/error/error-message'
import Select from 'react-select'
import origin from '@utils/origin-invitation'

const BenefIdent = ({index, children, editable, reset,register,errors,validate,}) => {
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [benefData, setBenefData] = useState(children)
  const [selectedType, setSelectedType] = useState(benefData.type)
  const [showTooltip, setShowTooltip] = useState(false)
  const [showDeclaration, setShowDeclaration] = useState({show: false, msg: ''})
  const [showErrorMsg, setShowErrorMsg] = useState({show: false, msg: ''})
  const isTitular = benefData.type === 0 && benefData.id === 0 && benefData.order === 0  ? true : false
  const [selectedRelation, setSelectedRelation] = useState(benefData.relation)

  const { originInvitation } = stepperStepsData

  const relationValues = [
    { label: 'Cónyuge', value: 1 },
    { label: 'Padre', value: 2 },
    { label: 'Madre', value: 3 },
    { label: 'Hijos', value: 4 },
    { label: 'Otro', value: 5 },
    { label: 'Conviviente Civil', value: 6 },
  ]
  const typeValues = [
    // { label: 'Titular', value: 0 },
    { label: 'Carga Legal', value: 2 },
    { label: 'Carga Médica', value: 1 },
  ]
  const sexValues = [
    { label: 'Masculino', value: 1 },
    { label: 'Femenino', value: 2 },
  ]

  useEffect(() => {
    checkDeclarationStatus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[benefData.relation])

  useEffect(() => {
    validate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[benefData])

  useEffect(() => {
    if (reset) setBenefData(children)
  }, [children, reset])


  function namesOnChangeHandler(e) {
    setBenefData({ ...benefData, names: e.target.value })
  }

  function paternalSurnameOnChangeHandler(e) {
    var valueTemp = e.target.value.replace('  ',' ')
    setBenefData({ ...benefData, paternalSurname: valueTemp })
  }

  function maternalSurnameOnChangeHandler(e) {
    var valueTemp = e.target.value.replace('  ',' ')
    setBenefData({ ...benefData, maternalSurname: valueTemp })
  }

  function rutOnChangeHandler(e) {
    setBenefData({ ...benefData, rut: format(e.target.value) })
  }

  function relationOnChangeHandler(e) {
    setSelectedRelation(e.value)
    setBenefData({ ...benefData, relation: e.value })
  }

  function typeOnChangeHandler(e) {
    setSelectedType(e.value)
    setBenefData({ ...benefData, type: e.value })
  }

  function weightOnChangeHandler(e) {
    setBenefData({ ...benefData, weight: e.target.value })
  }

  function otherOnChangeHandler(e) {
    setBenefData({ ...benefData, other: e.target.value })
  }

  function heightOnChangeHandler(e) {
    setBenefData({ ...benefData, height: e.target.value })
  }
  
  function createFieldClassName(fieldName) {
    return `form-control ${
      errors[fieldName]?.message ? 'is-invalid' : ''
    }`.trim()
  }

  function checkDeclarationStatus(){  
    // carga legal

    if(benefData.type === 2){
      // hijo menor entre 19 y 24
      if(benefData.relation === 4 && getAge(benefData.birthdate) >= 19 && getAge(benefData.birthdate) < 25 ){
        const msg = 'Declaro que mi carga se encuentra estudiando en una institución reconocida por el estado'
        setShowDeclaration({show: true, msg: msg})
      }
      // hijo mayor a 25
      if(benefData.relation === 4 && getAge(benefData.birthdate) > 25){
        const msg = 'Declaro que mi carga ha sido reconocida como inválida por la Comisión de Medicina Preventiva e Invalidez'
        setShowDeclaration({show: true, msg: msg})
      }
      // padre o madre menor o igual a 65
      if((benefData.relation === 2 || benefData.relation === 3) && getAge(benefData.birthdate) <= 65){
        const msg = 'Declaro que mi carga vive a expensas de mí y sus ingresos son menores al 50% del salario mínimo'
        setShowDeclaration({show: true, msg: msg})
      }
    }else if(benefData.type === 1){
      if(benefData.relation === 4 && getAge(benefData.birthdate) < 18){
        const msg = 'Los hijos menores de 18 años solo pueden ser cargas legales'
        setShowErrorMsg({show: true, msg: msg})
      }
    }
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 5,
    }),
    control: (provided) => ({
      ...provided,
      height: 23,
      minHeight: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      height: 23,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      height: 23,
    }),
    menu: (provided) => ({
      ...provided,
      top: 23,
      height: 'auto',
    }),
    valueContainer: () => ({
      height: 23,
    }),
    input: (provided) => ({
      ...provided,
      height: 23,
    }),
  }
  return (
    <div className="benef-box-unalterable">
      {showDeclaration.show ? (
        <MessageDeclaration
          message={showDeclaration.msg}
          isShowing={true}
          action={() => setShowDeclaration({ show: false, msg: "" })}
        />
      ) : null}
      <div className="benef-box-unalterable__index">{index}</div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor={`${benefData.id}_names`} className="form-label">
            Nombres
          </label>
          <input
            type="text"
            name={`${benefData.id}_names`}
            className={createFieldClassName(`${benefData.id}_names`)}
            value={benefData.names}
            readOnly={isTitular || !editable}
            onChange={namesOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_names`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_paternalSurname`} className="form-label">
            Apellido Paterno
          </label>
          <input
            type="text"
            name={`${benefData.id}_paternalSurname`}
            className={createFieldClassName(`${benefData.id}_paternalSurname`)}
            value={benefData.paternalSurname}
            readOnly={isTitular || !editable}
            onChange={paternalSurnameOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_paternalSurname`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_maternalSurname`} className="form-label">
            Apellido Materno
          </label>
          <input
            type="text"
            name={`${benefData.id}_maternalSurname`}
            className={createFieldClassName(`${benefData.id}_maternalSurname`)}
            value={benefData.maternalSurname}
            readOnly={isTitular || !editable}
            onChange={maternalSurnameOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_maternalSurname`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_rut`} className="form-label">
            Rut
          </label>
          <input
            type="text"
            name={`${benefData.id}_rut`}
            className={createFieldClassName(`${benefData.id}_rut`)}
            value={
              editable && !isTitular ? benefData.rut : format(benefData.rut)
            }
            readOnly={isTitular || !editable}
            onChange={rutOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_rut`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
      </div>
      <Separator isVertical />
      <div className="form-row">
        <div className="form-group">
          <label htmlFor={`${benefData.id}_sex`} className="form-label">
            Sexo
          </label>
          <p className="benef-box-unalterable__value">{sexValues.find( op => op.value === benefData.sex).label}</p>
        </div>
        <Separator />
        <div className="form-group benef-box-unalterable__datepicker">
          <label htmlFor={`${benefData.id}_birthdate`} className="form-label">
            Fecha de nacimiento
          </label>
          <p className="benef-box-unalterable__value">{benefData.birthdate}</p>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_relation`} className="form-label">
            Relación Familiar
          </label>
          {isTitular && index - 1 === 0 ? (
            <p className="benef-box-unalterable__value">Titular</p>
          ) : (
            <>
             {originInvitation === origin.dashboardSuscripcionElectronica ?
               <p className="benef-box-unalterable__value">
                 {relationValues.find(item => item.value === benefData.relation).label}
               </p>
             :
              <>
                <Select
                  isSearchable={false}
                  styles={customStyles}
                  height="10"
                  defaultValue={relationValues.find((op) => op.value === benefData.relation)}
                  className={`ns-select benef-box-unalterable__select${editable ? "" : "--disabled"}`}
                  classNamePrefix="ns-select"
                  onChange={relationOnChangeHandler}
                  options={relationValues}
                  placeholder="Selecciona tipo"
                />
                <ErrorMessage name={`${benefData.id}_relation`} errors={errors}>
                  {({ message }) => (
                    <div className="invalid-feedback">{message}</div>
                  )}
                </ErrorMessage>
              </>
              }
            </>
          )}
          <input
            hidden
            onChange={() => {}}
            name={`${benefData.id}_relation`}
            ref={register}
            value={selectedRelation}
          />        
        </div>
      </div>
      {benefData.relation === 5 ? 
        <>
          <Separator isVertical />
          <div className="form-row">
            <div className="form-group">
              <label htmlFor={`${benefData.id}_other`} className="form-label">
                Otro 
              </label>
            {originInvitation === origin.dashboardSuscripcionElectronica ?
                <p className="benef-box-unalterable__value">
                  {benefData.other}
                </p>
              :
              <>
                <input
                    type="text"
                    name={`${benefData.id}_other`}
                    className={createFieldClassName(`${benefData.id}_other`)}
                    value={benefData.other}
                    readOnly={isTitular || !editable}
                    onChange={otherOnChangeHandler}
                    disabled={isTitular || !editable}
                    ref={register}
                  />
                  <ErrorMessage name={`${benefData.id}_other`} errors={errors}>
                    {({ message }) => <div className="invalid-feedback">{message}</div>}
                  </ErrorMessage>
                </>
              }
              
              
            </div>
          </div>
        </>
        :
        null}
      
      <Separator isVertical />
      <div className="form-row">
        <div className="form-group">
        <label htmlFor={`${benefData.id}_type`} className="form-label">
            Tipo de Beneficiario
          </label>
          {isTitular && index - 1 === 0 ? (
            <p className="benef-box-alterable__value">Titular</p>
          ) : (
            <>
            {originInvitation === origin.dashboardSuscripcionElectronica ?
             <p className="benef-box-alterable__value">{typeValues.find(item => item.value === benefData.type).label}</p>
            :
            <>
                <Select
                  isSearchable={false}
                  styles={customStyles}
                  height="10"
                  defaultValue={typeValues.find(
                    (op) => op.value === benefData.type
                  )}
                  className={`ns-select benef-box-alterable__select benef-box-alterable__select${
                    editable ? "" : "--disabled"
                  }`}
                  isDisabled={isTitular || !editable}
                  classNamePrefix="ns-select"
                  onChange={typeOnChangeHandler}
                  options={typeValues}
                  placeholder="Selecciona tipo"
                />
                <ErrorMessage name={`${benefData.id}_type`} errors={errors}>
                  {({ message }) => (
                    <div className="invalid-feedback">{message}</div>
                  )}
                </ErrorMessage>
              </>
              }
            </>
          )}
          <input
            hidden
            onChange={() => {}}
            name={`${benefData.id}_type`}
            ref={register}
            value={selectedType}
          />
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_weight`} className="form-label">
            Peso (Kg)
          </label>
          <input
            type="text"
            name={`${benefData.id}_weight`}
            className={createFieldClassName(`${benefData.id}_weight`)}
            value={benefData.weight}
            readOnly={isTitular || !editable}
            onChange={weightOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_weight`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_height`} className="form-label">
            Estatura (Mts)
          </label>
          <input
            name={`${benefData.id}_height`}
            className={createFieldClassName(`${benefData.id}_height`)}
            value={editable ? benefData.height : parseFloat(benefData.height).toString()}
            readOnly={isTitular || !editable}
            onChange={heightOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_height`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div
        className={`benef-box-unalterable__action ${
          editable && !isTitular ? "benef-box-unalterable__action--show" : ""
        }`.trim()}
      >
        <div className="benef-box-unalterable__alert-toogle">
          <Tooltip visible={showTooltip} align="left">
            Para continuar debes completar todos los campos requeridos
          </Tooltip>
          <em 
            className="icon-alerta" 
            onClick={() => setShowTooltip(!showTooltip)}
          />
        </div>
      </div>

      <ErrorMsg isShowing={showErrorMsg.show} action={() => setShowErrorMsg({show: false, msg: ''})}>
        {showErrorMsg.msg}
      </ErrorMsg>
    </div>
  );
}

BenefIdent.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    sex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    birthdate: PropTypes.string.isRequired,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  editable: PropTypes.bool,
}
BenefIdent.defaultProps = {
  editable: false,
}

export default BenefIdent
