import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import ProcessFinalizationReducer from './process-finalization-reducer'
import * as ProcessFinalizationActions from './process-finalization-actions'

export const ProcessFinalizationContext = React.createContext()

export const ProcessFinalizationContextProvider = ({ children, wizard }) => {

  const [processFinalizationData, ProcessFinalizationDispatch] = useReducer(
    ProcessFinalizationReducer,
    {
    }
  )

  return (
    <ProcessFinalizationContext.Provider
      value={{
        wizard,
        processFinalizationData,
        ProcessFinalizationDispatch,
        ProcessFinalizationActions,
      }}
    >
      {children}
    </ProcessFinalizationContext.Provider>
  )
}

ProcessFinalizationContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
export default ProcessFinalizationContextProvider
