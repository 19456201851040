import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import  ProcessFinalizationIsapreReducer from './process-finalization-isapre-reducer'
import * as  ProcessFinalizationIsapreActions from './process-finalization-isapre-actions'

export const  ProcessFinalizationIsapreContext = React.createContext()

export const  ProcessFinalizationIsapreContextProvider = ({ children, wizard }) => {

  const [ processFinalizationIsapreData,  ProcessFinalizationIsapreDispatch] = useReducer(
     ProcessFinalizationIsapreReducer,
    {
    }
  )

  return (
    < ProcessFinalizationIsapreContext.Provider
      value={{
        wizard,
         processFinalizationIsapreData,
         ProcessFinalizationIsapreDispatch,
         ProcessFinalizationIsapreActions,
      }}
    >
      {children}
    </ ProcessFinalizationIsapreContext.Provider>
  )
}

 ProcessFinalizationIsapreContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
export default  ProcessFinalizationIsapreContextProvider
