import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import HealthStatementReducer from './health-statement-reducer'
import * as HealthStatementActions from './health-statement-actions'

export const HealthStatementContext = React.createContext()

export const HealthStatementContextProvider = ({ children, wizard }) => {
  const [healthStatementData, healthStatementDispatch] = useReducer(
    HealthStatementReducer,
    {
    }
  )

  return (
    <HealthStatementContext.Provider
      value={{ 
        wizard, 
        healthStatementData, 
        healthStatementDispatch, 
        HealthStatementActions 
      }}
    >
      {children}
    </HealthStatementContext.Provider>
  )
}

HealthStatementContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
export default HealthStatementContextProvider
