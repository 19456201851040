import './text-group.scss'
import React, { useState,useEffect} from 'react'
import PropTypes from 'prop-types'

const TextGroup = ({ name, label, error, register, value, cssClass, editable, setText }) => {
  const [localValue, setLocalValue] = useState(value)

  /**
   * @description Handle onChange Event for input field
   * @param {event} e Element event object
   */
  function inputOnChangeEvent(e) {
    if (editable) {
      setLocalValue(e.target.value)
      setText(e.target.value)
    }
  }

  useEffect(() => {
    if (editable) {
      setLocalValue(value)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  return (
    <div className="text-group form-group">
      <input
        className={`form-control ${error && 'is-invalid'} ${cssClass}`.trim()}
        name={name}
        type="text"
        ref={register}
        aria-invalid={error ? 'true' : 'false'}
        placeholder="&nbsp;"
        autoComplete="off"
        value={localValue}
        onChange={inputOnChangeEvent}
      />
      <label htmlFor="" className="form-label">
        {label}
      </label>
      {error && <div className="invalid-feedback">{error.message}</div>}
    </div>
  )
}
TextGroup.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  error: PropTypes.shape({ message: PropTypes.string }),
  register: PropTypes.func.isRequired,
  value: PropTypes.string,
  cssClass: PropTypes.string,
  editable: PropTypes.bool,
  setText: PropTypes.func,
}
TextGroup.defaultProps = {
  error: null,
  value: '',
  cssClass: '',
  editable: true,
  setText: () => {},
}

export default TextGroup
