import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import SignDocumentsReducer from './sign-documents-reducer'
import * as SignDocumentsActions from './sign-documents-actions'

export const SignDocumentsContext = React.createContext()

export const SignDocumentsContextProvider = ({ children, wizard }) => {
  const messages = ['Se deben leer y firmar los siguientes documentos']

  const [signDocumentsData, SignDocumentsDispatch] = useReducer(
    SignDocumentsReducer,
    {
      currentMessage: 0,
      message: messages[0],
      messages,
      allRead: false,
      allSigned: false,
      documents: [],
    }
  )

  return (
    <SignDocumentsContext.Provider
      value={{
        wizard,
        signDocumentsData,
        SignDocumentsDispatch,
        SignDocumentsActions,
      }}
    >
      {children}
    </SignDocumentsContext.Provider>
  )
}

SignDocumentsContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
export default SignDocumentsContextProvider
