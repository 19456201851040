import RequestService from '@core/request-service'

export default class ProcessFinalizationIsapreService extends RequestService {

    static getFun(payload) {
        return super.post(
        ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/ObtenerFolio' :
        'https://run.mocky.io/v3/5e6f6d47-562e-4553-af6a-c0f8e544d25a', 
        payload
        )
    }

    static sendDisaffiliationDocuments(payload) {
        return super.post(
        ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/CargaArchivoDesafiliacion' :
        'https://run.mocky.io/v3/14cd447a-c5e6-4db0-8f7b-8435d5f62ff6', 
        payload
        )
    }

    static disaffiliate(payload) {
        return super.post(
        ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Desafiliacion' :
        'http://www.mocky.io/v2/5eda7795330000181a79ec46', 
        payload
        )
    }

    static sysasapDisaffiliation(payload) {
        return super.post(
        ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/sysasapFront/v2/SysasapDesafiliacion' :
        'https://run.mocky.io/v3/50103687-e013-4463-808e-8c6e2941f47d', // ok
        // 'https://run.mocky.io/v3/86b19d91-4402-4af0-9708-145dd32aaf17', // error 500
        // 'https://run.mocky.io/v3/b468ba9c-1c54-4847-a4c1-301177a3a16d', // error 400
        payload
        )
    }

    static loadUrlIsapre(payload) {
        return super.post(
        ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/obtenerurlisapre' : //'api/sysasapInicioProceso/v2/obtenerurlisapre'
        // 'https://run.mocky.io/v3/a795b190-b28e-4963-927d-c059cf325dfa', // cruz blanca
        // 'https://run.mocky.io/v3/1138c86e-1ea4-4cad-bedf-997c2079e6b5', // afiliado a isapre
        // 'https://run.mocky.io/v3/5d67cb25-6531-4b8a-9d84-3ce5d7bfa992', // no afiliado
        'https://run.mocky.io/v3/86b19d91-4402-4af0-9708-145dd32aaf17',
        payload
        )
    }

    static signDisaffiliation(payload) {
        return super.post(
          ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/firmardocumentos' :
          'https://run.mocky.io/v3/38c70473-61b9-4d51-8694-6a1cfc49295a',
          payload
        )
      }

}