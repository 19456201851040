const FILL_ISAPRES_LIST = 'FILL_ISAPRES_LIST'
const SET_ISAPRE_ACTIVE = 'SET_ISAPRE_ACTIVE'
const SET_TYPE_HEALTH_STATEMENT = 'SET_TYPE_HEALTH_STATEMENT'
const SET_READY_TO_GO = 'SET_READY_TO_GO'
const SET_TYPE_USER = 'SET_TYPE_USER'
const SET_COMPLETED_STEP = 'SET_COMPLETED_STEP'
const SET_NEXT_STEP_MESSAGE = 'SET_NEXT_STEP_MESSAGE'
const REFRESH_DATA = 'REFRESH_DATA'

function setTypeHealthStatement(typeHealthStatement) {
  return { type: SET_TYPE_HEALTH_STATEMENT, typeHealthStatement }
}

function setActiveIsapre(isapre) {
  return { type: SET_ISAPRE_ACTIVE, isapre }
}

function setIsapreList(collection) {
  return { type: FILL_ISAPRES_LIST, collection }
}

function setReadyToGo(flag) {
  return { type: SET_READY_TO_GO, flag }
}

function setTypeUser(typeUser) {
  return { type: SET_TYPE_USER, typeUser }
}

function setCompletedStep() {
  return { type: SET_COMPLETED_STEP, flag: true }
}
function setNextStepMessage(index) {
  return {
    type: SET_NEXT_STEP_MESSAGE,
    currentMessage: index,
  }
}

function refreshData() {
  return {
    type: REFRESH_DATA,
  }
}

export {
  FILL_ISAPRES_LIST,
  SET_ISAPRE_ACTIVE,
  SET_TYPE_HEALTH_STATEMENT,
  SET_READY_TO_GO,
  SET_TYPE_USER,
  SET_COMPLETED_STEP,
  SET_NEXT_STEP_MESSAGE,
  REFRESH_DATA,
  setTypeHealthStatement,
  setActiveIsapre,
  setIsapreList,
  setReadyToGo,
  setTypeUser,
  setCompletedStep,
  setNextStepMessage,
  refreshData,
}
