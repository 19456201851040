/* eslint-disable jsx-a11y/no-onchange */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState, useEffect } from 'react'
import './footer.scss'
import Tooltip from '@commons/tooltip/tooltip'

const Footer = () => {
  const [showTooltip, setShowTooltip] = useState(true)

  const handlerTooltipOnMouseOut = () => {
    setShowTooltip(false)
  }

  const handlerTooltipOnMouseOver = () => {
    setShowTooltip(true)
  }

  useEffect(() => {
    if (showTooltip) {
      setTimeout(() => {
        setShowTooltip(false)
      }, 5000)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <footer className="footer">
      <div className="footer__contact">
        <div className="footer__contact-options">
          <a href="https://www.nuevamasvida.cl/contacto/" target="_blank" rel="noopener noreferrer"><em className="icon-mail" /></a>
          <a href="tel:6006000262"><em className="icon-fono" /></a>
        </div>
        <div className="footer__contact-rrss">
          <a href="https://www.facebook.com/NuevaMasvidaOficial" target="_blank" rel="noopener noreferrer"><em className="icon-facebook" /></a>
          <a href="https://twitter.com/nuevamasvida_/" target="_blank" rel="noopener noreferrer"><em className="icon-twitter" /></a>
          <a href="https://www.youtube.com/c/isaprenuevamasvida" target="_blank" rel="noopener noreferrer"><em className="icon-youtube" /></a>
        </div>
      </div>
      <div className="footer__help">
        <div className="footer__help-disclaimer">
          Te informamos que al postular estás autorizando a nuestra isapre para
          obtener antecedentes de tu isapre actual. Si no finalizas la
          postulación, los datos estarán disponibles hasta el último día del
          mes.
        </div>
        <div className="footer__help-action">
          <Tooltip visible={showTooltip}>
            <div>
              Si interrumpes el proceso podrás retomarlo ingresando tu Rut en la
              opción Venta Electrónica en www.nuevamasvida.cl.
            </div>
          </Tooltip>
          <em
            onMouseOver={handlerTooltipOnMouseOver}
            className={`${showTooltip ? 'icon-info' : 'icon-info-2'}`}
            onMouseOut={handlerTooltipOnMouseOut}
          />
        </div>
      </div>
    </footer>
  )
}

export default Footer
