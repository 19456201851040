import React, { useState, useContext } from "react";
import "./result-canceled.scss";
import Button from "@commons/buttons/button";
import ErrorMessage from '@commons/messages/error/error-message'
import { HealthStatementSentService } from "@components/health-statement-sent";
import { StepperStepsContext } from '@commons/steppers/steps'

const ResultCanceled = () => {
  const [message, setMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { stepperStepsData } = useContext(StepperStepsContext)

  /**
   * @description Handle close sesion
   */
  function handleCloseSesion() {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      resultado: 5,
      pin: null,
      acepta: null,
    }
    HealthStatementSentService.finishDS(body)
      .then((response) => {
        if (response.httpCode === 200) {
          window.location.assign("https://www.nuevamasvida.cl/")
        } else {
          setShowErrorMessage(true);
          setMessage(
            "No se ha podido finalizar el proceso, vuelve a intentarlo."
          );
        }
      })
      .catch(() => {
        setShowErrorMessage(true);
        setMessage(
          "No se ha podido finalizar el proceso, vuelve a intentarlo."
        );
      });
  }

  return (
    <div className="result-canceled">
      <div className="result-canceled__statement">
        De acuerdo a la evaluación de tú postulación esta isapre ha decidido no suscribir un contrato contigo.
      </div>
      <div className="result-canceled__buttons">
        <Button text="Finalizar Proceso" action={handleCloseSesion} />
      </div>
      <ErrorMessage
        isShowing={showErrorMessage}
        action={() => setShowErrorMessage(false)}
      >
        {message}
      </ErrorMessage>
    </div>
  );
};

export default ResultCanceled;
