import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
// import SummaryHealthStatementService from './summary-health-statement-service'
import SummaryHealthStatementReducer from './summary-health-statement-reducer'
import * as SummaryHealthStatementActions from './summary-health-statement-actions'

export const SummaryHealthStatementContext = React.createContext()

export const SummaryHealthStatementContextProvider = ({ children, wizard }) => {
  const [
    summaryHealthStatementData,
    SummaryHealthStatementDispatch,
  ] = useReducer(
    SummaryHealthStatementReducer, 
    {
      loading: false,
      confirm: false,
      showConfirmation: false,
    }
  )

  return (
    <SummaryHealthStatementContext.Provider
      value={{
        wizard,
        summaryHealthStatementData,
        SummaryHealthStatementDispatch,
        SummaryHealthStatementActions,
      }}
    >
      {children}
    </SummaryHealthStatementContext.Provider>
  )
}

SummaryHealthStatementContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
