import {
  COMPLETE_SUB_STEP,
  ADD_STEPS,
  REFRESH_PROGRESS,
  ADD_WIZARD,
  SET_MIXED_DATA,
  RESET,
  ADD_STEP,
  SET_URL_ISAPRE,
  SET_AUTHENTICATION_PAGE,
  SET_IS_TIME_OUT,

  SET_CONTRIBUTOR,
  SAVE_CONTRIBUTOR,
  LOADING_CONTRIBUTOR_DATA,

  ADD_BENEFICIARY,
  REMOVE_BENEFICIARY,
  SET_BENEFICIARY,

  BACKUP_QUESTIONS,
  SET_BACKUP_QUESTIONS_LIST,
  FILL_QUESTIONS_LIST,
  FILL_SUGGESTED_DIAGNOSTICS,
  SET_NEXT_QUESTION_TO_ANSWER,
  SET_NO_ANSWER,
  SET_YES_ANSWER,
  SET_FORM_YES_ANSWER,
  ADD_DIAGNOSTIC,
  REMOVE_DIAGNOSTIC,
  SET_DATA_DIAGNOSTIC,
  FILL_QUESTIONS_ANSWERED,

  SET_PATPAC_SIGN,

  SET_STATUS,

  SET_ACTUAL_STEP,
  SET_SUBSTEP,

  SET_BACKGROUD_CONTRACTUAL_DOCUMENTS,
  SET_REGIONS,
  SET_CITIES,
  SET_COMMUNES,
  SET_AFPS,
  SET_DATA_BACKGROUND_CONTRACTUAL_DOCUMENTS,
  SET_DATA_PAID_ENTITY,
} from './wizard-actions'

export default function WizardReducer(state, action) {
  let arrAux = []
  switch (action.type) {
    case COMPLETE_SUB_STEP:
      
      let completed = state.steps.find(item => item.name ===  action.step.name) !== undefined
      return {
        ...state,
        steps: state.steps.map((item) => {
          if (item.name === action.step.name) {
            completed = false
            return {
              ...item,
              completed: true,
            }
          }
          return {
            ...item,
            completed,
          }
        })
      }
    case ADD_STEPS:
      return {
        ...state,
        steps: action.steps,
      }
    case ADD_WIZARD:
      return {
        ...state,
        wizard: action.wizard,
      }
    case REFRESH_PROGRESS: {
      const percent =
        (state.steps.filter((item) => item.completed).length * 100) /
        state.steps.length
      return {
        ...state,
        progress: percent,
      }
    }
    case SET_MIXED_DATA:
      return {
        ...state,
        mixed: [...state.mixed, action.data]
      }
    case RESET:
      return {
        ...state,
        steps: [],
      }
    case ADD_STEP:
      let listTemporal = state.steps
      listTemporal.push(action.step)
      return {
        ...state,
        steps: listTemporal,
      }
    case SET_URL_ISAPRE:
      return {
        ...state,
        urlIsapre: action.url,
      }
    case SET_AUTHENTICATION_PAGE:
      return {
        ...state,
        authenticationPage: action.authPage,
      }
    case SET_IS_TIME_OUT:
      return {
        ...state,
        isTimeOut: action.payload,
      }

    case SET_CONTRIBUTOR:
      return {
        ...state,
        contributor: action.contributor
      }
    case SAVE_CONTRIBUTOR:
      return {
        ...state,
        contributor: action.contributor
      }
    case LOADING_CONTRIBUTOR_DATA:
      return {
        ...state,
        loadingContributor: action.flag,
      }

    case SET_BENEFICIARY:
      return {
        ...state,
        beneficiary: action.beneficiary
      }
    case ADD_BENEFICIARY:
      return {
        ...state,
        beneficiary: [
          ...state.beneficiary,
          {
            id: state.beneficiary.length+1,
            isNew: true,
            rut: '',
            names: '',
            paternalSurname: '',
            maternalSurname: '',
            sex: 0,
            birthdate: null,
            relation: 1,
            type: 1,
          },
        ],
      }
    case REMOVE_BENEFICIARY:
      return {
        ...state,
        beneficiary: state.beneficiary.filter(
          (item) => item.id !== action.benefID
        ),
      }
    case BACKUP_QUESTIONS:
      return {
        ...state,
        questions: JSON.parse(JSON.stringify(state.backupQuestions)),
      }
    case SET_BACKUP_QUESTIONS_LIST:
      return {
        ...state,
        backupQuestions: action.collection,
      }
    case FILL_QUESTIONS_LIST:
      return {
        ...state,
        questions: action.collection,
      }
    case FILL_SUGGESTED_DIAGNOSTICS:
      return {
        ...state,
        diagnosticsQuestion: action.collection,
      }
    case SET_NEXT_QUESTION_TO_ANSWER:
      return {
        ...state,
        nextQuestionToAnswer: action.nextQtoA
      }
    case SET_NO_ANSWER: {
      return {
        ...state, 
        questions: state.questions.map( question => {
          if(question.number === action.numQuestion){
            return{ ...question, 
              answer: false, 
              diagnostics: [{
                rut: '',
                diagnostico: {
                  codigo: '',
                  diagnostico: ''
                },
                fecha: '',
                intervencionQuirurgica: '',
                beneficiary: ''
              }]
          }         
          }else{
            return question
          }
        })
      }
    }
    case SET_YES_ANSWER: {
      arrAux = state.questions.map(resp => {
        if (resp.number === action.numQuestion) {
          resp.answer = true
        }
        return resp
      })
      return {
        ...state,
        questions: arrAux
      }
    }
    case SET_FORM_YES_ANSWER: {
      arrAux = state.questions.map(resp => {
        if (resp.number === action.answer.number) {
          resp = action.answer
        }
        return resp
      })

      return {
        ...state,
        questions: arrAux,
        answered: !state.answered.includes(action.answer.number)
          ? [...state.answered, action.answer.number]
          : [...state.answered],
      }
    }
    case ADD_DIAGNOSTIC: {
      arrAux = state.questions.map(resp => {
        if (resp.number === action.numQuestion) {
          if (resp.diagnostics === null) resp.diagnostics = []
          resp.diagnostics.push({
            rut: '',
            diagnostico: {
              codigo: '',
              diagnostico: ''
            },
            fecha: '',
            intervencionQuirurgica: '',
          })
        }
        return resp
      })
      return {
        ...state,
        questions: arrAux
      }
    }
    case REMOVE_DIAGNOSTIC: {
      arrAux = state.questions.map(resp => {
        if (resp.number === action.data.numQuestion) {
          resp.diagnostics.splice(action.data.indexRemove, 1)
        }
        return resp
      })
      return {
        ...state,
        questions: arrAux
      }
    }
    case SET_DATA_DIAGNOSTIC: {
      arrAux = state.questions.map((resp) => {
        let response = JSON.parse(JSON.stringify(resp))
        if (response.number === action.data.numQuestion) {
          response.diagnostics[action.data.index] = {...action.data.diagnostic}
          return response
        }
        return resp
      })
      return {
        ...state,
        questions: arrAux,
      }
    }
    case FILL_QUESTIONS_ANSWERED: {
      return {
        ...state,
        answered: action.data.collection,
      }
    }

    case SET_PATPAC_SIGN: {
      return {
        ...state,
        signPatPac: action.data
      }
    }

    case SET_STATUS:
      return {
        ...state,
        status: action.payload,
      }

    case SET_ACTUAL_STEP:
      return {
        ...state,
        actualStep: action.payload,
      }
    case SET_SUBSTEP:
      return {
        ...state,
        subStep: action.payload,
      }


    case SET_BACKGROUD_CONTRACTUAL_DOCUMENTS:
      return {
        ...state,
        backgroudContractualDocuments: action.payload,
      }
    case SET_REGIONS:
      const regionsList = action.payload.map( item => {
        return {
          ...item,
          codigo: item.codigo.toString(),
        }
      })
      return {
        ...state,
        regions: regionsList,
      }
    case SET_CITIES:
      const citiesList = action.payload.map( item => {
        return {
          ...item,
          codigoRegion: item.codigoRegion.toString(),
          codigo: item.codigo.toString(),
        }
      })
      return {
        ...state,
        cities: citiesList,
      }
    case SET_COMMUNES:
      const communesList = action.payload.map( item => {
        return {
          ...item,
          codigoCiudad: item.codigoCiudad.toString(),
          codigo: item.codigo.toString(),
        }
      })
      return {
        ...state,
        communes: communesList,
      }
    case SET_AFPS:
      const afpsList = action.payload.map( item => {
        return {
          ...item,
          codigo: item.codigo.toString(),
        }
      })
      return {
        ...state,
        afps: afpsList,
      }
    case SET_DATA_BACKGROUND_CONTRACTUAL_DOCUMENTS:
      return {
        ...state,
        backgroudContractualDocuments: {
          ...state.backgroudContractualDocuments,
          [action.payload.name]: action.payload.value,
        }
      }
    case SET_DATA_PAID_ENTITY:
      let listEmpleador = state.backgroudContractualDocuments.datosEmpleador.map(item => {
        if (action.payload.id === item.id) {
          return {
            ...item,
            [action.payload.name]: action.payload.value
          }
        }
        return item
      })

      return {
        ...state,
        backgroudContractualDocuments: {
          ...state.backgroudContractualDocuments,
          datosEmpleador: listEmpleador,
        }
      }
    default:
      return state
  }
}
