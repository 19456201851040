import './user-information-wizard.scss'
import React, { useContext, useEffect, useCallback } from 'react'
import { Wizard, Steps, Step } from 'react-albus'
import NSProgressBar from '@commons/progress-bar/progress-bar'
import StartSubscription from '@pages/start-subscription'
import ValidateIdentity from '@pages/validate-identity'
import RentStatement from '@pages/rent-statement'
import { WizardContext } from '@commons/steppers/wizard/'
import { StartSubscriptionContextProvider } from '@components/start-subscription'
import { ValidateIdentityContextProvider } from '@components/validate-identity'
import { RentStatementContextProvider } from '@components/rent-statement'
import { ManagerRouteContextProvider } from '@commons/steppers/manager-route/manager-route-context'

const UserInformationWizard = ({ history, route }) => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(
    WizardContext
  )

  const addSteps = useCallback(() => {
    WizardDispatch(
      WizardActions.addSteps([
        {
          name: 'inicio',
          completed: false,
        },
        {
          name: 'validacion',
          completed: false,
        },
        {
          name: 'renta',
          completed: false,
        },
      ])
    )
  }, [WizardActions, WizardDispatch])

  /**
   *  Side Effects
   */
  useEffect(() => addSteps(), [addSteps])

  return (
    <div className="wizard user-information">
      <div className="wizard__progress">
        <NSProgressBar percent={wizardData.progress} />
      </div>
      <div className="wizard__wrapper">
        <Wizard history={history} basename={route}>
          <Steps>
            <Step
              id="inicio-suscripcion"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <StartSubscriptionContextProvider wizard={wizard}>
                    <StartSubscription />
                  </StartSubscriptionContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="verificacion-identidad"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <ValidateIdentityContextProvider wizard={wizard}>
                    <ValidateIdentity />
                  </ValidateIdentityContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="declaracion-renta"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <RentStatementContextProvider wizard={wizard}>
                    <RentStatement />
                  </RentStatementContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
          </Steps>
        </Wizard>
      </div>
    </div>
  )
}

export default UserInformationWizard
