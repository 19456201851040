import './process-finalization-wizard.scss'
import React, { useContext, useEffect, useCallback } from 'react'
import { Wizard, Steps, Step } from 'react-albus'
import NSProgressBar from '@commons/progress-bar/progress-bar'
import ProcessFinalization from '@pages/process-finalization'
import ProcessFinalizationDisaffiliation from '@pages/process-finalization-disaffiliation'
import ProcessFinalizationIsapre from '@pages/process-finalization-isapre'
import ProcessFinalizationRevision from '@pages/process-finalization-revision'
import { WizardContext } from '@commons/steppers/wizard'
import { ManagerRouteContextProvider } from '@commons/steppers/manager-route/manager-route-context'
import { ProcessFinalizationIsapreContextProvider } from '@components/process-finalization-isapre'
import { ProcessFinalizationContextProvider } from '@components/process-finalization'


const ProcessFinalizationWizard = ({history, route}) => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(
    WizardContext
  )

  const addSteps = useCallback(() => {
    WizardDispatch(
      WizardActions.addSteps([
        {
          name: 'firmaDocumentos',
          completed: false,
        },
        {
          name: 'firmaIsapreRevision',
          completed: false,
        },
        {
          name: 'firmaIsapre',
          completed: false,
        },
      ])
    )
  }, [WizardActions, WizardDispatch])

  /**
   * Side Effects
   */
  useEffect(() => addSteps(), [addSteps])

  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  return (
    <div className="wizard health-statement">
      <div className="wizard__progress">
        <NSProgressBar percent={wizardData.progress} />
      </div>
      <div className="wizard__wrapper">
        <Wizard history={history} basename={route}>
          <Steps>
            <Step
              id="isapre"
              render={(wizard) => (
              <ManagerRouteContextProvider>
                <ProcessFinalizationIsapreContextProvider wizard={wizard}>
                  <ProcessFinalizationIsapre />
                </ProcessFinalizationIsapreContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
             <Step
              id="revision"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <ProcessFinalizationRevision wizard={wizard}/>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="terminar-proceso-desafiliacion"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <ProcessFinalizationContextProvider wizard={wizard}>
                      <ProcessFinalizationDisaffiliation />
                  </ProcessFinalizationContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="terminar-proceso"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <ProcessFinalizationContextProvider wizard={wizard}>
                      <ProcessFinalization />
                  </ProcessFinalizationContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
          </Steps>
        </Wizard>
      </div>
    </div>
  )
}

export default ProcessFinalizationWizard
