import React, { useContext, useState } from "react";
import Downloader from "@commons/downloader/downloader";
import Button from "@commons/buttons/button";
import PinValidation from "@commons/pin-validation/pin-validation";
import { HealthStatementSentContext, HealthStatementSentService } from "@components/health-statement-sent";
import { WizardContext } from '@commons/steppers/wizard'
import { useHistory } from 'react-router-dom'
import { StepperStepsContext } from '@commons/steppers/steps'
import "./result-restricted.scss"
// import { SignHealthStatementService } from '@components/sign-health-statement'
import ErrorMessage from '@commons/messages/error/error-message'

const ResultRestricted = () => {
  const history = useHistory()
  const { stepperStepsData, stepperStepsDispatch, StepperStepsActions } = useContext(StepperStepsContext)
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showButtons, setShowButtons] = useState(true);
  const [message, setMessage] = useState('')
  const [showReject,setShowReject] = useState(false)
  const [showPIN, setShowPIN] = useState(false);
  const [isSigned, setIsSigned] = useState(true);
  const [loading, setLoading] = useState(false)
  const { isAsisted } = stepperStepsData
  const {
    wizard,
    healthStatementSentData: {
      resultData: { nombre, archivo },
    },
  } = useContext(HealthStatementSentContext);

  function handleSignConfirmation() {
    setShowButtons(false);
    setShowPIN(true);
  }

  
  function onSign(pin) {
    setLoading(true)
/*     const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      pin,
    }
    SignHealthStatementService.signHealthStatement(body).then((response) => {
      if (response.httpCode === 200) { */
        const body = {
          identificador: stepperStepsData.userInformation.identificador,
          rut: stepperStepsData.userInformation.rut,
          resultado: 2,
          pin,
          acepta: true,
        }
        HealthStatementSentService.finishDS(body).then((response) => {
          if (response.httpCode === 200) {
            setLoading(false)
            WizardDispatch(WizardActions.setCompleteSubStep({ name: 'resultado' }))
            stepperStepsDispatch(StepperStepsActions.setResult(''))
            if (isAsisted) {
              history.push('/firmar-documentos')
            } else {
              wizard.next()
            }
          } else {
            setShowErrorMessage(true)
            setIsSigned(false)
            setLoading(false)
            setMessage('No se ha podido ingresar al siguiente paso, vuelve a intentarlo.')
          }
        }).catch(() => {
          setShowErrorMessage(true)
          setIsSigned(false)
          setMessage('No se ha podido ingresar al siguiente paso, vuelve a intentarlo.')
          setLoading(false)

        })
     /*  } else {
        setShowErrorMessage(true)
        setIsSigned(false)
        setMessage('No se ha podido firmar la declaración de salud, vuelva a intentarlo.')
      }
    }).catch(() => {
      setShowErrorMessage(true)
      setIsSigned(false)
      setMessage('No se ha podido firmar la declaración de salud, vuelva a intentarlo.')
    }) */
  }

  /**
   * @description Handle close error message
   */
  function handleCloseErrorMessage() {
    setShowErrorMessage(false)
    setIsSigned(true)
  }

  function handleCancelProcess() {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      resultado: 2,
      pin: null,
      acepta: false,
    }
    HealthStatementSentService.finishDS(body).then((response) => {
      if (response.httpCode === 200) {
        window.location = 'https://www.nuevamasvida.cl/'
      } else {
        setShowErrorMessage(true)
        setIsSigned(false)
        setMessage('No se ha podido finalizar el proceso, vuelva a intentarlo.')
      }
    }).catch(() => {
      setShowErrorMessage(true)
      setIsSigned(false)
      setMessage('No se ha podido finalizar el proceso, vuelva a intentarlo.')
    })
  
  }

  function returnToForm() {
    setShowReject(false)
    setShowButtons(true)
  }

  function handleRejectDS() {
    setShowReject(true)
    setShowButtons(false)
  }

  return (
    <div className="result-restricted">
      <div className="result-restricted__message">
        Tu{" "}
        <span className="result-restricted__message--bold">
          Declaración de Salud
        </span>{" "}
        ha sido{" "}
        <span className="result-restricted__message--status">
          Autorizada con Restricciones
        </span>
      </div>
      <div className="result-restricted__downloader">
        <Downloader text={nombre} fileUrl={archivo} />
      </div>
      {showButtons && (
        <>
          <div className="result-restricted__buttons">
            <Button
              text="Rechazar"
              action={handleRejectDS}
              cssClass="result-restricted__buttons--cancel"
            />
            <Button text="Aceptar" action={handleSignConfirmation} />
          </div>
        </>
      )}
      {showReject && (
        <>
          <div className="result-restricted__message-reject">
            Si continúas, el proceso de suscripción se suspenderá, ante
            cualquier duda deberás contactar a tu asesor.
          </div>
          <div className="result-restricted__buttons">
            <Button
              text="Suspender"
              action={handleCancelProcess}
              cssClass="result-restricted__buttons--cancel"
            />
            <Button text="Volver" action={returnToForm} />
          </div>
        </>
      )}
      {showPIN && (
        <div>
          <div className="result-restricted__message-pin">
            Para firmar tu{' '}
            <span className="result-restricted__message-pin--bold">
              Declaración de Salud
            </span>{' '}
            y continuar al siguiente paso, debes ingresar la clave segura
            enviada previamente por SMS a tu celular y a tu Correo.
          </div>
          <PinValidation
            action={onSign}
            buttonText="Firma Declaración de Salud"
            isSigned={isSigned}
            setLoading={setLoading}
            loading={loading}
          />
        </div>
      )}
      <ErrorMessage
        isShowing={showErrorMessage}
        action={handleCloseErrorMessage}
      >
        <div className="result-restricted__alert">
          {message}
        </div>
      </ErrorMessage>
    </div>
  );
};

export default ResultRestricted;
