import React,{ useContext, useState } from 'react';
import ContributorDataDocuments from '@components/health-statement-documents/elements/contributor-data-documents'
import ContributorPersonalData from '@components/health-statement-documents/elements/contributor-personal-data'
import PaidEntity from '@components/health-statement-documents/elements/paid-entity'
import ErrorMessage from '@commons/messages/error/error-message'
import { WizardContext } from '@commons/steppers/wizard'
import _ from 'lodash'

const HealthStatementDocManager = ({register,errors,control}) => {
  const { wizardData } = useContext(WizardContext)
  const { contributor, backgroudContractualDocuments } = wizardData
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')

   /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

    return (
      <div>
        {_.size(contributor) && (
          <ContributorDataDocuments contributor={contributor} />
        )}
        {_.size(backgroudContractualDocuments) && (
          <>
            <ContributorPersonalData  register={register} errors={errors} control={control} />
            <div className="contributor-data__title">Empleador</div>
            {backgroudContractualDocuments.datosEmpleador.map((itemEntity) => {
              return <PaidEntity key={itemEntity.id} entity={itemEntity} />
            })}
          </>
        )}
        <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
          {message}
        </ErrorMessage>
      </div>
    );
};

export default HealthStatementDocManager;