import {
  SET_CONFIRM_CONSIDERATIONS
} from './previous-considerations-actions'

export default function PreviousConsiderationsReducer(state, action) {
  switch (action.type) {
    case SET_CONFIRM_CONSIDERATIONS:
      return {
        ...state,
        confirm: action.confirm
      }
    default:
      return state
  }
}