import RequestService from '@core/request-service'

export default class SignHealthStatementService extends RequestService {
 
  static signHealthStatement(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Firmardeclaracionsalud' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46'
     ,payload
    )
  }

  static saveHealthStatement(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/Guardardeclaracionsalud' :
      'http://www.mocky.io/v2/5eda7795330000181a79ec46'
     ,payload
    )
  }

}