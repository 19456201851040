import './employer.scss'
import React, { useState, useEffect, useContext } from 'react'
import { RentStatementContext, RentStatementService } from '@components/rent-statement'
import PropTypes from 'prop-types'
import ConfirmMessage from '@commons/messages/confirm/confirm'
import { ErrorMessage } from 'react-hook-form'
import { format } from 'rut.js'
import FormatNum from '@utils/tools/number'
// import Separator from '@commons/separator/separator'
import IconButton from '@commons/buttons/icon-button'
import InputFile from '@commons/inputs/input-file'
import Tooltip from '@commons/tooltip/tooltip'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import { StepperStepsService, StepperStepsContext } from '@commons/steppers/steps'
import Radio from '@components/commons/inputs/radio'

//const { stepperStepsData } = useContext(StepperStepsContext)
const Employer = ({
  children,
  index,
  editable,
  onRemove,
  onChangeFiles,
  onRemoveFile,
  files,
  reset,
  register,
  errors
}) => {
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [employerData, setEmployerData] = useState(children)
  const [showMessage, setShowMessage] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [dataFirstTime, setDataFirstTime] = useState(false)

  const [rutEditable, setRutEditable] = useState(false)
  const [rsEditable, setRsEditable] = useState(false)
  const [rEditable, setREditable] = useState(false)

  const [showModal, setShowModal] = useState(true)
  const [checkValidate, setCheckValidate] = useState(false)

  const [dataProspecto, setDataProspecto] = useState();
  const {
    rentStatementData,
    RentStatementDispatch,
    RentStatementActions,
  } = useContext(RentStatementContext)

  /**
   * @description Remove an element File from attachedFilesList
   * @param {number} ind Index position of element
   */
  function removeFileAction(ind) {
    onRemoveFile(
      children.id,
      files.filter((item, i) => i !== ind)
    )
  }

  /**
   * @description HandlerRemove action
   */
  function removeActionEventHandler() {
    setShowMessage(true)
  }

  /**
   * @description Set false in delete message
   */
  function cancelAction() {
    setShowMessage(false)
  }

  /**
   * @description Remove self from ArrayData
   */
  function confirmAction() {
    onRemove(employerData.id)
  }

  /**
   * @description RUT Field onChange event
   * @param {event} e element event
   */
  function rutOnChangeHanlder(e) {
    setEmployerData({ ...employerData, rut: format(e.target.value) })
  }

  /**
   * @description businessName Field onChange event
   * @param {event} e element event
   */
  function businessOnChangeHanlder(e) {
    setEmployerData({ ...employerData, razonSocial: e.target.value })
  }

  /**
   * @description lastRent Field onChange event
   * @param {event} e element event
   */
  function rentOnChangeHanlder(e) {
    setEmployerData({ ...employerData, ultimaRenta: e.target.value })
  }

   /**
   * @description vigente Field onChange event
   * @param {event} e element event
   */
  function vigenteOnChangeHanlder(val) {
    if(val === "SI") setEmployerData({...employerData, vigente: true });
    if(val === "NO") setEmployerData({...employerData, vigente: false });
  }

  /**
   * @description entityType Field onChange event
   * @param {event} e element event
   */
  function entityTypeOnChangeHanlder(e) {
    if (e.target.value === '4') {//Voluntario
      setEmployerData({
        ...employerData,
        entity_type: e.target.value,
        rut: format(dataProspecto.rut),
        ultimaRenta: 0,
        razonSocial: dataProspecto.nombres + " " + dataProspecto.apellidoPaterno + " " + dataProspecto.apellidoMaterno,
        editableRazonSocial: false,
        editableRut: false,
        editableRenta: false,
        isNew: false,
        tipoTrabajador: '4'
      });
    } else if (e.target.value === '2') {//Independiente
      setEmployerData({
        ...employerData,
        entity_type: e.target.value,
        ultimaRenta: employerData.ultimaRenta,
        razonSocial: dataProspecto.nombres + " " + dataProspecto.apellidoPaterno + " " + dataProspecto.apellidoMaterno,
        editableRazonSocial: false,
        editableRut: false,
        editableRenta: true,
        tipoTrabajador: '2',
        rut: format(dataProspecto.rut)
      });
    } else {//Dependiente y Pensionado
      setEmployerData({
        ...employerData,
        entity_type: e.target.value,
        editableRenta: true,
        editableRazonSocial: true,
        editableRut: true,
        isNew: true,
        ultimaRenta: 0,
        rut: '',
        razonSocial: ''
      })
    }
  }

  /**
   * @description Return the String Classname for Input, this helps to reduce SonarQ congnitive complexity
   * @param {string} fieldName Input name
   */
  function createFieldClassName(fieldName) {
    return `form-control ${errors[fieldName]?.message ? 'is-invalid' : ''
      }`.trim()
  }

  /**
   * @description MouseLeave Event for tooltipComponent
   * @param {boolean} flag
   */
  function handlerTooltipOnHover(flag) {
    setShowTooltip(flag)
  }

  const getProspectData = async ({ prospectoId }) => {
    const body = {
      prospectoId,
      identificador: stepperStepsData.userInformation.identificador
    }
    await RentStatementService.getProspectData(body).then((response) => {
      if (response.httpCode != 200) {
        employerData.entity_type = "1";
        setShowModal(false)
      } else {
        if(!dataFirstTime){        
        setDataFirstTime(true)
        const infoProspecto = response.data;
        setDataProspecto(infoProspecto)

        const { empleadores, nombres, apellidoPaterno, apellidoMaterno } = infoProspecto;
        if(Array.isArray(empleadores) && empleadores.length){
          empleadores.forEach((item) => {
          // if (employerData.editableRazonSocial && employerData.rut === item.rut.trim()) {
          if (employerData.tipoTrabajador == "1") {//Dependiente
            setEmployerData({
              ...employerData,
              entity_type: "1",
              ultimaRenta: employerData.ultimaRenta,
              isNew: true,
              editableRut: true,
              editableRazonSocial: true,
              editableRenta: true
            })
          }
          if (employerData.tipoTrabajador == "2") {//Independiente
            setEmployerData({
              ...employerData,
              entity_type: "2",
              ultimaRenta: employerData.ultimaRenta,
              razonSocial: nombres + " " + apellidoPaterno + " " + apellidoMaterno,
              editableRazonSocial: false,
              editableRenta: true,
              editableRut: false,
              isNew: true
            })
          }
          if (employerData.tipoTrabajador == "3") {//Pensionado
            setEmployerData({
              ...employerData,
              entity_type: "3",
              ultimaRenta: employerData.ultimaRenta,
              isNew: true,
              editableRut: true,
              editableRazonSocial: true,
              editableRenta: true
            })
          }
          if (employerData.tipoTrabajador == "4") {//Voluntario
            setEmployerData({
              ...employerData,
              entity_type: "4",
              ultimaRenta: 0,
              razonSocial: nombres + " " + apellidoPaterno + " " + apellidoMaterno,
              editableRazonSocial: false,
              editableRenta: false,
              editableRut: false,
              isNew: true
            })
          }
          // } 
        })
      }

        setShowModal(false)
      } else {
        setShowModal(false)
      }
    }

    }).catch((err) => {
      console.log(err)
    })
  }

  const getDataDsWeb = async () => {
    const body = {}
    await RentStatementService.getDsV2(body,stepperStepsData.userInformation.identificador).then((response) => {
      if (response.httpCode !== 200) {
        return false;
      }

      getProspectData(response.data.data);

      return true;
    }).catch((err) => {
      console.log(err)
    })
  }

  /**
   * Side Effects
   */
  useEffect(() => {
    if (reset) setEmployerData(children)

    getDataDsWeb();
  }, [children, reset])

  const handlerTooltipOnMouseOut = () => {
    setShowTooltip(false)
  }

  const handlerTooltipOnMouseOver = () => {
    setShowTooltip(true)
  }

  return (
    <>
      <div className={`employer-box`.trim()}>
        <div className="employer-box__index">{index}</div>
        <div className="row">
          <div className="col-6" style={{ borderRight: "1px solid #B7B7B7" }}>
            <div className="employer-box__fields">

              <div className="form-group">
                <label htmlFor={`${employerData.id}_rut`} className="form-label">
                  Rut Empleador
                </label>
                <input
                  type="text"
                  name={`${employerData.id}_rut`}
                  className={createFieldClassName(`${employerData.id}_rut`)}
                  value={format(`${employerData.rut}`)}
                  readOnly={!editable || !employerData.isNew || !employerData.editableRut}
                  onChange={rutOnChangeHanlder}
                  disabled={!editable || !employerData.isNew || !employerData.editableRut}
                  ref={register}
                />
                <ErrorMessage name={`${employerData.id}_rut`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="employer-box__fields">
              <div className="form-group">
                <label htmlFor={`${employerData.id}_name`} className="form-label">
                  Razón Social o Nombre
                </label>
                {showModal ?
                  <SpinnerLoader show={showModal} />
                  :
                  <input
                    type="text"
                    name={`${employerData.id}_name`}
                    className={createFieldClassName(`${employerData.id}_name`)}
                    value={employerData.razonSocial}
                    readOnly={!editable || !employerData.editableRazonSocial || rsEditable}
                    onChange={businessOnChangeHanlder}
                    disabled={!editable || !employerData.editableRazonSocial || rsEditable}
                    ref={register}
                  />
                }
                <ErrorMessage name={`${employerData.id}_name`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage>
              </div>
              {/* <Separator /> */}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-11">
            <hr style={{ margin: "1rem 0px 1rem 40px" }} />
          </div>
          <div className="col-1">
          <div className="employer-box__attachment position-absolute" style={{bottom: "70px"}}>
            <div className="attachment__tooltip2">
              <Tooltip visible={showTooltip} align="left">
                <div>
                  Adjuntar ultimas 6 liquidaciones de sueldo o pensiones, contrato de trabajo,
                  ultimas 6 plantillas de pago de cotizaciones a la AFP o ISP y/o certificado de renta
                  emitido por el empleador en formato JPG, GIF o PDF. Tamaño máximo 2MB, 6 archivos
                </div>
              </Tooltip>
              <em
                className="icon-alerta"
                onMouseOver={handlerTooltipOnMouseOver}
                onMouseOut={handlerTooltipOnMouseOut}
              />
            </div>
          </div>
            <div
              className={`employer-box__action ${editable && 'employer-box__action--show'
                }`.trim()}
            >
              <IconButton action={removeActionEventHandler} icon="eliminar" />
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6" style={{ borderRight: "1px solid #B7B7B7" }}>
            <div className="employer-box__fields">
              <div className="form-group">
                <label
                  htmlFor={`${employerData.id}_last-rent`}
                  className="form-label"
                >
                  Renta Promedio
                </label>
                <input
                  type="text"
                  name={`${employerData.id}_last-rent`}
                  className={createFieldClassName(`${employerData.id}_last-rent`)}
                  value={employerData.ultimaRenta}
                  readOnly={!editable || !employerData.editableRenta || rEditable}
                  onChange={rentOnChangeHanlder}
                  disabled={!editable || !employerData.editableRenta || rEditable}
                  ref={register}
                />
                <ErrorMessage name={`${employerData.id}_last-rent`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>


          <div className="col-6">
            <div className="employer-box__fields">
              <div className="form-group">
                <label
                  htmlFor={`${employerData.id}_tipo_empleado`}
                  className="form-label"
                >
                  Tipo de empleado
                </label>

                {showModal ?
                  <SpinnerLoader show={showModal} />
                  :

                  (!editable) ?
                    <input
                      type="text"
                      name={`${employerData.entity_type}`}
                      className={createFieldClassName(`${employerData.entity_type}`)}
                      value={
                        (employerData.entity_type === "1") ? 'Dependiente' :
                          (employerData.entity_type === "2") ? 'Independiente' :
                            (employerData.entity_type === "3") ? 'Pensionado' :
                              (employerData.entity_type === "4") ? 'Voluntario' :
                                ''
                      }
                      readOnly={!editable}
                      onChange={rentOnChangeHanlder}
                      disabled={!editable}
                      ref={register}
                    />
                    :
                    <select
                      id={`${employerData.id}_tipo_empleado`}
                      name={`${employerData.id}_tipo_empleado`}
                      className={createFieldClassName(`${employerData.id}_tipo_empleado`)}
                      onChange={entityTypeOnChangeHanlder}
                      disabled={!editable}
                      ref={register}
                    >
                      <option value="1" selected={(employerData.entity_type === "1") ? true : false}>Dependiente</option>
                      <option value="2" selected={(employerData.entity_type === "2") ? true : false}>Independiente</option>
                      <option value="3" selected={(employerData.entity_type === "3") ? true : false}>Pensionado</option>
                      <option value="4" selected={(employerData.entity_type === "4") ? true : false}>Voluntario</option>
                    </select>

                }

                <ErrorMessage name={`${employerData.id}_last-rent`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage>
              </div>

            </div>
          </div>
        </div>

        <div className="employer-box__attachment">
          <div className="attachment__list">
            {files.map((item, i) => (
              <div
                className="attachment__list-item"
                key={`${item.name}_${item.lastModified}`}
              >
                <em className="icon-archivo" />
                <span>{item.name.split('.')[0]}</span>
                {editable && (
                  <IconButton
                    text=""
                    icon="eliminar"
                    action={() => removeFileAction(i)}
                  />
                )}
              </div>
            ))}
          </div>
          {/* {editable && employerData.isNew && ( */}
          {editable && (
            <div className="attachment__actions">
              <div>
                <InputFile
                  onChange={(e) => onChangeFiles(e, employerData.id)}
                  mimetypes={['.jpg', '.jpeg', 'application/pdf', '.gif']}
                  name={`${employerData.id}_files`}
                  register={register}
                  cssClass={createFieldClassName(`${employerData.id}_files`)}
                  value={files.map((item) => item.name).join(',')}
                />
                {/* <ErrorMessage name={`${employerData.id}_files`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage> */}
              </div>
              <div className='attachment__divLeyend'>
                  <p>
                    {employerData.entity_type === '2' || employerData.entity_type === '4'
                      ? `Adjuntar iniciación de actividades, declaración de renta, boleta de honorarios, recursos propios en formato JPG, GIF o PDF. Tamaño máximo 2MB, 6 archivos.`
                      : `Adjuntar ultimas 6 liquidaciones de sueldo o pensiones, contrato de trabajo, ultimas 6 planillas de pago de cotizaciones a la AFP o ISP y/o certificado de renta emitido por el empleador en formato JPG, GIF o PDF. Tamaño máximo 2MB, 6 archivos.`}
                  </p>
              </div>
              {/*<div
                className={`attachment__tooltip ${typeof errors[`${children.id}_files`] === 'object'
                  ? 'attachment__tooltip--invalid'
                  : ''
                  }`.trim()}
              >
                <Tooltip visible={showTooltip} align="left">
                  <b>
                    {employerData.entity_type === '2' || employerData.entity_type === '4'
                      ? `Adjuntar iniciación de actividades, declaración de renta, boleta de honorarios, recursos propios en formato JPG, GIF o PDF. Tamaño máximo 2MB, 6 archivos.`
                      : `Adjuntar ultimas 6 liquidaciones de sueldo o pensiones, contrato de trabajo, ultimas 6 planillas de pago de cotizaciones a la AFP o ISP y/o certificado de renta emitido por el empleador en formato JPG, GIF o PDF. Tamaño máximo 2MB, 6 archivos.`}
                  </b>
                </Tooltip>
                <em
                  className="icon-info-3"
                  onMouseOver={() => handlerTooltipOnHover(true)}
                  onMouseOut={() => handlerTooltipOnHover(false)}
                  onBlur={() => handlerTooltipOnHover(false)}
                  onFocus={() => handlerTooltipOnHover(true)}
                />
                </div>*/}
            </div>
          )}
        </div>
        <div className='row'>
          <div className="col-11">
            <hr style={{ margin: "1rem 0px 1rem 40px" }} />
          </div>
          <div className='col-12 text-center'>
            <div className="employer-box__fields">
                <div className="form-group">
                  <label htmlFor={`${employerData.id}_rut`} className="form-label">
                    Vigente
                  </label>
                  <div className="actions actions--active">
                    <div className="form-group__radio-options d-flex justify-content-center">
                      <label className='d-flex' htmlFor={`${employerData.id}_vigente`}>
                      <div
                          className={`ns-radio m-0 ${
                            !editable && 'ns-radio--disabled'
                          }`.trim()}
                          onClick={()=> editable && vigenteOnChangeHanlder("SI")}
                          role="button"
                          tabIndex={0}
                          onKeyPress={() => null}
                        >
                          <input
                            className='ns-radio'
                            type="radio"
                            name={`${employerData.id}_vigente`}
                            ref={register}
                            onChange={()=>vigenteOnChangeHanlder("SI")}
                            value={"SI"}
                            defaultChecked={employerData.vigente === true}
                            checked={employerData.vigente === true}
                            readOnly={!editable}
                            disabled={!editable}
                          />
                          <div className="ns-radio__marker" />
                          <span className="ns-radio__text">{"SI"}</span>
                        </div>
                        <div
                          className={`ns-radio m-0 ${
                            !editable && 'ns-radio--disabled'
                          }`.trim()}
                          onClick={()=> editable && vigenteOnChangeHanlder("NO")}
                          role="button"
                          tabIndex={0}
                          onKeyPress={() => null}
                        >
                          <input
                            className='ns-radio'
                            type="radio"
                            name={`${employerData.id}_vigente`}
                            ref={register}
                            onChange={()=>vigenteOnChangeHanlder("NO")}
                            value={"NO"}
                            defaultChecked={employerData.vigente === false}
                            checked={employerData.vigente === false}
                            readOnly={!editable}
                            disabled={!editable}
                          />
                          <div className="ns-radio__marker" />
                          <span className="ns-radio__text">{"NO"}</span>
                          </div>
                        </label>
                        <ErrorMessage name={`${employerData.id}_last-rent`} errors={errors}>
                          {({ message }) => <div className="invalid-feedback">{message}</div>}
                        </ErrorMessage>
                    </div>
                  </div>
                </div>
            </div>
          </div>
        </div>

        <ConfirmMessage
          confirm="Eliminar"
          cancel="Cancelar"
          isShowing={showMessage}
          confirmAction={confirmAction}
          cancelAction={cancelAction}
        >
          <div>¿Estás seguro que deseas eliminar este empleador?</div>
        </ConfirmMessage>

      </div>
    </>
  )
}
Employer.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rut: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    razonSocial: PropTypes.string.isRequired,
    ultimaRenta: PropTypes.number.isRequired,
  }).isRequired,
  editable: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func,
  onChangeFiles: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  reset: PropTypes.bool,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
}

Employer.defaultProps = {
  editable: false,
  reset: false,
  onRemoveFile: () => { },
  files: [],
}

export default Employer
