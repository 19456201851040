
var moment = require('moment'); // require
/**
   * @description Calculate the age of a person from today
   * @param {date} date string with format 'dd-mm-yyyy'
   */
export default function getAge(date) {
  const birthdate = moment(date,'DD-MM-YYYY').format('YYYY-MM-DD')
  return moment().diff(birthdate, 'years');
}

