import React from 'react';
import './call-center-message.scss'

import MessageManager from '@commons/message-manager/elements/message-manager'

const CallCenterMessage = () => {

    const handleRedirectPage = () => {
        window.location = 'https://www.nuevamasvida.cl/'
    }

    return (
        <MessageManager
            action={() => handleRedirectPage()}
            messages="Una vez que recibas el llamado y comprobemos tu identidad te enviaremos un correo para continuar con el proceso de postulación."
            textButton="Ir a Nueva Masvida"
            isShowing={true}
            iconColor="orange"
        />
    );
};

export default CallCenterMessage;