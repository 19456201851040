import React, { useContext } from 'react'
import RegisterForm from '@components/start-subscription/elements/register-form'
import { StartSubscriptionContext } from '@components/start-subscription'

const StartSubscription = () => {
  const { startSubscriptionData } = useContext(StartSubscriptionContext)
  return (
    <div className="start-sub">
      <h2 className="start-sub__title">Información del Usuario</h2>
      <div className="start-sub__message">{startSubscriptionData.message}</div>
      <div className="start-sub__form-wrapper">
        <RegisterForm />
      </div>
    </div>
  )
}

export default StartSubscription
