import React from 'react'
import './downloader.scss'
import PropTypes from 'prop-types'

const Downloader = ({ text, fileUrl }) => (
  <a
    href={`data:application/pdf;base64,${fileUrl}`}
    download={`${text.replace(' ', '_')}.pdf`}
    className="downloader"
  >
    <em className="icon-archivo" />
    <span>{text}</span>
    <em className="icon-descargar" />
  </a>
)

Downloader.propTypes = {
  text: PropTypes.string,
  fileUrl: PropTypes.string,
}

Downloader.defaultProps = {
  text: 'Archivo',
  fileUrl: '',
}

export default Downloader
