import React, { useState, useContext, useEffect } from 'react'
import { WizardContext, WizardService } from '@commons/steppers/wizard/'
import { BenefIdentContext } from '@components/beneficiary-identification'
import { StepperStepsService, StepperStepsContext } from '@commons/steppers/steps'
import EditBeneficiaryAlterable from '@components/beneficiary-identification/elements/alterable/benef-ident-edit-alterable'
import EditBeneficiaryUnalterable from '@components/beneficiary-identification/elements/unalterable/benef-ident-edit-unalterable'
import ErrorMessage from '@commons/messages/error/error-message'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import Button from '@commons/buttons/button'
import moment from 'moment'
import _ from 'lodash'
import origin from '@utils/origin-invitation'

const BenefIdentification = () => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [beneficiary, setBenef] = useState(wizardData.beneficiary)
  const { wizard } = useContext(BenefIdentContext)
  const [loading, setLoading] = useState(true)
  const [sending, setSending] = useState(false)
  const [message, setMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [valid, setValid] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))
  const rutPrincipal = JSON.parse(localStorage.getItem('userInformation')).rut
  
  useEffect(()=>{
    setBenef(wizardData.beneficiary)
  },[wizardData.beneficiary])

  useEffect(()=>{
    setLoading(true)
    WizardDispatch(WizardActions.refreshProgress())
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
    }
    WizardService.getBeneficiaries(body).then((response) => {
      let benefList = []
      if(response.httpCode === 200){
        if(_.size(response.data)){
          response.data.map(b => { 
            const beneficiaryAux = {
              id: b.id,
              names: b.nombres ? b.nombres : '',
              paternalSurname: b.apellidoPaterno,
              maternalSurname: b.apellidoMaterno,
              rut: b.rutBeneficiario ? b.rutBeneficiario : '',
              relation: b.relacionFamiliar,
              birthdate: b.fechaNacimiento ? compareDates(b.fechaNacimiento) : '',
              sex: b.sexo ? b.sexo : '',
              type: b.tipoBeneficiario,
              weight: b.peso !== null ? String(b.peso) : '',
              height: b.altura ? b.altura : '',
              order: b.orden,
              other: b.descripcionOtro,
            }
            return benefList.push(beneficiaryAux)
          })
            if((esCargaTitular) && (tieneCargas)){
               benefList = benefList.filter(
                (itemBenef) => itemBenef.rut.trim() !== rutPrincipal.trim()
              );
            }
          WizardDispatch(WizardActions.setBeneficiary(benefList))
          setLoading(false)
        }else{
          setLoading(false)
          setMessage('No se han podido obtener los beneficiarios del cotizante')
          setShowErrorMessage(true)
        }
      } else {
        setLoading(false)
        setMessage('No se han podido obtener los beneficiarios del cotizante')
        setShowErrorMessage(true)
      }
    }).catch(e =>{
      setLoading(false)
      setMessage('No se han podido obtener los beneficiarios del cotizante')
      setShowErrorMessage(true)
    }) 
  },[stepperStepsData, WizardActions, WizardDispatch, esCargaTitular, tieneCargas, rutPrincipal])


  function handleAddBeneficiary(){
    WizardDispatch(WizardActions.addBeneficiary())
  }

  function handleRemoveBeneficiary(benefId){
    WizardDispatch(WizardActions.removeBeneficiary(benefId))
  }

  function compareDates(date) {
    if (date) {
      var isafter = moment('01-01-1900', 'DD-MM-YYYY').isAfter(moment(date,'DD-MM-YYYY')); // true
      if(isafter === true){
          return ''
      } else {
          return date
      }
    } else {
      return ''
    }
    
  }

  /**
   * @description handle onClick for next step
   */
  function nextStep() {
    let benefList = []
    beneficiary.map( (b,i) => {
      const benefAux = {
        id: i,
        nombres: b.names,
        apellidoPaterno: b.paternalSurname,
        apellidoMaterno: b.maternalSurname,
        rutBeneficiario: b.rut.trim(),
        relacionFamiliar: parseInt(b.relation),
        fechaNacimiento: b.birthdate,
        sexo: parseInt(b.sex),
        tipoBeneficiario: parseInt(b.type),
        peso: parseInt(b.weight),
        altura: b.height,
        orden: i+1,
        descripcionOtro: b.other ? b.other : null,
      }
      return benefList.push(benefAux)
    })

    if(_.size(benefList) > 1){

      const loq = benefList.find((item) => {
        return ((parseFloat(item.peso) <= 0) || (parseFloat(item.altura) <= 0))
      })

      const loqa = benefList.find((row,i) => {
        if(i>0){
          return (benefList[0].rutBeneficiario === row.rutBeneficiario)
        }
        return false
      })


      const rutBeneficiario = benefList.map(beneficiario => beneficiario.rutBeneficiario)
      const invalidoRut = new Set([...rutBeneficiario]).size === rutBeneficiario.length

      if(loqa){
        setSending(false)
        setShowErrorMessage(true)
        setMessage('El rut de los beneficiario no puede ser el mismo que el rut del titular')
        return
      }

    if(invalidoRut === false){
        setSending(false)
        setShowErrorMessage(true)
        setMessage('El rut de los beneficiario no se puede repetir')
        return
    }

      if(loq){
          setSending(false)
          setShowErrorMessage(true)
          setMessage('Peso y altura debe ser mayor a "0"')
          return
      }

    }

    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      beneficiario: benefList
    }
    setSending(true)
    WizardService.verifyBeneficiaries(body).then((response) => {
      if(response.httpCode === 200){
        if(_.size(response.data) && _.size(response.data.errores) > 0){
          let errores = 'Se han encontrado los siguiente(s) error(es):'
          response.data.errores.map((e)=>{
            errores = ` ${errores} ${e.descripcion}.`
            return errores
          })
          setMessage(errores)
          setShowErrorMessage(true)
          setSending(false)
        }else{
          WizardService.saveBeneficiaries(body).then((response) => {
            if(response.httpCode === 200){
              const body2 = {
                identificador: stepperStepsData.userInformation.identificador,
                rut: stepperStepsData.userInformation.rut,
                etapa: 'beneficiarios',
                subetapa: '',
                fechaResgistro: moment().format('DD-MM-YYYY')
              }
              StepperStepsService.sendStep(body2).then((response) => {
                if(response.httpCode === 200){
                  WizardDispatch(WizardActions.setCompleteSubStep({ name: 'beneficiarios' }))
                  wizard.next()
                }else{
                  setSending(false)
                  setShowErrorMessage(true)
                  setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
                }
              }).catch(() => {
                setSending(false)
                setShowErrorMessage(true)
                setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
              })
            }else{
              setMessage('No se han podido guardar los benficiarios del cotizante')
              setShowErrorMessage(true)
              setSending(false)
            }
          }).catch( e =>{
            setMessage('No se han podido guardar los benficiarios del cotizante')
            setShowErrorMessage(true)
            setSending(false)
          })
        }
        
      }else{
        setMessage('No se han podido guardar los benficiarios del cotizante')
        setShowErrorMessage(true)
        setSending(false)
      }
    }).catch( response => {
        setMessage('No se han podido guardar los benficiarios del cotizante')
        setShowErrorMessage(true)
        setSending(false)
    })
  }
  
  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

  return (
    <div className="beneficiary-ident">
      <h2 className="beneficiary-ident__title">
        Identificación de Beneficiarios
      </h2>
      { stepperStepsData.isAsisted && (stepperStepsData.originInvitation) === origin.afiliacionAsistida ? 
        <>
          <div className="beneficiary-ident__message">
            El siguiente paso es revisar tus Beneficiarios y/o Cargas Médicas, debes
            agregar o eliminar las que no correspondan
          </div>
          <div className="text-center">
            <SpinnerLoader show={loading} />
          </div>

          { _.size(beneficiary) ?
            <EditBeneficiaryAlterable
              beneficiary={beneficiary} 
              addBeneficiary={()=>{handleAddBeneficiary()}}
              removeBeneficiary={(benefId)=>{handleRemoveBeneficiary(benefId)}}
              isEditing={(e) => setIsEditing(e)}
              isValid={(e) => setValid(e)}
            />
          : null }
          <div className="beneficiary-ident__button">
            <Button 
              text="Continuar" 
              action={nextStep}
              cssClass="button__continue"
              disabled={loading || isEditing || sending || !valid} 
              />
          </div>
        </>
      :
      <>
        <div className="beneficiary-ident__message">
          El siguiente paso es revisar tus Beneficiarios y/o Cargas Médicas, debes agregar los datos correspondientes
        </div>
        <div className="text-center">
          <SpinnerLoader show={loading} />
        </div>

        { _.size(beneficiary) ?
          <EditBeneficiaryUnalterable 
            beneficiary={beneficiary} 
            addBeneficiary={()=>{handleAddBeneficiary()}}
            removeBeneficiary={(benefId)=>{handleRemoveBeneficiary(benefId)}}
            isValid={(e) => setValid(e)}
          />
        : null }
        <div className="beneficiary-ident__button">
          <Button 
            text="Continuar" 
            action={nextStep}
            cssClass="button__continue"
            disabled={loading || !valid || sending} 
            />
        </div>
      </>
      }

      
      <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
        {message}
      </ErrorMessage>

    </div>
  )
}

export default BenefIdentification
