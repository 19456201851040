import {
  FILL_DOCUMENT_LIST,
  SET_ALL_AS_READ,
  SET_ALL_AS_SIGN,
} from './sign-documents-actions'

export default function StartSubscriptionReducer(state, action) {
  switch (action.type) {
    case FILL_DOCUMENT_LIST:
      return {
        ...state,
        documents: action.collection,
      }
    case SET_ALL_AS_READ:
      return {
        ...state,
        allRead: !action.allRead,
      }
    case SET_ALL_AS_SIGN:
      return {
        ...state,
        allSigned: true,
      }
    default:
      return state
  }
}
