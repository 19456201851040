import React, { useContext, useEffect, useState } from 'react'
import './identity-form.scss'
import { useHistory } from 'react-router-dom'
import Button from '@commons/buttons/button'
import TextGroup from '@commons/inputs/text-group'
import IconButton from '@commons/buttons/icon-button'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import ErrorMessage from '@commons/messages/error/error-message'
import SimpleModal from '@commons/messages/simple-modal/modal'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { fieldsErrorsMessages, rutSchema } from '@utils/fields-validators'
import {
  ValidateIdentityContext,
  ValidateIdentityService,
} from '@components/validate-identity'
import { WizardContext } from '@commons/steppers/wizard'
import { StepperStepsContext } from '@commons/steppers/steps'
import { format } from 'rut.js'
import Cleave from 'cleave.js/react'
import Checkbox from '@components/commons/inputs/checkbox'
import Cedula from '@assets/images/cedula.png'
import InfoMessage from '@commons/messages/info/info-message'
import moment from 'moment'

const IdentityForm = () => {

  const History = useHistory()

  // Contexts
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { ValidateIdentityActions, ValidateIdentityDispatch } = useContext(ValidateIdentityContext)

  // Local Variables
  const [showError, setShowError] = useState(false)
  const [numDoc, setNumDoc] = useState('')
  const [sending, setSending] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [dateLocked, setDateLocked] = useState('')
  const [dateUnlocked, setDateUnlocked] = useState('')
  const [showMessageError, setShowMessageError] = useState(false)
  const [confirm, setConfirm] = useState(false)
  const [showErrorCallCenter, setShowErrorCallCenter] = useState(false)

  const [days, setDays] = useState(0)
  const [hours, setHours] = useState(0)
  const [minutes, setMinutes] = useState(0)
  const [seconds, setSeconds] = useState(0)

  /**
   * Form Schema Validator
   */
  const schema = yup.object().shape({
    rut: rutSchema,
    nroDocumento: yup.string().required('Debe ingresar el número de documento'),
  })
  const { getValues, register, handleSubmit, errors } = useForm({
    validationSchema: schema,
  })

  /**
   * @description Handler Submit Form Event
   */
   function handleFormSubmit() {

    const values = getValues()

    const body = {
      rut: values.rut.replace(/[.-]/g,''),
      nroDocumento: values.nroDocumento.replace(/[.]/g,'')
    }

    setSending(true)

    ValidateIdentityService.getQuestions(body).then((response) => {
      
      if ( response.httpCode === 200 ) {
        if (!response.data.codigoError) {
          WizardDispatch(WizardActions.setCompleteSubStep({ name: 'validacion' }))
          ValidateIdentityDispatch(ValidateIdentityActions.setQuestionnaire(response.data))
          ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(1))
          setSending(false)

        } else if(response.data.codigoError === "400"
          && (
              (response.data.descripcion === 'El documento no esta vigente')
          || (response.data.descripcion === 'Timeout equifax')
          || (response.data.descripcion === 'Falla en el reconocimiento de patrones')
          || (response.data.descripcion === 'Fuente de datos no disponible')
          || (response.data.descripcion === 'Fuente de Datos Externa no disponible')
          || (response.data.descripcion === 'Cédula de identidad expirada')
          || (response.data.descripcion === 'Sistema no disponible')
          || (response.data.descripcion === 'Error en el sistema')
          || (response.data.descripcion === 'Cédula de Identidad Inválida')
          || (response.data.descripcion === 'Usuario con rut bloqueado')
          || (response.data.descripcion === 'Equifax no disponible forzado')
       )){
            setShowErrorCallCenter(true)
        } else {
          setShowMessageError(true)
          setDateLocked(response.data.fechaBloqueo)
          setDateUnlocked(response.data.fechaDesbloqueo)
          setSending(false)
          ValidateIdentityDispatch(ValidateIdentityActions.setLoading(false))
          ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(5))
          
        } 
      } else {
        setSending(false)
        setShowError(true)
      }
    }).catch(() => {
      
      setSending(false)
      setShowError(true)
    })
  }

  useEffect(() => {
    if (dateLocked !== '' && dateUnlocked !== '') {
      setInterval(() => {
        var diffDays = moment(dateUnlocked, 'MM/DD/YYYY HH:mm:ss A').diff(moment(), 'days')
        var diffHours = moment(dateUnlocked, 'MM/DD/YYYY HH:mm:ss A').diff(moment(), 'hours')
        var diffMinutes = moment(dateUnlocked, 'MM/DD/YYYY HH:mm:ss A').diff(moment(), 'minutes')
        var diffSeconds = moment(dateUnlocked, 'MM/DD/YYYY HH:mm:ss A').diff(moment(), 'seconds')
        setDays(diffDays)
        setHours(diffHours < 24 ? diffHours : diffHours % 24)
        setMinutes(diffMinutes < 60 ? diffMinutes : diffMinutes % 60)
        setSeconds(diffSeconds < 60 ? diffSeconds : diffSeconds % 60)
      }, [1000])
    }
  }, [dateLocked, dateUnlocked])

  /**
   * Side Effects
   */
  useEffect(() => {
    fieldsErrorsMessages()
  }, [])


  function handleNumChange(e){
    setNumDoc(e.target.value)
  }

  /**
   * @description Handler close error message
   */
  function handleCloseErrorMessage() {
    setShowError(false)
  }

  function handleRedirectPage() {
    window.location = 'https://www.nuevamasvida.cl/'
  }

  function handleRedirectFormCallCenter() {

    WizardDispatch(WizardActions.setAuthenticationPage('callcenter'))
  }

  return !showMessageError ? (
    <div className="identity-form">
      <div className="identity-form__goback-holder">
        <IconButton icon="volver" action={() => History.goBack()} />
      </div>
      <div className="d-flex">
        <div style={{ flex: 1 }}>
          <TextGroup
            register={register}
            name="rut"
            error={errors.rut}
            label="RUT"
            value={format(stepperStepsData.userInformation.rut)}
            editable={false}
          />
          <div className="text-group form-group">
            <input
              hidden
              name="nroDocumento"
              type="text"
              ref={register}
              value={numDoc}
              onChange={() => {}}
            />
            <Cleave
              placeholder=""
              className={`form-control ${
                errors.nroDocumento && "is-invalid"
              }`.trim()}
              options={{ blocks: [10], numericOnly: false }}
              onChange={handleNumChange}
            />
            <label htmlFor="" className="form-label">
              Número de documento
            </label>
            {errors.nroDocumento && (
              <div className="invalid-feedback">
                {errors.nroDocumento.message}
              </div>
            )}
          </div>
        </div>

        <div className="identity-form__info">
          <div className="identity-form__icon">
            <div className="identity-form__tooltip">
              <SimpleModal
                isShowing={showModal}
                action={() => setShowModal(false)}
              >
                <div>
                  <p>
                    El <strong>número de documento</strong> o{" "}
                    <strong>de serie</strong> es el número único que está
                    impreso en tu cédula de identidad.
                  </p>
                  <p>
                    En las cédulas nuevas se encuentran en el anverso y en las
                    cédulas antiguas en el reverso.
                  </p>
                  <img src={Cedula} alt="Cédula de identidad" width="550" />
                </div>
              </SimpleModal>
            </div>
            <em
              className="icon-alerta"
              onClick={() => {
                setShowModal(true);
              }}
              // onMouseOver={handlerTooltipOnMouseOver}
              // onMouseOut={handlerTooltipOnMouseOut}
            />
          </div>
        </div>
      </div>

      <div className="pt-3 my-3">
        <Checkbox
          name="confirm"
          text="Al continuar autorizo a mi AFP a entregar por medio de
        Previred mis 12 o 24 últimas cotizaciones a la institución ,
        con el fin de ser considerados como antecedentes a esta
        solicitud comercial, dando así cumplimiento al articulo 4° de
        la ley N° 19.628 sobre Protección de la vida Privada y a lo
        dispuesto en la ley N° 19.799 sobre Documentos Electrónicos
        Firma Electrónica y Servicios de Certificación de dicha firma."
          clickMethod={() => setConfirm(!confirm)}
        />
      </div>
      <div className="identity-form__buttons">
        {!sending ? (
          <Button
            text="Continuar"
            action={handleSubmit(handleFormSubmit)}
            disabled={!confirm}
          />
        ) : (
          <SpinnerLoader show={sending} text="Autenticando" />
        )}
      </div>
      <ErrorMessage isShowing={showError} action={handleCloseErrorMessage}>
        <div className="identity-form__alert">
          No se ha podido autenticar, intentalo mas tarde.
        </div>
      </ErrorMessage>
      <ErrorMessage cancelBtnText="Continuar" isShowing={showErrorCallCenter} action={handleRedirectFormCallCenter}>
        <div className="identity-form__alert">
          No Hemos podido verificar tu Identidad. Presiona Continuar para ofrecerte una autenticación alternativa para que puedas avanzar en tu proceso de postulación.
        </div>
      </ErrorMessage>
    </div>
  ) : (
    <div className="identity-form">
      <div className="identity-form__error">
        <InfoMessage
          isShowing={true}
          iconColor="orange"
          floating={false}
          locked={false}
        >
          <div className="identity-form__statement">
            Lamentablemente tus respuestas son incorrectas, y por ahora no
            dispones de más intentos.
          </div>
          <div className="identity-form__statement">
            Vuelve a intentarlo dentro de:
          </div>
          <div className="identity-form__timer">
            <div className="identity-form__section">
              <div className="identity-form__number">{days}</div>
              <div className="identity-form__label">Dias</div>
            </div>
            <div className="identity-form__separador">:</div>
            <div className="identity-form__section">
              <div className="identity-form__number">
                {hours < 10 ? "0" : null}
                {hours}
              </div>
              <div className="identity-form__label">Horas</div>
            </div>
            <div className="identity-form__separador">:</div>
            <div className="identity-form__section">
              <div className="identity-form__number">
                {minutes < 10 ? "0" : null}
                {minutes}
              </div>
              <div className="identity-form__label">Minutos</div>
            </div>
            <div className="identity-form__separador">:</div>
            <div className="identity-form__section">
              <div className="identity-form__number">
                {seconds < 10 ? "0" : null}
                {seconds}
              </div>
              <div className="identity-form__label">Segundos</div>
            </div>
          </div>
          
        </InfoMessage>

      </div>
      <div className="identity-form__buttons">
          <Button
            text="Ir a Nueva Masvida"
            action={() => handleRedirectPage()}
          />
        </div>
    </div>
  );
}

export default IdentityForm
