import React, { useContext, useState } from "react";
import "./heath-statement-uneditable.scss";
import AlertMessage from "@commons/messages/alert/alert-message";
import Button from "@commons/buttons/button";
import ErrorMessage from "@commons/messages/error/error-message";
import { WizardContext } from "@commons/steppers/wizard";

const HealthStatementUneditable = ({
  num,
  openQuestion,
  cancelAction,
  setOpenQuestion,
  isSummary,
}) => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(
    WizardContext
  );

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  /**
   * @description Handler Submit Form Event
   */
  function handleFormSubmit() {
    if (!isSummary) {
      WizardDispatch(
        WizardActions.setFormYesAnswer(wizardData.questions[num - 1])
      )
      if (num === wizardData.nextQuestionToAnswer) {
        WizardDispatch(WizardActions.setNextQuestionToAnswer(num + 1))
      }
      setOpenQuestion(false)
    } else {
      cancelAction()
    }
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false);
  }

  return (
    openQuestion && (
      <div className="health-statement-uneditable">
        <div className="health-statement-uneditable__question">
          <div className="health-statement-uneditable__alert">
            <AlertMessage>
              <>
                <div className="health-statement-uneditable__title">
                  IMPORTANTE
                </div>
                <div className="health-statement-uneditable__message">
                  Esta pregunta se suprime basado en la indicación de la Superintendencia de Salud
                </div>
              </>
            </AlertMessage>
          </div>
        </div>
        <div className="health-statement-uneditable__button">
          <Button text="Siguiente" action={handleFormSubmit} />
        </div>
        <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
          No se ha podido guardar la respuesta, vuelve a intentalor más tarde.
        </ErrorMessage>
      </div>
    )
  );
};

export default HealthStatementUneditable;
