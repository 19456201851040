import './icon-button.scss'
import React from 'react'
import PropTypes from 'prop-types'

const IconButton = ({ action, icon, title, disabled }) => (
  <button
    type="button"
    className="icon-button"
    onClick={action}
    disabled={disabled}
    title={title}
  >
    <em className={`icon-${icon}`} />
  </button>
)
IconButton.propTypes = {
  action: PropTypes.func.isRequired,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  disabled: PropTypes.bool,
}
IconButton.defaultProps = {
  title: '',
  disabled: false,
}
export default IconButton
