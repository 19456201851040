import {
  SET_CHANGED_EMAIL,
  SET_TITLE,
  SET_RESULT_DATA,
  SET_DS_DATA,
  SET_STATUS,
  SET_LOADING,
  SET_ERROR,
} from './health-statement-sent-actions'

export default function HealthStatementSentReducer(state, action) {
  switch (action.type) {
    case SET_CHANGED_EMAIL:
      return {
        ...state,
        changedEmail: action.payload.changed,
        email: action.payload.email ? action.payload.email : state.email,
      }
    case SET_TITLE:
      return {
        ...state,
        title: state.titles[action.payload],
      }
    case SET_RESULT_DATA:
      return {
        ...state,
        resultData: { ...action.payload },
      }
    case SET_DS_DATA:
      return {
        ...state,
        dsData: { ...action.payload },
      }
    case SET_STATUS:
      return {
        ...state,
        status: action.payload,
      }
    case SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      }
    case SET_ERROR:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}
