import './question.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const Question = ({ num, id, title, options, setAnswer }) => {
  const [checked, setChecked] = useState(false)
  function handlerQuestionOnChange(idQuestion, idAns) {
    setChecked(true)
    setAnswer(idQuestion, idAns)
  }
  return (
    <div className="question__container">
      <div className="question__title-box">
        <div
          className={`question__number ${
            checked ? 'question__number--checked' : ''
          }`.trim()}
        >
          {num}
        </div>
        <div className="question__title">
          <span>{title}</span>
        </div>
      </div>
      <div className="question__content">
        {options.map((item) => (
          <label key={item.id}>
            <input
              type="radio"
              name={id}
              value={item.id}
              onChange={() => handlerQuestionOnChange(id, item.id)}
            />
            {item.text}
          </label>
        ))}
      </div>
    </div>
  )
}
Question.propTypes = {
  num: PropTypes.number.isRequired,
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired,
    })
  ).isRequired,
  setAnswer: PropTypes.func.isRequired,
}

export default Question
