/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react'
import Button from '@commons/buttons/button'
import { WizardContext } from '@commons/steppers/wizard'

const ProcessFinalization = () => {
  const { WizardDispatch, WizardActions } = useContext(WizardContext)

  useEffect(() => {
    WizardDispatch(WizardActions.setCompleteSubStep({ name: 'firmaIsapre' }))
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [])
  
  /**
   * @description handle onClick for next step
   */
  function handleNextStep() {
    window.location.assign("https://www.nuevamasvida.cl/")
  }

  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  return (

    <div className="process-fin">
        <h2 className="process-fin__title">Finalización de Proceso</h2>
        <div className="process-fin__message">
        <p className="process-fin__subtitle">
          <span className="process-fin__hightligth">Tu proceso de suscripción ha finalizado.</span> 
        </p>
        <p className="process-fin__text">
            Te hemos enviado un correo electrónico con<br/>
            tu <span className="process-fin__hightligth">clave de acceso</span> a la Sucursal Virtual y tus <br/>
            <span className="process-fin__hightligth"> documentos contractuales.</span>
        </p>
        </div>
        <div className="process-fin__buttons">
          <Button
              text="Finalizar"
              action={handleNextStep}
              cssClass="button__continue"
          />
        </div>
    </div>
  )
}
export default ProcessFinalization
