const SET_CHANGED_EMAIL = 'SET_CHANGED_EMAIL'
const SET_TITLE = 'SET_TITLE'
const SET_RESULT_DATA = 'SET_RESULT_DATA'
const SET_DS_DATA = 'SET_DS_DATA'
const SET_STATUS = 'SET_STATUS'
const SET_LOADING = 'SET_LOADING'
const SET_ERROR = 'SET_ERROR'

function setChangedEmail(payload) {
  return { type: SET_CHANGED_EMAIL, payload }
}

function setTitle(payload) {
  return { type: SET_TITLE, payload }
}

function setResultData(payload) {
  return { type: SET_RESULT_DATA, payload }
}

function setDSData(payload) {
  return { type: SET_DS_DATA, payload }
}

function setStatus(payload) {
  return { type: SET_STATUS, payload }
}

function setLoading(payload) {
  return { type: SET_LOADING, payload }
}

function setError(payload) {
  return { type: SET_ERROR, payload }
}

export {
  SET_CHANGED_EMAIL,
  SET_TITLE,
  SET_RESULT_DATA,
  SET_DS_DATA,
  SET_STATUS,
  SET_LOADING,
  SET_ERROR,
  setChangedEmail,
  setTitle,
  setResultData,
  setDSData,
  setStatus,
  setLoading,
  setError,
}