import RequestService from '@core/request-service'

export default class StepperStepsService extends RequestService {
  static sendStep(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )
        ? '/api/v1/GuardarEtapa'
        : 'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  static getStep(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )
        ? '/api/v1/ObtenerEtapa'
        //: 'https://run.mocky.io/v3/96d42930-a195-47d4-9528-3d17b94a1673', //inicio
        //: 'https://run.mocky.io/v3/1b063c96-0262-44de-b2e4-9f92a131379c', // validacion
        //: 'https://run.mocky.io/v3/5a365267-117b-4676-b36f-a9118b8fb828', //renta
        //: 'https://run.mocky.io/v3/66aa7ca4-5ef7-46a2-893a-f43135e46a29', //resumenDeclaracion
        : 'https://run.mocky.io/v3/4c17fe06-1f48-42e6-b6a6-f37a9532708e', //firma
        payload
    )
    // return super.get('http://localhost:4000/stages.json')
  }

  // api/inicioproceso
  static startProcess(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )
        ? '/api/InicioProceso'
        : 'https://run.mocky.io/v3/ba6956c4-5e8b-47a5-b6e3-e9c1139f88f9',
      payload
    )
  }

  static recoverPassword(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )
        ? '/api/v2/ObtenerClave'
        : 'https://www.mocky.io/v2/5eda7945330000181a79ec53',
      payload
    )
  }

  static getFluxOrigin(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )
      ? '/api/v2/obtenerOrigenFlujo'
      // 'https://run.mocky.io/v3/685cf941-4c65-456d-a397-d505fded027c', // dashboard venta manual
      //  : 'https://run.mocky.io/v3/1e615a5c-034e-4982-ac5e-d2356fbbe27d', // dashboard  venta electronica
      //  : 'https://run.mocky.io/v3/300c0b90-7f05-4fa2-b982-c155f8376da0', // afiliacion asistida
      //  : 'https://run.mocky.io/v3/bc0af26b-e528-4938-9dcf-42fa95e9f854', // cotizador nuevo
      //  : 'https://run.mocky.io/v3/fcc7179b-3fdd-4638-a334-4a9bc984b6fd', // cotizador actual
        :'https://run.mocky.io/v3/7f59146c-34ac-4f08-9063-4e584266545d',
      payload
    )
  }

  static validarCargaATitular(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )
      ? '/api/v1/validarCargaATitular'
        : 'https://run.mocky.io/v3/4e8ec3b4-db45-47db-a12e-a65bf77e4cc0', // esCarga true tieneCarga true
        //: 'https://run.mocky.io/v3/5168b90b-8aa7-4d9b-8377-89b024f226ec', // esCarga true tieneCarga false
      payload
    )
  }

  static contactHelp(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )
        ? '/api/v1/ContactarEjecutivo'
        : 'http://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  static getInvitationOrigin(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )
      ? '/api/v1/obtenerOrigenInvitacionAsistida'
        : 'https://run.mocky.io/v3/eef8b381-9d9f-436a-8b8e-76d9c273fe39', // 1 afiliacion asistida
        // : 'https://run.mocky.io/v3/0a1d8d56-66cc-4aa1-88fa-5f3c996e074d', // 2 dashboard
      payload
    )
  }
}
