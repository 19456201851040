import './input-file.scss'
import React, { useRef } from 'react'
import PropTypes from 'prop-types'

const InputFile = ({ onChange, mimetypes, name, register, value, cssClass, textInputFile, disabled, iconStyle }) => {
  const fileRef = useRef()

  return (
    <div
      role="button"
      onKeyPress={() => null}
      tabIndex="0"
      className={`ns-inputfile ${cssClass}`}
      onClick={() => {
        fileRef.current.click()
      }}
    >
      <input type="text" name={name} value={value} ref={register} readOnly />
      <input
        type="file"
        ref={fileRef}
        onChange={onChange}
        multiple
        accept={mimetypes.join(',')}
        value=""
        disabled={disabled}
      />
      <em className="icon-adjuntar" style={iconStyle}/>{' '}
      <span className="ns-inputfile__text"> {textInputFile} </span>
    </div>
  )
}

InputFile.propTypes = {
  onChange: PropTypes.func,
  mimetypes: PropTypes.arrayOf(PropTypes.string),
  name: PropTypes.string,
  register: PropTypes.func,
  value: PropTypes.string,
  textInputFile: PropTypes.string,
  disabled: PropTypes.bool,
}
InputFile.defaultProps = {
  onChange: () => null,
  mimetypes: [],
  name: null,
  value: '',
  register: () => {},
  textInputFile: 'Adjuntar documentos',
  disabled: false,
}
export default InputFile
