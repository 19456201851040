import './main-stepper.scss'
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { StepperStepsContext } from '@commons/steppers/steps'
import Stepper from 'react-js-stepper'

const MainStepper = ({ activeStep }) => {
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { result } = stepperStepsData
  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))

  if((esCargaTitular) && (!tieneCargas)){
    stepperStepsData.steps[1].title = 'Declaración de renta';    
  }

  return (
    <div
      className={`main-stepper ${
        result !== '' ? `main-stepper--ds-${result}` : ''
      }`}
    >
      <Stepper
        steps={stepperStepsData.steps}
        activeStep={activeStep}
        showNumber
      />
    </div>
  )
}

MainStepper.defaultProps = {
  activeStep: 1,
}

MainStepper.propTypes = {
  activeStep: PropTypes.number,
}

export default MainStepper
