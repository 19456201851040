import React, { useState, useEffect } from 'react';
import Separator from '@commons/separator/separator'
import Radio from '@commons/inputs/radio'

const ContributorData = ({contributor}) => {
  const [showDetailPension, setShowDetailPension] = useState(false)
  const pensionCauseValues = [
    { label: '', value: 0 },
    { label: 'Enfermedad Común', value: 1 },
    { label: 'Enfermedad Profesional o Accidente de Trabajo', value: 2 },
  ]

  useEffect(() => {
    if(contributor!==null){
      if(contributor.pension === true) setShowDetailPension(true)
    }
  },[contributor])

  return (
    <div>
      <div className="contributor-data">
        <div className="contributor-data__title">Cotizante</div>
        <div className="contributor-data__manager">
          <div className="contributor-data__box">
            <div className="form-row">
            <div className="form-group--four">
                <label htmlFor="names" className="form-label">
                Nombres
                </label>
                <p className="contributor-data__value-data">{contributor.names}</p>
            </div>
            <Separator />
            <div className="form-group--four">
                <label htmlFor="paternalSurname" className="form-label">
                Apellido Paterno
                </label>
                <p className="contributor-data__value-data">{contributor.paternalSurname}</p>
            </div>
            <Separator />
            <div className="form-group--four">
                <label htmlFor="apellidoMaterno" className="form-label">
                Apellido Materno
                </label>
                <p className="contributor-data__value-data">{contributor.maternalSurname}</p>
            </div>
            <Separator />
            <div className="form-group--four">
                <label htmlFor="rut" className="form-label">
                Rut
                </label>
                <p className="contributor-data__value-data">{contributor.rut}</p>
            </div>
            </div>
            <Separator isVertical={true} />
            <div className="form-row">
            <div className="form-group">
                <label htmlFor="birthdate" className="form-label">
                Fecha Nacimiento
                </label>
                <p className="contributor-data__value-data">{contributor.birthdate}</p>
            </div>
            <Separator />
            <div className="form-group--xlarge">
                <label htmlFor="email" className="form-label">
                Correo Electrónico
                </label>
                <p className="contributor-data__value-data">{contributor.email}</p>
            </div>
            <Separator />
            <div className="form-group--medium">
                <label htmlFor="isapre" className="form-label">
                Institución Salud Actual
                </label>
                <p className="contributor-data__value-data">{contributor.isapre}</p>
            </div>
            </div>
          </div>
        </div>
      </div>

      <div className="contributor-other-data-box">
        <div className="form-row">
          <div className="row form-group">
            <div className="col-12 my-auto px-1">
              <label htmlFor="weight" className="form-label">
                Peso
              </label>
              <div className="d-flex px-1">
                <p className="contributor-data__value-data">{contributor.weight}</p>
                <b className="ml-3">Kgs.</b>
              </div>
            </div>
          </div>
          <Separator />
          <div className="row form-group">
            <div className='col-12 px-1 my-auto'>
              <label htmlFor="height" className="form-label">
                Estatura
              </label>
              <div className="d-flex px-1">
                <p className="contributor-data__value-data">{contributor.height}</p>
                <b className="ml-3">Mts.</b>
              </div>
          </div>
          </div>
          <Separator />
          <div className="row form-group__pension form-group--xlarge">
            <span className="form-group__label">Pensión de invalidez:</span>
            <div className="form-group__radio-options">
              <Radio
                text="NO"
                name="pension"
                value="NO"
                isChecked={!contributor.pension}
                disabled={true}
                onChangeEvent={()=>{}}
              />
              <Radio
                text="SI"
                name="pension"
                value="SI"
                isChecked={contributor.pension}
                disabled={true}
                onChangeEvent={()=>{}}
              />
            </div>
          </div>
        </div>
        {showDetailPension ? <Separator isVertical /> : null}
        <div className={`pl-1 form-row${showDetailPension ? '' : ' form-row--hide'}`}>
          <span className="form-group__label">Tipo de Pensión de Invalidez:</span>
          <div className="form-group--all">
            <p>{pensionCauseValues.find((op) => {
                return op.value === contributor.pensionCause
              }).label}</p>
          </div>
        </div>
        {showDetailPension ? <Separator isVertical /> : null}
        <div className={`pl-1 form-row${showDetailPension ? '' : ' form-row--hide'}`}>
          <span className="form-group__label">Diagnóstico:</span>
          <div className="form-group--all">
            <p>{contributor.pensionDiagnostic}</p>
          </div>
        </div>
      </div>
  
    </div>
  );
};

export default ContributorData;