import './radio.scss'
import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'

const Radio = ({
  name,
  text,
  value,
  onChangeEvent,
  disabled,
  isChecked,
  cssClass,
}) => {
  const RadioRef = useRef()
  const [isCheck, setIsCheck] = useState(false)
  /**
   * @description Activate Input Radio button field
   */
  function onClickHandler() {
    if (!disabled) {
      RadioRef.current.click()
      setIsCheck(!isCheck)
    }
  }

  useEffect(() => {
    RadioRef.current.checked = isChecked
  }, [disabled, isChecked])

  return (
    <div
      className={`ns-radio ${cssClass} ${
        disabled && 'ns-radio--disabled'
      }`.trim()}
      onClick={onClickHandler}
      role="button"
      tabIndex={0}
      onKeyPress={() => null}
    >
      <input
        type="radio"
        name={name}
        ref={RadioRef}
        onChange={onChangeEvent}
        value={value}
        defaultChecked={isCheck}
      />
      <div className="ns-radio__marker" />
      <span className="ns-radio__text">{text}</span>
    </div>
  )
}
Radio.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string,
  onChangeEvent: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  isChecked: PropTypes.bool,
  cssClass: PropTypes.string,
}
Radio.defaultProps = {
  text: '',
  disabled: false,
  isChecked: false,
  cssClass: '',
}

export default Radio