import './benef-ident-alterable.scss'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Separator from '@commons/separator/separator'
import ConfirmMessage from '@commons/messages/confirm/confirm'
import MessageDeclaration from '@commons/message-manager/elements/message-declaration'
import Tooltip from '@commons/tooltip/tooltip'
import DatePicker, { registerLocale } from 'react-datepicker'
import Select from 'react-select'
import { format } from 'rut.js'
import { getAge } from '@utils/fields-validators'
import moment from 'moment'
import { ErrorMessage } from 'react-hook-form'
import ErrorMsg from '@commons/messages/error/error-message'
import es from 'date-fns/locale/es'
import { Controller } from 'react-hook-form'

registerLocale('es', es)

const BenefIdent = ({ 
  index, 
  children, 
  editable, 
  isNew, 
  removeBeneficiary,
  reset,
  register,
  errors,
  control
}) => {
  const [benefData, setBenefData] = useState(children)
  const [showTooltip, setShowTooltip] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [showDeclaration, setShowDeclaration] = useState({show: false, msg: ''})
  const [showErrorMsg, setShowErrorMsg] = useState({show: false, msg: ''})
  const [selectedSex, setSelectedSex] = useState(benefData.sex)
  const [selectedRelation, setSelectedRelation] = useState(benefData.relation)
  const [selectedType, setSelectedType] = useState(benefData.type)
  const [selectedBirthdate, setSelectedBirthdate] = useState(benefData.birthdate)
  const isTitular = benefData.type === 0 && benefData.id === 0 && benefData.order === 0  ? true : false

  const relationValues = [
    { label: 'Cónyuge', value: 1 },
    { label: 'Padre', value: 2 },
    { label: 'Madre', value: 3 },
    { label: 'Hijos', value: 4 },
    { label: 'Otro', value: 5 },
    { label: 'Conviviente Civil', value: 6 },
  ]
  const typeValues = [
    { label: 'Carga Legal', value: 2 },
    { label: 'Carga Médica', value: 1 },
  ]
  const sexValues = [
    { label: 'Masculino', value: 1 },
    { label: 'Femenino', value: 2 },
  ]

  useEffect(()=>{
    checkDeclarationStatus()
  },[benefData.type, benefData.relation, benefData.birthdate])

  useEffect(() => {
    if (reset) setBenefData(children)
  }, [children, reset])
  /**
   * @description RUT Field onChange event
   * @param {event} e element event
   */
  function rutOnChangeHandler(e) {
    setBenefData({ ...benefData, rut: format(e.target.value) })
  }
  /**
   * @description Names Field onChange event
   * @param {event} e element event
   */
  function namesOnChangeHandler(e) {
    setBenefData({ ...benefData, names: e.target.value })
  }
  /**
   * @description Surnames Field onChange event
   * @param {event} e element event
   */
  function paternalSurnameOnChangeHandler(e) {
    var valueTemp = e.target.value.replace('  ',' ')
    setBenefData({ ...benefData, paternalSurname: valueTemp })
  }
  /**
   * @description Surnames Field onChange event
   * @param {event} e element event
   */
   function maternalSurnameOnChangeHandler(e) {
    var valueTemp = e.target.value.replace('  ',' ')
    setBenefData({ ...benefData, maternalSurname: valueTemp })
  }
  /**
   * @description Birthdate Field onChange event
   * @param {event} e element event
   */
  function birthdateOnChangeHandler(e) {
    const date = e.toLocaleDateString('es-CL')
    setBenefData({ ...benefData, birthdate: date })
    setSelectedBirthdate(date)
    checkDeclarationStatus()
  }
  /**
   * @description Sex Selector onChange event
   * @param {event} e element event
   */
  function sexOnChangeHandler(e) {
    setSelectedSex(e.value)
    setBenefData({ ...benefData, sex: e.value })
  }
  /**
   * @description Relation Selector onChange event
   * @param {event} e element event
   */
  function relationOnChangeHandler(e) {
    setSelectedRelation(e.value)
    setBenefData({ ...benefData, relation: e.value })
  }

  function typeOnChangeHandler(e) {
    setSelectedType(e.value)
    if(e.value === 1){
      checkDeclarationStatus()  
    }
    setBenefData({ ...benefData, type: e.value })
  }
  /**
   * @description Weight Field onChange event
   * @param {event} e element event
   */
  function weightOnChangeHandler(e) {
    setBenefData({ ...benefData, weight: e.target.value })
  }
  /**
   * @description Height Field onChange event
   * @param {event} e element event
   */
  function heightOnChangeHandler(e) {
    setBenefData({ ...benefData, height: e.target.value })
  }
  /**
   * @description Height Field onChange event
   * @param {event} e element event
   */
  function otherOnChangeHandler(e) {
    setBenefData({ ...benefData, other: e.target.value })
  }
  /**
   * @description Set true in delete message
   */
  function removeItemAction() {
    setShowMessage(true)
  }
  /**
   * @description Set false in delete message
   */
  function cancelAction() {
    setShowMessage(false)
  }
  /**
   * @description Remove self from ArrayData
   */
  function confirmAction() {
    removeBeneficiary(children.id)
  }

  /**
   * @description Return the String Classname for Input, this helps to reduce SonarQ congnitive complexity
   * @param {string} fieldName Input name
   */
  function createFieldClassName(fieldName) {
    return `form-control ${
      errors[fieldName]?.message ? 'is-invalid' : ''
    }`.trim()
  }

  function checkDeclarationStatus(){  
    
    // carga legal
    if(benefData.type === 2){
      // hijo menor entre 19 y 24
      if(benefData.relation === 4 && getAge(benefData.birthdate) >= 19 && getAge(benefData.birthdate) < 25 ){
        const msg = 'Declaro que mi carga se encuentra estudiando en una institución reconocida por el estado'
        setShowDeclaration({show: true, msg: msg})
      }
      // hijo mayor a 25
      if(benefData.relation === 4 && getAge(benefData.birthdate) > 25){
        const msg = 'Declaro que mi carga ha sido reconocida como inválida por la Comisión de Medicina Preventiva e Invalidez'
        setShowDeclaration({show: true, msg: msg})
      }
      // padre o madre menor o igual a 65
      if((benefData.relation === 2 || benefData.relation === 3) && getAge(benefData.birthdate) <= 65){
        const msg = 'Declaro que mi carga vive a expensas de mí y sus ingresos son menores al 50% del salario mínimo'
        setShowDeclaration({show: true, msg: msg})
      }
    }
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 5,
    }),
    control: (provided) => ({
      ...provided,
      height: 23,
      minHeight: 0,
    }),
    singleValue: (provided) => ({
      ...provided,
      height: 23,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      height: 23,
    }),
    menu: (provided) => ({
      ...provided,
      top: 23,
      height: 'auto',
    }),
    valueContainer: () => ({
      height: 23,
    }),
    input: (provided) => ({
      ...provided,
      height: 23,
    }),
  }


  const handlerTooltipOnMouseOut = () => {
    setShowTooltip(false)
  }

  const handlerTooltipOnMouseOver = () => {
    setShowTooltip(true)
  }


  return (
    <div className={`benef-box-alterable ${isNew ? "benef-box-alterable--new" : ""}`.trim()}>
      {showDeclaration.show ? (
        <MessageDeclaration
          message={showDeclaration.msg}
          isShowing={true}
          action={() => setShowDeclaration({ show: false, msg: "" })}
        />
      ) : null}
      <div className="benef-box-alterable__index">{index}</div>
      <div className="form-row">
        <div className="form-group">
          <label htmlFor={`${benefData.id}_names`} className="form-label">
            Nombres
          </label>
          <input
            type="text"
            name={`${benefData.id}_names`}
            className={createFieldClassName(`${benefData.id}_names`)}
            value={benefData.names}
            readOnly={isTitular || !editable}
            onChange={namesOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_names`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_paternalSurname`} className="form-label">
            Apellido Paterno
          </label>
          <input
            type="text"
            name={`${benefData.id}_paternalSurname`}
            className={createFieldClassName(`${benefData.id}_paternalSurname`)}
            value={benefData.paternalSurname}
            readOnly={isTitular || !editable}
            onChange={paternalSurnameOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_paternalSurname`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_maternalSurname`} className="form-label">
            Apellido Materno
          </label>
          <input
            type="text"
            name={`${benefData.id}_maternalSurname`}
            className={createFieldClassName(`${benefData.id}_maternalSurname`)}
            value={benefData.maternalSurname}
            readOnly={isTitular || !editable}
            onChange={maternalSurnameOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_maternalSurname`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_rut`} className="form-label">
            Rut
          </label>
          <input
            type="text"
            name={`${benefData.id}_rut`}
            className={createFieldClassName(`${benefData.id}_rut`)}
            value={
              editable && !isTitular ? benefData.rut : format(benefData.rut)
            }
            readOnly={isTitular || !editable}
            onChange={rutOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_rut`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
      </div>
      <Separator isVertical />
      <div className="form-row">
        <div className="form-group">
          <label htmlFor={`${benefData.id}_sex`} className="form-label">
            Sexo
          </label>
            <Controller
              name={`${benefData.id}_sex`}
              control={control}
              as={Select}
              options={sexValues}
              className={`ns-select benef-box-alterable__select benef-box-alterable__select${
                editable ? '' : '--disabled'
              }`}
              classNamePrefix="ns-select"
              styles={customStyles}
              isDisabled={!editable}
              placeholder="Selecciona Sexo"
              isSearchable={false}
              value={sexValues.find( op => op.value === benefData.sex)}
              rules={{ required: true }}
              defaultValue={sexValues.find( op => op.value === benefData.sex)}
              onChange={(e) => {
                sexOnChangeHandler(e[0])
                return e[0]
              }}
              
              
            />
            <ErrorMessage name={`${benefData.id}_sex`} errors={errors}>
              {({ message }) => <div className="invalid-feedback">{message}</div>}
            </ErrorMessage>
          <input hidden onChange={()=>{}} name={`${benefData.id}_sex`} ref={register} value={selectedSex}/>
        </div>
        <Separator />
        <div className="form-group benef-box-alterable__datepicker">
          <label htmlFor={`${benefData.id}_birthdate`} className="form-label">
            Fecha de nacimiento
          </label>
          <DatePicker
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            maxDate={new Date()}
            dropdownMode="select"
            value={selectedBirthdate ? selectedBirthdate : ""}
            openToDate={moment(selectedBirthdate ? selectedBirthdate : new Date(), "DD-MM-YYYY").toDate()}
            readOnly={!editable}
            disabled={!editable}
            locale="es"
            className={createFieldClassName(`${benefData.id}_birthdate`)}
            onChange={birthdateOnChangeHandler}
          />
          <input
            hidden
            onChange={() => {}}
            name={`${benefData.id}_birthdate`}
            ref={register}
            value={selectedBirthdate ? selectedBirthdate : ""}
          />
          <ErrorMessage name={`${benefData.id}_birthdate`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_relation`} className="form-label">
            Relación Familiar
          </label>
          {isTitular && index - 1 === 0 ? (
            <p className="benef-box-alterable__value">Titular</p>
          ) : (
            <>
              <Select
                isSearchable={false}
                styles={customStyles}
                height="10"
                defaultValue={relationValues.find(
                  (op) => op.value === benefData.relation
                )}
                className={`ns-select benef-box-alterable__select${
                  editable ? "" : "--disabled"
                }`}
                classNamePrefix="ns-select"
                onChange={relationOnChangeHandler}
                options={relationValues}
                placeholder="Selecciona tipo"
              />
              <ErrorMessage name={`${benefData.id}_relation`} errors={errors}>
                {({ message }) => (
                  <div className="invalid-feedback">{message}</div>
                )}
              </ErrorMessage>
            </>
          )}
          <input
            hidden
            onChange={() => {}}
            name={`${benefData.id}_relation`}
            ref={register}
            value={selectedRelation}
          />
        </div>
      </div>
      {benefData.relation === 5 ? 
        <>
          <Separator isVertical />
          <div className="form-row">
            <div className="form-group">
              <label htmlFor={`${benefData.id}_other`} className="form-label">
                Otro 
              </label>
              <input
                type="text"
                name={`${benefData.id}_other`}
                className={createFieldClassName(`${benefData.id}_other`)}
                value={benefData.other}
                readOnly={isTitular || !editable}
                onChange={otherOnChangeHandler}
                disabled={isTitular || !editable}
                ref={register}
              />
              <ErrorMessage name={`${benefData.id}_other`} errors={errors}>
                {({ message }) => <div className="invalid-feedback">{message}</div>}
              </ErrorMessage>
            </div>
          </div>
        </>
        :
        null
      }
      <Separator isVertical />
      <div className="form-row">
        <div className="form-group">
          <label htmlFor={`${benefData.id}_type`} className="form-label">
            Tipo de Beneficiario
          </label>
          {isTitular && index - 1 === 0 ? (
            <p className="benef-box-alterable__value">Titular</p>
          ) : (
            <>
              <Select
                isSearchable={false}
                styles={customStyles}
                height="10"
                defaultValue={typeValues.find(
                  (op) => op.value === benefData.type
                )}
                className={`ns-select benef-box-alterable__select benef-box-alterable__select${
                  editable ? "" : "--disabled"
                }`}
                isDisabled={isTitular || !editable}
                classNamePrefix="ns-select"
                onChange={typeOnChangeHandler}
                options={typeValues}
                placeholder="Selecciona tipo"
              />
              <ErrorMessage name={`${benefData.id}_type`} errors={errors}>
                {({ message }) => (
                  <div className="invalid-feedback">{message}</div>
                )}
              </ErrorMessage>
            </>
          )}
          <input
            hidden
            onChange={() => {}}
            name={`${benefData.id}_type`}
            ref={register}
            value={selectedType}
          />
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_weight`} className="form-label">
            Peso (Kg)
          </label>
          <input
            type="text"
            name={`${benefData.id}_weight`}
            className={createFieldClassName(`${benefData.id}_weight`)}
            value={benefData.weight}
            readOnly={isTitular || !editable}
            onChange={weightOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_weight`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
        <Separator />
        <div className="form-group">
          <label htmlFor={`${benefData.id}_height`} className="form-label">
            Estatura (Mts)
          </label>
          <input
            name={`${benefData.id}_height`}
            className={createFieldClassName(`${benefData.id}_height`)}
            value={benefData.height}
            readOnly={isTitular || !editable}
            onChange={heightOnChangeHandler}
            disabled={isTitular || !editable}
            ref={register}
          />
          <ErrorMessage name={`${benefData.id}_height`} errors={errors}>
            {({ message }) => <div className="invalid-feedback">{message}</div>}
          </ErrorMessage>
        </div>
      </div>
      <div
        className={`benef-box-alterable__action ${
          editable && !isTitular ? "benef-box-alterable__action--show" : ""
        }`.trim()}
      >
        <button
          type="button"
          className="actions__action"
          onClick={removeItemAction}
        >
          <em className="icon-eliminar" />
        </button>
      </div>
      <div className="benef-box-alterable__tooltip">
        <div className="benef-ident__tooltip">
          <Tooltip visible={showTooltip} align="left">
            <div>
              <b>1. Carga legal:</b> Son familiares que se benefician de quien
              cotiza en la Isapre, es decir, aquellos que cumplan con las
              exigencias legales para beneficiarse de la asignación familiar,
              sea que la reciban o no. Entran a esta categoría los convivientes
              civiles del afiliado.
            </div>
            <div>
              <b>2. Carga Médica:</b> Persona sin previsión de salud y sin
              ingresos y, que además no es carga legal de otro.
            </div>
          </Tooltip>
        </div>
        <em
          className="icon-alerta"
          onMouseOver={handlerTooltipOnMouseOver}
          onMouseOut={handlerTooltipOnMouseOut}
        />
      </div>
      <ConfirmMessage
        confirm="Eliminar"
        cancel="Cancelar"
        isShowing={showMessage}
        confirmAction={confirmAction}
        cancelAction={cancelAction}
      >
        <div>
          Estás a punto de eliminar una de tus cargas. Si estás seguro confirma
          la acción
        </div>
      </ConfirmMessage>
      <ErrorMsg isShowing={showErrorMsg.show} action={() => setShowErrorMsg({show: false, msg: ''})}>
        {showErrorMsg.msg}
      </ErrorMsg>
    </div>
  );
}

BenefIdent.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rut: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    names: PropTypes.string.isRequired,
    paternalSurname: PropTypes.string.isRequired,
    maternalSurname: PropTypes.string.isRequired,
    sex: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    birthdate: PropTypes.string.isRequired,
    relation: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    type: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  }).isRequired,
  editable: PropTypes.bool,
  isNew: PropTypes.bool,
}
BenefIdent.defaultProps = {
  editable: false,
  isNew: false,
}

export default BenefIdent
