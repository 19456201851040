import './alert-message.scss'
import React from 'react'
import PropTypes from 'prop-types'

const AlertMessage = ({ children }) => {
  return (
    <div className="alert__container">
      <div className="alert__icon">
        <em className="icon-alerta" />
      </div>
      <div className="alert__message">{children}</div>
    </div>
  )
}
AlertMessage.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
}

export default AlertMessage
