import React, { useEffect, useContext } from 'react'
import './result-finantial-evaluation.scss'
import Button from '@commons/buttons/button'
import { HealthStatementSentContext, HealthStatementSentService } from '@components/health-statement-sent'
import { StepperStepsContext } from '@commons/steppers/steps'

const ResultFinantialEvaluation = () => {
  const { stepperStepsData } = useContext(StepperStepsContext)
  const {
    healthStatementSentData,
    HealthStatementSentDispatch,
    HealthStatementSentActions,
  } = useContext(HealthStatementSentContext)
  const {
    dsData: { correo },
  } = healthStatementSentData
  /**
   * @description Handle close sesion
   */
  function handleCloseSesion() {
    window.location.assign("https://www.nuevamasvida.cl/")
  }

  useEffect(() => {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
    }
    HealthStatementSentService.getDSData(body)
      .then((response) => {
        if (response.httpCode === 200) {
          HealthStatementSentDispatch(
            HealthStatementSentActions.setDSData(response.data)
          )
        }
      })
      .catch(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="result-finantial-evaluation">
      <div className="result-finantial-evaluation__message">
        Tu{' '}
        <span className="result-finantial-evaluation__message--bold">
          Postulación
        </span>{' '}
        aún se encuentra en estado de{' '}
        <span className="result-finantial-evaluation__message--status">Evaluación</span>,
        cuando esté listo el resultado será enviada a{' '}
        <span className="result-finantial-evaluation__message--bold">
         {correo}
        </span>
      </div>
      <div className="result-finantial-evaluation__message">
        Si ya se cumplió el plazo de{' '}
        <span className="result-finantial-evaluation__message--bold">5 días hábiles,</span>{' '}
        comunícate con nuestra mesa de ayuda telefónica al{' '}
        <span className="result-finantial-evaluation__message--bold">600 6000 262</span>
      </div>
      <div className="result-finantial-evaluation__buttons">
        <Button text="Cerrar Sesión" action={handleCloseSesion} />
      </div>
    </div>
  )
}

export default ResultFinantialEvaluation
