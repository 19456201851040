import RequestService from '@core/request-service'

export default class ValidateIdentityService extends RequestService {
  static validateUserIdentity(user) {
    return super.put('', '', user)
  }

  static getQuestions(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) && process.env.REACT_APP_AUTH_ENABLED ? '/api/v1/ObtenerPreguntas' :
       'https://run.mocky.io/v3/eb77963b-731f-4339-ad04-d3b7af572e9c', // ok get questions
      //  'https://run.mocky.io/v3/8ff1ad6a-ae9a-4ab8-8aee-a01c291984e5', // bloqueo
      payload
    )
  }

  static sendAnswers(answers) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) && process.env.REACT_APP_AUTH_ENABLED ? '/api/v2/autenticar' :
      // 'https://run.mocky.io/v3/7d3909be-a781-4282-a7d1-4cd694ed57ea', // ok
      // 'https://run.mocky.io/v3/559496e2-8083-46f9-9ffc-2a0dfb9776fd', //previred no disponible
       'https://run.mocky.io/v3/b558692e-0a00-4abd-893a-c482a3065bef', // respuestas incorrectas
      answers
    )
  }

  static preloadInformation(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) && process.env.REACT_APP_AUTH_ENABLED ? '/api/sysasapFront/v3/SysasapPrecargarInformacion' :
      'https://run.mocky.io/v3/50103687-e013-4463-808e-8c6e2941f47d',
      payload
    )
  }

  static saveDocument(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) && process.env.REACT_APP_AUTH_ENABLED ? '/api/v1/guardarAdjunto' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  static authCallCenter(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) && process.env.REACT_APP_AUTH_ENABLED ? '/api/v1/autenticarCallCenter' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  static authClaveUnica(payload) {
    //return super.postClave('http://150.10.10.110/apiAutenticacionClaveUnica/Autenticar/Autenticar', payload )
    return super.postClave(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) && process.env.REACT_APP_AUTH_ENABLED ?
      '/api/v1/cluni/Autenticar/Autenticar':
      'http://150.10.10.110/apiAutenticacionClaveUnica/Autenticar/Autenticar', //qa
      //'/api/v1/cluni/Autenticar/Autenticar',
      payload
    )
  }

  static validarClaveUnica(payload) {
    //return super.postClave('http://150.10.10.110/apiAutenticacionClaveUnica/Autenticar/Validar', payload )
    return super.postClave(  
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) && process.env.REACT_APP_AUTH_ENABLED ? 
      '/api/v1/cluni/Autenticar/Validar':
      'http://150.10.10.110/apiAutenticacionClaveUnica/Autenticar/Validar', //qa
      //'/api/v1/cluni/Autenticar/Validar', //prod
      payload
    )
  }

  static estadoLogin(payload) {
    //return super.postClave('http://150.10.10.110/apiAutenticacionClaveUnica/Validar/EstadoLogin', payload )
    return super.postClave(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) && process.env.REACT_APP_AUTH_ENABLED ? 
      '/api/v1/cluni/Validar/EstadoLogin':
      'http://150.10.10.110/apiAutenticacionClaveUnica/Validar/EstadoLogin', //qa
      //'/api/v1/cluni/Validar/EstadoLogin',
      payload
    )
  }
  
}
