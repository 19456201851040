import RequestService from '@core/request-service'

export default class StartSubscriptionService extends RequestService {
  static getListIsapres() {
    return super.get(  
    ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Isapres' :
    'http://www.mocky.io/v2/5eda79d0330000e51579ec5b'
    )
  }

  static getUrlIsapre(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/obtenerurlisapre' : //'api/sysasapInicioProceso/v2/obtenerurlisapre'
       //   'https://run.mocky.io/v3/b160466f-bd3c-4277-b2b0-60d8208d4a8b', // cruz blanca
       // 'https://run.mocky.io/v3/020ea6ae-be1a-4754-a70d-683490578341', // cruz blanca
       // 'https://run.mocky.io/v3/2ecd5e9b-5a82-43e3-865f-b965e7bb3f81', // cruz blanca

        // 'https://run.mocky.io/v3/fea70fb6-afeb-41bc-9f73-b62cbfa20fd4',  // consalud
        // 'https://run.mocky.io/v3/361f2182-adb2-40b5-b5f1-5288a21a5661', // redireccion ""
        // 'https://run.mocky.io/v3/1463c435-8f3e-43cf-a100-1f0e2fb52149', // redireccion "previred-equifax"
        'https://run.mocky.io/v3/79c75fb0-bae9-4ae9-9e3a-3ed1df535e67', // redireccion "renta"
      payload
    )
  }

  static getIsapreInitial(payload) {
    return super.post(
      (process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/obtenerIsapreInicio' :
      'https://run.mocky.io/v3/03450a69-e4d8-4aee-85a4-6267371855e2',
      payload
    )
  }
}
