const SET_VALID_USER = 'SET_VALID_USER'
const SET_QUESTIONNAIRE = 'SET_QUESTIONNAIRE'
const LOADING_DATA = 'LOADING_DATA'
const SET_URL_ISAPRE = 'SET_URL_ISAPRE'
const SET_STATE_AUTHENTICATION = 'SET_STATE_AUTHENTICATION'
const SET_NEXT_STEP_MESSAGE = 'SET_NEXT_STEP_MESSAGE'

function setValidUser(flag) {
  return { type: SET_VALID_USER, flag }
}

function setQuestionnaire(questionnaire) {
  return { type: SET_QUESTIONNAIRE, questionnaire }
}
function setLoading(flag) {
  return {
    type: LOADING_DATA,
    flag,
  }
}
function setUrlIsapre(isapre) {
  return {
    type: SET_URL_ISAPRE,
    isapre,
  }
}

function setStateAuthentication(stateAuth) {
  return {
    type: SET_STATE_AUTHENTICATION,
    stateAuth,
  }
}

function setNextStepMessage(index) {
  return {
    type: SET_NEXT_STEP_MESSAGE,
    currentMessage: index,
  }
}

export {
  SET_VALID_USER,
  SET_QUESTIONNAIRE,
  LOADING_DATA,
  SET_URL_ISAPRE,
  SET_STATE_AUTHENTICATION,
  SET_NEXT_STEP_MESSAGE,
  setLoading,
  setValidUser,
  setQuestionnaire,
  setUrlIsapre,
  setStateAuthentication,
  setNextStepMessage,
}
