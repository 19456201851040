import React, { useContext, useState, useEffect } from 'react'
import './register-form.scss'
import Select from 'react-select'
import Button from '@commons/buttons/button'
import { StartSubscriptionContext, StartSubscriptionService } from '@components/start-subscription'
import { WizardContext } from '@commons/steppers/wizard/'
import { StepperStepsContext, StepperStepsService } from '@commons/steppers/steps'
import AlertMessage from '@commons/messages/alert/alert-message'
import MessageManager from '@commons/message-manager/elements/message-manager'
import ErrorMessage from '@commons/messages/error/error-message'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import moment from 'moment'
import { ValidateIdentityContext, ValidateIdentityService } from "@components/validate-identity/";
import { format } from 'rut.js'

const RegisterForm = () => {

  const _raw = require('./../../../claveunica.txt')
  const [claveUnica, setClaveUnica] = useState(true)
  const [closedIsapre, isClosedIsapre] = useState(false)
  const [showMessageError, setShowMessageError] = useState(false)
  const [showErrorMessagePopup, setShowErrorMessagePopup] = useState(false)
  const [messages, setMessages] = useState([])
  const [loading, setLoading] = useState(false)
  const [textButtonMessage, setTextButtonMessage] = useState('')
  const [actionMessage, setActionMessage] = useState()
  const [isapresList, setIsapresList] = useState([])
  const { stepperStepsData: { userInformation: { rut, identificador } } } = useContext(StepperStepsContext)
  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const [iuseEfect, setIuseEfect] = useState(0)
  const [cluniCount, setCluniCount] = useState(4)

  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const {
    wizard,
    startSubscriptionData,
    startSubscriptionDispatch,
    StartSubscriptionActions,
  } = useContext(StartSubscriptionContext)

  const {
    selectedTypeHealthStatement,
    selectedIsapre,
    readyToGo,
    typeUser,
  } = startSubscriptionData

  useEffect(() => {

    fetch(_raw)
      .then((resp) => resp.text())
      .then(data => {
        setClaveUnica(data === 'true' ? true : false)
      })

  }, [claveUnica])

  useEffect(() => {

    if (esCargaTitular) {
      if (iuseEfect === 0) {
        handleRedirectFormCallCenter()
      }
      setIuseEfect(iuseEfect + 1)
    }

    if (selectedTypeHealthStatement === "1") {
      startSubscriptionDispatch(StartSubscriptionActions.setReadyToGo(true))
    } else if (selectedTypeHealthStatement === "2") {
      if (selectedIsapre !== undefined && selectedIsapre !== null) {
        if (typeUser !== undefined && typeUser !== null) {
          startSubscriptionDispatch(StartSubscriptionActions.setReadyToGo(true))
        } else {
          startSubscriptionDispatch(StartSubscriptionActions.setReadyToGo(false))
        }
      } else {
        startSubscriptionDispatch(StartSubscriptionActions.setReadyToGo(false))
      }
    } else if (selectedTypeHealthStatement === "3") {
      startSubscriptionDispatch(StartSubscriptionActions.setReadyToGo(true))
    }
  }, [selectedTypeHealthStatement, selectedIsapre, typeUser])

  useEffect(() => {
    var listIsapre = startSubscriptionData.isapresList.map((item) => ({
      label: item.nombre,
      value: { id: item.codigo, closed: item.tipo, codIsa: item.codigoIsa, sysasap: item.sysasap },
    }))
    setIsapresList(listIsapre)
    const body = {
      identificador,
      rut,
    }
    StartSubscriptionService.getIsapreInitial(body).then((response) => {
      if (response.httpCode === 200) {
        var type = response.data.tipoInstitucion
        var isapre = response.data.codigoIsa
        if (type !== undefined && type !== null && type !== '') {
          startSubscriptionDispatch(StartSubscriptionActions.setTypeHealthStatement(type.toString()))
        }
        if (isapre !== undefined && isapre !== null && isapre !== '') {
          var option = listIsapre.find(op => op.value.codIsa === isapre)
          startSubscriptionDispatch(StartSubscriptionActions.setActiveIsapre(option.value))
        }
      }
    }).catch(() => {

    })

  }, [startSubscriptionData.isapresList, showMessageError])

  /**
   * @description Handler OnChange event of type user
   * @param {event} event Element event
   */
  function TypeUserOnChangeHandler(event) {
    const { value } = event
    startSubscriptionDispatch(StartSubscriptionActions.setTypeUser(value))
  }

  /**
   * @description Handler OnChange event of IsapreList
   * @param {event} event Element event
   */
  function IsapreListOnChangeHandler(event) {
    const { value } = event
    if (value.closed === 'C') {
      isClosedIsapre(true)
    } else {
      isClosedIsapre(false)
    }
    startSubscriptionDispatch(StartSubscriptionActions.setActiveIsapre(value))
    startSubscriptionDispatch(StartSubscriptionActions.setNextStepMessage(2))
  }

  /**
   * @description Handler OnChange event of TypeHealthStatement
   * @param {event} event Element event
   */
  function TypeHealthStatementOnChangeHandler(event) {
    isClosedIsapre(false)
    const { value } = event
    const insideFlag = value === '1' || value === '3'
    const currentMessageIndex = insideFlag ? 0 : 1
    startSubscriptionDispatch(StartSubscriptionActions.setActiveIsapre(null))
    startSubscriptionDispatch(
      StartSubscriptionActions.setTypeHealthStatement(value)
    )
    if (value === '1') {
      startSubscriptionDispatch(StartSubscriptionActions.setTypeUser('1'))
    }
    if (value === '3') {
      startSubscriptionDispatch(StartSubscriptionActions.setTypeUser('0'))
    }
    startSubscriptionDispatch(
      StartSubscriptionActions.setNextStepMessage(currentMessageIndex)
    )
  }

  /**
   * @description Handler onClick clear message error and set initial message form
   */
  // eslint-disable-next-line no-unused-vars
  function handleReturnForm() {
    setShowMessageError(false)
    startSubscriptionDispatch(StartSubscriptionActions.setNextStepMessage(0))
    startSubscriptionDispatch(StartSubscriptionActions.refreshData(0))
    isClosedIsapre(false)
  }

  /**
   * @description Handler onClick redirect to page isapre
   */
  function handleRedirectPage() {
    window.location = 'https://www.nuevamasvida.cl/'
  }

  function handleRedirectFormCallCenter() {
    WizardDispatch(WizardActions.setAuthenticationPage('callcenter'))
    wizard.next()
  }

  function handleRedirectFormPreviredEquifax() {
    WizardDispatch(WizardActions.setAuthenticationPage('previred-equifax'))
    WizardDispatch(WizardActions.setMixedData(startSubscriptionData))
    WizardDispatch(WizardActions.setCompleteSubStep({ name: 'inicio' }))
    wizard.next()
  }

  const loginClaveUnica = () => {

    const bodyAuthClaveUnica = {
      "rut": format(rut).split('.').join('').trim(),
      "idFlujo": process.env.REACT_APP_API_ID_FLUJO, // prod 5, qa 8, local 9
      "idExterno": identificador
    }

    ValidateIdentityService.authClaveUnica(bodyAuthClaveUnica).then((response) => {

      if (response.httpCode === 200 && response.data.CodError === 0 && cluniCount>0) {
        
        setCluniCount(cluniCount-1);

        const popupTick = window.open(response.data.Valor.UrlRedirigir, 'Clave Unica')

        const timer = setInterval(() => {

          if (popupTick.closed) {

            clearInterval(timer)

            const bodyEstado = {
              "idTrxClaveUnica": response.data.Valor.trxClaveUnica
            }

            ValidateIdentityService.estadoLogin(bodyEstado).then((response) => {
              if (response.data.Valor.EstadoLogin === 'OK') {

                const bodyStep = {
                  identificador: identificador,
                  rut: rut,
                  etapa: 'validacion',
                  subetapa: 'autenticacionPreguntas',
                  fechaRegistro: moment().format('DD-MM-YYYY')
                }

                return StepperStepsService.sendStep(bodyStep).then((response) => {
                  if (response.httpCode === 200) {
                    WizardDispatch(WizardActions.setMixedData(startSubscriptionData))
                    WizardDispatch(WizardActions.setCompleteSubStep({ name: 'validacion' }))
                    wizard.push('declaracion-renta')
                  } else {
                    throw new Error('Error al invocar el servicio sendStep');
                  }
                }).catch(() => {

                  // setShowError({ show: true, msg: 'No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.' })
                  // if (!showError) {
                  //     window.location.assign("https://www.nuevamasvida.cl/")
                  // }
                  // setLoading(false)
                })

              } else {

                //setShowError({ show: true, msg: `No se ha podido validar, vuelve a intentarlo más tarde. Quedan ${cluniCount} intentos.` })

                throw new Error('Error al invocar el servicio estadoLogin');

              }

            }).catch(() => {

              setShowErrorMessagePopup(true)
              // if (!showError) {
              //     window.location.assign("https://www.nuevamasvida.cl/")
              // }
              // setLoading(false)
            })

          }

        }, 500)

      } else {

        handleRedirectFormCallCenter();

        // setShowError({ show: true, msg: 'No se han podido enviar los documentos. Inténtalo nuevamente.' })
        // setLoading(false)

      }
    })
  }

  /**
   * @description Handler for submit form event
   */
  function handleSubmitForm() {
    setLoading(true)
    let validationSysasap = true
    if (selectedTypeHealthStatement === '2') {
      validationSysasap = selectedIsapre.sysasap
    }

    const bodyGetUrlIsapre = {
      codigoPrevision: parseInt(selectedTypeHealthStatement),
      codigoIsapre: selectedIsapre ? parseInt(selectedIsapre.id) : 0,
      tipoCarga: typeUser ? parseInt(typeUser) : 0,
      rut,
      identificador,
    }
    StartSubscriptionService.getUrlIsapre(bodyGetUrlIsapre).then((response) => {
      if (response.httpCode === 200) {
        const { url, esIsapre, tieneAntiguedad, mensaje, redireccion, codigoError } = response.data
        if (redireccion && redireccion !== "") {

          if (redireccion === 'previred-equifax') {
            // cuando la isapre tuvo error time out y pasa a autenticacion de preguntas equifax - previred
            //WizardDispatch(WizardActions.setIsTimeOut(true))
            //handleRedirectFormPreviredEquifax()
            loginClaveUnica();

          } else if (redireccion === 'renta') {
            // cuando el cliente tiene una carta de desafiliación en curso antes de la autenticación
            const bodySendStep = {
              identificador,
              rut,
              etapa: 'validacion',
              subetapa: 'autenticacionSysAsap',
              fechaRegistro: moment().format('DD-MM-YYYY')
            }
            StepperStepsService.sendStep(bodySendStep).then((response) => {
              if (response.httpCode === 200) {
                WizardDispatch(WizardActions.setCompleteSubStep({ name: 'validacion' }))
                wizard.push('declaracion-renta')
              }
            }).catch(console.log)
          }

        } else if (!codigoError) {
          if (selectedTypeHealthStatement === '2' && selectedIsapre.closed === 'C') {

            // Cuando la isapre esta dada de baja
            // setMessages([
            //   'Te informamos que no podemos continuar con el proceso de venta electrónica.',
            //   'Un asesor de nuestra Isapre se pondrá en contacto contigo para continuar con el proceso.',
            // ])
            // setTextButtonMessage('Ir a Nueva Masvida')
            // setActionMessage(() => handleRedirectPage)
            // setShowMessageError(true)
            // startSubscriptionDispatch(StartSubscriptionActions.setNextStepMessage(3))

            loginClaveUnica()

          } else {

            if (selectedTypeHealthStatement === '1' || selectedTypeHealthStatement === '3') {
              // FONASA OR 'NO TIENE'
              if (!esIsapre) {

               // claveUnica === true ? loginClaveUnica():handleRedirectFormPreviredEquifax()
                loginClaveUnica();

              } else {
                // tiene isapre
                setMessages(errorMessageFonasa())
                setTextButtonMessage('Corregir')
                setActionMessage(() => handleReturnForm)
                setShowMessageError(true)
                startSubscriptionDispatch(StartSubscriptionActions.setNextStepMessage(3))
              }

            } else if (selectedTypeHealthStatement === '2') {
              // ISAPRE
              
              if (claveUnica && esIsapre) {
                loginClaveUnica()
              } else if (validationSysasap) {
                if (esIsapre) {
                  // es titular
                  try {
                    // Tiene antiguedad
                    WizardDispatch(WizardActions.setAuthenticationPage('sysasap'))
                    WizardDispatch(WizardActions.setURL(url))
                    WizardDispatch(WizardActions.setMixedData(startSubscriptionData))
                    WizardDispatch(WizardActions.setCompleteSubStep({ name: 'inicio' }))
                    wizard.next()

                  } catch (error) {
                    // No tiene antiguedad
                    setMessages('Error interno. Favor comunicarse con el administrador.')
                    setTextButtonMessage('Ir a Nueva Masvida')
                    setActionMessage(() => handleRedirectPage)
                    setShowMessageError(true)
                    startSubscriptionDispatch(StartSubscriptionActions.setNextStepMessage(3))
                  }
                } else {
                    // no tiene isapre
                    setMessages(errorMessageIsapre(mensaje))
                    setTextButtonMessage('Corregir')
                    setActionMessage(() => handleReturnForm)
                    setShowMessageError(true)
                    startSubscriptionDispatch(StartSubscriptionActions.setNextStepMessage(3))
                }
              } else {
                loginClaveUnica();
                //handleRedirectFormPreviredEquifax()
              }
            }
          }
        } else if (codigoError) {
          setShowErrorMessagePopup(true)
        }
      } else if (response.httpCode === 504) {

        // cuando la isapre tuvo error time out y pasa a autenticacion de preguntas equifax - previred
       // handleRedirectFormPreviredEquifax()
        loginClaveUnica();

      } else {

        setShowErrorMessagePopup(true)

      }

      setLoading(false)

    }).catch(() => {

      setShowErrorMessagePopup(true)
      setLoading(false)

    })

  }

  const handleCloseErrorMessage = () => {
    setShowErrorMessagePopup(false)
  }

  const errorMessageFonasa = () => (
    <div className="register-form__message">
      <div>Hemos detectado que estás afiliado a una isapre.</div>
      <span className="register-form__message--bold">Por favor corrige tu selección</span>
    </div>
  )

  const errorMessageIsapre = (message) => (
    <div className="register-form__message">
      <div>{message}</div>
    </div>
  )

  const listTypeHealthStatement = [
    { label: 'FONASA', value: '1' },
    { label: 'ISAPRE', value: '2' },
    { label: 'No tengo', value: '3' },
  ]

  const listTypeCarga = [
    { label: 'Titular', value: '1' },
    { label: 'Carga', value: '2' },
  ]

  return !showMessageError ? (
    <div className="register-form">
      <div className="form-group">
        <Select
          placeholder="Seleccione su Prevision de Salud actual"
          className="ns-select"
          classNamePrefix="ns-select"
          onChange={TypeHealthStatementOnChangeHandler}
          options={listTypeHealthStatement}
          value={listTypeHealthStatement.find(op => op.value === selectedTypeHealthStatement)}
          defaultValue={listTypeHealthStatement.find(op => op.value === selectedTypeHealthStatement)}
        />
      </div>
      {selectedTypeHealthStatement === '2' && (
        <div className="form-group">
          <Select
            placeholder="Seleccione su Isapre actual"
            className="ns-select"
            classNamePrefix="ns-select"
            noOptionsMessage={() =>
              'No se pudo cargar las isapres, vuelva a intentarlo mas tarde.'
            }
            onChange={IsapreListOnChangeHandler}
            options={isapresList}
            value={selectedIsapre ? isapresList.find(op => op.value.codIsa === selectedIsapre.codIsa) : null}
            defaultValue={selectedIsapre ? isapresList.find(op => op.value.codIsa === selectedIsapre.codIsa) : null}
          />
        </div>
      )}
      {selectedTypeHealthStatement === '2' && selectedIsapre && (
        <div className="form-group">
          <Select
            placeholder="¿Es Titular o Carga de su Isapre actual?"
            className="ns-select"
            classNamePrefix="ns-select"
            onChange={TypeUserOnChangeHandler}
            options={listTypeCarga}
          />
        </div>
      )}
      {closedIsapre && (
        <div className="register-form__alert">
          <AlertMessage>
            <p>Has seleccionado una Isapre cerrada,</p>
            <p>si continúas no podrás terminar el proceso.</p>
          </AlertMessage>
        </div>
      )}
      {readyToGo && (
        <div>
          <Button text="Continuar" action={handleSubmitForm} disabled={loading} />
        </div>
      )}
      <ErrorMessage isShowing={showErrorMessagePopup} action={handleCloseErrorMessage}>
        No se pudo verificar tu información, por favor intentelo nuevamente. Quedan {cluniCount} intentos.
      </ErrorMessage>
      <div className="register-form__loading">
        <SpinnerLoader show={loading} text="Validando Datos" />
      </div>
    </div>
  ) : (
    <MessageManager
      action={() => actionMessage()}
      messages={messages}
      textButton={textButtonMessage}
      isShowing={showMessageError}
      iconColor="orange"
    />
  )
}
export default RegisterForm
