import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { RentStatementContext } from '@components/rent-statement'
import Format from '@utils/tools/number'

const RentSummary = ({ setEditStatement, clickEdit }) => {
  const { rentStatementData } = useContext(RentStatementContext)
  const {
    lastRemuneration,
    averagedRemuneration,
    employers,
  } = rentStatementData
  return (
    <>
      <div className="card card-border text-center mb-3">
        <div className="card-body">
          <div className="rent-statement__message">
            Tu última remuneración fue:
          </div>
          <div className="rent-statement__amount">
            ${Format(lastRemuneration)}
          </div>
          <hr />
          <div className="rent-statement__message">
            El promedio de tus últimas remuneraciones es:
          </div>
          <div className="rent-statement__amount">
            ${Format(averagedRemuneration)}
          </div>
        </div>
      </div>
      <div className="card card-border mb-3">
        <div className="card-body">
          <p className="rent-statement__message">
            Tu(s) empleador(es) registrados son los siguientes:
          </p>
          {employers.map((item, i) => (
            <div className="rent-statement__name" key={item.id}>
              <span className="rent-statement__blue">{i + 1}.</span>{' '}
              {item.razonSocial}
            </div>
          ))}
        </div>
      </div>
    </>
  )
}
RentSummary.propTypes = {
  setEditStatement: PropTypes.func.isRequired,
}
export default RentSummary
