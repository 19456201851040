import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import HealthStatementDocumentsReducer from './health-statement-documents-reducer'
import * as HealthStatementDocumentsActions from './health-statement-documents-actions'

export const HealthStatementDocumentsContext = React.createContext()

export const HealthStatementDocumentsContextProvider = ({ children, wizard }) => {
  const [HealthStatementDocumentsData, HealthStatementDocumentsDispatch] = useReducer(
    HealthStatementDocumentsReducer,
    {
    }
  )

  return (
    <HealthStatementDocumentsContext.Provider
      value={{ 
        wizard, 
        HealthStatementDocumentsData, 
        HealthStatementDocumentsDispatch, 
        HealthStatementDocumentsActions 
      }}
    >
      {children}
    </HealthStatementDocumentsContext.Provider>
  )
}

HealthStatementDocumentsContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
export default HealthStatementDocumentsContextProvider
