import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
// import ValidateIdentityService from './validate-identity-service'
import ValidateIdentityReducer from './validate-identity-reducer'

import * as ValidateIdentityActions from './validate-identity-actions'

export const ValidateIdentityContext = React.createContext()

export const ValidateIdentityContextProvider = ({ children, wizard }) => {

  const [validateIdentityData, ValidateIdentityDispatch] = useReducer(
    ValidateIdentityReducer,
    {
      loading: true,
      urlIsapre: '',
      isOkAuthentication: false,
      message: '',
      messages: [
        'Para proceder a la autenticación de tus datos debes ingresar tu Rut y número de documento de tu cédula de identidad',
        'Para completar la autenticación de tus datos por favor responde las siguientes preguntas',
        'Puedes avanzar a la revisión de tu declaración de renta',
        'Estamos verificando tu información de usuario',
        'Para proceder a la autenticación de tus datos debes acceder a tu institución de salud previsional actual',
        '',
        'Debes registrarte en la pagina de tu isapre',
        'Estamos verificando la autenticación de tu isapre',
        'Ha ocurrido un error, vuelve a ingresar tus datos',
        'Para avanzar en el proceso de autenticación debes adjuntar la imagen por ambos lados de tu cedula de identidad y una selfie tuya con tu cedula de identidad cerca de tu mentòn para la validaciòn de tu identidad.',
        'Para avanzar en el proceso de autenticación debes adjuntar los documentos que se solicitan a continuación.'
      ],
    }
  )

  useEffect(() => {
    ValidateIdentityDispatch(ValidateIdentityActions.setLoading(false))
  }, [])

  return (
    <ValidateIdentityContext.Provider
      value={{
        wizard,
        validateIdentityData,
        ValidateIdentityDispatch,
        ValidateIdentityActions,
      }}
    >
      {children}
    </ValidateIdentityContext.Provider>
  )
}

ValidateIdentityContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
