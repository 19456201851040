import './modal.scss'
import React from 'react'
import PropTypes from 'prop-types'

const SimpleModalObli = ({  
  children,
  action,
  isShowing,
  iconColor,
  floating,
}) => (
  <>
  <div
    className={`simple-modal simple-modal--${
      isShowing ? 'show' : 'hide'
    } simple-modal--${floating ? 'floating' : 'not-floating'}`}
  >
    <div className={`simple-modal__icon simple-modal__icon--cl-${iconColor}`}>
    </div>
    <div className="simple-modal__content">{children}</div>

  </div>
  {isShowing && <div className="simple-modal__content-locked"></div>}
  </>
)

SimpleModalObli.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  floating: PropTypes.bool,
  iconColor: PropTypes.string,
}

SimpleModalObli.defaultProps = {
  floating: true,
  iconColor: 'orange',
}

export default SimpleModalObli
