import React, { useContext, useEffect } from 'react'
import { HealthStatementContext } from '@components/health-statement'
import { WizardContext, WizardService } from '@commons/steppers/wizard'
import { StepperStepsService, StepperStepsContext } from '@commons/steppers/steps/'
import HealthStatementQuestionnaire from '@components/health-statement/elements/health-statement-questionnaire'
import HealthStatementStatus from '@components/health-statement/elements/health-statement-status'
import Button from '@commons/buttons/button'
import _ from 'lodash'
import { useState } from 'react'
import ErrorMessage from '@commons/messages/error/error-message'
import moment from 'moment'

const HealthStatement = () => {
  const { wizard } = useContext(HealthStatementContext)
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [error, setError] = useState(false)
  const [completed, setCompleted] = useState(false)
  const [message, setMessage] = useState('')
  const { stepperStepsData } = useContext(StepperStepsContext)

  const [wizard2Active, setWizard2Active] = useState([]);
  const [wizard2Inactive, setWizard2Inactive] = useState([]);

  var indexBall = 0

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  useEffect(() => {
    if (_.size(wizardData.questions) === 0) {
      fetchQuestions()
    }
    if (_.size(wizardData.diagnosticsQuestion) === 0) {
      fetchDiagnostics()
    }
  }, [])

  useEffect(() => {
    let wizard2Active = []
    let wizard2Inactive = []
    wizardData.questions.map(i => {
      if (i.editable) {
        wizard2Active.push(i)
      } else {
        wizard2Inactive.push(i)
      }
    })

    setWizard2Active(wizard2Active)
    setWizard2Inactive(wizard2Inactive)

    if (wizardData.answered.length === wizard2Active.length) {
      setCompleted(true)
    } else {
      setCompleted(false)
    }
  }, [wizardData.answered, wizardData.questions.length])

  /**
  * @description Fetch all de DS questions and save them on the wizard
  */
  const fetchQuestions = () => {
    let questionList = []
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut
    }
    WizardService.getDSQuestions(body)
      .then((response) => {
        if (response.httpCode === 200) {
          if (_.size(response.data)) {
            let answered = []
            let questionToAnswer = 0
            const empty_diagnostic = [{
              rut: '',
              diagnostico: { codigo: '', diagnostico: '' },
              fecha: '',
              intervencionQuirurgica: '',
            }]
            response.data.map((q) => {
              const questionAux = {
                number: typeof (q.id) === 'string' ? parseInt(q.id) : q.id,
                question: q.pregunta,
                example: q.ejemplo,
                answer: q.respuesta,
                diagnostics: q.diagnosticos === null || q.diagnosticos.length === 0 ? empty_diagnostic : q.diagnosticos,
                editable: q.editable,
              }
              if (questionAux.answer !== null && questionAux.editable) answered.push(questionAux.number)
              if (questionToAnswer === 0 && questionAux.answer === null && questionAux.editable) questionToAnswer = questionAux.number
              return questionList.push(questionAux)
            })
            WizardDispatch(WizardActions.setNextQuestionToAnswer(questionToAnswer))
            WizardDispatch(WizardActions.setQuestionsAnswered(answered))
            WizardDispatch(WizardActions.setQuestionsList(questionList))
          }
          setError(false)
        } else {
          setShowErrorMessage(true)
          setMessage('No se ha podido cargar las preguntas correctamente, contactate con tu ejecutivo')
          setError(true)
        }
      }).catch(e => {
        setShowErrorMessage(true)
        setMessage('No se ha podido cargar las preguntas correctamente, contactate con tu ejecutivo')
        setError(true)
      })
  }

  /**
   * @description Fetch all de DS sugegsted diagnostics and save them on the wizard
   */
  const fetchDiagnostics = () => {
    WizardService.getSuggestedDiagnostics().then((response) => {
      let diagnosticsQuestionAux = []
      if (response.httpCode === 200) {
        diagnosticsQuestionAux = response.data
      }
      WizardDispatch(
        WizardActions.setSuggestedDiagnostics(diagnosticsQuestionAux)
      )
    }).catch(e => { console.log(e) })
  }

  /**
   * @description handle onClick for next step
   */
  async function handleNextStep() {
    if (!error && completed) {

      try {
        let questionInactive = {}
        
        for (let inactive of wizard2Inactive) {
          questionInactive = {
            identificador: stepperStepsData.userInformation.identificador,
            rut: stepperStepsData.userInformation.rut,
            codigoPregunta: inactive.number,
            respuesta: false,
            diagnosticos: []
          }

          await WizardService.saveDSQuestionsAnswers(questionInactive).then((response) => {
            if (response.httpCode !== 200) {
              setShowErrorMessage(true)
              setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.')
            }
          }).catch(() => {
            setShowErrorMessage(true)
            setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.')
          })
        }

        const body = {
          identificador: stepperStepsData.userInformation.identificador,
          rut: stepperStepsData.userInformation.rut,
          etapa: "preguntas",
          subetapa: '',
          fechaRegistro: moment().format('DD-MM-YYYY')
        }

        StepperStepsService.sendStep(body).then((response) => {
          if (response.httpCode === 200) {
            WizardDispatch(WizardActions.setCompleteSubStep({ name: 'preguntas' }))
            wizard.next()
          } else {
            setShowErrorMessage(true)
            setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.')
          }
        }).catch(() => {
          setShowErrorMessage(true)
          setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.')
        })
      } catch (error) {
        console.log('error', error)
        setShowErrorMessage(true)
        setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.')
      }
    }
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

  return (
    <div className="health-stat">
      <h2 className="health-stat__title">Declaración de Salud</h2>
      <div className="health-stat__message">
        Es momento de responder tu Declaración de Salud, debes responder las{' '}
        {wizard2Active.length} preguntas para continuar al siguiente paso.
        No deben declararse las enfermedades o condiciones de salud congénitas que le hayan sido
        diagnosticadas a usted o alguno de sus beneficiarios hasta el término de su período neonatal ni las
        enfermedades mentales o discapacidades psíquicas o intelectuales.
        No es obligatoria la declaración de patologías oncológicas, respecto de las cuales hayan
        transcurrido cinco años desde la finalización del tratamiento radical sin recaída posterior, en virtud
        de lo dispuesto en el artículo 8° bis de la Ley Nº 21.258.
        
        El siguiente listado es sólo referencial, de modo que, si usted ha tenido otra enfermedad, patología o
        condición de salud no indicada aquí, debe declararla.
      </div>
      <div className="health-stat__message">
        {error &&
          'No se pudo cargar las preguntas correctamente, vuelve a intentarlo mas tarde.'}
      </div>
      <div className="health-stat__form-wrapper">
        <div className="row mb-4">
          {wizardData.questions.map((question, i) => {
            let index = i

            if (!question.editable) index = i + 1
            if (question.editable) indexBall = indexBall + 1
            return <HealthStatementQuestionnaire key={question.number} index={index} indexBall={indexBall} question={question} />
          })}
        </div>
        {!error && (
          <HealthStatementStatus
            totalQuestions={wizard2Active.length}
            totalAnswered={wizardData.answered.length}
          />
        )}
      </div>
      <div className="health-stat__button">
        <Button
          text="Continuar"
          action={handleNextStep}
          cssClass="button__continue"
          disabled={!completed}
        />
      </div>
      <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
        {message}
      </ErrorMessage>
    </div>
  )
}

export default HealthStatement
