import React from 'react'
import PropTypes from 'prop-types'
import './tooltip.scss'

const Tooltip = ({ children, align, visible }) => {
  return (
    <div className="tooltip-help">
      <div
        className={`tooltip-help__container ${
          !visible && 'tooltip-help__container--novisible'
        }`.trim()}
      >
        <div className="tooltip-help__box">
          <div className="tooltip-help__message">{children}</div>
        </div>
        <div
          className={`tooltip-help__arrow ${
            align && `tooltip-help__arrow--${align}`
          }`.trim()}
        />
      </div>
    </div>
  )
}
Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  visible: PropTypes.bool.isRequired,
  align: PropTypes.string,
}
Tooltip.defaultProps = {
  align: 'right',
}

export default Tooltip
