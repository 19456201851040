import './error-message.scss'
import React from 'react'
import PropTypes from 'prop-types'

const ErrorMessageBtn = ({
  children,
  isShowing,
  iconColor,
  floating,
}) => (
  <>
  <div
    className={`error-message error-message--${
      isShowing ? 'show' : 'hide'
    } error-message--${floating ? 'floating' : 'not-floating'}`}
  >
    <div className={`error-message__icon error-message__icon--cl-${iconColor}`}>
      <em className="icon-alerta" />
    </div>
    <div className="error-message__content">{children}</div>
  </div>
  {isShowing && <div className="error-message__content-locked"></div>}
  </>
)

ErrorMessageBtn.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  floating: PropTypes.bool,
  iconColor: PropTypes.string,
}

ErrorMessageBtn.defaultProps = {
  floating: true,
  iconColor: 'red',
}

export default ErrorMessageBtn
