import * as yup from 'yup'

/**
 * @description Function get height in schema and return if is valid.
 */
const heightSchema = yup
  .number('Debe ser un número')
  .required('Debe ingresar su Estatura en metros y centímetros')
  .positive('La estatura debe ser positiva')
  .typeError('Debe ingresar un número')
  .test(
    'is-decimal',
    'El número debe tener un dígito entero y al menos un dígito decimal',
    value => (value.toString()).match(/^\d(\.\d{0,2})?$/),
  )
export default heightSchema


