import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import BenefIdentReducer from './beneficiary-identification-reducer'
import * as BenefIdentActions from './beneficiary-identification-actions'

export const BenefIdentContext = React.createContext()

export const BenefIdentContextProvider = ({ children, wizard }) => {
  const [benefIdentData, BenefIdentDispatch] = useReducer(BenefIdentReducer, {
  })
  return (
    <BenefIdentContext.Provider
      value={{
        wizard,
        benefIdentData,
        BenefIdentDispatch,
        BenefIdentActions,
      }}
    >
      {children}
    </BenefIdentContext.Provider>
  )
}
BenefIdentContext.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
