import './sign.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Button from '@commons/buttons/button'
import PinValidation from '@commons/pin-validation/pin-validation'

const Sign = ({
  onSign,
  onCancel,
  signButtonText,
  pinDescription,
  isSigned,
  signBtnAction,
  tooglePin,
  showCancel,
  hideDeclaration,
  loading,
  setLoading
}) => {

  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))

  let textParrafo = 'Declaro que he respondido en forma EXACTA Y VERAZ cada una de las preguntas\n'
                    +'que constan en este documento respecto de patologías y enfermedades declarables.\n'
                    +'Cualquier omisión de la información solicitada o falsedad en los datos entregados\n'
                    +'será causal de término del Contrato de Salud Previsional y la Isapre Nueva Masvida no\n'
                    +'cubrirá las prestaciones derivadas de la patología o condición de salud preexistente'

  if((esCargaTitular) && (!tieneCargas)){
    textParrafo = 'Declaro que he respondido en forma EXACTA Y VERAZ cada una de las preguntas\n'
                   +'que constan en este documento respecto de patologías y enfermedades declarables.\n'
                   +'Cualquier omisión de la información solicitada o falsedad en los datos entregados\n' 
                   +'será causal de rechazo de esta solicitud'
  }

  return (
    <>
      {!hideDeclaration && 
        <div className="sign__disclaimer">
            {textParrafo}
        </div>
      } 
      <div className="sign__sign">
        {tooglePin ? (
          <>
            <div className="sign__pin-description">{pinDescription}</div>
            <PinValidation
              action={onSign}
              buttonText={signButtonText}
              isSigned={isSigned}
              loading={loading}
              setLoading={setLoading}
            />
          </>
        ) : (
          <div className="sign__actions">
           {showCancel && <Button
              action={onCancel}
              text="Revisar"
              cssClass="ns-btn--inverted"
            />}
            <Button 
            action={() => {
                signBtnAction()
              }} 
            text="Firmar" />
          </div>
        )}
      </div>
    </>
  )
}

Sign.propTypes = {
  onSign: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  signButtonText: PropTypes.string.isRequired,
  pinDescription: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  isSigned: PropTypes.bool,
  showCancel: PropTypes.bool,
  hideDeclaration: PropTypes.bool,

}

Sign.defaultProps = {
  isSigned: true,
  pinDescription: '',
  showCancel: true,
  hideDeclaration: false,
}

export default Sign
