const SET_USER_RENT = 'SET_USER_RENT'
const SET_COMPLETED_STEP = 'SET_COMPLETED_STEP'
const REMOVE_EMPLOYER = 'REMOVE_EMPLOYER'
const ADD_EMPLOYER = 'ADD_EMPLOYER'
const SET_READY_TO_GO = 'SET_READY_TO_GO'
const FILL_EMPLOYER_DATA = 'FILL_EMPLOYER_DATA'
const FILL_QUOTATION_LIST = 'FILL_QUOTATION_LIST'
const LOADING_DATA = 'LOADING_DATA'
const SET_EMPLOYERS = 'SET_EMPLOYERS'
const ENABLED_EDIT_MODE = 'ENABLED_EDIT_MODE'

function setUserRent(rentData) {
  return { type: SET_USER_RENT, rentData }
}

function setReadyToGo(flag) {
  return { type: SET_READY_TO_GO, flag }
}

function setCompletedStep() {
  return { type: SET_COMPLETED_STEP, flag: true }
}

function setEmployers(employers) {
  return { type: SET_EMPLOYERS, employers }
}

function removeEmployer(employerID) {
  return { type: REMOVE_EMPLOYER, employerID }
}

function addEmployer(data) {
  return { type: ADD_EMPLOYER, data }
}

function setEmployerData(data) {
  return { type: FILL_EMPLOYER_DATA, data }
}

function setQuotationList(collection) {
  return { type: FILL_QUOTATION_LIST, collection }
}

function setLoading(flag) {
  return { type: LOADING_DATA, flag }
}

function enabledEditMode(flag) {
  return { type: ENABLED_EDIT_MODE, flag }
}

export {
  SET_USER_RENT,
  SET_COMPLETED_STEP,
  REMOVE_EMPLOYER,
  ADD_EMPLOYER,
  SET_READY_TO_GO,
  FILL_EMPLOYER_DATA,
  FILL_QUOTATION_LIST,
  SET_EMPLOYERS,
  LOADING_DATA,
  ENABLED_EDIT_MODE,
  setUserRent,
  removeEmployer,
  addEmployer,
  setReadyToGo,
  setCompletedStep,
  setEmployerData,
  setQuotationList,
  setLoading,
  setEmployers,
  enabledEditMode,
}
