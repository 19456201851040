import * as yup from 'yup'

export default function fieldsErrorMessages() {
  yup.setLocale({
    string: {
      default: 'Ingrese un valor',
    },
    number: {
      default: 'Ingrese un número válido',
    },
  })
}
