import React, { useContext, useEffect, useState } from 'react'
import { HealthStatementDocumentsContext } from '@components/health-statement-documents'
import { WizardContext } from '@commons/steppers/wizard'
import HealthStatementDocMananger from '@components/health-statement-documents/elements/health-statement-doc-manager'
import Button from '@commons/buttons/button'
import ErrorMessage from '@commons/messages/error/error-message'
import AddScriptRut from '@utils/tools/convertRut'
import {
  StepperStepsContext,
  StepperStepsService,
} from '@commons/steppers/steps/'
import { WizardService } from '@commons/steppers/wizard'
import { format } from 'rut.js'
import _ from 'lodash'
import moment from 'moment'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import SpinnerLoader from '@commons/loaders/spinner-loader'

const HealthStatementDocuments = () => {
  const { wizard } = useContext(HealthStatementDocumentsContext)
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const { contributor, backgroudContractualDocuments } = wizardData
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [message, setMessage] = useState('')
  const [disabled, setDisabled] = useState(false)
  const [loading, setLoading] = useState(false)
  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  /**
   * Form Schema Validator
   */

  function getSchema() {
    let schemaContructor = {
      domicilio: yup.string().required('El domicilio es obligatorio'),
      afps:  yup.string()
      .ensure()
      .required("La AFP es obligatorio"),
      region: yup.string()
      .ensure()
      .required("La region es obligatorio"),
      ciudad: yup.string()
      .ensure()
      .required("La ciudad es obligatorio"),
      comuna: yup.string()
      .ensure()
      .required("La comuna es obligatorio"),
    }
    if (backgroudContractualDocuments) {
      schemaContructor = {
        ...schemaContructor,
      }
    }
    return schemaContructor
  } 
  const schema = yup.object().shape(getSchema())
  const {
    register,
    errors,
    triggerValidation,
    control,
  } = useForm({
    validationSchema: schema,
  })

    /**
   * Side Effects
   */
  useEffect(() => {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut
    }
    WizardService.getRegions().then((response) => {
      if (response.httpCode === 200) {
        WizardDispatch(WizardActions.setRegions(response.data))
      } else {
        setShowErrorMessage(true)
        setMessage('No se han podido obtener datos de antecedentes del cotizante')
      }
    }).catch(e => {
      setShowErrorMessage(true)
      setMessage('No se han podido obtener datos de antecedentes del cotizante')
    })
    
    WizardService.getCities().then((response) => {
      if (response.httpCode === 200) {
        WizardDispatch(WizardActions.setCities(response.data))
      } else {
        setShowErrorMessage(true)
        setMessage('No se han podido obtener datos de antecedentes del cotizante')
      }
    }).catch(e => {
      setShowErrorMessage(true)
      setMessage('No se han podido obtener datos de antecedentes del cotizante')
    })

    WizardService.getCommunes().then((response) => {
      if (response.httpCode === 200) {
        WizardDispatch(WizardActions.setCommunes(response.data))
      } else {
        setShowErrorMessage(true)
        setMessage('No se han podido obtener datos de antecedentes del cotizante')
      }
    }).catch(e => {
      setShowErrorMessage(true)
      setMessage('No se han podido obtener datos de antecedentes del cotizante')
    })

    WizardService.getAFPs().then((response) => {
      if (response.httpCode === 200) {
        WizardDispatch(WizardActions.setAFPs(response.data))
      } else {
        setShowErrorMessage(true)
        setMessage('No se han podido obtener datos de antecedentes del cotizante')
      }
    }).catch(e => {
      setShowErrorMessage(true)
      setMessage('No se han podido obtener datos de antecedentes del cotizante')
    })
    
    WizardService.getContributorBackground(body).then((response) => {
      if(response.httpCode === 200){
        const contributorAux = {
          rut: format(response.data.rut),
          names: response.data.nombres,
          paternalSurname: response.data.apellidoPaterno,
          maternalSurname: response.data.apellidoMaterno,
          email: response.data.correo,
          birthdate: response.data.fechaNacimiento,
          isapre: response.data.descripcionSalud,
          isapreId: response.data.idSalud,
          weight: String(response.data.peso),
          height: response.data.altura,
          pension: response.data.pension,
          pensionCause: response.data.causal,
          pensionDiagnostic: response.data.diagnostico
        }
        WizardDispatch(WizardActions.setContributor(contributorAux))
      }else{
        setShowErrorMessage(true)
        setMessage('No se han podido obtener datos de antecedentes del cotizante')
      }
    }).catch(e => {

      setShowErrorMessage(true)
      setMessage('No se han podido obtener datos de antecedentes del cotizante')
    })

    WizardService.getBackgroundContractualDocuments(body).then((response) => {
      if (response.httpCode === 200) {
        WizardDispatch(WizardActions.setBackgroundContractualDocuments(response.data))
      } else {
        setShowErrorMessage(true)
        setMessage('No se han podido obtener datos de antecedentes del cotizante')
      }
    }).catch(e => {
      setShowErrorMessage(true)
      setMessage('No se han podido obtener datos de antecedentes del cotizante')
    })
    
  },[])

  /**
   * @description handle onClick for next step
   */
  async function handleNextStep() {
    const validate = await triggerValidation()
    if (validate) {
      setDisabled(true)
      setLoading(true)
      const bodySaveBackground = {
        ...backgroudContractualDocuments,
       identificador: stepperStepsData.userInformation.identificador,
        rut: AddScriptRut(stepperStepsData.userInformation.rut),
        datosEmpleador: backgroudContractualDocuments.datosEmpleador.map(itemEmpleador => {
          return {
            id : itemEmpleador.id,
            rut : itemEmpleador.rut,
            domicilio: itemEmpleador.domicilio, 
            telefono: itemEmpleador.telefono,
            correo: itemEmpleador.correo,
            codigoComuna: itemEmpleador.codigoComuna,
            codigoRegion: itemEmpleador.codigoRegion,
            codigoCiudad: itemEmpleador.codigoCiudad,
          }
        })
      }
      WizardService.saveBackgroundContractualDocuments(bodySaveBackground).then((response) => {
        if (response.httpCode === 200) {
          const bodySendStep = {
            identificador: stepperStepsData.userInformation.identificador,
            rut: AddScriptRut(stepperStepsData.userInformation.rut),
            etapa: 'documentosContractuales',
            subetapa: '',
            fechaRegistro: moment().format('DD-MM-YYYY')
          }
          StepperStepsService.sendStep(bodySendStep).then((response) => {
            if (response.httpCode === 200) {
              WizardDispatch(WizardActions.setCompleteSubStep({ name: 'documentosContractuales' }))
              wizard.next()
            } else {
              setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
              setShowErrorMessage(true)
              setDisabled(false)
              setLoading(false)
            }
          }).catch(() => {
            setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
            setShowErrorMessage(true)
            setDisabled(false)
            setLoading(false)
          })
        } else {
          setMessage('No se ha podido guardar los antecedentes documentos contractuales, vuelve a intentarlo.')
          setShowErrorMessage(true)
          setDisabled(false)
          setLoading(false)
        }
        
      }).catch(() => {
        setMessage('No se ha podido guardar los antecedentes documentos contractuales, vuelve a intentarlo.')
        setShowErrorMessage(true)
        setDisabled(false)
        setLoading(false)
      })
    
    }
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

  return (
    <div className="health-statement-doc">
      <h2 className="health-statement-doc__title">Completar Datos Pendientes</h2>
      <div className="health-statement-doc__message">
        Antes de continuar a la firma de documentos contractuales, debes
        completar la información pendiente.
      </div>
      {_.size(contributor) && _.size(backgroudContractualDocuments) ? <HealthStatementDocMananger control={control} register={register} errors={errors}  />: <SpinnerLoader show={true} />}
      <SpinnerLoader show={loading} />
      <div className="health-statement-doc__button">
        <Button
          text="Continuar"
          action={handleNextStep}
          cssClass="button__continue"
          disabled={disabled}
        />
      </div>
      <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
        {message}
      </ErrorMessage>
    </div>
  )
}

export default HealthStatementDocuments
