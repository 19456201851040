import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import SignDocumentsSuccessReducer from './sign-documents-success-reducer'
import * as SignDocumentsSuccessActions from './sign-documents-success-actions'

export const SignDocumentsSuccessContext = React.createContext()

export const SignDocumentsSuccessContextProvider = ({ children, wizard }) => {

  const [signDocumentsSuccessData, SignDocumentsSuccessDispatch] = useReducer(
    SignDocumentsSuccessReducer,
    {
      independent: false,
      isapre: false,
      titular: false,
    }
  )

  return (
    <SignDocumentsSuccessContext.Provider
      value={{
        wizard,
        signDocumentsSuccessData,
        SignDocumentsSuccessDispatch,
        SignDocumentsSuccessActions,
      }}
    >
      {children}
    </SignDocumentsSuccessContext.Provider>
  )
}

SignDocumentsSuccessContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
export default SignDocumentsSuccessContextProvider
