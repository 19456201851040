import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import PreviousConsiderationsReducer from './previous-considerations-reducer'
import * as PreviousConsiderationsActions from './previous-considerations-actions'

export const PreviousConsiderationsContext = React.createContext()

export const PreviousConsiderationsContextProvider = ({ children, wizard }) => {
  const [previousConsiderationsData, previousConsiderationsDispatch] = useReducer(
    PreviousConsiderationsReducer,
    {
        considerations: [
            <>Terminada la etapa de Renta, avanzarás a la etapa de Declaración de Salud. Este documento es una <span className="consideration__text--bold">declaración formal</span> y debe ser firmada electrónicamente según te indiquemos.</>,
            <>Debe registrar TODAS las enfermedades, patologías o condiciones de salud físicas, originadas con posterioridad al nacimiento y diagnosticadas médicamente a usted y a cada uno de sus beneficiarios, hayan o no requerido tratamiento, hospitalización o intervención quirúrgica,
            cualquiera sea la fecha de diagnóstico y su estado actual, incluso si se ha recuperado y ha sido dado de alta. No deben declararse las enfermedades o condiciones de salud congénitas que le hayan sido diagnosticadas a usted
            o alguno de sus beneficiarios hasta el término de su período neonatal ni las enfermedades mentales o discapacidades psíquicas o intelectuales.
            No es obligatoria la declaración de patologías oncológicas, respecto de las cuales hayan transcurrido cinco años desde la finalización del tratamiento radical sin recaída posterior, en virtud de lo dispuesto en el artículo 8° bis de la Ley Nº 21.258.</>,
            <>Recuerda que toda la <span className="consideration__text--bold">información que incluyas debe ser correcta y fidedigna, para evitar posteriores reducciones de cobertura o un término de contrato anticipado.</span></>
        ],
        confirm: false
    }
  )

  return (
    <PreviousConsiderationsContext.Provider
      value={{ wizard, previousConsiderationsData, previousConsiderationsDispatch, PreviousConsiderationsActions }}
    >
      {children}
    </PreviousConsiderationsContext.Provider>
  )
}

PreviousConsiderationsContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
export default PreviousConsiderationsContextProvider
