import {
  SET_USER_RENT,
  SET_COMPLETED_STEP,
  REMOVE_EMPLOYER,
  ADD_EMPLOYER,
  FILL_EMPLOYER_DATA,
  FILL_QUOTATION_LIST,
  LOADING_DATA,
  SET_EMPLOYERS,
  ENABLED_EDIT_MODE,
} from './rent-statement-actions'
import { v4 as uuidv4 } from 'uuid'

export default function RentStatementReducer(state, action) {
  switch (action.type) {
    case SET_USER_RENT:
      return {
        ...state,
        rent: action.rent,
      }
    case SET_COMPLETED_STEP:
      return {
        ...state,
        completedStep: action.flag,
      }
    case ENABLED_EDIT_MODE:
      return {
        ...state,
        enabledEditMode: action.flag,
      }
    case ADD_EMPLOYER: {
      const name = action.data?.name || ''
      const rut = action.data?.rut || ''
      const typeEntity = action.data?.typeEntity || 1
      const orden = action.data.orden
      const dependiente = action.data.dependiente
      //const id = action.data.id

      return {
        ...state,
        employers: [
          ...state.employers,
          {
            id: state.employers.length + 1,
            isNew: true,
            razonSocial: name,
            rut,
            ultimaRenta: 0,
            entity_type: typeEntity,
            tipoTrabajador: 1,
            orden,
            archivos: [],
            dependiente,
            editableRazonSocial: true,
            editableRut: true,
            editableRenta: true
          },
        ],
      }
    }
    case SET_EMPLOYERS:
      return {
        ...state,
        employers: action.employers,
      }
    case REMOVE_EMPLOYER:
      return {
        ...state,
        employers: state.employers.filter(
          (item) => item.id !== action.employerID
        ),
      }
    case LOADING_DATA:
      return {
        ...state,
        loading: action.flag,
      }
    case FILL_EMPLOYER_DATA:
      return {
        ...state,
        employers: action.data.entidadPagadora,
        lastRemuneration: action.data.ultimaRemuneracion,
        averagedRemuneration: action.data.promedioRemuneracion,
      }
    case FILL_QUOTATION_LIST:
      return {
        ...state,
        quotes: action.collection.cotizaciones,
      }
    default:
      return state
  }
}
