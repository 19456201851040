import './header.scss'
import React, {useState, useContext } from 'react'
import Logo from '@assets/images/logo-nueva-masvida.svg'
import { StepperStepsService, StepperStepsContext } from '@commons/steppers/steps'
import { format } from 'rut.js'
import ErrorMessage from '@commons/messages/error/error-message'
import QuestionMessage from '@commons/messages/question/question-message'
import AddFileModal from '../add-file-modal/add-file-modal'
import packages from '../../../../package.json'

const Header = () => {
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { userInformation } = stepperStepsData
  const [showError, setShowError] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [showConfirmation, setShowConfirmation] = useState(false)

  function handleHelpBtn(){
    setShowConfirmation(false)
    const body ={
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
    }
    StepperStepsService.contactHelp(body).then(response => {
        if(response.httpCode === 200){
            setShowMessage(true)
        }else{
            setShowError(true)
        }
    }).catch(() => {
        setShowError(true)
    })
  }

  function handleRedirection(){
      window.location.assign("https://www.nuevamasvida.cl/")
  }

  return (
    <header>
      {Object.keys(userInformation).length !== 0 ? (
        <>
          <img src={Logo} alt="" />
          <div className="platform">
            <div className="platform__data">
              <div className="platform__title">
                Plataforma de Suscripción Electrónica {packages?.version}
              </div>
              <div className="platform__client">
                <div className="client">
                  <div className="client__name">{userInformation.nombres} {userInformation.apellidoPaterno} {userInformation.apellidoMaterno}</div>
                  <div className="client__separator">&nbsp;|&nbsp;</div>
                  <div className="client__rut">{format(userInformation.rut)}</div>
                </div>
              </div>
              { stepperStepsData.isAsisted ? 
                <div className="platform__sales-executive">
                  Ejecutivo asesor: {userInformation.nombresEjecutivo} {userInformation.apellidoPaternoEjecutivo} {userInformation.apellidoMaternoEjecutivo}
                </div>
                :
                <div className="platform__get-help-wrapper">
                  <button className="platform__get-help-text" onClick={() => setShowConfirmation(true)}>
                    Solicitar Asistencia
                  </button>
                </div>
              }
            </div>
            {!stepperStepsData.isAsisted ? 
            <div className="platform__help">
              <button className="platform__get-help-btn" onClick={() => setShowConfirmation(true)}>
                  <img
                      alt="Solicitar Asistencia"
                      src={`${process.env.PUBLIC_URL}/assets/images/asistencia.svg`}
                  />
              </button>
            </div>
            : 
            <div className="platform__help d-flex">
              <div className="platform__get-help-btn">
                  <img
                      alt="Solicitar Asistencia"
                      src={`${process.env.PUBLIC_URL}/assets/images/asistencia.svg`}
                  />
              </div>
              <div className="platform__get-add-doc-btn">
                  <AddFileModal id={stepperStepsData.userInformation.identificador}/>
              </div>
          </div>
            }
          </div>
        </>
      ) : (
        <div className="logo--center">
          <img src={Logo} alt="Nueva Masvida" />
        </div>
      )}
      <ErrorMessage isShowing={showError} action={() => setShowError(false)}>
          No se ha podido enviar su solicitud de ayuda, intente nuevamente
      </ErrorMessage>
      <QuestionMessage
          buttonText="Solicitar"
          isShowing={showConfirmation}
          action={handleHelpBtn}
          cancelAction={() => setShowConfirmation(false)}
      >
          <strong>¿Desea solicitar asistencia en su suscripción?</strong>
      </QuestionMessage>
      <QuestionMessage
          buttonText="Cerrar Sesión"
          isShowing={showMessage}
          action={handleRedirection}
      >
          Un asesor se pondrá en contacto contigo
      </QuestionMessage>
    </header>
  )
}

export default Header
