import React, { useContext, useState, useEffect } from 'react'
import './form-change-email.scss'
import ChangeEmail from '@commons/change-email/change-email'
import ErrorMessage from '@commons/messages/error/error-message'
import {
  HealthStatementSentContext,
  HealthStatementSentService,
} from '@components/health-statement-sent'
import { StepperStepsContext } from '@commons/steppers/steps'

const FormChangeEmail = () => {
  const {
    healthStatementSentData,
    HealthStatementSentDispatch,
    HealthStatementSentActions,
  } = useContext(HealthStatementSentContext)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const {
    dsData: { correo, plazo },
  } = healthStatementSentData

  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))

  let textTitulo = 'Declaración de Salud'
  let textTituloContraloria = 'Contraloría Médica de'

  if((esCargaTitular) && (!tieneCargas)){
    textTitulo = 'Declaración de Renta'
    textTituloContraloria = ''
  }

  /**
   * @description Handle onClick changed email
   */
  function handleChangeEmail(validation, email) {
    if (validation) {
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
        correo: email,
        confirmaCorreo: email,
      }
      HealthStatementSentService.changeEmail(body).then((response) => {
        if (response.httpCode === 200) {
          HealthStatementSentDispatch(
            HealthStatementSentActions.setChangedEmail({ changed: true, email })
          )
        } else {
          setShowErrorMessage(true)
        }
      }).catch(() => {
        setShowErrorMessage(true)
      })
    }
  }


  useEffect(() => {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
    }
    HealthStatementSentService.getDSData(body)
      .then((response) => {
        if (response.httpCode === 200) {
          HealthStatementSentDispatch(
            HealthStatementSentActions.setDSData(response.data)
          )
        }
      })
      .catch(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  /**
   * @description Handle close error message
   */
  function handleCloseErrorMessage() {
    setShowErrorMessage(false)
  }

  return (
    <div className="form-change-email">
      <div className="form-change-email__subtitle">
        <div>
          Tu{' '}
          <span className="form-change-email__subtitle--bold">
            {textTitulo}
          </span>{' '}
        </div>
        <div>ha sido enviada exitosamente</div>
      </div>
      <div className="form-change-email__statement">
        Una vez evaluada por {textTituloContraloria}{' '}
        <span className="form-change-email__statement--bold">
          Nueva Masvida
        </span>{' '}
        te enviaremos un correo electrónico en un plazo máximo de{' '}
        <span className="form-change-email__statement--bold">{plazo} días hábiles</span> con
        el resultado de tu evaluación.
      </div>
      <div className="form-change-email__message">
        <div>Tu correo electrónico registrado es</div>
        <span className="form-change-email__message--bold">
          {correo}
        </span>
        <div>
          Si necesitas que te enviemos el resultado a otra dirección de correo
          electrónico, puedes modificarla a continuación:
        </div>
      </div>
      <div className="form-change-email__form">
        {correo && <ChangeEmail action={handleChangeEmail} emailCustom={correo} />}
      </div>
      <ErrorMessage
        isShowing={showErrorMessage}
        action={handleCloseErrorMessage}
      >
        <div className="pin-validation__alert">
          No se ha podido enviar el pin, vuelva a intentarlo más tarde.
        </div>
      </ErrorMessage>
    </div>
  )
}

export default FormChangeEmail
