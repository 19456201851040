import './error-message.scss'
import React from 'react'
import Button from '@commons/buttons/button'
import PropTypes from 'prop-types'

const ErrorMessage = ({
  children,
  action,
  isShowing,
  iconColor,
  floating,
  cancelBtnText,
}) => (
  <>
  <div
    className={`error-message error-message--${
      isShowing ? 'show' : 'hide'
    } error-message--${floating ? 'floating' : 'not-floating'}`}
  >
    <div className={`error-message__icon error-message__icon--cl-${iconColor}`}>
      <em className="icon-alerta" />
    </div>
    <div className="error-message__content">{children}</div>
      <div className="error-message__actions">
        <Button text={ cancelBtnText ? cancelBtnText : 'Cerrar'} action={action} />
      </div>
  </div>
  {isShowing && <div className="error-message__content-locked"></div>}
  </>
)

ErrorMessage.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  floating: PropTypes.bool,
  iconColor: PropTypes.string,
}

ErrorMessage.defaultProps = {
  floating: true,
  iconColor: 'red',
}

export default ErrorMessage
