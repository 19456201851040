import './employer-manager.scss'
import React, { useState, useContext } from 'react'
import { RentStatementContext } from '@components/rent-statement'
import { StepperStepsContext } from '@commons/steppers/steps'
import InfoMessage from '@commons/messages/info/info-message'
import { validateFile, rutSchema, fileToBase64 } from '@utils/fields-validators'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import _ from 'lodash'
import EmployerBox from './employer-noeditable'
import { useEffect } from 'react'
import ErrorMessage from '@components/commons/messages/error/error-message'

const EmployerManagerNoEdit = ({finalSave}) => {
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [editable, setEditable] = useState(!finalSave)
  const {
    rentStatementData,
    RentStatementDispatch,
    RentStatementActions,
  } = useContext(RentStatementContext)
  const { employers } = rentStatementData
  const [employersClone, setEmployersClone] = useState([...employers])
  const [resetData, setResetData] = useState(false)

  const [showInvalidFileMessage, setShowInvalidFileMessage] = useState(false)
  const [showErrorNoEmployers, setShowErrorNoEmployers] = useState(false)
  const [
    showErrorRegisteredEmployer,
    setShowErrorRegisteredEmployer,   
  ] = useState(false)
  
  const [showErrorSameNameFile, setShowErrorSameNameFiles,] = useState(false)
  const [showErrorFiles, setShowErrorFiles,] = useState(false)
  const [attachedFilesList, setAttachedFilesList] = useState(
    employers
      .map((item) => ({ [item.id]: item.archivos }))
      .reduce((ini, curr) => ({ ...ini, ...curr }), {})
  )
  const [attachedFilesListClone, setAttachedFilesListClone] = useState({})
  const [message, setMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  /**
   * @description Set List of files to show from InputFile Element data
   * @param {event} e element event object
   * @param {string} entity Employer Entity
   */
  function attachedFilesOnChangeHandler(e, entity) {
    const { files } = e.target

    const validFiles = Object.keys(files).filter((item) =>
      validateFile(files[item])
    )
    const invalidFiles = Object.keys(files).filter(
      (item) => !validateFile(files[item])
    )

    setShowInvalidFileMessage(invalidFiles.length > 0)

    const entityFiles = [
      ...attachedFilesList[employers.find(element => element.id === entity).orden],
      ...validFiles.map((item) => {
        files[item].new = true
        return files[item]
      }),
    ].slice(0, 6)    

    setAttachedFilesList({ ...attachedFilesList, [entity]: entityFiles })
  }

  /**
   * @description Remove an item from FilesList
   * @param {string} entity ID Employer
   * @param {Array} list List of files
   */
  function removeFileFromList(entity, list) {
    setAttachedFilesList({ ...attachedFilesList, [entity]: list })
  }

  /**
   * @description Remove self from ArrayData
   * @param {string} id item ID value
   */
  function removeItemAction(id) {
    let filesById = attachedFilesList[id]
    RentStatementDispatch(RentStatementActions.removeEmployer(id))
    setAttachedFilesList({ ...attachedFilesList, [id]: [] })
  }

  /**
   * Form Schema Validator
   */
  const schema = yup.object().shape(
    employers
      .map((item) => ({
        [`${item.id}_rut`]: rutSchema,
        [`${item.id}_files`]: yup.string().ensure(),
        [`${item.id}_name`]: yup
          .string()
          .required('Debe ingresar Razón Social o Nombre'),
        [`${item.id}_last-rent`]: yup
          .number()
          .required('Debe ingresar un monto')
          .typeError('Debe ingresar un número')
      }))
      .reduce((ini, curr) => ({ ...ini, ...curr }), {})
  )
  const {
    register,
    errors,
    triggerValidation,
    clearError,
    getValues,
  } = useForm({
    validationSchema: schema,
  })

  /**
   * @description Invoke Form Validation
   */
  async function saveAction() {
    setEditable(false)
    if (employers.length === 0) {
      setShowErrorNoEmployers(true)
      return
    }
    
    const values = getValues()
    const archivos = employers.map(item => fileToBase64(attachedFilesList[item.id]).then((archivos) => ({ archivos, item })));
    const archivosResponse = await Promise.all(archivos)
    let archivosTemporalesNombres = []

    for(let o = 0; o < archivosResponse.length; o++){
      for(let i = 0; i < archivosResponse[o].archivos.length; i++){
        archivosTemporalesNombres.
        push(archivosResponse[o].archivos[i].nombreArchivo.trim())
      }
    }

    if (containsDuplicates(archivosTemporalesNombres)) {
      setShowErrorSameNameFiles(true)
      return
    }
    

    const newEmployers = archivosResponse.map(({ archivos, item }) => {
      return {
        ...item,
        entity_type: values[`${item.id}_tipo_empleado`],
        ultimaRenta: Number(values[`${item.id}_last-rent`].split('.').join('')),
        rut: values[`${item.id}_rut`],
        razonSocial: values[`${item.id}_name`],
        archivos: _.size(attachedFilesList) > 0 ? archivos : [],
        vigente: values[`${item.id}_vigente`] === "SI" ? true : values[`${item.id}_vigente`] === "NO" ? false : null
      }
    })

    if (newEmployers.some(emp => emp.vigente === true) === false){
      localStorage.setItem('employerValidForm', false)
      setMessage("Debe haber al menos un empleador Vigente.")
      setShowErrorMessage(true)
      return
    }

    if (newEmployers.some(isValido)){
      localStorage.setItem('employerValidForm', false)
      setMessage("La renta no puede ser igual a 0")
      setShowErrorMessage(true)
      return
    }
    
    const validEmployersFilter = newEmployers.filter(x=>x.vigente===true) 
    
    const uniqueEmployers = [...new Set(newEmployers.map((a) => a.rut))]
    if (uniqueEmployers.length !== employers.length) {
      setShowErrorRegisteredEmployer(true)
      return
    }

    const validForm = await triggerValidation()
    localStorage.setItem('employerValidForm', validForm)
    
    if (validForm) {
      RentStatementDispatch(RentStatementActions.setEmployers(validEmployersFilter))
      RentStatementDispatch(RentStatementActions.enabledEditMode(false))
    }

    setResetData(false)
  }

  function isValido(emp){
    if (emp.ultimaRenta === 0) {
        if (emp.entity_type !== '4') { //Renta 0 solo para voluntarios = 4
            return true
        }
    }
  }

  function containsDuplicates(array) {
    const result = array.some(element => {
      if (array.indexOf(element) !== array.lastIndexOf(element)) {
        return true;
      }
  
      return false;
    });
  
    return result;
  }

  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

  useEffect(()=>{
    if(finalSave){
      setEmployersClone([...employers])
      setAttachedFilesListClone({ ...attachedFilesList })
      setResetData(false)
      saveAction()
    }else{
      setEditable(true)
    }
  },[finalSave])

  return (
    <div className="employer-manager" style={{ width: "125%" }} >
      <div className="employer-manager__list">
        {employers.map((item, i) => {
          return(
          <EmployerBox
            index={i + 1}
            key={item.id}
            editable={editable}
            onRemove={removeItemAction}
            onChangeFiles={attachedFilesOnChangeHandler}
            register={register}
            errors={errors}
            reset={resetData}
            files={attachedFilesList[item.id]}
            onRemoveFile={removeFileFromList}
            finalSave={finalSave}
          >
            {item}
          </EmployerBox>
        )})}
        {!editable && employers.length === 0 ? (
          <div className="employer-manager__message">No existen empleadores</div>
        ) : null}
      </div>
      <div className="attachment__errors">
        <div className="errors__item">
          <InfoMessage
            buttonText="Volver a intentar"
            action={() => setShowInvalidFileMessage(false)}
            isShowing={showInvalidFileMessage}
          >
            Uno o más de los archivos adjuntados tienen un formato inválido, los
            formatos permitidos son JPG, GIF o PDF, con un tamaño máximo de 2MB.
          </InfoMessage>
          <InfoMessage
            buttonText="Volver a intentar"
            action={() => setShowErrorNoEmployers(false)}
            isShowing={showErrorNoEmployers}
          >
            Debe agregar al menos 1 empleador
          </InfoMessage>
          <InfoMessage
            buttonText="Volver a intentar"
            action={() => setShowErrorRegisteredEmployer(false)}
            isShowing={showErrorRegisteredEmployer}
          > 
            El rut ingresado ya se encuentra registrado como empleador
          </InfoMessage>
          <InfoMessage
            buttonText="Volver a intentar"
            action={() => setShowErrorSameNameFiles(false)}
            isShowing={showErrorSameNameFile}
          >
            Los archivos ingresados deben tener nombre distintos
          </InfoMessage>

          <InfoMessage
            buttonText="Volver a intentar"
            action={() => setShowErrorFiles(false)}
            isShowing={showErrorFiles}
          >
            Los empleadores nuevos deben tener archivos adjuntos
          </InfoMessage>
          
        </div>
      </div>
      <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
        {message}
      </ErrorMessage>
    </div>
  )
}

export default EmployerManagerNoEdit
