import React, { useContext, useEffect, useState } from 'react'
import PreviousConsiderationItem from '@components/previous-considerations/elements/previous-consideration-item'
import ErrorMessage from '@commons/messages/error/error-message'
import { PreviousConsiderationsContext } from '@components/previous-considerations'
import { WizardContext } from '@commons/steppers/wizard/'
import { StepperStepsService, StepperStepsContext } from '@commons/steppers/steps/'
import Checkbox from '@components/commons/inputs/checkbox'
import Button from '@commons/buttons/button'
import moment from 'moment'
import AddScriptRut from '@utils/tools/convertRut'

const PreviousConsiderations = () => {
  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))
  const [showError, setShowError] = useState(false)
  const [sending, setSending] = useState(false)
  const {
    wizard,
    previousConsiderationsData,
    previousConsiderationsDispatch,
    PreviousConsiderationsActions,
  } = useContext(PreviousConsiderationsContext)
  const { considerations, confirm } = previousConsiderationsData
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const [loadView, setLoadView] = useState(true)


  if((esCargaTitular) && (!tieneCargas) && (loadView)){
    considerations.splice(1, 1)
    setLoadView(false)   
  }

  /**
   * @description Handler continue next step
   */
  function handleNextStep() {
    setSending(true)
    if (confirm) {
      let body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: AddScriptRut(stepperStepsData.userInformation.rut),
        etapa: 'consideraciones',
        subetapa: '',
        fechaRegistro: moment().format('DD-MM-YYYY')
      }
      
      console.log("paso 1",esCargaTitular,tieneCargas)
      if((esCargaTitular) && (!tieneCargas)){
        console.log("paso 2",esCargaTitular,tieneCargas)
        body = {
          identificador: stepperStepsData.userInformation.identificador,
          rut: AddScriptRut(stepperStepsData.userInformation.rut),
          etapa: 'resumenDeclaracion',
          subetapa: '',
          fechaRegistro: moment().format('DD-MM-YYYY')
        }   
      }else if((esCargaTitular) && (tieneCargas)){
        console.log("paso 3",esCargaTitular,tieneCargas)
        body = {
          identificador: stepperStepsData.userInformation.identificador,
          rut: AddScriptRut(stepperStepsData.userInformation.rut),
          etapa: 'cotizante',
          subetapa: '',
          fechaRegistro: moment().format('DD-MM-YYYY')
        }
      }

      StepperStepsService.sendStep(body).then((response) => {
        if (response.httpCode === 200) {
          if((esCargaTitular) && (!tieneCargas)){
            console.log("paso 1.1",esCargaTitular,tieneCargas)
            WizardDispatch(WizardActions.setCompleteSubStep({ name: 'resumenDeclaracion' }))
          }else if((esCargaTitular) && (tieneCargas)){
            console.log("paso 1.2",esCargaTitular,tieneCargas)
            WizardDispatch(WizardActions.setCompleteSubStep({ name: 'cotizante' }))
          }else{
            console.log("paso 1.3",esCargaTitular,tieneCargas)
            WizardDispatch(WizardActions.setCompleteSubStep({ name: 'consideraciones' }))
          }
          wizard.next()
        } else {
          setSending(false)
          setShowError(true)
        }
      }).catch(() => {
        setSending(false)
        setShowError(true)
      })
    }
  }

  /**
   * @description Handler check in box confirm considerations
   */
  function handleConfirm() {
    previousConsiderationsDispatch(
      PreviousConsiderationsActions.setConfirmConsiderations(!confirm)
    )
  }

  /**
   * @description Handler close error message
   */
  function handleCloseErrorMessage() {
    setShowError(false)
  }

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  return (
    <div className="prev-considerations">
      <h2 className="prev-considerations__title">Información Importante</h2>
      <div className="prev-considerations__form-wrapper">
        <div className="considerations__form">
          {considerations.map((item, i) => (
            <div className="row" key={i}>
              <div className="col-12">
                <PreviousConsiderationItem num={i + 1} consideration={item} />
              </div>
            </div>
          ))}
          <div className="considerations__confirm">
            <Checkbox
              name="confirm"
              text="Confirmo que he leído y estoy de acuerdo con la información mencionada."
              clickMethod={handleConfirm}
            />
          </div>
          <div className="considerations__button">
            <Button
              disabled={sending}
              text="Continuar"
              action={handleNextStep}
              cssClass={`button__continue ${
                !confirm ? 'button__continue--disabled' : ''
              }`.trim()}
            />
          </div>
        </div>
      </div>
      <ErrorMessage isShowing={showError} action={handleCloseErrorMessage}>
        <div className="prev-considerations__alert">
          No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde
        </div>
      </ErrorMessage>
    </div>
  )
}

export default PreviousConsiderations