import RequestService from '@core/request-service'

export default class PinValidationService extends RequestService {
  static resendPIN(payload) {
    // api/reenviopin
    return super.post(
       ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/Reenviopin' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }
}
