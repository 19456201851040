import './spinner-loader.scss'
import React from 'react'
import PropTypes from 'prop-types'

const SpinnerLoader = ({ show, color, text, size }) => (
  <>
    {show && (
      <div className="spinner-wrapper">
        <div className="spinner-text">{text}</div>
        <div
          className={`spinner spinners-spinner spinner--cl-${color} spinner--${size}`}
          aria-hidden="false"
        />
      </div>
    )}
  </>
)
SpinnerLoader.propTypes = {
  show: PropTypes.bool,
  color: PropTypes.string,
  text: PropTypes.string,
  size: PropTypes.string,
}
SpinnerLoader.defaultProps = {
  show: true,
  color: 'blue',
  text: '',
  size: 'normal',
}

export default SpinnerLoader
