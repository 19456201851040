export default function fileToBase64(files) {
  return Promise.all(files.map((file) => getFile(file)));
}

function getFile(file) {
  if (file.new) {
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error parsing file"));
      };
      reader.onload = function () {
        let bytes = Array.from(new Uint8Array(this.result));
  
        let base64StringFile = btoa(
          bytes.map((item) => String.fromCharCode(item)).join("")
        );
  
        resolve({
          binario: base64StringFile,
          nombreArchivo: file.name,
          tipoMIME: file.type,
          tipoAdjunto: 10,
        });
      };
      reader.readAsArrayBuffer(file);
    });
  } else {
    return file
  }
}
