/* eslint-disable react-hooks/exhaustive-deps */
import './health-diagnostic-form.scss'
import React, { useState, useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import ConfirmMessage from '@commons/messages/confirm/confirm'
import { WizardContext, WizardService } from '@commons/steppers/wizard'
import { StepperStepsContext } from '@commons/steppers/steps'
import Select from 'react-select'
import Autosuggest from 'react-autosuggest'
import DatePicker, { registerLocale } from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import _ from 'lodash'
// import { setLoading } from '../../summary-health-statement/summary-health-statement-actions'
registerLocale('es', es)

const HealthDiagnosticForm = ({ numQuestion, index, children, completedForm,formData }) => {
  const [diagnosticData, setDiagnosticData] = useState(children)
  const [showMessage, setShowMessage] = useState(false)
  const [suggestedValue, setSuggestedValue] = useState(diagnosticData.diagnostico.diagnostico)
  const [suggestions, setSuggestions] = useState([])
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const [listDiagnostics] = useState(() => {
    return wizardData.diagnosticsQuestion.filter(diag =>Number(diag.codigoPregunta) === numQuestion)
  })
  const [beneficiaries,setBenefeciaries] = useState([])
  const [benefValues, setBenefValues] = useState([])
  const [selectedBenef, setSelectedBenef] = useState({label: "", value: 0})
  const [loadingBenef, setLoadingBenef] = useState(true)
  const { stepperStepsData } = useContext(StepperStepsContext)

  useEffect(() => {
    if(_.size(diagnosticData.rut) && _.size(diagnosticData.intervencionQuirurgica) && _.size(diagnosticData.diagnostico.diagnostico) && _.size(diagnosticData.fecha)){
      completedForm(true)
      formData(diagnosticData)
    }

  }, [diagnosticData])

  useEffect(()=>{
    fetchBeneficiary()
  },[])

  useEffect(()=>{
    if(!_.size(benefValues)){
      let benefAux = []
      beneficiaries.map((b,i)=>{
        if (diagnosticData.rut !== "") {
          if ((diagnosticData.rut.trim()).replace("-","") === b.rut.trim()) {
            setSelectedBenef({label: `${b.names} ${b.surnames}`, value: b.id})
            setDiagnosticData({ ...diagnosticData, rut: b.rut })
          }
        } else if(i === 0){ 
          setSelectedBenef({label: `${b.names} ${b.surnames}`, value: b.id})
          setDiagnosticData({ ...diagnosticData, rut: b.rut })
        }
        return benefAux.push({label: `${b.names} ${b.surnames}`, value: b.id})
      })
      setBenefValues(benefAux)
      setLoadingBenef(false)
    }
  },[beneficiaries])


  function fetchBeneficiary() {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut
    }
    WizardService.getBeneficiaries(body)
    .then((response) => {
      let benefList = []
      if(response.httpCode === 200){
        if(_.size(response.data)){
          response.data.map(b => {
            const beneficiaryAux = {
              id: b.id,
              names: b.nombres,
              surnames: `${b.apellidoPaterno} ${b.apellidoMaterno}`,
              rut: b.rutBeneficiario.trim(),
              relation: b.relacionFamiliar,
              birthdate: b.fechaNacimiento,
              sex: b.sexo,
              type: b.tipoBeneficiario,
              weight: String(b.peso),
              height: b.altura,
              order: b.orden,
              other: b.descripcionOtro,
            }
            return benefList.push(beneficiaryAux)
          })
        }
        WizardDispatch(WizardActions.setBeneficiary(benefList))
        setBenefeciaries(benefList)
      }
    }).catch(e => console.log(e))

  }

  /**
   * @description Beneficiary Selector onChange event
   * @param {event} e element event
   */
  function beneficiaryOnChangeHandler(e) {
    setSelectedBenef(e)
    const benef = beneficiaries.find(b => b.id === e.value)
    setDiagnosticData({ ...diagnosticData, rut: benef.rut })
    WizardDispatch(WizardActions.setDiagnosticData(numQuestion, { ...diagnosticData, rut: benef.rut }, index))
  }

  /**
   * @description Diagnostic Field onChange event
   * @param {event} e element event
   */
  function diagnosticOnChangeHandler(e) {
    let valueDiagnostic = e.target.value ? e.target.value : e.target.innerHTML
    valueDiagnostic = valueDiagnostic.replace('<div>', '')
    valueDiagnostic = valueDiagnostic.replace('</div>', '')
    setSuggestedValue(valueDiagnostic)
    const aux = listDiagnostics.filter(diag =>
      diag.diagnostico === valueDiagnostic
    )
    let value = { codigo: '', diagnostico: valueDiagnostic}
    if( aux.length > 0) {
      value = aux[0]
    }
    setDiagnosticData({ ...diagnosticData, diagnostico: value})
    // setDiagnisticoString(value)
    // diagnosticData.diagnostic = {diagnostico: value}
    WizardDispatch(WizardActions.setDiagnosticData(numQuestion, { ...diagnosticData, diagnostico: value }, index))  
  }

  /**
   * @description Intervention Field onChange event
   * @param {event} e element event
   */
  function interventionOnChangeHandler(e) {
    setDiagnosticData({ ...diagnosticData, intervencionQuirurgica: e.target.value })
    WizardDispatch(WizardActions.setDiagnosticData(numQuestion, { ...diagnosticData, intervencionQuirurgica: e.target.value }, index))
  }

  /**
   * @description Diagnostic Date Field onChange event
   * @param {event} e element event
   */
  function diagnosticDateOnChangeHandler(e) {
    const date = e.toLocaleDateString('es-CL')
    setDiagnosticData({ ...diagnosticData, fecha: date.replace(/-/g, '/') })
    WizardDispatch(WizardActions.setDiagnosticData(numQuestion, { ...diagnosticData, fecha: date.replace(/-/g, '/') }, index))
  }

  /**
   * @description Handler Click Del diagnostic button
   */
  function delDiagnostic() {
    setShowMessage(true)
  }

  /**
   * @description Set false in delete message
   */
  function cancelDelDiagnostic() {
    setShowMessage(false)
  }

  /**
   * @description Remove self from diagnostics ArrayData
   */
  function confirmDelDiagnostic() {
    WizardDispatch(WizardActions.removeDiagnostic(numQuestion, index))
    setShowMessage(false)
  }

  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 5
    }),
    control: (provided) => ({
      ...provided,
      height: 35,
      minHeight: 0,
      padding: 'auto'
    }),
    singleValue: (provided) => ({
      ...provided,
      height: 40,
      paddingTop: 10,
      color: diagnosticData.rut !== '' ? '#0085CA !important' : '#939393 !important'
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      height: 40,
    }),
    menu: (provided) => ({
      ...provided,
      top: 40,
      height: 'auto',
      padding: 0
    }),
    valueContainer: () => ({
      height: 40,
    }),
    input: (provided) => ({
      ...provided,
      height: 40,
    }),
  }

  /**
   * @description Obtain diagnostics suggestions for any given input value
   */
  function getSuggestions(value) {
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length
    return inputLength === 0 ? [] : listDiagnostics.filter(diag =>
      diag.diagnostico.toLowerCase().includes(inputValue)
    )
  }

  function updateDiagnosticValue(value) {
    setSuggestedValue(value)
    const aux = listDiagnostics.filter(diag =>
      diag.diagnostico === value
    )
    let valueAux = { code: '', description: value}
    if( aux.length > 0) {
      valueAux = aux[0]
    }
    setDiagnosticData({ ...diagnosticData, diagnostico: valueAux})
  }

  return (
    <div className="health-diagnostic__form">
      {wizardData.questions[numQuestion - 1].diagnostics.length > 1 ? <div className="form__action form__action--show">
        <button type="button" className="actions__action" onClick={delDiagnostic}>
          <em className="icon-eliminar"></em>
        </button>
      </div> : null}
      <div className="form__item">
        <span>*</span>
        <div className="text-group form-group">
          {diagnosticData.rut !== '' ? <label htmlFor="" className="form-label-data">
            Beneficiario
          </label> : null}
          {loadingBenef ? null : 
          <Select
            name={`beneficiary_${numQuestion}_${index}`}
            isSearchable={false}
            styles={customStyles}
            height="10"
            className="ns-select"
            classNamePrefix="ns-select"
            onChange={beneficiaryOnChangeHandler}
            options={benefValues}
            value={selectedBenef}
            noOptionsMessage={() => 'No se pudo cargar los beneficiarios, vuelve a intentarlo mas tarde.'}
          />
          }
        </div>
      </div>
      <div className="form__item">
        <span>*</span>
        <div className="text-group form-group">
          {diagnosticData.diagnostico.diagnostico !== '' ? <label htmlFor="" className="form-label-data">
            Diagnóstico
          </label> : null}
          <Autosuggest
            suggestions={suggestions}
            onSuggestionsFetchRequested={({ value }) => {
              setSuggestedValue(value)
              setSuggestions(getSuggestions(value))
            }}
            onSuggestionsClearRequested={() => setSuggestions([])}
            onSuggestionSelected={(_, { suggestionValue }) => {
              updateDiagnosticValue(suggestionValue)
            }}
            getSuggestionValue={suggestion => suggestion.diagnostico}
            renderSuggestion={suggestion => <div>{suggestion.diagnostico}</div>}
            inputProps={{
              placeholder: 'Diagnóstico',
              value: suggestedValue,
              onChange: (e) => diagnosticOnChangeHandler(e),
              autoFocus: true,
            }}
          />
        </div>
      </div>
      <div className="form__item">
        <span>&nbsp;</span>
        <div className="text-group form-group">
          <input
            className="form-control form-control--ds"
            name={`intervention_${index}`}
            type="text"
            placeholder="&nbsp;"
            autoComplete="off"
            value={diagnosticData.intervencionQuirurgica}
            onChange={interventionOnChangeHandler}
          />
          <label htmlFor="" className="form-label">
            Intervención quirúrgica
          </label>
        </div>
      </div>
      <div className="form__item form__item--noborder">
        <span>*</span>
        <div className="text-group form-group form-group__datepicker">
          <label htmlFor="" className="form-label-data">
            Fecha aproximada del diagnóstico
          </label>
          <DatePicker
            maxDate={new Date()}
            value={diagnosticData.fecha}
            locale="es"
            name={`diagnosticDate_${index}`}
            className="form-control form-control--ds"
            onChange={diagnosticDateOnChangeHandler}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
          />
        </div>
      </div>
      <ConfirmMessage
        confirm="Eliminar"
        cancel="Cancelar"
        isShowing={showMessage}
        confirmAction={confirmDelDiagnostic}
        cancelAction={cancelDelDiagnostic}
      >
        <div>
          Estás a punto de eliminar un diagnóstico. Si estás seguro confirma
          la acción
        </div>
      </ConfirmMessage>
    </div>
  )
}

HealthDiagnosticForm.prototype = {
  index: PropTypes.number.isRequired,
  numQuestion: PropTypes.number.isRequired,
  children: PropTypes.shape({
    diagnostic: PropTypes.object.isRequired,
    intervention: PropTypes.string,
    diagnosticDate: PropTypes.string,
  }).isRequired,
}

export default HealthDiagnosticForm
