/**
 * @description Base class for Api Request
 * extends your services using this class if you want
 */

// TODO dirty hack change this later

const wrapFetch = ($fetch) => {
  let token = ''
  const params = {
    grant_type: 'client_credentials',
    client_id: process.env.REACT_APP_API_CLIENT_ID,
    client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
  }
  const retrieveToken = () => {
    if (token) return Promise.resolve(token)
    return fetch('/oauth/access/oauth2/token', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: Object.keys(params)
        .map((key) => {
          return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`
        })
        .join('&'),
    })
      .then((res) => res.json())
      .then(({ access_token }) => {


        token = access_token
        return token
      })
  }

  return (endpoint, params) => {


    if (process.env.NODE_ENV === 'production' || process.env.REACT_APP_ENABLE_API) {
      return retrieveToken().then((access_token) => {
        return $fetch(endpoint, {
          ...params,
          headers: {
            ...params.headers,
            Authorization: `Bearer ${access_token}`,
          },
        })
      })
    }
    return fetch(endpoint, params)
  }
}

const _fetch = wrapFetch(window.fetch)
export default class RequestService {
  /**
   * @description Fetch Data using GET method
   * @param {string} endpoint requested url
   * @param {object} headers Headers object used on the request
   */
  static async get(endpoint, headers) {
    const response = await _fetch(endpoint, {
      method: 'GET',
      headers,
    })

    const data = response.ok ? await response.json() : []
    return {
      httpCode: response.status,
      data: data.data,
    }
  }

  /**
   * @description Send data using PUT method
   * @param {string} endpoint requested url
   * @param {object} params object used like body data
   * @param {object} headers Headers object used on the request
   */
  static async put(endpoint, params, dataUrl, headers) {
    const response = await _fetch(endpoint, {
      method: 'PUT',
      headers: {
        "Content-type": "application/json",
      },
      body: JSON.stringify(params)
    })
    const data = response.ok ? await response.json() : []
    return {
      httpCode: response.status,
      data,
    }
  }

  /**
   * @description Send data using POST method
   * @param {string} endpoint requested url
   * @param {object} params object used like body data
   * @param {object} headers Headers object used on the request
   */
  static async post(endpoint, params, headers) {
    const response = await _fetch(endpoint, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
    const data = response.ok ? await response.json() : []
    return {
      httpCode: response.status,
      data: data.data,
    }
  }

  static async postClave(endpoint, params, headers) {
    const response = await _fetch(endpoint, {
      method: 'POST',
      headers: {
        ...headers,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(params),
    })
    const data = response.status == 200 ? await response.json() : []
    return {
      httpCode: response.status,
      data: data,
    }
  }
}
