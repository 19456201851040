import './separator.scss'
import React from 'react'
import PropTypes from 'prop-types'

const Separator = ({ isVertical }) => (
  <div className={`separator ${isVertical && 'separator--vertical'}`}>
    &nbsp;
  </div>
)
Separator.propTypes = {
  isVertical: PropTypes.bool,
}
Separator.defaultProps = {
  isVertical: false,
}
export default Separator
