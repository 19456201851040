/* eslint-disable react/forbid-prop-types */
import React, { useState } from 'react'
import './message-declaration.scss'
import InfoMessage from '@commons/messages/info/info-message'
import PropTypes from 'prop-types'
import Checkbox from '@commons/inputs/checkbox'

const MessageDeclaration = ({ message, isShowing, action }) => {
  const [checked, setChecked] = useState(false)
  
  const handleConfirm = () => {
    setChecked(!checked)
  }
    /**
   * @description Handler onClick mesage
   */
  const handleOnclick = () => {
    action()
  }

  return (
    <div className="message-error">
      <div>
        <InfoMessage 
            buttonText='Declaro' 
            isShowing={isShowing} 
            iconColor="orange" 
            floating={false}
            action={handleOnclick}
            disabled={!checked}
        >
          <div>
            <Checkbox
                name="test"
                text={message}
                clickMethod={handleConfirm}
            />
          </div>
        </InfoMessage>
      </div>
    </div>
  )
}

MessageDeclaration.propTypes = {
  message: PropTypes.string.isRequired,
  isShowing: PropTypes.bool.isRequired,
  action: PropTypes.func.isRequired,
}

export default MessageDeclaration
