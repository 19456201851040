import './health-statement-status.scss'
import React, { useState } from 'react'
import Tooltip from '@commons/tooltip/tooltip'
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import PropTypes from 'prop-types'
import { useEffect } from 'react';

const HealthStatementStatus = ({ totalQuestions, totalAnswered }) => {
  const [showTooltip, setShowTooltip] = useState(true)

  const handlerTooltipOnMouseOut = () => {
    setShowTooltip(false)
  }

  const handlerTooltipOnMouseOver = () => {
    setShowTooltip(true)
  }

  useEffect(() => {
    setTimeout(() => {
      handlerTooltipOnMouseOut()
    }, 5000);
  },[])
  return (
    <div className="health-status">
      <div className="health-status__icon">
        <div className="health-status__tooltip">
          <Tooltip visible={showTooltip}>
            <div>
              Debe registrar TODAS las enfermedades, patologías o condiciones de
              salud originadas con posterioridad al nacimiento y diagnosticadas
              médicamente a usted y a cada uno de sus beneficiarios, hayan o no
              requerido tratamiento, hospitalización o intervención quirúrgica,
              cualquiera sea la fecha de diagnóstico y su estado actual, incluso
              si se ha recuperado y ha sido dado de alta. No deben declararse las 
              enfermedades o condiciones de salud congénitas que le hayan sido diagnosticadas 
              a usted o alguno de sus beneficiarios hasta el término de su período neonatal 
              ni las enfermedades mentales o discapacidades psíquicas o intelectuales. 
              No es obligatoria la declaración de patologías oncológicas, respecto de las 
              cuales hayan transcurrido cinco años desde la finalización del tratamiento radical sin recaída
              posterior, en virtud de lo dispuesto en el artículo 8° bis de la Ley Nº 21.258. 
              El siguiente listado es sólo referencial, de modo que si usted ha tenido otra
              enfermedad, patología o condición de salud no indicada aquí, debe declararla
            </div>
          </Tooltip>
        </div>
        <em
          className="icon-alerta"
          onMouseOver={handlerTooltipOnMouseOver}
          onMouseOut={handlerTooltipOnMouseOut}
        />
      </div>
      <div className="health-status__container">
        <CircularProgressbar
          value={(totalAnswered/totalQuestions)*100}
          text={`${totalAnswered} de ${totalQuestions}`} />
      </div>
    </div>
  )
}
HealthStatementStatus.propTypes = {
  totalQuestions: PropTypes.number.isRequired,
  totalAnswered: PropTypes.number.isRequired,
}

export default HealthStatementStatus
