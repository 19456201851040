import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import Steps from '@utils/steps'
import StepperStepsReducer from './stepper-steps-reducer'
import * as StepperStepsActions from './stepper-steps-actions'

export const StepperStepsContext = React.createContext()

export const StepperStepsContextProvider = ({ children }) => {
  const [stepperStepsData, stepperStepsDispatch] = useReducer(
    StepperStepsReducer,
    {
      steps: Steps,
      userInformation: localStorage.getItem('userInformation')
        ? JSON.parse(localStorage.getItem('userInformation'))
        : {},
      result: '',
      stage: '',
      isAsisted: localStorage.getItem('isAsisted')
        ? JSON.parse(localStorage.getItem('isAsisted'))
        : '',
        originInvitation: localStorage.getItem('originInvitation')
        ? JSON.parse(localStorage.getItem('originInvitation'))
        : '',
    }
  )

  return (
    <StepperStepsContext.Provider
      value={{ stepperStepsData, stepperStepsDispatch, StepperStepsActions }}
    >
      {children}
    </StepperStepsContext.Provider>
  )
}
StepperStepsContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
}