import './layout.scss'
import React from 'react'
import PropTypes from 'prop-types'
import Header from '@commons/header/header'
import Footer from '@commons/footer/footer'
import { useHistory } from 'react-router-dom'
import LandingLeft from '@assets/images/landing-left.jpg'
import LandingRight from '@assets/images/landing-right.png'

const Layout = ({ children }) => {
  const history = useHistory()

  return (
    <>
      {history.location.pathname !== '/retomar-proceso' ? (
        <>
          <Header />
          <main>
            <div className="container">
              <div className="content-wrapper">{children}</div>
            </div>
          </main>
        </>
      ) : (
        <>
          <main>
            <div className="welcome">
              <div className="welcome__landing-left">
                <img src={LandingLeft} alt="landing-left" />
              </div>
              <div className="welcome__wrapper">{children}</div>
              <div className="welcome__landing-right">
                <img src={LandingRight} alt="landing-right" />
              </div>
            </div>
          </main>
        </>
      )}

      <Footer />
    </>
  )
}
Layout.propTypes = {
  children: PropTypes.element.isRequired,
}

export default Layout
