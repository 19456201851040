import './consideration-number.scss'
import React from 'react'
import PropTypes from 'prop-types'

const ConsiderationNumber = ({ number }) => {

  return (
    <div className="number__item">
      <div className="item__outer">
        <div className="item__inner">
          {number}
        </div>
      </div>
    </div>
  )
}
ConsiderationNumber.propTypes = {
  number: PropTypes.number.isRequired
}

export default ConsiderationNumber
