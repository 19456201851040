import * as yup from 'yup'

/**
 * @description Function get weight in schema and return if is valid.
 */
const weightSchema = yup
  .number('Debe ser un número')
  .required('Debe ingresar su Peso en kilos')
  .positive('El peso debe ser positivo')
  .typeError('Debe ingresar un número')
export default weightSchema


