import React, { useState, useContext, useEffect } from 'react'
import {
  RentStatementContext,
  RentStatementService,
} from '@components/rent-statement'
import { WizardContext } from '@commons/steppers/wizard'
import { Table, Td, Th } from '@commons/table/table'
import Separator from '@commons/separator/separator'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import { StepperStepsContext } from '@commons/steppers/steps'
import { format } from 'rut.js'
import FormatNum from '@utils/tools/number'
import EmployerManager from './employer-manager'
import ErrorMessage from '@commons/messages/error/error-message'
import _ from 'lodash'

const EditStatement = ({ finalSave }) => {
  const {
    rentStatementData,
    RentStatementDispatch,
    RentStatementActions,
  } = useContext(RentStatementContext)
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const { quotes, employers } = rentStatementData
  const [loading, setLoading] = useState(false)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { userInformation } = stepperStepsData
  const [ showErrorMessage, setShowErrorMessage] = useState(false)

  useEffect(() => {
    setLoading(true)
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
    }
    RentStatementService.getQuotationList(body).then((response) => {
      if(response.httpCode === 200){
        RentStatementDispatch(
          RentStatementActions.setQuotationList(response.data)
        )
        setLoading(false)
      }
      else{
        setShowErrorMessage(true)
        setLoading(false)
      }
    }).catch(() => {
      setShowErrorMessage(true)
      setLoading(false)
    })
  }, [stepperStepsData.userInformation,RentStatementActions, RentStatementDispatch])

  /**
   * Side effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  return (
    <div className="rent-statement rent-statement--edit-mode">
      <div className="rent-statement__title">Cotizante</div>
      
      <div className="rent-statement__user">
        <div className="user__name">
          <div className="user__firstname">
            <span>Nombres</span>
            {userInformation.nombres}
          </div>
          <Separator />
          <div className="user__lastname">
            <span>Apellidos</span>
            {userInformation.apellidoPaterno} {userInformation.apellidoMaterno}
          </div>
        </div>
      </div>
      <div className="rent-statement__employers">
        <div className="rent-statement__section-title">
          <div className="rent-statement__title">
            Existen varios empleadores. Favor seleccione su(s) empleador(es) actual(es).
          </div>
        </div>
        <EmployerManager finalSave={finalSave}>{employers}</EmployerManager>
      </div>
      <div className="rent-statement__health-quotes">
        { _.size(quotes) > 0 ?
        <>
          <div className="rent-statement__title">
            Cotizaciones de los últimos {quotes.length} meses
          </div>
          {loading ? (
            <SpinnerLoader show={loading} />
          ) : (
            <Table>
              <thead>
                <tr>
                  <Th>Fecha</Th>
                  <Th>Rut Empleador</Th>
                  <Th>Renta Imponible</Th>
                </tr>
              </thead>
              <tbody>
                {quotes.map((item) => (
                  <tr key={item.id}>
                    <Td>{item.fecha}</Td>
                    <Td>{format(String(item.rut))}</Td>
                    <Td>{FormatNum(item.renta)}</Td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </>
        :
          null
        }
      </div>
      <ErrorMessage isShowing={showErrorMessage} action={() => setShowErrorMessage(false)}>
        No se ha podido obtener las cotizaciones del cotizante.
      </ErrorMessage>
    </div>
  )
}
export default EditStatement
