import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { WizardContext } from '@commons/steppers/wizard/'
import { RentStatementContext, RentStatementService } from '@components/rent-statement'
import Button from '@commons/buttons/button'
import RentSummary from '@components/rent-statement/elements/rent-summary'
import EditStatement from '@components/rent-statement/elements/edit-statement'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import ErrorMessage from '@commons/messages/error/error-message'
import ErrorMessageBtn from '@commons/messages/error/error-message-btn'
import { StepperStepsService, StepperStepsContext } from '@commons/steppers/steps'
import moment from 'moment'
import InfoMessage from '@commons/messages/info/info-message'
import _ from 'lodash'


import EditStatementNoCotizaciones from '@components/rent-statement/elements/edit-statement-no-cotizaciones'
import ContributorDataManager from '@components/contributor-background/elements/contributor-data-manager'

const RentStatement = () => {
  const history = useHistory()
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { userInformation } = stepperStepsData
  const {
    rentStatementData,
    RentStatementDispatch,
    RentStatementActions,
  } = useContext(RentStatementContext)
  const [readyToGo, setReadyToGo] = useState(false)
  const [showErrorNoFiles, setShowErrorNoFiles] = useState(false)
  const [editStatement, setEditStatement] = useState(false)
  const [sending, setSending] = useState(false)
  const [clickEdit, setClickEdit] = useState(false)
  const [message, setMessage] = useState('')
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showErrorMessageBtn, setShowErrorMessageBtn] = useState(false)
  const [showErrorAcreditation, setShowErrorAcreditation] = useState({ show: false, msg: '' })
  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))

  const [finalSave, setFinalSave] = useState(false)
  const [loading, setLoading] = useState(false)

  /**
   * @description Move to the Next BigStep
   */
  async function completeuserInformation() {

    if (rentStatementData.employers.length === 0) {

      readyToComplete()
      return false

    }
    setSending(true)

    let value_entityType = [];
    rentStatementData.employers.map(item => value_entityType.push(item.entity_type));

    let tipoAfiliado = '';

    if (value_entityType.includes("1")) {
      tipoAfiliado = '1';
    } else if (value_entityType.includes("3")) {
      tipoAfiliado = '3';
    } else if (value_entityType.includes("2")) {
      tipoAfiliado = '2';
    } else if (value_entityType.includes("4")) {
      tipoAfiliado = '4';
    }

    const body = {
      "tipoAfiliado": tipoAfiliado
    }

    await RentStatementService.getDsV2(body, stepperStepsData.userInformation.identificador).then((response) => {
      if (response.httpCode !== 200) {
        setSending(false);
        setShowErrorMessage(true);
        setMessage('No se ha podido guardar tu información de renta');
        return false;
      }

      completeuserInformationContinue();
      return true;
    }).catch(() => {
      setShowErrorMessage(true);
      setMessage('No se ha podido guardar tu información de renta');
    })
  }

  async function completeuserInformationNoEdit() {

    if (rentStatementData.employers.length === 0) {

      readyToComplete()
      return false

    }

    setSending(true)
    completeuserInformationNoEditContinue()
    return true
    
  }

  async function rechazarUserInformation() {
    setShowErrorMessageBtn(true)
    setMessage(<>
      <div className="rent-statement__text mb-3">
        Debido a que no esta de acuerdo con la información presentada, debes contactar a
        tu Ejecutivo. ¿Estàs seguro en Rechazar?
      </div>
      <div className="rent-statement__text mb-3">
        <div className="rent-statement__buttons">
          <Button
            text="SI"
            action={deleteuserInformation}
          />
          <Button
            text="NO"
            action={continuarSusctiption}
          />
        </div>
      </div>
    </>
    )
    setSending(false)
  }

  async function continuarSusctiption() {
    
    setShowErrorMessageBtn(false)

  }

  async function deleteuserInformation() {
    setShowErrorMessageBtn(false)

    const body = {}
    RentStatementService.getDs(body).then((response) => {
      if (response.httpCode === 200) {

        const anularProspectoRequest = {
          "prospectoId": response.data.data.prospectoId
        }

        RentStatementService.anularProspecto(anularProspectoRequest).then((response) => {
          if (response.httpCode === 200) {

            setShowErrorMessage(true);
            setMessage('El prospecto se ha eliminado correctamente')
            handleRedirection()

          } else {

            setShowErrorMessage(true);
            setMessage('No se ha podido eliminar el prospecto')

          }
        }).catch(() => {

          setShowErrorMessage(true);
          setMessage('No se ha podido eliminar el prospecto');
          // handleRedirection()

        })

      }
    }).catch((err) => {
      console.log(err)
    })
  }

  async function completeuserInformationNoEditContinue() {

    const employers = rentStatementData.employers.map((employer, index) => {
      return {
        ...employer
      }
    })

    const employersFilter = employers.filter(emp => emp.vigente === true)
    const body = {
      identificador: userInformation.identificador,
      rut: userInformation.rut,
      entidadPagadora: employersFilter,
    }

    if (!stepperStepsData.isAsisted && _.size(employers) > 1) {
      setShowErrorMessage(true)
      setMessage('Presentas más de un empleador. Accede al detalle y deja un solo empleador o solicita asistencia.')
      setSending(false)
    } else {
      RentStatementService.saveEmployerData(body)
        .then((response) => {
          if (response.httpCode === 200) {
            if (!clickEdit) {
              saveRentStep()
            } else {
              if (!stepperStepsData.isAsisted) {
                if (_.size(response.data.errores)) {
                  const msg = response.data.errores[0].descripcion
                  setShowErrorAcreditation({ show: true, msg: msg })
                }
                else {
                  saveRentStep()
                }
              } else {
                saveRentStep()
              }
            }
          } else {
            setSending(false)
            setShowErrorMessage(true)
            setMessage('No se ha podido guardar tu información de renta')
          }
        })
        .catch(() => {
          setSending(false)
          setShowErrorMessage(true)
          setMessage('No se ha podido guardar tu información de renta')
        })
    }

  }

  async function completeuserInformationContinue() {
    // CODIGO ORIGINAL
    const documents = rentStatementData.employers.map(async employer => {

      const documentsEmployer = await employer.archivos.map(async file => {
        let document
        if (file.adJ_CORREL) {

          document = file.adJ_CORREL.toString()
        } else {

          const body = {
            ...file,
            identificador: userInformation.identificador,
          }
          document = await RentStatementService.loadDocument(body).then((response) => {
            return response.httpCode === 200 ? response.data.adJ_CORREL.toString() : ''
          })
        }

        return document
      })

      const documentResponse = await Promise.all(documentsEmployer)
      return documentResponse
    })

    const documentsResponse = await Promise.all(documents)
    let errors = false
    documentsResponse.map((employer) => {
      employer.map((doc) => {
        if (doc === "") {
          errors = true
        }
      })
    })

    if (!errors) {
      const employers = rentStatementData.employers.map((employer, index) => {
        return {
          ...employer,
          archivos: documentsResponse[index],
        }
      })
      const employersFilter = employers.filter(emp => emp.vigente === true)
      const body = {
        identificador: userInformation.identificador,
        rut: userInformation.rut,
        entidadPagadora: employersFilter,
      }

      if (!stepperStepsData.isAsisted && _.size(employers) > 1) {
        setShowErrorMessage(true)
        setMessage('Presentas más de un empleador. Accede al detalle y deja un solo empleador o solicita asistencia.')
        setSending(false)
      } else {
        RentStatementService.saveEmployerData(body)
          .then((response) => {
            if (response.httpCode === 200) {
              if (!clickEdit) {
                saveRentStep()
              } else {
                if (!stepperStepsData.isAsisted) {
                  if (_.size(response.data.errores)) {
                    const msg = response.data.errores[0].descripcion
                    setShowErrorAcreditation({ show: true, msg: msg })
                  }
                  else {
                    saveRentStep()
                  }
                } else {
                  saveRentStep()
                }
              }
            } else {
              setSending(false)
              setShowErrorMessage(true)
              setMessage('No se ha podido guardar tu información de renta')
            }
          })
          .catch(() => {
            setSending(false)
            setShowErrorMessage(true)
            setMessage('No se ha podido guardar tu información de renta')
          })
      }
    } else {
      setSending(false)
      setShowErrorMessage(true)
      setMessage('No se ha podido guardar los documentos, revisa los documentos')
    }

  }

  function saveRentStep() {
    const body2 = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      etapa: 'renta',
      subetapa: '',
      fechaRegistro: moment().format('DD-MM-YYYY')
    }
    StepperStepsService.sendStep(body2).then((response) => {
      if (response.httpCode === 200) {
        WizardDispatch(WizardActions.reset())
        history.push('/declaracion-salud/consideraciones-previas')
      } else {
        setSending(false)
        setShowErrorMessage(true)
        setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
      }
    }).catch(() => {
      setSending(false)
      setShowErrorMessage(true)
      setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
    })
  }

  /**
   * @description Confirm page information then you can continue the process
   */
  function readyToComplete() {
    setLoading(true)
    setFinalSave(!finalSave)
    setReadyToGo(!readyToGo)
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

  function handleRedirection() {
    window.location.assign("https://www.nuevamasvida.cl/")
  }

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  useEffect(() => {
    const loadRent = async () => {
      RentStatementDispatch(RentStatementActions.setLoading(true))
      await RentStatementService.getEmployerData({
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
      })
        .then(async (response) => {
          if (response.httpCode === 200) {
            const employerData = response.data

            if (response.data) {
              let dataEmpleado = response.data.entidadPagadora;
              let tipoEmpleado = [];

              dataEmpleado.map((item) => {
                tipoEmpleado.push(item.dependiente)
              });
              localStorage.setItem("dataEmpleado", JSON.stringify(tipoEmpleado))
            }

            const employers = await employerData.entidadPagadora.map(
              async (item) => {
                return {
                  ...item,
                  rut: item.rut.trim(),
                  valid: true,
                  verified: false,
                  entity_type: item.dependiente ? '1' : '2',
                  archivos: [],
                  tipoTrabajador: item.tipoTrabajador === null ? '1' : item.tipoTrabajador,
                }
              }
            )
            const employersResponse = await Promise.all(employers)
            employerData.entidadPagadora = employersResponse
            RentStatementDispatch(RentStatementActions.setEmployerData(employerData))
            RentStatementDispatch(RentStatementActions.setLoading(false))

            RentStatementService.validarEntidadesPagadoras({ identificador: stepperStepsData.userInformation.identificador })
              .then(response => {
                if (response.httpCode === 200) {
                  if ((response.data.respuesta === 0) || esCargaTitular) {
                    setShowErrorMessageBtn(true)
                    setMessage(<>
                      <div className="rent-statement__text mb-3 rent-statement__txt_red">
                        ¡REVISAR EMPLEADORES!
                      </div>
                      <div className="rent-statement__text mb-3">
                        Si la información es correcta, "Apruebe", de lo contrario "Rechace" y contacte a su asesor.
                        <div onClick={() => {
                          setShowErrorMessageBtn(false)
                          setEditStatement(true)
                        }}
                          className="rent-statement__blue rent-statement__blue--link"
                        >
                          <div className="rent-statement__text mb-3">IR AL DETALLE</div>
                        </div>
                      </div>
                    </>
                    )
                    setSending(false)
                  }
                } else {
                  setShowErrorMessage(true)
                  setMessage('No se pudo validar las entidades pagadoras')
                }
              })
              .catch(() => {
                setShowErrorMessage(true)
                setMessage('No se pudo validar las entidades pagadoras')
              })

          } else {
            RentStatementDispatch(RentStatementActions.setLoading(false))
            setShowErrorMessage(true)
            setMessage('No se ha podido obtener tu información de renta')
          }
        })
        .catch(() => {
          RentStatementDispatch(RentStatementActions.setLoading(false))
          setShowErrorMessage(true)
          setMessage('No se ha podido obtener tu información de renta')
        })
    }

    loadRent()
  }, [
    RentStatementDispatch,
    RentStatementActions,
    stepperStepsData.userInformation.identificador,
    stepperStepsData.userInformation.rut,
  ])

  useEffect(() => {
    if (editStatement && rentStatementData.enabledEditMode) {
      setReadyToGo(false)
    }
  }, [editStatement, rentStatementData.enabledEditMode])

  useEffect(() => {
    setTimeout(() => {
      if (finalSave) {
        let local = localStorage.getItem('employerValidForm')
        if (local == "false") {
          setMessage("Hay errores en el formulario. Favor revisar.")
          setShowErrorMessage(true)
          readyToComplete()
        }
      }
      setLoading(false)
    }, 1500)
  }, [finalSave])


  if (stepperStepsData.originInvitation === 4 || stepperStepsData.originInvitation === 5) {

    return (
      <div
        className={`rent-statement rent-statement--${editStatement ? 'edit' : 'summary'
          }`}
      >
        <div className="rent-statement__title">Declaración de Renta</div>
        <div className="rent-statement__subtitle">
          {editStatement ? (
            <div>
              Este es el detalle de las rentas informadas, <br /> puedes editar, eliminar o agregar en
              caso de ser necesario
            </div>
          ) : (
            'A continuación revisaremos tu información de renta'
          )}
        </div>
        <div className="text-center">
          <SpinnerLoader show={rentStatementData.loading} />
        </div>
        {!rentStatementData.loading && (
          <>
            {editStatement ? (
              <EditStatement finalSave={finalSave} />
            ) : (
              <RentSummary
                clickEdit={() => setClickEdit(true)}
                setEditStatement={() => {
                  setEditStatement(true)
                  setReadyToGo(false)
                }}
              />
            )}
            <SpinnerLoader show={loading} />
            <div className="rent-statement__confirm">
              <div className="ns-checkbox">
                <div className="ns-checkbox__wrapper">
                  <div
                    className="ns-checkbox__input"
                    role="button"
                    tabIndex={0}
                    onClick={() => readyToComplete()}
                    onKeyPress={() => readyToComplete()}
                  >
                    <input
                      type="checkbox"
                      className="ns-checkbox__check"
                      name="test"
                      checked={readyToGo}
                    />
                    <span className="ns-checkbox__inner">
                      <em
                        className={`ns-checkbox__icon ${readyToGo && 'icon-check'}`.trim()}
                      />
                    </span>
                  </div>
                  <div className="ns-checkbox__text">La información señalada es correcta, autorizo su uso en la etapas posteriores de la Suscripción Electrónica</div>
                </div>
              </div>
            </div>
            <div className="rent-statement__button">
              <Button
                text="Continuar"
                action={completeuserInformation}
                disabled={!readyToGo || sending}
              />
            </div>
          </>
        )}

        <InfoMessage
          buttonText="Volver a intentar"
          action={() => setShowErrorNoFiles(false)}
          isShowing={showErrorNoFiles}
        >
          Debe tener archivos ingresados por cada Empleador
        </InfoMessage>

        <ErrorMessage
          isShowing={showErrorAcreditation.show}
          action={handleRedirection}
          cancelBtnText='Cerrar Sesión'
        >
          {showErrorAcreditation.msg}
        </ErrorMessage>
        <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
          {message}
        </ErrorMessage>
        <ErrorMessageBtn isShowing={showErrorMessageBtn}>
          {message}
        </ErrorMessageBtn>
      </div>
    )

  } else {

    return (

      <div className={`rent-statement rent-statement--${editStatement ? 'edit' : 'summary'}`} >
        <div className="rent-statement__title">Declaración de Renta</div>
        <div className="rent-statement__subtitle">
          A continuación se presenta(n) la(s) renta(s) promedio informada(s) y el(los)
          Empleador(es) vigente(s) responsable(s) del pago de las cotizaciones.
        </div>
        <div className="text-center">
          <SpinnerLoader show={rentStatementData.loading} />
        </div>
        {!rentStatementData.loading && (
          <div>
            {editStatement ? (
              <EditStatementNoCotizaciones finalSave={finalSave} />
            ) : (
              <RentSummary
                clickEdit={() => setClickEdit(true)}
                setEditStatement={() => {
                  setEditStatement(true)
                  setReadyToGo(false)
                }}
              />
            )}
            <SpinnerLoader show={loading} />
            <div className="rent-statement__subinfo">Para avanzar en el proceso necesitamos tu aprobaciòn de la informaciòn presentada</div>
            <div className="rent-statement__buttons">
              <Button
                text="Aprobar"
                action={completeuserInformationNoEdit}
              />
              <Button
                text="Rechazar"
                action={rechazarUserInformation}
              />
            </div>
          </div>
        )}

        <ErrorMessageBtn isShowing={showErrorMessageBtn}>
          {message}
        </ErrorMessageBtn>
        <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
          {message}
        </ErrorMessage>
      </div>
    )

  }

}

export default RentStatement
