import './modal.scss'
import React from 'react'
import PropTypes from 'prop-types'

const SimpleModal = ({  
  children,
  action,
  isShowing,
  iconColor,
  floating,
  styles
}) => (
  <>
  <div
    className={`simple-modal simple-modal--${
      isShowing ? 'show' : 'hide'
    } simple-modal--${floating ? 'floating' : 'not-floating'}`}
    style={styles?.modalBody}
  >
    <div className={`simple-modal__icon simple-modal__icon--cl-${iconColor}`} style={{...styles?.closeBtn, cursor: 'pointer'}}>
      <em className="icon-agregar" 
        onClick={action}
      />
    </div>
    <div className="simple-modal__content" style={styles?.modalContainer}>{children}</div>

  </div>
  {isShowing && <div className="simple-modal__content-locked"></div>}
  </>
)

SimpleModal.propTypes = {
  children: PropTypes.node.isRequired,
  action: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  floating: PropTypes.bool,
  iconColor: PropTypes.string,
}

SimpleModal.defaultProps = {
  floating: true,
  iconColor: 'orange',
}

export default SimpleModal
