import './contributor-data-manager.scss'
import React, { useCallback } from 'react'
import Separator from '@commons/separator/separator'
import DatePicker from 'react-datepicker'
import { useState } from 'react'
import { useEffect } from 'react'

const ContributorDataManager = ({ children, setValidData, setContributor, editableData}) => {

  const [birthdateData, setBirthdateData] = useState(children.birthdate)
  const [errorBirthdate, setErrorBirthdate] = useState(null)

  const validateData = useCallback(() => {
    let result = true
  
    if(birthdateData === ''){
      result = false
      setErrorBirthdate('La fecha de nacimiento es obligatorio')
    } else {
      setErrorBirthdate(null)
    }

    setValidData(result)
    return result

    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[birthdateData, errorBirthdate])
  /**
   * @description Birthdate Field onChange event
   * @param {event} e element event
   */
  function birthdateOnChangeHandler(e) {
    const date = e.toLocaleDateString('es-CL')
    setContributor({ ...children, birthdate: date })
    setBirthdateData(date)
    validateData()
  }

  useEffect(() => {
    validateData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthdateData, errorBirthdate])
  
  return (
    <div className="contributor-data">
      <div className="contributor-data__title">Cotizante</div>
      <div className="contributor-data__manager">
          <div className="contributor-data__box">
            <div className="form-row">
              <div className="form-group--four">
                <label htmlFor="names" className="form-label">
                  Nombres
                </label>
                <p className="contributor-data__value-data">{children.names}</p>
              </div>
              <Separator />
              <div className="form-group--four">
                <label htmlFor="paternalSurname" className="form-label">
                  Apellido Paterno
                </label>
                <p className="contributor-data__value-data">{children.paternalSurname}</p>
              </div>
              <Separator />
              <div className="form-group--four">
                <label htmlFor="maternalSurname" className="form-label">
                  Apellido Materno
                </label>
                <p className="contributor-data__value-data">{children.maternalSurname}</p>
              </div>
              <Separator />
              <div className="form-group--four">
                <label htmlFor="rut" className="form-label">
                  Rut
                </label>
                <p className="contributor-data__value-data">{children.rut}</p>
              </div>
            </div>
            <Separator isVertical={true} />
            <div className="form-row">
              <div className="form-group">
                <label htmlFor="birthdate" className="form-label">
                  Fecha Nacimiento
                </label>
               {editableData ? 
                <>
                  <DatePicker
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    maxDate={new Date()}
                    minDate={new Date('01-01-1900')}
                    dropdownMode="select"
                    value={birthdateData}
                    readOnly={false}
                    disabled={false}
                    locale="es"
                    className="form-control"
                    onChange={birthdateOnChangeHandler}
                  />
                  {errorBirthdate && (
                    <div className="invalid-feedback">{errorBirthdate}</div>
                  )}
                </>
                :
                <p className="contributor-data__value-data">{children.birthdate}</p>}
              </div>
              <Separator />
              <div className="form-group--xlarge">
                <label htmlFor="email" className="form-label">
                  Correo Electrónico
                </label>
                <p className="contributor-data__value-data">{children.email}</p>
              </div>
              <Separator />
              <div className="form-group--medium">
                <label htmlFor="isapre" className="form-label">
                  Institución Salud Actual
                </label>
                <p className="contributor-data__value-data">{children.isapre}</p>
              </div>
            </div>
        </div>
      </div>
    </div>
  )
}

export default ContributorDataManager
