const SET_COMPLETED_STEP = 'SET_COMPLETED_STEP'
const LOADING_DATA = 'LOADING_DATA'
const SET_CONFIRMED_BACKGROUND = 'SET_CONFIRMED_BACKGROUND'

function setCompletedStep() {
    return { type: SET_COMPLETED_STEP, flag: true }
}

function setLoading(flag) {
    return {type: LOADING_DATA,flag}
}

function setConfirmedBackground(flag) {
    return {type: SET_CONFIRMED_BACKGROUND,flag: true}
}


export {
    SET_COMPLETED_STEP,
    LOADING_DATA,
    SET_CONFIRMED_BACKGROUND,
    setCompletedStep,
    setLoading,
    setConfirmedBackground,
}

  