import './info-message.scss'
import React from 'react'
import Button from '@commons/buttons/button'
import PropTypes from 'prop-types'

const InfoMessage = ({
  children,
  buttonText,
  action,
  isShowing,
  iconColor,
  floating,
  disabled,
  locked,
}) => (
  <>
  <div
    className={`info-message info-message--${
      isShowing ? 'show' : 'hide'
    } info-message--${floating ? 'floating' : 'not-floating'}`}
  >
    <div className={`info-message__icon info-message__icon--cl-${iconColor}`}>
      <em className="icon-alerta" />
    </div>
    <div className="info-message__content">{children}</div>
    {buttonText ? (
      <div className="info-message__actions">
        <Button text={buttonText} action={action} disabled={disabled} />
      </div>
    ) : null}
  </div>
  {isShowing && locked && <div className="info-message__content-locked"></div>}
  </>
)

InfoMessage.propTypes = {
  children: PropTypes.node.isRequired,
  isShowing: PropTypes.bool.isRequired,
  floating: PropTypes.bool,
  iconColor: PropTypes.string,
  locked: PropTypes.bool,
}

InfoMessage.defaultProps = {
  floating: true,
  iconColor: 'orange',
  locked: true,
}

export default InfoMessage
