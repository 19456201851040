import RequestService from '@core/request-service'
// import docs from '@utils/documents-to-sign'

export default class SignDocumentsService extends RequestService {
  static signDocuments(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/firmardocumentos' :
      'https://run.mocky.io/v3/38c70473-61b9-4d51-8694-6a1cfc49295a',
      payload
    )
  }

  static signDocumentsCompensated(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/firmarCompensados' :
      'https://run.mocky.io/v3/38c70473-61b9-4d51-8694-6a1cfc49295a',
      payload
    )
  }  
  
  static validateCompensated(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/validarCompensado' :
      'https://run.mocky.io/v3/38c70473-61b9-4d51-8694-6a1cfc49295a',
      payload
    )
  }

  static getDocuments(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/documentos' :
      'https://run.mocky.io/v3/d2fe19c9-d61f-4210-ab10-ff59d33ab86d',
      // 'https://run.mocky.io/v3/af13b713-5aba-4508-8766-3bf394425daf', // 404 not found
      payload
    )
    // return new Promise((resolve) => resolve({ data: docs, httpCode: 200 }))
  }
}
