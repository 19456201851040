import RequestService from '@core/request-service'

export default class QuoteSummaryService extends RequestService {

    static getQuotatiosnSummary(payload) {
        return super.post(
          (process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/ResumenCotizacion' :
          'https://run.mocky.io/v3/13413f63-45ab-4b1c-abef-7cec7ddf6679',
          payload
        )
      }

}
