import './contributor-other-data.scss'
import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Separator from '@commons/separator/separator'
import Select from 'react-select'
import Radio from '@commons/inputs/radio'

const ContributorOtherData = ({ children, editable, setValidPension, setValidData, setContributor }) => {
  const [contributorData, setContributorBackgroundData] = useState(children)
  const [showDetailPension, setShowDetailPension] = useState(false)
  const [errorWeight, setErrorWeight] = useState(null)
  const [errorHeight, setErrorHeight] = useState(null)
  const [errorPension, setErrorPension] = useState(null)
  const [errorPensionCause, setErrorPensionCause] = useState(null)
  const [errorPensionDiagnostic, setErrorPensionDiagnostic] = useState(null)
  
  const validatePensionData = useCallback(() => {
      let result = true
      if (contributorData.pensionCause === null || contributorData.pensionCause <= 0) {
        setErrorPensionCause('Debe indicar la causal de su pensión')
        result = false
      } else {
        setErrorPensionCause(null)
      }
      if (contributorData.pensionDiagnostic === null || contributorData.pensionDiagnostic === '') {
        setErrorPensionDiagnostic('Debe indicar el diagnóstico de su pensión')
        result = false
      } else {
        setErrorPensionDiagnostic(null)
      }
      setValidPension(result)
      return result

    },[setValidPension, contributorData])

  const validateData = useCallback(() => {
        let result = true
        if (result && contributorData.weight === '') {
          setErrorWeight('Debe ingresar su peso')
          result = false
        } else if (contributorData.weight <= 0) {
          setErrorWeight('Debe ser un número mayor a 0')
          result = false
        } else if (result) {
          const regex = /^\d{1,3}?$/g
          if (!regex.test(contributorData.weight)) {
            setErrorWeight('Debe ser número entero')
            result = false
          } else {
            setErrorWeight(null)
          }
        }
        if (result && contributorData.height === '') {
          setErrorHeight('Debe ingresar su estatura')
          result = false
        }else if (result && contributorData.height <= 0) {
          setErrorHeight('Debe ser un número mayor a 0')
          result = false
        }  else if (result) {
          const regex = /^\d{1}(\.\d{1,2})?$/g
          if (!regex.test(contributorData.height)) {
            setErrorHeight('Debe ser en Mts Ej: 1.65')
            result = false
          } else {
            setErrorHeight(null)
          }
        }
        if (result && contributorData.pension === null) {
          setErrorPension('Debe indicar si tiene pensión o no')
          result = false
        } else if (result) {
          setErrorPension(null)
          setErrorPensionCause(null)
          setErrorPensionDiagnostic(null)
        }
        if(contributorData.pension === true){
          validatePensionData()
        } else {
          setValidPension(true)
        }
        setContributor(contributorData)
        setValidData(result)
        return result
      // eslint-disable-next-line react-hooks/exhaustive-deps
      },[setValidData,validatePensionData,contributorData, setValidPension])
      
      useEffect(() => {
        setContributorBackgroundData(children)
      },[children])

  useEffect(() => {
    if(contributorData!==null){
      if(contributorData.pension === true) setShowDetailPension(true)

        if(contributorData.weight <= 0 || contributorData.height <= 0)
        {
          setValidData(false)
        } else {
          setValidData(true)
        }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[contributorData])
  /**
   * @description Weight Field onChange event
   * @param {event} e element event
   */
  function weightOnChangeHandler(e) {
    setContributorBackgroundData({ ...contributorData, weight: e.target.value })
    contributorData.weight = e.target.value
    validateData()
  }

  /**
   * @description Height Field onChange event
   * @param {event} e element event
   */
  function heightOnChangeHandler(e) {
    setContributorBackgroundData({ ...contributorData, height: e.target.value })
    contributorData.height = e.target.value
    validateData()
  }

  /**
   * @description Pension Field onChange event
   * @param {event} e element event
   */
  function pensionOnChangeHandler(e) {
    if (e.target.value === 'NO') {
      setContributorBackgroundData({
        ...contributorData,
        pension: false,
        pensionCause: -1,
        pensionDiagnostic: '',
      })
      contributorData.pension = false
      contributorData.pensionCause = -1
      contributorData.pensionDiagnostic = ''
    } else {
      setContributorBackgroundData({
        ...contributorData,
        pension: true,
      })
      contributorData.pension = true
    }
    setShowDetailPension(e.target.value === 'SI')
    validateData()
    if (errorWeight || errorHeight) {
      validatePensionData()
    }
  }

  /**
   * @description Pension Cause Select onChange event
   * @param {event} e element event
   */
  function pensionCauseOnChangeHandler(e) {
    setContributorBackgroundData({ ...contributorData, pensionCause: e.value })
    contributorData.pensionCause = e.value
    validatePensionData()
  }
  /**
   * @description Pension Diagnostic Field onChange event
   * @param {event} e element event
   */
  function pensionDiagnosticOnChangeHandler(e) {
    setContributorBackgroundData({
      ...contributorData,
      pensionDiagnostic: e.target.value,
    })
    contributorData.pensionDiagnostic = e.target.value
    validatePensionData()
  }




  const customStyles = {
    option: (provided) => ({
      ...provided,
      padding: 5,
    }),
    control: (provided) => ({
      ...provided,
      height: 23,
      minHeight: 0,
      border: 'none',
    }),
    singleValue: (provided) => ({
      ...provided,
      height: 23,
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      height: 23,
    }),
    menu: (provided) => ({
      ...provided,
      top: 23,
      height: 'auto',
    }),
    valueContainer: () => ({
      height: 23,
    }),
    input: (provided) => ({
      ...provided,
      height: 23,
    }),
  }

  const pensionCauseValues = [
    { label: 'Enfermedad Común', value: 1 },
    { label: 'Enfermedad Profesional o Accidente de Trabajo', value: 2 },
    // { label: 'Accidente de Trabajo', value: 2 },
  ]

  return (
    <>
      <div className="contributor-other-data-box">
        <div className="form-row">
          <div className="row form-group">
            <div className="col-12 my-auto px-1">
              <label htmlFor="weight" className="form-label">
                Peso
              </label>
              <div className="d-flex px-1">
                <input
                  type="text"
                  name="weight"
                  className={`form-control ${errorWeight && 'is-invalid'}`}
                  value={contributorData.weight}
                  maxLength="3"
                  readOnly={!editable}
                  onChange={weightOnChangeHandler}
                  disabled={!editable}
                />
                <b className="ml-3">Kgs.</b>
              </div>
            </div>
            {errorWeight && (
              <div className="invalid-feedback">{errorWeight}</div>
            )}
          </div>
          <Separator />
          <div className="row form-group">
            <div className='col-12 px-1 my-auto'>
              <label htmlFor="height" className="form-label">
                Estatura
              </label>
              <div className="d-flex px-1">
                <input
                  type="text"
                  name="height"
                  className={`form-control ${errorHeight && 'is-invalid'}`}
                  value={contributorData.height}
                  maxLength="4"
                  readOnly={!editable}
                  onChange={heightOnChangeHandler}
                  disabled={!editable}
                />
                <b className="ml-3">Mts.</b>
              </div>
            </div>
            {errorHeight && (
              <div className="invalid-feedback">{errorHeight}</div>
            )}
          </div>
          <Separator />
          <div className="row form-group__pension form-group--xlarge">
            <span className="form-group__label">Pensión de invalidez:</span>
            <div className="form-group__radio-options">
              <Radio
                text="NO"
                name="pension"
                value="NO"
                isChecked={!contributorData.pension}
                onChangeEvent={pensionOnChangeHandler}
              />
              <Radio
                text="SI"
                name="pension"
                value="SI"
                isChecked={contributorData.pension}
                onChangeEvent={pensionOnChangeHandler}
              />
            </div>
            {errorPension && (
              <div className="invalid-feedback">{errorPension}</div>
            )}
          </div>
        </div>
        {showDetailPension ? <Separator isVertical /> : null}
        <div
          className={`form-row${showDetailPension ? '' : ' form-row--hide'}`}
        >
          <div className="form-group--all">
            <Select
              name="pensionCause"
              isSearchable={false}
              styles={customStyles}
              height="10"
              placeholder="Seleccione la causal de su pensión de invalidez"
              defaultValue={
                contributorData.pensionCause < 0
                  ? null
                  : pensionCauseValues.find((op) => {
                      return op.value === contributorData.pensionCause
                    })
              }
              className={`ns-select contributor-other-data-box__select${
                editable ? '' : '--disabled'
              } ${errorPensionCause && 'form-control is-invalid'}`}
              isDisabled={!editable}
              classNamePrefix="ns-select"
              onChange={pensionCauseOnChangeHandler}
              options={pensionCauseValues}
              value={
                contributorData.pensionCause < 0
                  ? null
                  : pensionCauseValues.find((op) => {
                      return op.value === contributorData.pensionCause
                    })
              }
            />
            {errorPensionCause && (
              <div className="invalid-feedback">{errorPensionCause}</div>
            )}
          </div>
        </div>
        {showDetailPension ? <Separator isVertical /> : null}
        <div
          className={`form-row${showDetailPension ? '' : ' form-row--hide'}`}
        >
          <div className="form-group--all">
            <div className="form-group__diagnostic">
              <span className="form-group__label">Diagnóstico:</span>
              <textarea
                placeholder="Descripción del diagnóstico"
                name="pensionDiagnostic"
                className={`form-control ${
                  errorPensionDiagnostic && 'is-invalid'
                }`}
                value={contributorData.pensionDiagnostic ? contributorData.pensionDiagnostic : ''}
                maxLength="100"
                readOnly={!editable}
                onChange={pensionDiagnosticOnChangeHandler}
                disabled={!editable}
              />
              {errorPensionDiagnostic && (
                <div className="invalid-feedback">{errorPensionDiagnostic}</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

ContributorOtherData.propTypes = {
  children: PropTypes.shape({
    rut: PropTypes.string.isRequired,
    names: PropTypes.string.isRequired,
    paternalSurname: PropTypes.string.isRequired,
    maternalSurname: PropTypes.string.isRequired,
    email: PropTypes.string.isRequired,
    birthdate: PropTypes.string.isRequired,
    isapre: PropTypes.string.isRequired,
    pension: PropTypes.bool,
  }).isRequired,
  editable: PropTypes.bool,
}
ContributorOtherData.defaultProps = {
  editable: true,
}

export default ContributorOtherData
