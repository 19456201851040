import React, { useEffect, useContext } from 'react'
import './result-evaluation.scss'
import Button from '@commons/buttons/button'
import { HealthStatementSentContext, HealthStatementSentService } from '@components/health-statement-sent'
import { StepperStepsContext } from '@commons/steppers/steps'

const ResultEvaluation = () => {
  const { stepperStepsData } = useContext(StepperStepsContext)
  const {
    healthStatementSentData,
    HealthStatementSentDispatch,
    HealthStatementSentActions,
  } = useContext(HealthStatementSentContext)
  const {
    dsData: { correo, plazo },
  } = healthStatementSentData


  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))
  let textTituloPa = 'Declaración de Salud'

  if((esCargaTitular) && (!tieneCargas)){
    textTituloPa = 'Declaración de Renta'
  }

  /**
   * @description Handle close sesion
   */
  function handleCloseSesion() {
    window.location.assign("https://www.nuevamasvida.cl/")
  }

  useEffect(() => {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
    }
    HealthStatementSentService.getDSData(body)
      .then((response) => {
        if (response.httpCode === 200) {
          HealthStatementSentDispatch(
            HealthStatementSentActions.setDSData(response.data)
          )
        }
      })
      .catch(() => {})
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className="result-evaluation">
      <div className="result-evaluation__message">
        Tu{' '}
        <span className="result-evaluation__message--bold">
          {textTituloPa}
        </span>{' '}
        aún se encuentra en estado de{' '}
        <span className="result-evaluation__message--status">Evaluación</span>,
        cuando esté listo el resultado será enviada a{' '}
        <span className="result-evaluation__message--bold">
         {correo}
        </span>
      </div>
      <div className="result-evaluation__message">
        Si ya se cumplió el plazo de{' '}
        <span className="result-evaluation__message--bold">{plazo} días hábiles,</span>{' '}
        contados desde el envío de tus antecedentes, comunícate con 
        nuestra mesa de ayuda telefónica al{' '}
        <span className="result-evaluation__message--bold">600 6000 262</span>
      </div>
      <div className="result-evaluation__buttons">
        <Button text="Cerrar Sesión" action={handleCloseSesion} />
      </div>
    </div>
  )
}

export default ResultEvaluation
