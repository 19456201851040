import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import RentStatementReducer from './rent-statement-reducer'
import * as RentStatementActions from './rent-statement-actions'

export const RentStatementContext = React.createContext()

export const RentStatementContextProvider = ({ children, wizard }) => {
  const [rentStatementData, RentStatementDispatch] = useReducer(
    RentStatementReducer,
    {
      loading: false,
      lastRemuneration: '',
      averagedRemuneration: '',
      quotes: [],
      employers: [],
      enabledEditMode: false,
    }
  )

  return (
    <RentStatementContext.Provider
      value={{
        wizard,
        rentStatementData,
        RentStatementDispatch,
        RentStatementActions,
      }}
    >
      {children}
    </RentStatementContext.Provider>
  )
}

RentStatementContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
