/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react'
import FormChangeEmail from '@components/health-statement-sent/elements/form-change-email'
import EmailChanged from '@components/health-statement-sent/elements/email-changed'
import { HealthStatementSentContext } from '@components/health-statement-sent'
import { WizardContext } from '@commons/steppers/wizard'
import ResultHealthStatement from '@components/health-statement-sent/elements/result-health-statement'

const HealthStatementSent = () => {
  const {
    healthStatementSentData: { changedEmail, title },
  } = useContext(HealthStatementSentContext)

  const { wizardData: {status} , WizardDispatch, WizardActions } = useContext(WizardContext)

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  return (
    <div className="sign-health-statement">
      <div className="sign-health-statement__title">{title}</div>
      {status === 'sent' ? (
        changedEmail ? (
          <EmailChanged />
        ) : (
          <FormChangeEmail />
        )
      ) : (
        <ResultHealthStatement />
      )}
    </div>
  )
}

export default HealthStatementSent
