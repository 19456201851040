import {
  SET_COMPLETED_STEP,
  LOADING_DATA,
  SET_CONFIRMED_QUESTIONS,
  SET_SHOW_CONFIRMATION,
} from './summary-health-statement-actions'

export default function SummaryHealthStatementReducer(state, action) {
  switch (action.type) {
    case SET_COMPLETED_STEP:
      return {
        ...state,
        completedStep: action.flag,
      }
    case LOADING_DATA:
      return {
        ...state,
        loading: action.flag,
      }

    case SET_CONFIRMED_QUESTIONS:
      return {
        ...state,
        confirm: action.flag,
      }
    case SET_SHOW_CONFIRMATION:
      return {
        ...state,
        showConfirmation: action.flag,
      }
    default:
      return state
  }
}
