import React, { useContext, useEffect, useState} from 'react'
import { WizardContext } from '@commons/steppers/wizard'
import { QuoteSummaryService } from '@components/quote-summary'
import { StepperStepsContext, StepperStepsService } from '@commons/steppers/steps/'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import Button from '@commons/buttons/button'
import ErrorMessage from '@commons/messages/error/error-message'
import { useHistory } from 'react-router-dom'
import AddScriptRut from '@utils/tools/convertRut'
import moment from 'moment'
import ConvertComma from '@utils/tools/convertComma'

const QuoteSummary = () => {
    const history = useHistory()
    const { WizardDispatch, WizardActions } = useContext(WizardContext)
    const { stepperStepsData } = useContext(StepperStepsContext)
    const [loading, setLoading] = useState(true)
    const [showErrorMessage, setShowErrorMessage] = useState(false)
    const [data, setData] = useState({})
    const [message, setMessage] = useState('')

    useEffect(() =>{
        const body = {
            identificador: stepperStepsData.userInformation.identificador,
            rut: stepperStepsData.userInformation.rut
        }
        QuoteSummaryService.getQuotatiosnSummary(body).then(response =>{
            if(response.httpCode === 200){
                setData(response.data[0])
                setLoading(false)
            } else {
                setLoading(false)
                setMessage(' No se han podido traer los datos de la cotización, por favor intente nuevamente.')
                setShowErrorMessage(true)
            }
        })
        .catch(() => {
            setLoading(false)
            setMessage(' No se han podido traer los datos de la cotización, por favor intente nuevamente.')
            setShowErrorMessage(true)
        })
    },[stepperStepsData])

    /**
     * Side Effects
     */
    useEffect(() => {
        WizardDispatch(WizardActions.refreshProgress())
    }, [WizardActions, WizardDispatch])

    function handleNextStep(){
        const bodySendStep = {
            identificador: stepperStepsData.userInformation.identificador,
            rut: AddScriptRut(stepperStepsData.userInformation.rut),
            etapa: 'resumenCotizacion',
            subetapa: '',
            fechaRegistro: moment().format('DD-MM-YYYY')
          }
          StepperStepsService.sendStep(bodySendStep).then((response) => {
            if (response.httpCode === 200) {
              WizardDispatch(WizardActions.setCompleteSubStep({ name: 'resumenCotizacion' }))
              history.push('/firmar-documentos')
            } else {
              setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
              setShowErrorMessage(true)
            }
          }).catch(() => {
            setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde')
            setShowErrorMessage(true)
          })
    }

      /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

    return (
        <div className="quote-sum">
            <h2 className="quote-sum__title">Resumen Cotización Pactada</h2>
        {loading ?  
            <SpinnerLoader show={loading} />
        :
        <>
            <div className="quote-sum__plan">
                <div className="quote-sum__general-info">
                    <p className="plan">Plan</p>
                    <p className="plan-name">{data.codigoPlan}</p>
                    <p className="plan-cost">${new Intl.NumberFormat("es-CL").format(parseInt(data.totalPesos))}</p>
                    <p className="plan-period">mensuales</p>
                </div>
                <div className="quote-sum__line"></div>

            </div>
            <div className="quote-sum__breakdown">
                <p className="price-subtitle">Desglose de la Cotización Pactada</p>
                <div className="price-row">
                    <div className="price-item text-right">
                        <p className="price-item__title">Precio Base Plan</p>
                        <p className="price-item__value">{ConvertComma(data.preciobasePlanUF)} UF</p>
                    </div>
                    <div className="price-multi">
                        <p>*</p>
                    </div>
                    <div className="price-item text-left">
                        <p className="price-item__title">Precio Grupo Familiar</p>
                        <p className="price-item__value">{ConvertComma(data.factorGrupoFamiliarUF)} UF</p>
                    </div>
                </div>
                <div className="price-row">
                    <div className="price-item">
                        <p className="price-item__title">Precio Plan</p>
                        <p className="price-item__value">{ConvertComma(data.precioPlanUF)} UF</p>
                    </div>
                </div>

            </div>
            <div className="quote-sum__prices">
                <div className="quote-sum__prices-left">
                    <p className="title">Precio GES</p>
                    <p className="value">+ {ConvertComma(data.precioGesUF)} UF</p>
                    <p className="title">CAEC</p>
                    <p className="value">+ {(ConvertComma(data.caecuf))} UF</p>
{/*                     <p className="title">Precio BES</p>
                    <p className="value">+ {ConvertComma(data.besuf)} UF</p> */}
                </div>
                <div className="quote-sum__prices-right">
                    <div className="content">
                        <p className="total">Total<br/>Cotización<br/>Pactada</p>
                        <p className="total-value-uf">{ConvertComma(data.totalUF)} UF</p>
                        <p className="total-value-clp">${new Intl.NumberFormat("es-CL").format(parseInt(data.totalPesos))}</p>
                    </div>
                </div>
            </div>

            <div className="quote-sum__button">
                <Button 
                text="Continuar" 
                action={handleNextStep}
                cssClass="button__continue"
                disabled={loading} 
                />
            </div>
        </>
        }
            <ErrorMessage isShowing={showErrorMessage} action={() => closeMsg()}>
                {message}
            </ErrorMessage>
        </div>
    );
};

export default QuoteSummary;