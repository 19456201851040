import React, { useState, useContext, useEffect } from 'react'
import './pin-validation.scss'
import InputPassword from '@commons/inputs/input-password'
import Button from '@commons/buttons/button'
import PropTypes from 'prop-types'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import InfoMessage from '@commons/messages/info/info-message'
import ErrorMessage from '@commons/messages/error/error-message'
import { StepperStepsContext } from '@commons/steppers/steps'
import Tooltip from '@commons/tooltip/tooltip'
import PinValidationService from './pin-validation-service'

const PinValidation = ({ action, buttonText, isSigned, loading, setLoading }) => {
  const [showTooltip, setShowTooltip] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showMessageResendPin, setShowMessageResendPin] = useState(false)
  const [showMessageProblems, setShowMessageProblems] = useState(false)
  const [chance, setChance] = useState(0)
  const [password, setPassword] = useState('')
  const { stepperStepsData } = useContext(StepperStepsContext)

  /**
   * Form Schema Validator
   */
  const schema = yup.object().shape({
    pin: yup.string().required('Debe ingresar su clave'),
  })
  const { register, handleSubmit, errors,getValues } = useForm({
    validationSchema: schema,
  })

  /**
   * @description Handle onClick sign validation and send health statement
   */
  function handleSignValidation() {
    setLoading(true)
    action(password)
  }

  /**
   * @description Handle onClick close message
   */
  const handleCloseMessage = () => {
    setShowMessage(false)
  }

  /**
   * @description Handle onClick close resend pin
   */
  const handleCloseMessageResendPin = () => {
    setShowMessageResendPin(false)
  }

  /**
   * @description Handle onClick redirect to login
   */
  const handleRedirectLogin = () => {
    window.location.assign("https://www.nuevamasvida.cl/")
  }

  /**
   * @description Handle onClick resend PIN
   */
  function handleResendPin() {
    if (chance !== 3) {
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
      }
      PinValidationService.resendPIN(body)
        .then((response) => {
          if (response.httpCode === 200) {
            setShowMessageResendPin(true)
            setChance(chance + 1)
          } else {
            setShowErrorMessage(true)
          }
        })
        .catch(() => {
          setShowErrorMessage(true)
        })
    } else {
      setShowMessageProblems(true)
    }
  }

  /**
   * @description Handle close error message
   */
  function handleCloseErrorMessage() {
    setShowErrorMessage(false)
  }

  useEffect(() => {
    if (!isSigned) {
      setLoading(false)
      setShowMessage(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSigned])

  return (
    <div className="pin-validation">
      <div className="pin-validation__loading">
        <SpinnerLoader show={loading} text="Firmando documentos" />
      </div>
      {!loading && (
        <>
          <div className="pin-validation__input">
            <InputPassword
              name="pin"
              register={register}
              value={getValues().pin}
              setPassword={setPassword}
              placeholder="Ingresa tu PIN"
              error={errors.pin}
            />
          </div>
          <div className="pin-validation__actions">
            <Button
              text={buttonText}
              action={handleSubmit(handleSignValidation)}
            />
          </div>
        </>
      )}
      <div className="pin-validation__recovery">
        <span
          role="button"
          tabIndex={0}
          className="pin-validation__resend"
          onKeyPress={handleResendPin}
          onClick={handleResendPin}
        >
          Reenviar clave segura
        </span>
        <div className="pin-validation__recovery-tooltip">
          <Tooltip visible={showTooltip} align="left">
            Puedes solicitar el reenvío de clave segura solamente en 3
            oportunidades.
          </Tooltip>
          <em
            className="icon-alerta"
            onMouseOver={() => setShowTooltip(true)}
            onMouseOut={() => setShowTooltip(false)}
            onBlur={() => setShowTooltip(false)}
            onFocus={() => setShowTooltip(true)}
          />
        </div>
      </div>
      <InfoMessage
        buttonText="Vuelve a intentarlo"
        isShowing={showMessage}
        action={handleCloseMessage}
      >
        <div className="pin-validation__alert">
          El PIN ingresado no es correcto
        </div>
      </InfoMessage>
      <InfoMessage
        buttonText="Cerrar"
        isShowing={showMessageResendPin}
        action={handleCloseMessageResendPin}
      >
        <div className="pin-validation__alert">
          Hemos reenviado la clave segura a tu correo electrónico registrado
        </div>
      </InfoMessage>
      <InfoMessage
        buttonText="Cerrar Sesión"
        isShowing={showMessageProblems}
        action={handleRedirectLogin}
      >
        <div className="pin-validation__alert">
          <div>
            Estimado/a{' '}
            <span className="pin-validation__alert--bold">
              {stepperStepsData.userInformation.nombres}
            </span>
            ,
          </div>
          <div>
            Hemos detectado que tienes problemas para firmar tu declaración. Por
            favor ponte en contacto con el asesor para continuar con el proceso.
          </div>
        </div>
      </InfoMessage>
      <ErrorMessage
        isShowing={showErrorMessage}
        action={handleCloseErrorMessage}
      >
        <div className="pin-validation__alert">
          No se ha podido enviar el pin, vuelva a intentarlo más tarde.
        </div>
      </ErrorMessage>
    </div>
  )
}

PinValidation.propTypes = {
  action: PropTypes.func.isRequired,
  buttonText: PropTypes.string,
  isSigned: PropTypes.bool.isRequired,
}
PinValidation.defaultProps = {
  buttonText: '',
}

export default PinValidation
