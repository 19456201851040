import React, { useContext } from 'react'
import './email-changed.scss'
import Button from '@commons/buttons/button'
import { HealthStatementSentContext } from '@components/health-statement-sent'

const EmailChanged = () => {
  const {
    healthStatementSentData: { email, dsData: { plazo } },
  } = useContext(HealthStatementSentContext)

  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))
  let textTituloPa = 'Declaración de Salud'

  if((esCargaTitular) && (!tieneCargas)){
    textTituloPa = 'Declaración de Renta'
  }

  /**
   * @description Handle close sesion
   */
  function handleCloseSesion() {
    window.location.assign("https://www.nuevamasvida.cl/")
  }

  return (
    <div className="email-changed">
      <div className="email-changed__subtitle">
        El resultado de la evaluación de tu{' '}
        <span className="email-changed__subtitle--bold">
          {textTituloPa}
        </span>{' '}
        será enviada a{' '}
        <span className="email-changed__subtitle--bold">{email}</span>
      </div>
      <div className="email-changed__message">
        Si dentro del plazo de{' '}
        <span className="email-changed__message--bold">{plazo} días hábiles</span> contados 
        desde el envío de tus antencedentes, no recibes
        el resultado, puedes revisar el estado accediendo a{' '}
        <span className="email-changed__message--bold">
          www.nuevamasvida.cl
        </span>{' '}
        o llamando a nuestra mesa de ayuda telefónica al{' '}
        <span className="email-changed__message--bold">600 6000 262</span>
      </div>
      <div className="email-changed__buttons">
        <Button text="Cerrar Sesión" action={handleCloseSesion} />
      </div>
    </div>
  )
}

export default EmailChanged
