import React from 'react';
import Button from "@commons/buttons/button";
import { useEffect, useContext } from 'react';
import { WizardContext } from '@commons/steppers/wizard'

const ProcessFinalizationRevision = () => {
    const { WizardDispatch, WizardActions } = useContext(WizardContext)

    useEffect(() => {
        WizardDispatch(WizardActions.setCompleteSubStep({ name: 'firmaIsapreRevision' }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function handleSingOut(){
        window.location.assign("https://www.nuevamasvida.cl/")
    }

    useEffect(() => {
        WizardDispatch(WizardActions.refreshProgress())
      }, [WizardActions, WizardDispatch])

    return (
        <div className="process-fin-isapre">
            <h2 className="process-fin-isapre__title">Desafiliación Isapre Anterior</h2>
            <div className="process-fin-isapre__text">
                <p>
                    Tu Carta de Desafiliación se encuentra en <span className="process-fin-isapre__status">Revisión</span>.<br/> 
                    Una vez validada te informaremos por correo<br/>
                    electrónico el resultado del proceso.
                </p>
            </div>
            <div className="process-fin-isapre__buttons">
              <Button
                  text="Cerrar Sesión"
                  action={handleSingOut}
              />
          </div>
        </div>
    );
};


export default ProcessFinalizationRevision
