import './styles/main.scss'
import React from 'react'
import Layout from '@commons/layout/layout'
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom'
import { WizardContextProvider } from '@commons/steppers/wizard'
import { StepperStepsContextProvider } from '@commons/steppers/steps'

import UserInformationWizard from '@wizards/user-information/user-information-wizard'
import MainStepper from '@commons/main-stepper/main-stepper'
import Login from '@pages/login'
import RecoverPassword from '@pages/recover-password'
// import Retorno from '@components/sign-retorno-clave-unica/retorno'
import HealthStatementWizard from '@wizards/health-statement/health-statement-wizard'
import SignDocumentsWizard from '@wizards/sign-documents/sign-documents-wizard'
import ProcessFinalizationWizard from '@wizards/process-finalization/process-finalization-wizard'
import buildInfo from './buildInfo';

const buildDate = new Date(buildInfo.buildDate).toLocaleDateString('es-ES', { weekday:"long", year:"numeric", month:"long", day:"numeric", hour:"numeric", minute:"numeric", second:"numeric"})
console.log(`\u001b[1;31m Build version: \u001b[1;36m ${buildInfo.buildVersion}`);
console.log(`\u001b[1;31m Build date: \u001b[1;36m ${buildDate.toString()}`);

function App() {
  return (
    <StepperStepsContextProvider>
      <Router>
        <Layout>
          <Switch>
            <Route
              exact
              path="/pse/retomar-proceso"
              component={RecoverPassword}
            />
            <Route
              path="/informacion-usuario"
              render={({ history, match: { url } }) => (
                <>
                  <MainStepper />
                  <WizardContextProvider>
                    <UserInformationWizard history={history} route={url} />
                  </WizardContextProvider>
                </>
              )}
            />
            <Route
              path="/declaracion-salud"
              render={({ history, match: { url } }) => (
                <>
                  <MainStepper activeStep={2} />
                  <WizardContextProvider>
                    <HealthStatementWizard history={history} route={url} />
                  </WizardContextProvider>
                </>
              )}
            />
            <Route
              path="/firmar-documentos"
              render={({ history, match: { url } }) => (
                <>
                  <MainStepper activeStep={3} />
                  <WizardContextProvider>
                    <SignDocumentsWizard history={history} route={url} />
                  </WizardContextProvider>
                </>
              )}
            />
            <Route
              path="/finalizar-proceso"
              render={({ history, match: { url } }) => (
                <>
                  <MainStepper activeStep={4} />
                  <WizardContextProvider>
                    <ProcessFinalizationWizard history={history} route={url} />
                  </WizardContextProvider>
                </>
              )}
            />
            
             <WizardContextProvider>
             <Route path="/" component={Login} />
             </WizardContextProvider>

          </Switch>
        </Layout>
{/* 
        <Route path="/Retorno/:id" component={(Retorno)} /> */}

      </Router>
    </StepperStepsContextProvider>
  )
}

export default App
