import RequestService from '@core/request-service'

// process.env.NODE_ENV==='production'
export default class HealthStatementSentService extends RequestService {
  // api/cambiacorreo
  static changeEmail(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/cambiacorreo' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46', 
    payload
    )
  }

  // api/v1/datosdeclaracionsalud
  static getDSData(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/datosdeclaracionsalud' :
      //'https://www.mocky.io/v2/5eda7795330000181a79ec46',
       'https://run.mocky.io/v3/83bd89b3-e2f9-466f-b4c0-2a71b36e729b',
      payload
    )
  }

  // api no se encuntra
  static resultDS(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/resultadoevaluacionds' :
      // 'https://run.mocky.io/v3/93540d60-5e47-496a-86d1-08d4ff6bc22d',  // autorizada
      // 'https://run.mocky.io/v3/df99e494-1374-49e0-bebc-95cddd504905', // rechazada
      //  'https://run.mocky.io/v3/95c4a068-426e-47f0-82a9-7092221b522c', // evaluación
      // 'https://run.mocky.io/v3/2df1fe66-f0bd-4465-a26d-e33f8f8257df', // restricted
      // 'https://run.mocky.io/v3/37e98702-e85d-4dda-987e-35a9cf73b3f7', // revision
      // 'https://run.mocky.io/v3/354acbd0-dffd-4b8c-851e-1755369b9a7e', // cancelado
      // 'https://run.mocky.io/v3/22a59f94-5aec-4991-99bf-3d063eafeee9', // evaluación financiera
      // 'https://run.mocky.io/v3/b3360ab0-0c3a-4d74-80e7-e8a11839c0d5', // revision financiera
      //'https://run.mocky.io/v3/a46b7809-d065-41ad-81cf-72c998d3c095', // renta insuficiente
        'https://run.mocky.io/v3/909a6bff-4647-48cd-adb3-075514ac7b23', //D. de Salud En Evaluación
       payload
    )
  }
// api/v1/Subirdocumento
  static sendDocuments(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/subirdocumento' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }
 // no esta 
  static finishDS(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/finalizaevaluacionds' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  // api/v1/CargaArchivoCapacidadPago
  static sendFinantialDocuments(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/CargaArchivoCapacidadPago' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }
  static headerSendDocs(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/cargaArchivoTAD' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }
}
