import React, { useState, useEffect, useContext, useCallback} from 'react'
import DisaffiliationDocuments from '@components/process-finalization-isapre/elements/disaffiliation-documents'
import DisaffiliationSysasap from '@components/process-finalization-isapre/elements/disaffiliation-sysasap'
import SpinnerLoader from '@components/commons/loaders/spinner-loader'
import ErrorMessage from '@commons/messages/error/error-message'
import { ProcessFinalizationIsapreService } from "@components/process-finalization-isapre";
import { StepperStepsContext } from '@commons/steppers/steps'
import { WizardContext } from '@commons/steppers/wizard'
import substeps from '../utils/substeps'

const ProcessFinalization = () => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const [loading, setLoading] = useState(true)
  const [sysasapOk, setSysasapOk] = useState(null)
  const [url, setUrl] = useState('')
  const [retryCount, setRetryCount] = useState(1)
  const [showError, setShowError] = useState({show: false, msg: ''})
  const { stepperStepsData } = useContext(StepperStepsContext)

  useEffect(() => {
    WizardDispatch(WizardActions.setCompleteSubStep({ name: 'firmaDocumentos' }))
}, [])

useEffect(() => {
  WizardDispatch(WizardActions.refreshProgress())
}, [WizardActions, WizardDispatch])

  const getUrlIsapre = useCallback(() => {
    setLoading(true)
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      codigoPrevision: null,
      tipoCarga: null,
      codigoIsapre: null
    }
    ProcessFinalizationIsapreService.loadUrlIsapre(body).then((response) => {
      if(response.httpCode === 200) {
        if(response.data.codigoError){
          if(response.data.codigoError === "400"){
            // TODO: define what should happen here
            setShowError({show: true, msg: response.data.descripcion})
            setLoading(false)
          }else if(response.data.codigoError === "500" || response.data.codigoError === "404"){
            setSysasapOk(false)
            setLoading(false)
          }else{
            setShowError({show: true, msg: response.data.descripcion})
            setLoading(false)
          }
        }else{
          if (response.data.url !== null && response.data.url !== undefined && response.data.url !== '') {
            setUrl(response.data.url)
            setSysasapOk(true)
            setLoading(false)
          } else {
            setSysasapOk(false)
            setLoading(false)
          }
        }
      }else{
        setSysasapOk(false)
        setLoading(false)
      }
    }).catch(() => {
      setSysasapOk(false)
      setLoading(false)
    })
  }, [])

  function handleSysasapfail(){
    setSysasapOk(false)
  }

  useEffect(() => {
    const { subStep } = wizardData
    if (subStep !== null) {
      if (subStep === substeps.cartaDesafiliacion) {
        setLoading(false)
        setSysasapOk(false)
      } else {
        getUrlIsapre()
      }
    }
    
  }, [wizardData.subStep])

  return(
    <>
    <div className="process-fin-isapre">
      <h2 className="process-fin-isapre__title">Desafiliación Isapre Anterior</h2>
    </div>
      {!loading ? 
        sysasapOk ?
        <DisaffiliationSysasap url={url} fail={handleSysasapfail} retryCountSysasap={retryCount} setRetryCountSysasap={setRetryCount}/>
        :
        <DisaffiliationDocuments /> 
      :
        <SpinnerLoader/>
      }
      <ErrorMessage isShowing={showError.show} action={() => setShowError({show: false, msg: ''})}>
        {showError.msg}
      </ErrorMessage>
    </>
  )

}
export default ProcessFinalization
