import './employer.scss'
import React, { useState, useEffect, useContext } from 'react'
import { RentStatementContext, RentStatementService } from '@components/rent-statement'
import PropTypes from 'prop-types'
import ConfirmMessage from '@commons/messages/confirm/confirm'
import { ErrorMessage } from 'react-hook-form'
import { format } from 'rut.js'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import { StepperStepsService, StepperStepsContext } from '@commons/steppers/steps'

const EmployerNoEdit = ({
  children,
  index,
  editable,
  onRemove,
  onChangeFiles,
  onRemoveFile,
  files,
  reset,
  register,
  errors
}) => {
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [employerData, setEmployerData] = useState(children)
  const [showMessage, setShowMessage] = useState(false)
  const [showTooltip, setShowTooltip] = useState(false)
  const [dataFirstTime, setDataFirstTime] = useState(false)

  const [rsEditable, setRsEditable] = useState(false)
  const [rEditable, setREditable] = useState(false)

  const [showModal, setShowModal] = useState(true)
  const [checkValidate, setCheckValidate] = useState(false)

  const [dataProspecto, setDataProspecto] = useState();
  const {
    rentStatementData,
    RentStatementDispatch,
    RentStatementActions,
  } = useContext(RentStatementContext)

  /**
   * @description HandlerRemove action
   */
  function removeActionEventHandler() {
    setShowMessage(true)
  }

  /**
   * @description Set false in delete message
   */
  function cancelAction() {
    setShowMessage(false)
  }

  /**
   * @description Remove self from ArrayData
   */
  function confirmAction() {
    onRemove(employerData.id)
  }

  /**
   * @description RUT Field onChange event
   * @param {event} e element event
   */
  function rutOnChangeHanlder(e) {
    setEmployerData({ ...employerData, rut: format(e.target.value) })
  }

  /**
   * @description businessName Field onChange event
   * @param {event} e element event
   */
  function businessOnChangeHanlder(e) {
    setEmployerData({ ...employerData, razonSocial: e.target.value })
  }

  /**
   * @description lastRent Field onChange event
   * @param {event} e element event
   */
  function rentOnChangeHanlder(e) {
    setEmployerData({ ...employerData, ultimaRenta: e.target.value })
  }

  /**
   * @description Return the String Classname for Input, this helps to reduce SonarQ congnitive complexity
   * @param {string} fieldName Input name
   */
  function createFieldClassName(fieldName) {
    return `form-control ${errors[fieldName]?.message ? 'is-invalid' : ''
      }`.trim()
  }

  /**
   * @description MouseLeave Event for tooltipComponent
   * @param {boolean} flag
   */
  function handlerTooltipOnHover(flag) {
    setShowTooltip(flag)
  }

  const getProspectData = async ({ prospectoId }) => {
    const body = {
      prospectoId,
      identificador: stepperStepsData.userInformation.identificador
    }
    await RentStatementService.getProspectData(body).then((response) => {
      if (response.httpCode != 200) {
        employerData.entity_type = "1";
        setShowModal(false)
      } else {
        if(!dataFirstTime){        
        setDataFirstTime(true)
        const infoProspecto = response.data;
        setDataProspecto(infoProspecto)

        const { empleadores, nombres, apellidoPaterno, apellidoMaterno } = infoProspecto;
        if(Array.isArray(empleadores) && empleadores.length){
          empleadores.forEach((item) => {
          // if (employerData.editableRazonSocial && employerData.rut === item.rut.trim()) {
          if (employerData.tipoTrabajador == "1") {//Dependiente
            setEmployerData({
              ...employerData,
              entity_type: "1",
              ultimaRenta: employerData.ultimaRenta,
              isNew: true,
              editableRut: true,
              editableRazonSocial: true,
              editableRenta: true
            })
          }
          if (employerData.tipoTrabajador == "2") {//Independiente
            setEmployerData({
              ...employerData,
              entity_type: "2",
              ultimaRenta: employerData.ultimaRenta,
              razonSocial: nombres + " " + apellidoPaterno + " " + apellidoMaterno,
              editableRazonSocial: false,
              editableRenta: true,
              editableRut: false,
              isNew: true
            })
          }
          if (employerData.tipoTrabajador == "3") {//Pensionado
            setEmployerData({
              ...employerData,
              entity_type: "3",
              ultimaRenta: employerData.ultimaRenta,
              isNew: true,
              editableRut: true,
              editableRazonSocial: true,
              editableRenta: true
            })
          }
          if (employerData.tipoTrabajador == "4") {//Voluntario
            setEmployerData({
              ...employerData,
              entity_type: "4",
              ultimaRenta: 0,
              razonSocial: nombres + " " + apellidoPaterno + " " + apellidoMaterno,
              editableRazonSocial: false,
              editableRenta: false,
              editableRut: false,
              isNew: true
            })
          }
          // } 
        })
      }

        setShowModal(false)
      } else {
        setShowModal(false)
      }
    }

    }).catch((err) => {
      console.log(err)
    })
  }

  const getDataDsWeb = async () => {

    const body = {}
    await RentStatementService.getDsV2(body,stepperStepsData.userInformation.identificador).then((response) => {
      if (response.httpCode !== 200) {
        return false;
      }

      getProspectData(response.data.data);

      return true;
    }).catch((err) => {
      console.log(err)
    })
  }

  /**
   * Side Effects
   */
  useEffect(() => {
    if (reset) setEmployerData(children)

    getDataDsWeb();
  }, [children, reset])

  return (
    <>
      <div className={`employer-box`.trim()}>
        <div className="employer-box__index">{index}</div>
        <div className="row">
          <div className="col-6" style={{ borderRight: "1px solid #B7B7B7" }}>
            <div className="employer-box__fields">

              <div className="form-group">
                <label htmlFor={`${employerData.id}_rut`} className="form-label">
                  Rut Empleador
                </label>
                <input
                  type="text"
                  name={`${employerData.id}_rut`}
                  className={createFieldClassName(`${employerData.id}_rut`)}
                  value={format(`${employerData.rut}`)}
                  readOnly={true}
                  onChange={rutOnChangeHanlder}
                  disabled={true}
                  ref={register}
                />
                <ErrorMessage name={`${employerData.id}_rut`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>

          <div className="col-6">
            <div className="employer-box__fields">
              <div className="form-group">
                <label htmlFor={`${employerData.id}_name`} className="form-label">
                  Razón Social o Nombre
                </label>
                {false ?
                  <SpinnerLoader show={showModal} />
                  :
                  <input
                    type="text"
                    name={`${employerData.id}_name`}
                    className={createFieldClassName(`${employerData.id}_name`)}
                    value={employerData.razonSocial}
                    readOnly={true}
                    onChange={businessOnChangeHanlder}
                    disabled={true}
                    ref={register}
                  />
                }
                <ErrorMessage name={`${employerData.id}_name`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6" style={{ borderRight: "1px solid #B7B7B7" }}>
            <div className="employer-box__fields">
              <div className="form-group">
                <label
                  htmlFor={`${employerData.id}_last-rent`}
                  className="form-label"
                >
                  Renta Promedio
                </label>
                <input
                  type="text"
                  name={`${employerData.id}_last-rent`}
                  className={createFieldClassName(`${employerData.id}_last-rent`)}
                  value={employerData.ultimaRenta}
                  readOnly={true}
                  onChange={rentOnChangeHanlder}
                  disabled={true}
                  ref={register}
                />
                <ErrorMessage name={`${employerData.id}_last-rent`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="employer-box__fields">
              <div className="form-group">
                <label
                  htmlFor={`${employerData.id}_tipo_empleado`}
                  className="form-label"
                >
                  Tipo de empleado
                </label>

                {false ?
                  <SpinnerLoader show={false} />
                  :
                    <input
                      type="text"
                      name={`${employerData.entity_type}`}
                      className={createFieldClassName(`${employerData.entity_type}`)}
                      value={
                        (employerData.entity_type === "1") ? 'Dependiente' :
                          (employerData.entity_type === "2") ? 'Independiente' :
                            (employerData.entity_type === "3") ? 'Pensionado' :
                              (employerData.entity_type === "4") ? 'Voluntario' :
                                ''
                      }
                      readOnly={true}
                      onChange={rentOnChangeHanlder}
                      disabled={true}
                      ref={register}
                    />
                }

                <ErrorMessage name={`${employerData.id}_last-rent`} errors={errors}>
                  {({ message }) => <div className="invalid-feedback">{message}</div>}
                </ErrorMessage>
              </div>

            </div>
          </div>
        </div>
        <ConfirmMessage
          confirm="Eliminar"
          cancel="Cancelar"
          isShowing={showMessage}
          confirmAction={confirmAction}
          cancelAction={cancelAction}
        >
          <div>¿Estás seguro que deseas eliminar este empleador?</div>
        </ConfirmMessage>

      </div>
    </>
  )
}
EmployerNoEdit.propTypes = {
  index: PropTypes.number.isRequired,
  children: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    rut: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    razonSocial: PropTypes.string.isRequired,
    ultimaRenta: PropTypes.number.isRequired,
  }).isRequired,
  editable: PropTypes.bool,
  onRemove: PropTypes.func.isRequired,
  onRemoveFile: PropTypes.func,
  onChangeFiles: PropTypes.func.isRequired,
  files: PropTypes.arrayOf(PropTypes.shape({})),
  reset: PropTypes.bool,
  register: PropTypes.func.isRequired,
  errors: PropTypes.shape({}).isRequired,
}

EmployerNoEdit.defaultProps = {
  editable: false,
  reset: false,
  onRemoveFile: () => { },
  files: [],
}

export default EmployerNoEdit
