import './checkbox.scss'
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

const Checkbox = ({ name, text, clickMethod, disabled, value }) => {
  const [checked, setChecked] = useState(value)

  function onClickCheckBox() {
    if (disabled) return
    setChecked(!checked)
    clickMethod()
  }

  /**
   * Side Effects
   */
  useEffect(() => {
    setChecked(value)
  }, [value])

  return (
    <div className="ns-checkbox">
      <div className="ns-checkbox__wrapper">
        <div
          className="ns-checkbox__input"
          role="button"
          tabIndex={0}
          onClick={() => onClickCheckBox()}
          onKeyPress={() => onClickCheckBox()}
        >
          <input
            type="checkbox"
            className="ns-checkbox__check"
            name={name}
            checked={checked}
            readOnly
          />
          <span className="ns-checkbox__inner">
            <em
              className={`ns-checkbox__icon ${checked && 'icon-check'}`.trim()}
            />
          </span>
        </div>
        <div className="ns-checkbox__text">{text}</div>
      </div>
    </div>
  )
}
Checkbox.propTypes = {
  name: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  clickMethod: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
}

Checkbox.defaultProps = {
  value: false,
  disabled: false,
}

export default Checkbox
