import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
import QuoteSummaryReducer from './quote-summary-reducer'
import * as QuoteSummaryActions from './quote-summary-actions'

export const QuoteSummaryContext = React.createContext()

export const QuoteSummaryContextProvider = ({ children, wizard }) => {
  const [QuoteSummaryData, QuoteSummaryDispatch] = useReducer(
    QuoteSummaryReducer,
    {
    }
  )

  return (
    <QuoteSummaryContext.Provider
      value={{ 
        wizard, 
        QuoteSummaryData, 
        QuoteSummaryDispatch, 
        QuoteSummaryActions 
      }}
    >
      {children}
    </QuoteSummaryContext.Provider>
  )
}

QuoteSummaryContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
export default QuoteSummaryContextProvider
