import './sign-documents-wizard.scss'
import React, { useContext, useEffect, useCallback } from 'react'
import { Wizard, Steps, Step } from 'react-albus'
import NSProgressBar from '@commons/progress-bar/progress-bar'
import SignDocuments from '@pages/sign-documents'
import SignDocumentsSuccess from '@pages/sign-documents-success'
import { WizardContext } from '@commons/steppers/wizard'
import { SignDocumentsContextProvider } from '@components/sign-documents'
import { SignDocumentsSuccessContextProvider } from '@components/sign-documents-success'
import { ManagerRouteContextProvider } from '@commons/steppers/manager-route/manager-route-context'

const SignDocumentsWizard = ({ history, route }) => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(
    WizardContext
  )

  const addSteps = useCallback(() => {
    WizardDispatch(
      WizardActions.addSteps([
        {
          name: 'documentos',
          completed: false,
        },
        {
          name: 'done',
          completed: false,
        },
      ])
    )
  }, [WizardActions, WizardDispatch])

  /**
   * Side Effects
   */
  useEffect(() => addSteps(), [addSteps])

  return (
    <div className="wizard health-statement">
      <div className="wizard__progress">
        <NSProgressBar percent={wizardData.progress} />
      </div>
      <div className="wizard__wrapper">
        <Wizard history={history} basename={route}>
          <Steps>
            <Step
              id="documentos"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <SignDocumentsContextProvider wizard={wizard}>
                    <SignDocuments />
                  </SignDocumentsContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="done"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <SignDocumentsSuccessContextProvider wizard={wizard}>
                    <SignDocumentsSuccess />
                  </SignDocumentsSuccessContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
          </Steps>
        </Wizard>
      </div>
    </div>
  )
}

export default SignDocumentsWizard
