import './benef-ident-edit-alterable.scss'
import React, { useEffect, useState, useContext } from 'react'
import Tooltip from '@commons/tooltip/tooltip'
import BenefBox from './benef-ident-alterable'
import IconButton from '@commons/buttons/icon-button'
import { WizardContext } from '@commons/steppers/wizard/'
import _ from 'lodash'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { rutSchema, heightSchema, weightSchema, getAge18 } from '@utils/fields-validators'
import { parse, isDate } from "date-fns";

const EditBeneficiary = (props) => {
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const [showTooltip, setShowTooltip] = useState(false)
  const [showInitTooltip, setInitShowTooltip] = useState(true)
  const [editable, setEditable] = useState(false)
  const [beneficiaries, setBeneficiaries] = useState(props.beneficiary)
  const [resetData, setResetData] = useState(false)
  const [beneficiariesClone, setBeneficiariesClone] = useState([...props.beneficiary])

  useEffect(() => {
    setBeneficiaries(props.beneficiary)
  },[props.beneficiary])

  const handlerTooltipOnMouseOut = () => {
    setShowTooltip(false)
  }

  const handlerTooltipOnMouseOver = () => {
    setShowTooltip(true)
  }

  useEffect(() => {
    if (showInitTooltip) {
      setTimeout(() => {
        setInitShowTooltip(false)
      }, 5000)
    }
  }, [showInitTooltip])

  function parseYupDateString(value, originalValue) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "dd-MM-yyyy", new Date());
    return parsedDate;
  }

    /**
   * Form Schema Validator
   */
  const schema = yup.object().shape(
    beneficiaries
      .map((item) => {
        let schemaObject = {}
        if (item.id !== 0) {
          schemaObject = {
            [`${item.id}_relation`]: yup.number().min(1, 'Debe seleccionar la relación').required('Debe seleccionar la relación'),
            [`${item.id}_other`]: yup.string().required('Debe ingresar otro tipo relación'),
            [`${item.id}_type`]: yup.number().min(1, 'Debe seleccionar el tipo de carga').required('Debe seleccionar el tipo de carga'),
          }
        }
        schemaObject = {
          ...schemaObject,
          [`${item.id}_names`]: yup.string().required('Debe ingresar sus nombres'),
          [`${item.id}_paternalSurname`]: yup.string().required('Debe ingresar su apellido paterno'),
          [`${item.id}_maternalSurname`]: yup.string().required('Debe ingresar su apellido materno'),
          [`${item.id}_rut`]: rutSchema,
          [`${item.id}_sex`]: yup.string().ensure().required("El sexo es obligatorio"),
          [`${item.id}_birthdate`]: yup.string().required('Debe ingresar su fecha de nacimiento').when(`${item.id}_relation`, (relation) => {
            if(parseInt(relation) === 0){
              return yup.date().transform(parseYupDateString).max(getAge18(), 'El titular no puede ser menor de 18 años')
            }
          }),
          [`${item.id}_weight`]: weightSchema,
          [`${item.id}_height`]: heightSchema,
        }
        return schemaObject
      })
      .reduce((ini, curr) => ({ ...ini, ...curr }), {})
  )
  const {
    register,
    errors,
    triggerValidation,
    clearError,
    getValues,
    control,
  } = useForm({
    validationSchema: schema,
  })

    /**
   * @description Invoke Form Validation
   */
  async function saveAction() {
    props.isEditing(!editable)
    const validForm = await triggerValidation()
    props.isValid(validForm)
    if(validForm) {
      const values = getValues()
      const newBeneficiaries = [...props.beneficiary].map((item) => ({
        ...item,
        names: values[`${item.id}_names`],
        paternalSurname: values[`${item.id}_paternalSurname`],
        maternalSurname: values[`${item.id}_maternalSurname`],
        rut: values[`${item.id}_rut`].replace(/[.-\s]/g,''),
        sex: values[`${item.id}_sex`].value,
        birthdate: values[`${item.id}_birthdate`],
        relation: values[`${item.id}_relation`],
        type: values[`${item.id}_type`],
        weight: values[`${item.id}_weight`],
        height: values[`${item.id}_height`],
        other: values[`${item.id}_other`],
      }))
      WizardDispatch(WizardActions.setBeneficiary(newBeneficiaries))
      setEditable(!editable)
    }
    setResetData(false)
  }

  /**
   * @description handle onClick event of EditButton
   */
  function editActionToggle() {
    // Edition mode enabling
    if (!editable) {
      setBeneficiariesClone([...props.beneficiary])
      setResetData(false)
    }

    // Edition mode disabling
    if (editable) {
      WizardDispatch(WizardActions.setBeneficiary(beneficiariesClone))
      clearError()
      setResetData(true)
    }
    setEditable(!editable)
    props.isEditing(!editable)
  }

  function createNewBeneficiary() {
    props.addBeneficiary()
  }

  return (
    <div className="benef-ident-alterable">
      <div className="benef-ident-alterable__beneficiary">
        <div className="benef-ident-alterable__container">
          <div className="benef-ident-alterable__title benef-ident-alterable--edit-mode">
            Cargas legales y médicas
          </div>
          <div className="benef-ident-alterable__icon">
            <div className="benef-ident-alterable__tooltip">
              <Tooltip visible={showTooltip || showInitTooltip} align="left">
                <div>
                  Debes ingresar todas tus cargas legales y médicas, si omites
                  alguna de ellas y se demuestra que esta omisión perjudico a la
                  Isapre, al no poder evaluar el riesgo, podemos poner término a
                  tu contrato de salud.
                </div>
              </Tooltip>
            </div>
            <em
              className="icon-alerta"
              onMouseOver={handlerTooltipOnMouseOver}
              onMouseOut={handlerTooltipOnMouseOut}
            />
          </div>
        </div>
        <div className="benef-ident-manager-alterable">
          {_.size(beneficiaries) ? (
            <div className="benef-ident-manager-alterable__list">
              {beneficiaries.map((item, i) => (
                <BenefBox
                  index={i + 1}
                  key={item.id}
                  editable={editable}
                  isNew={item.isNew}
                  removeBeneficiary={props.removeBeneficiary}
                  register={register}
                  errors={errors}
                  reset={resetData}
                  control={control}
                >
                  {item}
                </BenefBox>
              ))}
              {editable && (
                <div
                  className={`benef-ident-manager-alterable__create ${
                    beneficiaries.length === 0
                      ? "benef-ident-manager-alterable__create--box"
                      : ""
                  }`.trim()}
                >
                  <button type="button" onClick={createNewBeneficiary}>
                    <em className="icon-agregar" /> Agregar nueva carga
                  </button>
                </div>
              )}
            </div>
          ) : null}
          <div className="benef-ident-manager-alterable__actions-wrapper">
            <div
              className={`actions ${editable ? "actions--active" : ""}`.trim()}
            >
              {!editable && (
                <IconButton
                  action={editActionToggle}
                  icon="editar"
                  title="Editar"
                />
              )}
              {editable && (
                <>
                  <IconButton
                    action={editActionToggle}
                    icon="cerrar"
                    title="Descartar cambios"
                  />
                  <IconButton
                    action={saveAction}
                    icon="guardar"
                    title="Guardar cambios"
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditBeneficiary
