import './employer-create.scss'
import { RentStatementContext, RentStatementService } from '@components/rent-statement'
import React, { useState, useContext } from 'react'
import IconButton from '@commons/buttons/icon-button'
import Radio from '@commons/inputs/radio'
import PropTypes from 'prop-types'

const EmployerCreate = ({
  action,
  enableSelfEmployer,
  enableEntityEmployer,
  enableVoluntaryEmployer,
  enablePensionerEmployer
}) => {
  const [disabledAddButton, setDisabledAddButton] = useState(true)
  const [entityType, setEntityType] = useState()

  const {
    rentStatementData,
  } = useContext(RentStatementContext)

  const dataEmplado = localStorage.getItem("dataEmpleado");

  /**
   * @description AddEvent handler
   */
  function AddActionEventHandler() {
    action({
      entity_type: entityType,
    })
    // if (entityType === '2') setDisabledAddButton(true)
    
  }

  /**
   * @description Entity type onChange event handler
   * @param {event} e Element Event object
   */
  function entityTypeOnChangeHandler(e) {
    setEntityType(e.target.value)
    setDisabledAddButton(false)
    localStorage.setItem("entityType", e.target.value);
  }

  return (
    <div className="employer-create">
      <div className="employer-create__title-box">
        Si no aparece su empleador actual, agréguelo AQUÍ
      </div>
      {/* <div className="employer-create__content-box">
        <Radio
          text="Dependiente"
          name="tipo_entidad"
          value="1"
          onChangeEvent={entityTypeOnChangeHandler}
          isChecked={dataEmplado.includes(true) ? true : false}
        // disabled={!enableEntityEmployer}
        />
        <Radio
          text="Independiente"
          name="tipo_entidad"
          value="2"
          onChangeEvent={entityTypeOnChangeHandler}
        // disabled={!enableSelfEmployer}
          isChecked={!dataEmplado.includes(true) ? true : false}
        />
        <Radio
          text="Voluntario"
          name="tipo_entidad"
          value="3"
          onChangeEvent={entityTypeOnChangeHandler}
        />
        <Radio
          text="Pensionado"
          name="tipo_entidad"
          value="4"
          onChangeEvent={entityTypeOnChangeHandler}
        />
      </div> */}
      <div className="employer-create__actions">
        <IconButton
          action={AddActionEventHandler}
          icon="agregar"
          // disabled={disabledAddButton}
        />
      </div>
    </div>
  )
}
EmployerCreate.propTypes = {
  action: PropTypes.func.isRequired,
  enableSelfEmployer: PropTypes.bool,
  enableEntityEmployer: PropTypes.bool,
  enableVoluntaryEmployer: PropTypes.bool,
  enablePensionerEmployer: PropTypes.bool,
}
EmployerCreate.defaultProps = {
  enableSelfEmployer: true,
  enableEntityEmployer: true,
  enableVoluntaryEmployer: true,
  enablePensionerEmployer: true,
}

export default EmployerCreate
