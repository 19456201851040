import React, { useContext, useEffect } from "react";
import { WizardContext } from "@commons/steppers/wizard";
import SummaryHealthStatementQuestions from "@components/summary-health-statement/elements/summary-questions";

const SummaryHealthStatement = () => {
  const { WizardDispatch, WizardActions } = useContext(WizardContext);

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  return (
    <div className="sum-health-statement">
      <h2 className="sum-health-statement__title">
        Resumen Declaración de Salud
      </h2>
      <SummaryHealthStatementQuestions />
    </div>
  )
}

export default SummaryHealthStatement;
