import React, { useContext, useState } from 'react'
import './result-authorized.scss'
import Button from '@commons/buttons/button'
import Downloader from '@commons/downloader/downloader'
import ErrorMessage from '@commons/messages/error/error-message'
import { HealthStatementSentContext, HealthStatementSentService } from '@components/health-statement-sent'
import { WizardContext } from '@commons/steppers/wizard'
import { useHistory } from 'react-router-dom'
import { StepperStepsContext } from '@commons/steppers/steps'

const ResultAuthorized = () => {
  const history = useHistory()
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { stepperStepsData, stepperStepsDispatch, StepperStepsActions } = useContext(StepperStepsContext)
  const { isAsisted } = stepperStepsData

  const { WizardDispatch, WizardActions } = useContext(WizardContext)

  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))

  let textTitulo = 'Declaración de Salud'

  if((esCargaTitular) && (!tieneCargas)){
    textTitulo = 'Declaración de Renta'
  }

  const {
    wizard,
    healthStatementSentData: {
      resultData: { nombre, archivo },
    },
    HealthStatementSentDispatch,
    HealthStatementSentActions,
  } = useContext(HealthStatementSentContext)
  /**
   * @description Handle next step
   */
  function handleNextStep() {
    HealthStatementSentDispatch(HealthStatementSentActions.setLoading(true))
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      resultado: 1,
      pin: null,
      acepta: null,
    }
    HealthStatementSentService.finishDS(body).then((response) => {
      if (response.httpCode === 200) {
        WizardDispatch(WizardActions.setCompleteSubStep({ name: 'resultado' }))
        stepperStepsDispatch(StepperStepsActions.setResult(''))
        if (isAsisted) {
          history.push('/firmar-documentos')
        } else {
          wizard.next()
        }
      } else {
        setShowErrorMessage(true)
      }
      HealthStatementSentDispatch(HealthStatementSentActions.setLoading(false))
    }).catch(() => {
      setShowErrorMessage(true)
      HealthStatementSentDispatch(HealthStatementSentActions.setLoading(false))
    })
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
  }

  return (
    <div className="result-authorized">
      <div className="result-authorized__message">
        Tu{' '}
        <span className="result-authorized__message--bold">
          {textTitulo}
        </span>{' '}
        ha sido{' '}
        <span className="result-authorized__message--bold">Autorizada</span>
      </div>
      <div className="result-authorized__downloader">
        <Downloader text={nombre} fileUrl={archivo} />
      </div>
      <div className="result-authorized__buttons">
        <Button text="Continuar" action={handleNextStep} />
      </div>
      <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
        No se pudo finalizar el proceso, intentalo más tarde.
      </ErrorMessage>
    </div>
  )
}

export default ResultAuthorized
