import React, { useState, useContext } from "react";
import "./result-insufficient-income.scss";
import Button from "@commons/buttons/button";
import ErrorMessage from '@commons/messages/error/error-message'
import { HealthStatementSentService, HealthStatementSentContext } from "@components/health-statement-sent";
import { StepperStepsContext } from '@commons/steppers/steps'
import FormatNum from '@utils/tools/number'

const ResultInsufficientIncome = () => {
  const [message, setMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { stepperStepsData } = useContext(StepperStepsContext)
  const {
    healthStatementSentData: {
      resultData: { renta },
    },
  } = useContext(HealthStatementSentContext)
  /**
   * @description Handle close sesion
   */
  function handleCloseSesion() {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      resultado: 7,
      pin: null,
      acepta: null,
    }
    HealthStatementSentService.finishDS(body)
      .then((response) => {
        if (response.httpCode === 200) {
          window.location = 'http://apps2.nuevamasvida.cl/cotizaplanes/'
        } else {
          setShowErrorMessage(true);
          setMessage(
            "No se ha podido finalizar el proceso, vuelve a intentarlo."
          );
        }
      })
      .catch(() => {
        setShowErrorMessage(true);
        setMessage(
          "No se ha podido finalizar el proceso, vuelve a intentarlo."
        );
      });
  }

  return (
    <div className="result-insufficient">
      <div className="result-insufficient__statement">
        Según tu renta informada el plan que has seleccionado supera el monto máximo a descontar
        de tu remuneración. Debes seleccionar otro plan de acuerdo a la siguiente renta acreditada:<br/>
        <span>${FormatNum(renta)}</span><br/>
        <span>(Promedio renta)</span>
      </div>
      
      <div className="result-insufficient__buttons">
      {!stepperStepsData.isAsisted &&
        <Button text="Continuar" action={handleCloseSesion} />
      }
      </div>
      <ErrorMessage
        isShowing={showErrorMessage}
        action={() => setShowErrorMessage(false)}
      >
        {message}
      </ErrorMessage>
    </div>
  );
};

export default ResultInsufficientIncome;
