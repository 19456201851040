import React, { useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import StartSubscriptionReducer from './start-subscription-reducer'
import StartSubscriptionService from './start-subscription-service'
import * as StartSubscriptionActions from './start-subscription-actions'

export const StartSubscriptionContext = React.createContext()
export const StartSubscriptionContextProvider = ({ children, wizard }) => {
  const messages = [
    '¡Genial!, ya podemos iniciar el proceso. Para comenzar debes seleccionar tu institución de salud previsional actual',
    'Ahora debes seleccionar la Isapre a la que perteneces actualmente',
    'Infórmanos si eres Titular o Carga de la Isapre a la que perteneces actualmente',
    '',
  ]
  const [startSubscriptionData, startSubscriptionDispatch] = useReducer(
    StartSubscriptionReducer,
    {
      currentMessage: 0,
      message: messages[0],
      messages,
      isapresList: [],
    }
  )

  /** SideEffects */
  useEffect(() => {
    StartSubscriptionService.getListIsapres().then((response) =>{
      if (response.httpCode === 200) {
        startSubscriptionDispatch(
          StartSubscriptionActions.setIsapreList(response.data)
        )
      }
    })
  }, [])

  return (
    <StartSubscriptionContext.Provider
      value={{
        wizard,
        startSubscriptionData,
        startSubscriptionDispatch,
        StartSubscriptionActions,
      }}
    >
      {children}
    </StartSubscriptionContext.Provider>
  )
}
StartSubscriptionContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
