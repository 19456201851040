import IconButton from "@components/commons/buttons/icon-button";
import "./add-file-modal.scss";
import React, { useContext, useEffect, useState } from "react";
import { useRef } from "react";
import { validateFile } from "@utils/fields-validators";
import { fileToBase64 } from "@utils/fields-validators";
import InputFile from "@components/commons/inputs/input-file";
import Tooltip from "@components/commons/tooltip/tooltip";
import SpinnerLoader from "@components/commons/loaders/spinner-loader";
import SimpleModal from "../messages/simple-modal/modal";
import { HealthStatementSentService } from "@components/health-statement-sent";

const modalStyle = {
  modalBody: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "720px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #B7B7B7",
    borderRadius: "16px",
    zIndex: "10"
  },
  closeBtn: {
    position: "absolute !important",
    transform: "translate(700px, -15px)"
  },
  modalContainer: {
    margin: "50px 14px 14px",
    textAlign: "center",
    fontFamily: "Raleway",
    fontWeight: "500",
    fontSize: "16px"
  }
};

const AddFileModal = ({ id }) => {
  const [showModal, setShowModal] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [showInvalidFileMessage, setShowInvalidFileMessage] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showTooltipInfo, setShowTooltipInfo] = useState(false);
  const [loading, setLoading] = useState(false);
  const [resMsg, setResMsg] = useState(null);

  const closeModalAndRemoveFiles = () => {
    setResMsg(null);
    setShowModal(false);
    setAttachedFiles([]);
  };

  function removeFileFromList(index) {
    const auxList = attachedFiles.filter((item, i) => i !== index);
    setAttachedFiles(auxList);
  }

  function attachedFilesOnChangeHandler(e) {
    const { files } = e.target;
    const validFiles = Object.keys(files).filter((item) =>
      validateFile(files[item])
    );
    const invalidFiles = Object.keys(files).filter(
      (item) => !validateFile(files[item])
    );
    setShowInvalidFileMessage(invalidFiles.length > 0);

    const entityFiles = [
      ...attachedFiles,
      ...validFiles
        .map((item) => {
          files[item].new = true;
          return files[item];
        })
        .slice(0, 10)
    ];
    setAttachedFiles(entityFiles);
  }

  const saveFiles = async () => {
    setLoading(true);
    const archives = await fileToBase64(attachedFiles);
    archives.forEach(async (file) => {
      let result;
      //prospect
      const bodyTAD = {
        identificador: id,
        nombreArchivo: file.nombreArchivo,
        binario: file.binario,
        tipoMIME: file.tipoMIME,
        TAD_CODIGO: 40
      };
      result = await HealthStatementSentService.sendFinantialDocuments(
        bodyTAD
      )
        .then((response) => {
          setLoading(false);
          if (response.httpCode === 200) {
            setResMsg("success");
            return true;
          } else {
            setResMsg("error");
            return false;
          }
        })
        .catch(() => {
          return false;
        });
    });
  };

  const handlerTooltipOnMouseOut = () => {
    setShowTooltip(false);
  };

  function handlerTooltipOnHover(flag) {
    setShowTooltip(flag);
  }
  const handlerTooltipInfoOnMouseOut = () => {
    setShowTooltipInfo(false);
  };

  function handlerTooltipInfoOnHover(flag) {
    setShowTooltipInfo(flag);
  }

  useEffect(() => {
    setResMsg(null);
    setTimeout(() => {
      handlerTooltipOnMouseOut();
      handlerTooltipInfoOnMouseOut();
    }, 5000);
  }, []);

  const AddFiles = () => {
    return (
      <>
        <div className="home__subtitle d-block">
          <span>
            El formato debe ser JPG, GIF o PDF, con un tamaño máximo de 2MB.
          </span>
        </div>
        <div className="result-revision__attachment claseMia">
          <div className="attachment__list">
            {attachedFiles.map((item, i) => (
              <div key={i} className="attachment__list-item">
                <em className="icon-archivo" />
                <span>{item.name.split(".")[0]}</span>
                <IconButton
                  text=""
                  icon="eliminar"
                  action={() => removeFileFromList(i)}
                />
              </div>
            ))}
          </div>
          <div className="attachment__actions d-block">
            <InputFile
              onChange={(e) => attachedFilesOnChangeHandler(e)}
              mimetypes={[".jpg", ".jpeg", "application/pdf"]}
              value={
                attachedFiles && attachedFiles.map((item) => item).join(",")
              }
            />
          </div>
          {resMsg === "success" && <span>Documentos guardados con éxito.</span>}
          {resMsg === "error" && (
            <span>Ha ocurrido un error. Intentelo más tarde.</span>
          )}
          <hr />
          <div className="pb-3">
            <button
              disabled={!attachedFiles.length || attachedFiles.length > 5}
              className="ns-btn pb-2"
              style={{
                backgroundColor:
                  !attachedFiles.length || attachedFiles.length > 5 || loading ? "#F0F0F0" : "#0085CA",
                color: !attachedFiles.length || attachedFiles.length > 5 || loading ? "#939393" : "#FFF",
                width: "auto"
              }}
              onClick={() => saveFiles()}
            >
              Guardar
            </button>
          </div>
        </div>
        <SpinnerLoader show={loading} />
      </>
    );
  };

  return (
    <>
      <div
        role="button"
        onKeyPress={() => null}
        tabIndex="0"
        className="ns-inputfile"
        style={{ position: "absolute", right: "0", top: "50px" }}
        onClick={() => setShowModal(true)}
      >
        <div className="add-file-tooltip">
          <Tooltip visible={showTooltip} align="top">
            <div>Presione este icono si desea adjuntar documentos</div>
          </Tooltip>
          <em
            className="icon-adjuntar"
            onMouseOver={() => handlerTooltipOnHover(true)}
            onMouseOut={() => handlerTooltipOnHover(false)}
            onBlur={() => handlerTooltipOnHover(false)}
            onFocus={() => handlerTooltipOnHover(true)}
          />{" "}
        </div>
      </div>
      <SimpleModal
        isShowing={showModal}
        action={closeModalAndRemoveFiles}
        styles={modalStyle}
      >
        <AddFiles />
      </SimpleModal>
    </>
  );
};

export default AddFileModal;
