import './health-statement-question.scss'
import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import Radio from '@commons/inputs/radio'
import Button from '@commons/buttons/button'
import { WizardContext, WizardService } from '@commons/steppers/wizard'
import { StepperStepsContext } from '@commons/steppers/steps'
import HealthDiagnosticForm from '@components/health-statement/elements/health-diagnostic-form'
import InfoMessage from '@commons/messages/info/info-message'
import ErrorMessage from '@commons/messages/error/error-message'

const HealthStatementQuestion = ({ num, openQuestion, example, isSummary, isEditing, cancelAction, setOpenQuestion }) => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [openForm, setOpenForm] = useState(
    wizardData.questions[num - 1].answer === true
  )
  const [showMsgAddDiagnostic, setShowMsgAddDiagnostic] = useState(false)
  const [showMsgNext, setShowMsgNext] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [completed, setCompleted] = useState(false)
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([])
  const [showErrorMessage, setShowErrorMessage] = useState(false)

  /**
   * @description Handler Click event of Yes Button
   */
  function handleYesOption() {
    WizardDispatch(WizardActions.setYesAnswer(num))
    setOpenForm(true)
  }

  /**
   * @description Handler Click event of No Button
   */
  function handleNoOption() {
    WizardDispatch(WizardActions.setNoAnswer(num))
    setOpenForm(false)
  }

  /**
   * @description Handler Click event of No Button
   */
  function handleBackupQuestions() {
    WizardDispatch(WizardActions.backupQuestions())
    cancelAction()
  }

  /**
   * @description Validates that all diagnoses are complete
   */
  function diagnosticsComplete() {
    let result = true
    wizardData.questions[num - 1].diagnostics.forEach((d) => {
      if (
        d.rut === '' ||
        d.diagnostico.diagnostico === '' ||
        d.fecha === ''
      ) {
        result = false
      }
    })
    return result
  }

  /**
   * @description Validates that answer are complete
   */
  function answerComplete() {
    let result = true
    if (wizardData.questions[num - 1].answer === true) {
      switch (wizardData.questions[num - 1].answer) {
        case false:
          result = true
          break
        case true:
          wizardData.questions[num - 1].diagnostics.forEach((d) => {
            if (
              d.rut === '' ||
              d.diagnostico.diagnostico === '' ||
              d.fecha === ''
            ) {
              result = false
            }
          })
          break
        default:
          result = false
      }
    } else if (wizardData.questions[num - 1].answer === false) {
      result = true
    }
    return result
  }

  /**
   * @description Handler Click Add diagnostic button
   */
  function addDiagnostic() {
    if (diagnosticsComplete()) {
      WizardDispatch(WizardActions.addDiagnostic(num))
    } else {
      setShowMsgAddDiagnostic(true)
    }
  }

  /**
   * @description Handler Click Close Message Add button
   */
  function closeMsgAddDiagnostic() {
    setShowMsgAddDiagnostic(false)
  }

  /**
   * @description Handler Click Close Message Next button
   */
  function closeMsgNext() {
    setShowMsgNext(false)
  }

  /**
   * @description Handler Submit Form Event
   */
  function handleFormSubmit() {
    if (answerComplete()) {
      const responseAnswer = wizardData.questions[num - 1].answer
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
        codigoPregunta: num,
        respuesta: responseAnswer,
        diagnosticos: responseAnswer ? wizardData.questions[num - 1].diagnostics.map(q => {
          return {
            ...q,
            diagnostico: [q.diagnostico]
          }
        }) : [],
      }
      if (!isSummary) {
        WizardService.saveDSQuestionsAnswers(body).then((response) => {
          if (response.httpCode === 200) {
            WizardDispatch(WizardActions.setFormYesAnswer(wizardData.questions[num - 1]))
            if (num === 10) {
              if (num === wizardData.nextQuestionToAnswer) {
                WizardDispatch(WizardActions.setNextQuestionToAnswer(num + 2))
              }
            } else {
              if (num === wizardData.nextQuestionToAnswer) {
                WizardDispatch(WizardActions.setNextQuestionToAnswer(num + 1))
              }
            }
            setOpenQuestion(false)
          } else {
            setShowErrorMessage(true)
          }

        }).catch(() => {
          setShowErrorMessage(true)
        })

      } else {
        WizardService.saveDSQuestionsAnswers(body).then((response) => {
          console.log('response', response)
          if (response.httpCode === 200) {
            WizardDispatch(WizardActions.setBackupQuestionsList((JSON.parse(JSON.stringify(wizardData.questions)))))
            cancelAction()
          } else {
            setShowErrorMessage(true)
          }
        }).catch(() => {
          setShowErrorMessage(true)
        })
      }

    }
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
  }

  return (
    <>
      {(openQuestion || wizardData.nextQuestionToAnswer === num) && (
        <>
          <div className="health-question__content">
            <div className="row content__example">
              <div className="col-8 example__content">
                <div className="example__text">{example}</div>
                <div className="row answer">
                  <div className="col-2 answer__text">
                    {wizardData.questions[num - 1].answer === null
                      ? 'Declara:'
                      : 'Declaro:'}
                  </div>
                  <div className="col-10 answer__options">
                    <Radio
                      text="NO"
                      name={`pension__${num}`}
                      value="false"
                      isChecked={
                        wizardData.questions[num - 1].answer === false
                      }
                      onChangeEvent={handleNoOption}
                      cssClass={
                        wizardData.questions[num - 1].answer !== false
                          ? 'ns-radio--disabled'
                          : ''
                      }
                    />
                    <Radio
                      text="SI"
                      name={`pension__${num}`}
                      value="true"
                      isChecked={
                        wizardData.questions[num - 1].answer === true
                      }
                      onChangeEvent={handleYesOption}
                      cssClass={`${wizardData.questions[num - 1].answer !== true && 'ns-radio--disabled'
                        } ${wizardData.questions[num - 1].answer === true && 'ns-radio--yes'
                        }`.trim()}
                    />
                  </div>
                </div>
              </div>
              <div className="col-4 question__icon">
                <img
                  alt={`pregunta${num}`}
                  src={`${process.env.PUBLIC_URL}/assets/images/health-questions-icons/${num}.svg`}
                />
              </div>
            </div>
            {openForm && (
              <>
                {wizardData.questions[num - 1].diagnostics.map((d, index) => (
                  <HealthDiagnosticForm
                    index={index}
                    numQuestion={num}
                    key={`${d.rut}-${index}`}
                    completedForm={e => setCompleted(e)}
                    formData={data => setData(data)}
                  >
                    {d}
                  </HealthDiagnosticForm>
                )
                )}
                <div className="content__mandatory">
                  <span>*</span> Campos obligatorios
                </div>
              </>
            )}
          </div>
          {wizardData.questions[num - 1].answer !== null && (
            <div className="row health-question__buttons">
              <div className="col-6 pl-5">
                {wizardData.questions[num - 1].answer === true && (
                  <div className="button__add">
                    <button type="button" onClick={addDiagnostic}>
                      <em className="icon-agregar" /> Declarar otro diagnóstico
                    </button>
                  </div>
                )}
              </div>
              <div className="col-6 pr-5 d-flex">
                {isSummary &&
                  <Button
                    text="Cancelar"
                    cssClass="button__cancel"
                    action={handleBackupQuestions}
                  />
                }
                {
                  isEditing ?
                    <Button
                      text="Guardar"
                      cssClass="button__next"
                      action={handleFormSubmit}
                      disabled={!answerComplete()}
                    />
                    :
                    <Button
                      text="Siguiente"
                      cssClass="button__next"
                      action={handleFormSubmit}
                      disabled={!answerComplete()}
                    />
                }
              </div>
            </div>
          )}
        </>
      )}
      <InfoMessage
        buttonText="Aceptar"
        isShowing={showMsgAddDiagnostic}
        action={closeMsgAddDiagnostic}
      >
        <p className="msgtxt">
          Debe completar los campos obligatorios para poder declarar otro
          diagnóstico
        </p>
      </InfoMessage>
      <InfoMessage
        buttonText="Aceptar"
        isShowing={showMsgNext}
        action={closeMsgNext}
      >
        <p className="msgtxt">
          Debe completar los campos obligatorios (
          <span
            style={{ color: 'red', fontWeight: 800, verticalAlign: 'bottom' }}
          >
            *
          </span>
          ) para pasar a la siguiente pregunta
        </p>
      </InfoMessage>
      <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
        No se ha podido guardar la respuesta, vuelve a intentalor más tarde.
      </ErrorMessage>
    </>
  )
}

HealthStatementQuestion.propTypes = {
  example: PropTypes.string.isRequired,
  setOpenQuestion: PropTypes.func.isRequired,
}

export default HealthStatementQuestion
