import './disaffiliation-sysasap.scss'
import React, { useState, useContext } from 'react'
import { StepperStepsContext } from '@commons/steppers/steps/'
import { ProcessFinalizationIsapreContext, ProcessFinalizationIsapreService } from "@components/process-finalization-isapre";
import ErrorMessage from '@commons/messages/error/error-message'
import Sign from '@commons/sign/sign'
import WindowOpen from "@commons/window-open/window-open";
import _ from 'lodash';
import { useHistory } from 'react-router-dom'
import SimpleModal from '@components/commons/messages/simple-modal/modal';
import Radio from '@components/commons/inputs/radio';
import Button from '@components/commons/buttons/button';

const modalStyle = {
  modalBody: {
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "720px",
    backgroundColor: "#FFFFFF",
    border: "1px solid #B7B7B7",
    borderRadius: "16px",
    zIndex: "10"
  },
  closeBtn: {
    display: "none"
  },
  modalContainer: {
    margin: "50px 14px 14px",
    textAlign: "center",
    fontFamily: "Raleway",
    fontWeight: "500",
    fontSize: "16px"
  }
};

const DisaffiliationSysasap = ({url, fail, setRetryCountSysasap, retryCountSysasap}) => {
  const history = useHistory()
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [logged, setLogged] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showSubtitle, setShowSubtitle] = useState(true)
  const [showError, setShowError] = useState({show: false, msg: ''})
  const [errorCode, setErrorCode] = useState(null)
  const { wizard } = useContext(ProcessFinalizationIsapreContext)
  const [okDisaffiliation, setOkDisaffiliation] = useState(false)
  const [retryCount, setRetryCount] = useState(0)

  const [onlineDisaffiliation, setOnlineDisaffiliation] = useState(true) //if is true -> normal flow
  const [showModal, setShowModal] = useState(true)

  function handleIsapreDisaffiliation(pin){
    setLoading(true)
    const body ={     
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      pin,
      proceso: "D"
    }
    if (!okDisaffiliation) {
      ProcessFinalizationIsapreService.sysasapDisaffiliation(body).then((response) => {
        if (response.httpCode === 200) {
          if(_.size(response.data)){
            if (retryCountSysasap <= 3) {
              setRetryCountSysasap(retryCountSysasap + 1)
              setErrorCode(400)
              setLoading(false)
              setShowError({show:true, msg: `El servicio con tu isapre no responde en estos momentos. Por favor intenta nuevamente. Te quedan ${4 - retryCountSysasap} intentos`})
            } else {
              setErrorCode(0)
              setLoading(false)
              setShowError({show:true, msg: 'No se ha podido ejecutar el proceso de desafiliación de tu Isapre actual, inténtalo más tarde o continúa el proceso mediante carta de desafiliación'})
            }
            
          }else{
            setOkDisaffiliation(true)
            const body2 = {
              identificador: stepperStepsData.userInformation.identificador,
              rut: stepperStepsData.userInformation.rut,
              pin,
            }
            ProcessFinalizationIsapreService.signDisaffiliation(body2).then((response)=>{
              if (response.httpCode === 200) {
                wizard.push('terminar-proceso')
              }else{
                setErrorCode(null)
                setLoading(false)
                setShowError({show: true, msg: 'Ha ocurrido un error. Inténtalo nuevamente.'})
              }
            }).catch(()=>{
              setErrorCode(null)
              setLoading(false)
              setShowError({show: true, msg: 'Ha ocurrido un error. Inténtalo nuevamente.'})
            })
          }

        } else {
          setLoading(false)
        }
      }).catch((e) => {
        setLoading(false)
        setShowError({show: true, msg: 'Ha ocurrido un error. Inténtalo nuevamente.'})
      })
    } else {
      if (retryCount <= 3) {
        const body2 = {
          identificador: stepperStepsData.userInformation.identificador,
          rut: stepperStepsData.userInformation.rut,
          pin,
        }
        ProcessFinalizationIsapreService.signDisaffiliation(body2).then((response)=>{
          if (response.httpCode === 200) {
            wizard.push('terminar-proceso')
          }else{
            setRetryCount(retryCount + 1)
            setErrorCode(null)
            setLoading(false)
            setShowError({show: true, msg: 'Ha ocurrido un error. Inténtalo nuevamente.'})
          }
        }).catch(()=>{
          setRetryCount(retryCount + 1)
          setErrorCode(null)
          setLoading(false)
          setShowError({show: true, msg: 'Ha ocurrido un error. Inténtalo nuevamente.'})
        })
      } else {
        history.push('/finalizar-proceso/terminar-proceso-desafiliacion')
      }
    }
  }

  function cancelAction() {
  }

  function handleCustomOpenWindow(){
    setShowSubtitle(false)
  }

  const handleCustomCloseWindow = (state) => {
    if (state) {
      setLogged(true)
    } else {
      setLogged(false)
    }
  };
  
  function handleCloseErrorMessage(){
    if( errorCode !== null ){
      if(errorCode === 400){
        setShowError({show: false, msg: ''})
        setShowSubtitle(true)
        setLogged(false)
      } else if (errorCode === 0) {
        handleOtheFlux()
      }
    }else{
      setShowError({show: false, msg: ''})
    }
  }

  function handleOtheFlux(){
    fail()
  }

  const showPopUp = () => {
    return(
      <div className="desaffiliation-sysasap__subtitle">
        {showSubtitle && 
          <p>
            A continuación procederás a realizar la <span>desafiliación de tu isapre actual</span>.
            Por favor confirma la acción con el botón <span>Continuar</span>.
          </p>
        }
        <WindowOpen
          textButton="Continuar"
          customActionOpen={handleCustomOpenWindow}
          customActionClose={handleCustomCloseWindow}
          actionOtherFlux={handleOtheFlux}
          messageOtherFlux={
            <div>No se ha podido ejecutar el proceso de desafiliación de tu Isapre actual, inténtalo 
              más tarde o continúa el proceso mediante <span>carta de desafiliación</span>.
            </div>
          }
          textOtherFlux="Continuar"
          urlIsapre={url}
          proceso="D"
          skipToFinal={!onlineDisaffiliation}
        />
      </div>
    )
  }

  const showSign = () => {
    return(
      <div>
          <Sign
          hideDeclaration={true}
          onSign={handleIsapreDisaffiliation}
          onCancel={cancelAction}
          signButtonText="Desafiliar"
          pinDescription={
            <div className="desaffiliation-sysasap__sign">
                Para completar el proceso de <span>desafiliación de
                tu isapre actual</span>, debes ingresar la clave segura 
                enviada previamente por SMS a tu celular y a tu Correo. 
              </div>
            }
            tooglePin={true}
            showCancel={false}
            loading={loading}
            setLoading={setLoading}
          />
      </div>
    )
  }

  const BodyModal = () => {
    return(
      <div className="form-group__extranjero form-group">
        <span className="form-group__label text-center">A continuacion procederas a realizar la desafiliacion de tu Isapre Actual.
        Si ya tienes en tu poder la carta de desafiliacion,
        selecciona la opción respectiva,
        sino  selecciona Desafiliación en Linea.</span>
        <div className="form-group__radio-options m-5">
          <Radio
            cssClass="m-2"
            text="Desafiliación en Linea"
            name="extranjero"
            value="false"
            isChecked={onlineDisaffiliation}
            onChangeEvent={() => setOnlineDisaffiliation(true)}
          />
          <Radio
            cssClass="m-2"
            text="Adjuntar carta de Desafiliacion"
            name="extranjero"
            value="true"
            isChecked={!onlineDisaffiliation}
            onChangeEvent={() => setOnlineDisaffiliation(false)}
          />
        </div>
        <div className="sum-health-statement__button">
          <Button
            cssClass="radio-dis-btn"
            text="Aceptar"
            action={()=> setShowModal(false)}
            disabled={false}
          />
        </div>
      </div>
    )
  }
  
  return (
    <div className="desaffiliation-sysasap">
      { !logged ? 
        showPopUp()
      :
        showSign()
      }
      <SimpleModal isShowing={showModal} styles={modalStyle} action>
          <BodyModal/>
      </SimpleModal>
      <ErrorMessage isShowing={showError.show} action={handleCloseErrorMessage}>
        {showError.msg}
      </ErrorMessage>
    </div>
  );
};

export default DisaffiliationSysasap;