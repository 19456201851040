import {
  SET_CONTRIBUTOR_STATE,
} from './sign-documents-success-actions'

export default function StartSubscriptionReducer(state, action) {
  switch (action.type) {
    case SET_CONTRIBUTOR_STATE:
      return {
        ...state,
        independent: action.data,
      }
    default:
      return state
  }
}
