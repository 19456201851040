export default [
  {
    title: 'Información del Usuario',
    completed: false,
  },
  {
    title: 'Declaración de salud',
    completed: false,
  },
  {
    title: 'Firma de Documentos',
    completed: false,
  },
  {
    title: 'Finalización del Proceso',
    completed: false,
  },
]
