import React from 'react';
import Separator from '@commons/separator/separator'
import { format } from 'rut.js'
import DSDocView from './ds-doc-view';

const BeneficaryData = ({beneficiary, setBeneficiary}) => {

  const relationValues = [
    { label: 'Titular', value: 0 },
    { label: 'Cónyuge', value: 1 },
    { label: 'Padre', value: 2 },
    { label: 'Madre', value: 3 },
    { label: 'Hijos', value: 4 },
    { label: 'Otro', value: 5 },
    { label: 'Conviviente Civil', value: 6 },
  ]
  const typeValues = [
    { label: 'Titular', value: 0 },
    { label: 'Carga Legal', value: 2 },
    { label: 'Carga Médica', value: 1 },
  ]
  const sexValues = [
    { label: 'Masculino', value: 1 },
    { label: 'Femenino', value: 2 },
  ]

  return (
    <div className="benef-ident-manager-alterable__list">
      <div className="benef-ident-manager-alterable__title">Cargas legales y médicas</div>

      { beneficiary.map((benefData, index) => {
        return(
          <div key={index} className="benef-box-alterable">

            <div className="benef-box-alterable__index">{index+1}</div>
            <div className="form-row">
              <div className="form-group">
                <label htmlFor={`${benefData.id}_names`} className="form-label">
                  Nombres
                </label>
                <p className="benef-box-alterable__value">{benefData.names}</p>
  
              </div>
              <Separator />
              <div className="form-group">
                <label htmlFor={`${benefData.id}_paternalSurname`} className="form-label">
                  Apellido Paterno
                </label>
                <p className="benef-box-alterable__value">{benefData.paternalSurname}</p>
              </div>
              <Separator />
              <div className="form-group">
                <label htmlFor={`${benefData.id}_maternalSurname`} className="form-label">
                  Apellido Materno
                </label>
                <p className="benef-box-alterable__value">{benefData.maternalSurname}</p>
              </div>
              <Separator />
              <div className="form-group">
                <label htmlFor={`${benefData.id}_rut`} className="form-label">
                  Rut
                </label>
                <p className="benef-box-alterable__value">{format(benefData.rut)}</p>
              </div>
            </div>
            <Separator isVertical />
            <div className="form-row">
              <div className="form-group">
                <label htmlFor={`${benefData.id}_sex`} className="form-label">
                  Sexo
                </label>
                <p className="benef-box-alterable__value">{sexValues.find( op => op.value === benefData.sex).label}</p>
              </div>
              <Separator />
              <div className="form-group benef-box-alterable__datepicker">
                <label htmlFor={`${benefData.id}_birthdate`} className="form-label">
                  Fecha de nacimiento
                </label>
                <p className="benef-box-alterable__value">{benefData.birthdate}</p>
              </div>
              <Separator />
              <div className="form-group">
                <label htmlFor={`${benefData.id}_relation`} className="form-label">
                  Relación
                </label>
                <p className="benef-box-alterable__value">{relationValues.find((op) => op.value === benefData.relation).label}</p>
              </div>
            </div>
            {
              benefData.relation === 5 ? 
              <>
              <Separator isVertical />
                <div className="form-row">
                  <div className="form-group">
                    <label htmlFor={`${benefData.id}_other`} className="form-label">
                      Otro
                    </label>
                    <p className="benef-box-alterable__value">{benefData.other}</p>
                  </div>
                </div>
              </>
              :
              null
            }
            <Separator isVertical />
            <div className="form-row">
              <div className="form-group">
                <label htmlFor={`${benefData.id}_type`} className="form-label">
                  Tipo
                </label>
                <p className="benef-box-alterable__value">{typeValues.find((op) => op.value === benefData.type).label}</p>
              </div>
              <Separator />
              <div className="form-group">
                <label htmlFor={`${benefData.id}_weight`} className="form-label">
                  Peso (Kg)
                </label>
                <p className="benef-box-alterable__value">{benefData.weight}</p>
              </div>
              <Separator />
              <div className="form-group">
                <label htmlFor={`${benefData.id}_height`} className="form-label">
                  Estatura (Mts)
                </label>
                <p className="benef-box-alterable__value">{benefData.height}</p>
              </div>
            </div>
            {benefData.idArchivo ? 
            <>
              <hr />
              <div className="form-row">
                <div className="form-group text-center">
                  <DSDocView file={{
                      id: benefData.idArchivo,
                      name: benefData.nombreArchivo,
                      accepetd: benefData.accepetd || false
                    }}
                    beneficiariesCM={beneficiary}
                    setBeneficiariesCM={setBeneficiary}/>
                </div>
              </div>
            </>
            :
            null}
          </div>
        )})
      }
    </div>
  )
};

export default BeneficaryData;