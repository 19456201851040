import React, { useContext, useState, useEffect } from "react";
import "./result-revision.scss";
import { HealthStatementSentContext, HealthStatementSentService } from "@components/health-statement-sent";
import { StepperStepsContext } from '@commons/steppers/steps'
import InputFile from "@commons/inputs/input-file";
import IconButton from "@commons/buttons/icon-button";
import InfoMessage from "@commons/messages/info/info-message";
import Tooltip from "@commons/tooltip/tooltip";
import Button from "@commons/buttons/button";
import ErrorMessage from '@commons/messages/error/error-message'
import { validateFile } from "@utils/fields-validators";
import { fileToBase64 } from '@utils/fields-validators'
import { WizardContext } from '@commons/steppers/wizard'

const ResultRevision = () => {
  const [showTooltip, setShowTooltip] = useState(false);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const [disableButton, setDisableButton] = useState(true)
  const [message, setMessage] = useState('')
  const [showInvalidFileMessage, setShowInvalidFileMessage] = useState(false)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { WizardDispatch, WizardActions } = useContext(WizardContext)

  const {
    healthStatementSentData: {
      resultData: { antecedentes },
    },
    HealthStatementSentDispatch,
    HealthStatementSentActions,
  } = useContext(HealthStatementSentContext);
  const { stepperStepsData, stepperStepsDispatch, StepperStepsActions } = useContext(
    StepperStepsContext
  )
  useEffect(() => {
    if (attachedFiles && attachedFiles.length !== 0) {
      setDisableButton(false)
    } else {
      setDisableButton(true)
    }
  }, [antecedentes, attachedFiles])

  function attachedFilesOnChangeHandler(e) {
    const { files } = e.target;
    const validFiles = Object.keys(files).filter((item) =>
      validateFile(files[item])
    );
    const invalidFiles = Object.keys(files).filter(
      (item) => !validateFile(files[item])
    );
    setShowInvalidFileMessage(invalidFiles.length > 0)

    const entityFiles = [
      ...attachedFiles,
      ...validFiles.map((item) => {
        files[item].new = true
        return files[item]
      }).slice(0, 10),
    ];
    setAttachedFiles(entityFiles);
  }

  /**
   * @description MouseLeave Event for tooltipComponent
   * @param {boolean} flag
   */
  function handlerTooltipOnHover(flag) {
    setShowTooltip(flag);
  }

  /**
   * @description Remove an item from FilesList
   * @param {int} index
   */
  function removeFileFromList(index) {
    const auxList = attachedFiles.filter((item, i) => i !== index);
    setAttachedFiles(auxList);
  }

  /**
   * @description handle send DS
   */
  async function handleSendDS() {
    HealthStatementSentDispatch(HealthStatementSentActions.setLoading(true))
    const archives = await fileToBase64(attachedFiles)

    const archivesSent = await archives.map(async (file) => {
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
        nombreArchivo: file.nombreArchivo,
        binario: file.binario,
        tipoMIME: file.tipoMIME,
      }
    
      const result = await HealthStatementSentService.sendDocuments(body).then((response) => {
        if (response.httpCode === 200) {
          return true
        } else {
          return false
        }
      }).catch(() => {
        return false
      })
      return result
    })
    const archivesResponse = await Promise.all(archivesSent)

    let archivesSuccess = true
    // eslint-disable-next-line array-callback-return
    archivesResponse.map((archive) => {
      if (!archive) {
        archivesSuccess = false
      }
    })
    if (archivesSuccess) {
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
        resultado: 3,
        pin: null,
        acepta: null,
      }
      HealthStatementSentService.finishDS(body).then((response) => {
        if (response.httpCode === 200) {
          WizardDispatch(WizardActions.setStatus('sent'))
          stepperStepsDispatch(StepperStepsActions.setResult(''))
          stepperStepsDispatch(StepperStepsActions.setStep({index:1, title:'Declaración de Salud'}))
          HealthStatementSentDispatch(HealthStatementSentActions.setChangedEmail({changed:true}))
        } else {
          setShowErrorMessage(true)
          setMessage('No se ha podido guardar el estado del proceso, vuelve a intentarlo.')
        }
      }).catch(() => {
        setShowErrorMessage(true)
        setMessage('No se ha podido guardar el estado del proceso, vuelve a intentarlo.')
      })
    } else {
      setShowErrorMessage(true)
      setMessage('No se ha podido subir los documentos, vuelve a intentarlo.')
    }
    
    HealthStatementSentDispatch(HealthStatementSentActions.setLoading(false))
  }

  return (
    <div className="result-revision">
      <div className="result-revision__message">
        Tu{" "}
        <span className="result-revision__message--bold">
          Declaración de Salud
        </span>{" "}
        se encuentra en{" "}
        <span className="result-revision__message--status">Revisión</span>.
        Debes{" "}
        <span className="result-revision__message--status">
          adjuntar los siguientes antecedentes:
        </span>
      </div>
      <div className="result-revision__background">
        {antecedentes &&
          antecedentes.map((antecedente, i) => {
            return (
              <div key={i} className="result-revision__antecedent">• {antecedente}</div>
            );
          })}
      </div>
      <div className="result-revision__attachment">
        <div className="attachment__list">
          {attachedFiles.map((item, i) => (
            <div  key={i} className="attachment__list-item">
              <em className="icon-archivo" />
              <span>{item.name.split(".")[0]}</span>
              <IconButton
                text=""
                icon="eliminar"
                action={() => removeFileFromList(i)}
              />
            </div>
          ))}
        </div>
        <div className="attachment__actions">
          <InputFile
            onChange={(e) => attachedFilesOnChangeHandler(e)}
            mimetypes={[".jpg", ".jpeg", "application/pdf"]}
            value={attachedFiles && attachedFiles.map((item) => item).join(",")}
          />
          <div className="attachment__tooltip">
            <Tooltip visible={showTooltip} align="left">
              El formato debe ser JPG, GIF o PDF, con un tamaño máximo de 2MB.
            </Tooltip>
            <em
              className="icon-info-3"
              onMouseOver={() => handlerTooltipOnHover(true)}
              onMouseOut={() => handlerTooltipOnHover(false)}
              onBlur={() => handlerTooltipOnHover(false)}
              onFocus={() => handlerTooltipOnHover(true)}
            />
          </div>
        </div>
      </div>
      <div className="result-revision__buttons">
        <Button
          text="Volver a enviar Declaración de Salud"
          action={handleSendDS}
          disabled={disableButton}
        />
      </div>

      <ErrorMessage
        isShowing={showErrorMessage}
        action={() => setShowErrorMessage(false)}
      >
        <div className="pin-validation__alert">
            {message}
        </div>
      </ErrorMessage>
      <InfoMessage
            buttonText="Volver a intentar"
            action={() => setShowInvalidFileMessage(false)}
            isShowing={showInvalidFileMessage}
          >
            Uno o más de los archivos adjuntados tienen un formato inválido, los
            formatos permitidos son JPG, GIF o PDF, con un tamaño máximo de 2MB.
          </InfoMessage>
    </div>
  );
};

export default ResultRevision;
