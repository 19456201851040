import './benef-ident-edit-unalterable.scss'
import React, { useEffect, useState, useContext } from 'react'
import BenefBox from './benef-ident-unalterable'
import _ from 'lodash'
import origin from '@utils/origin-invitation'
import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { WizardContext } from '@commons/steppers/wizard/'
import { StepperStepsContext } from '@commons/steppers/steps'
import { rutSchema, heightSchema, weightSchema } from '@utils/fields-validators'


const EditBeneficiary = (props) => {
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [editable, setEditable] = useState(false)
  const [beneficiaries, setBeneficiaries] = useState(props.beneficiary)
  const [resetData, setResetData] = useState(false)

  const { originInvitation } = stepperStepsData

  useEffect(() => {
    setBeneficiaries(props.beneficiary)
  },[props.beneficiary])

  const buildSchema = () => {
    let schema = yup.object().shape(
      beneficiaries
        .map((item) => {
          let schema = {}
          if(originInvitation === origin.cotizadorNuevo || originInvitation === origin.cotizadorActual) {
            if (item.id !== 0) {
              schema = {
                [`${item.id}_relation`]: yup.number().min(1, 'Debe seleccionar la relación').required('Debe seleccionar la relación'),
                [`${item.id}_other`]: yup.string().required('Debe ingresar otro tipo relación'),
                [`${item.id}_type`]: yup.number().min(1, 'Debe seleccionar el tipo de carga').required('Debe seleccionar el tipo de carga'),
              }
            }
            
          }
         
          schema = {
            ...schema,
            [`${item.id}_names`]: yup.string().required('Debe ingresar sus nombres'),
            [`${item.id}_paternalSurname`]: yup.string().required('Debe ingresar su apellido paterno'),
            [`${item.id}_maternalSurname`]: yup.string().required('Debe ingresar su apellido materno'),
            [`${item.id}_rut`]: rutSchema,
            [`${item.id}_weight`]: weightSchema,
            [`${item.id}_height`]: heightSchema,
          }
          return schema
        })
        .reduce((ini, curr) => ({ ...ini, ...curr }), {})
    )

    return schema
  }
  
  const { register, errors, triggerValidation, getValues,} = useForm({validationSchema: buildSchema(),})

  async function checkAction() {
    const validForm = await triggerValidation()
    props.isValid(validForm)
    if (validForm) {
      const values = getValues()
      const newBeneficiaries = [...props.beneficiary].map((item) => ({
        ...item,
        names: values[`${item.id}_names`],
        paternalSurname: values[`${item.id}_paternalSurname`],
        maternalSurname: values[`${item.id}_maternalSurname`],
        rut: values[`${item.id}_rut`].replace(/[.-\s]/g,''),
        relation: values[`${item.id}_relation`],
        weight: values[`${item.id}_weight`],
        height: parseFloat(values[`${item.id}_height`]).toString(),
        other: values[`${item.id}_other`],
        type: values[`${item.id}_type`],
      }))
      WizardDispatch(WizardActions.setBeneficiary(newBeneficiaries))
      setEditable(!editable)
    }
    setResetData(false)
  }

  return (
    <div className="benef-ident-unalterable">
      <div className="benef-ident-unalterable__beneficiary">
        <div className="benef-ident-unalterable__container">
          <div className="benef-ident-unalterable__title benef-ident-unalterable--edit-mode">
            Cargas legales y médicas
          </div>
        </div>
        <div className="benef-ident-manager-unalterable ">
          {_.size(beneficiaries) ? (
            <div className="benef-ident-manager-unalterable__list">
              {beneficiaries.map((item, i) => (
                <BenefBox
                  index={i + 1}
                  key={item.id}
                  editable={true}
                  register={register}
                  errors={errors}
                  reset={resetData}
                  validate={checkAction}
                >
                  {item}
                </BenefBox>
              ))}

            </div>
          ) : null}

        </div>
      </div>
    </div>
  );
}

export default EditBeneficiary
