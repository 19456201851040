import './summary-questions.scss'
import React, { useState, useContext, useEffect } from 'react'
import HealthStatementQuestion from '@components/health-statement/elements/health-statement-question'
import { WizardContext, WizardService } from '@commons/steppers/wizard'
import { SummaryHealthStatementContext } from '@components/summary-health-statement'
import { StepperStepsContext, StepperStepsService } from '@commons/steppers/steps'
import ErrorMessage from '@commons/messages/error/error-message'
import SpinnerLoader from '@commons/loaders/spinner-loader'
import Checkbox from '@commons/inputs/checkbox'
import Button from '@commons/buttons/button'
import _ from 'lodash'
import HealthStatementUneditable from '@components/health-statement/elements/heath-statement-uneditable'
import moment from 'moment'
import AddScriptRut from '@utils/tools/convertRut'

const SumaryQuestions = () => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(
    WizardContext
  )

  const {
    wizard,
    summaryHealthStatementData,
    SummaryHealthStatementDispatch,
    SummaryHealthStatementActions,
  } = useContext(SummaryHealthStatementContext)
  const { stepperStepsData } = useContext(StepperStepsContext)
  const [questions, setQuestions] = useState(null)
  const [edit, setEdit] = useState(false)
  const [questionToEdit, setQuestionToEdit] = useState(null)
  const [indexQuestionToEdit, setIndexQuestionToEdit] = useState(null)
  const [showNext, setShowNext] = useState(true)
  const [editing, setEditing] = useState(false)
  const [error, setError] = useState(true)
  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const { confirm } = summaryHealthStatementData
  const [message, setMessage] = useState('')

  const [wizard2Active, setWizard2Active] = useState([]);
  const [wizard2Inactive, setWizard2Inactive] = useState([]);


  useEffect(() => {
    WizardDispatch(WizardActions.setQuestionsList([]))
    WizardDispatch(WizardActions.setBackupQuestionsList([]))
    fetchQuestions()
    fetchDiagnostics()
  }, [])

  useEffect(() => {
    let wizard2ActiveU = []
    wizardData.questions.map(i => {
      if (i.editable) {
        wizard2ActiveU.push(i)
      }
    })

    setWizard2Active(wizard2ActiveU)

    if (wizard2ActiveU !== questions) {
      setQuestions(wizard2ActiveU)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [wizardData.questions])

  function handleEditQuestion(q, index) {
    setIndexQuestionToEdit(index)
    setQuestionToEdit(q)
    setShowNext(false)
    setEdit(true)
    setEditing(true)
  }

  /**
   * @description Fetch all de DS sugegsted diagnostics and save them on the wizard
   */
  const fetchDiagnostics = () => {
    WizardService.getSuggestedDiagnostics().then((response) => {
      let diagnosticsQuestionAux = []
      if (response.httpCode === 200) {
        diagnosticsQuestionAux = response.data
      }
      WizardDispatch(
        WizardActions.setSuggestedDiagnostics(diagnosticsQuestionAux)
      )
    }).catch(e => { console.log(e) })
  }

  /**
   * @description Fetch all de DS questions and save them on the wizard
   */
  const fetchQuestions = () => {
    setShowNext(false)
    let questionList = []
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut
    }
    WizardService.getSummaryDSQuestions(body)
      .then((response) => {
        if (response.httpCode === 200) {
          if (_.size(response.data)) {
            const empty_diagnostic = [
              {
                rut: '',
                diagnostico: { codigo: '', diagnostico: '' },
                fecha: '',
                intervencionQuirurgica: '',
              },
            ]

            response.data.map((q) => {
              const questionAux = {
                number: typeof q.id === 'string' ? parseInt(q.id) : q.id,
                question: q.pregunta,
                example: q.ejemplo,
                answer: q.respuesta,
                diagnostics:
                  q.diagnosticos === null || q.diagnosticos.length === 0 ? empty_diagnostic : q.diagnosticos.map(d => {
                    return {
                      ...d,
                    }
                  }),
                editable: q.editable,
              }
              return questionList.push(questionAux)
            })
            WizardDispatch(WizardActions.setQuestionsList(questionList))
            WizardDispatch(WizardActions.setBackupQuestionsList(JSON.parse(JSON.stringify(questionList))))
            setShowNext(true)
            setError(false)
            setShowErrorMessage(false)
          }
        } else {
          WizardDispatch(WizardActions.setQuestionsList([]))
          WizardDispatch(WizardActions.setBackupQuestionsList([]))
          setError(true)
          setShowErrorMessage(true)
        }
      })
      .catch(() => {
        WizardDispatch(WizardActions.setQuestionsList([]))
        WizardDispatch(WizardActions.setBackupQuestionsList([]))
        setError(true)
        setShowErrorMessage(true)
      })
  }

  function handleConfirm() {
    SummaryHealthStatementDispatch(
      SummaryHealthStatementActions.setConfirmedQuestions(
        !summaryHealthStatementData.confirm
      )
    )
  }

  function handleShowConfirmationMessage() {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: AddScriptRut(stepperStepsData.userInformation.rut),
      etapa: 'resumenDeclaracion',
      subetapa: '',
      fechaRegistro: moment().format('DD-MM-YYYY')
    }
    StepperStepsService.sendStep(body).then((response) => {
      if (response.httpCode === 200) {
        wizard.next()
      } else {
        setShowErrorMessage(true)
        setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.')
        setError(true)
      }
    }).catch(() => {
      setShowErrorMessage(true)
      setMessage('No se ha podido avanzar al siguiente paso, vuelve a intentarlo más tarde.')
      setError(true)
    })
  }

  /**
   * @description Function that renders the list of the answered questions
   */
  function showList() {
    return (
      _.size(questions) > 0 &&
      <div className="d-flex flex-wrap justify-content-start">
        {questions.map((q, index) => {
          // eslint-disable-next-line prefer-const
          let answer = q.answer === true ? 'si' : 'no'
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index} className={`sum-questions__card ${q.editable === false && "d-none"}`}>
              <div className="sum-questions__switch-wrapper">
                <div className={`sum-questions__switch--${answer}`}>
                  <div className={`sum-questions__number--${answer}`}>
                    {index + 1}
                  </div>
                  <div className={`sum-questions__answer-boolean--${answer}`}>
                    {answer}
                  </div>
                </div>
              </div>
              <div className="sum-questions__content">
                <div className="sum-questions__text">{q.question}</div>
              </div>
              <button
                type="button"
                className="sum-questions__button"
                onClick={() => handleEditQuestion(q, index)}
              >
                <em className="icon-editar" />
              </button>
            </div>
          )
        })}
      </div>
    )
  }

  /**
   * @description Function that renders question you want to edit
   */
  function showQuestion() {
    return (
      <div className="d-flex flex-wrap justify-content-center">
        <div className="sum-questions__container">
          <div className="sum-questions__title-box">
            <div
              className={
                questionToEdit.answer === true
                  ? 'sum-questions__detail-number--si'
                  : 'sum-questions__detail-number--no'
              }
            >
              {indexQuestionToEdit + 1}
            </div>
            <div className="sum-questions__title">
              <span> {questionToEdit.question}</span>
            </div>
          </div>

          {questionToEdit.editable ? (
            <HealthStatementQuestion
              index={1}
              num={questionToEdit.number}
              question={questionToEdit.question}
              example={questionToEdit.example}
              openQuestion
              isSummary
              isEditing
              cancelAction={() => {
                setEdit(false)
                setShowNext(true)
              }}
            />
          ) : (
            <HealthStatementUneditable
              openQuestion
              num={questionToEdit.number}
              isSummary
              cancelAction={() => {
                setEdit(false)
                setShowNext(true)
              }}
            />
          )}
        </div>
      </div>
    )
  }

  /**
   * @description handle onClick for close error message
   */
  function closeMsg() {
    setShowErrorMessage(false)
    setMessage('')
  }

  return (
    <div className="sum-questions">
      <div className="sum-questions__message">
        {error ? (
          <>
            No se pudo cargar las preguntas correctamente,
            <br /> vuelve a intentarlo mas tarde.
          </>
        ) : (
          <>
            Este es el resumen de tu información en la Declaración de Salud,
            <br />
            puedes revisar, editar y confirmar.
          </>
        )}
      </div>
      <div className="w-100 text-center">
        <SpinnerLoader show={!editing && !showNext && !error} />
      </div>
      <div className="sum-questions__questions">
        {!error && (
          <div className="sum-questions__subtitle">Declaración de Salud</div>
        )}
        <div className="d-flex flex-wrap justify-content-center">
          {!edit ? showList() : showQuestion()}
        </div>
      </div>
      {showNext && (
        <>
          <div className="sum-questions__confirm">
            <Checkbox
              name="test"
              value={confirm}
              text="He revisado la declaración de salud en forma completa, no tengo nada más que declarar"
              clickMethod={handleConfirm}
            />
          </div>
          <div className="sum-questions__buttons">
            <Button
              text="Firmar Declaración de Salud"
              action={handleShowConfirmationMessage}
              disabled={!confirm}
            />
          </div>
        </>
      )}
      <ErrorMessage isShowing={showErrorMessage} action={closeMsg}>
        No se pudo cargar las preguntas correctamente, vuelve a intentarlo más
        tarde.
      </ErrorMessage>
    </div>
  )
}

export default SumaryQuestions
