const COMPLETE_SUB_STEP = 'COMPLETE_SUB_STEP'
const ADD_STEPS = 'ADD_STEPS'
const REFRESH_PROGRESS = 'REFRESH_PROGRESS'
const ADD_WIZARD = 'ADD_WIZARD'
const SET_MIXED_DATA = 'SET_MIXED_DATA'
const RESET = 'RESET'
const ADD_STEP = 'ADD_STEP'
const SET_URL_ISAPRE = 'SET_URL_ISAPRE'
const SET_AUTHENTICATION_PAGE = 'SET_AUTHENTICATION_PAGE'
const SET_IS_TIME_OUT = 'SET_IS_TIME_OUT'

const SET_CONTRIBUTOR = 'SET_CONTRIBUTOR'
const SAVE_CONTRIBUTOR = 'SAVE_CONTRIBUTOR'
const LOADING_CONTRIBUTOR_DATA = 'LOADING_CONTRIBUTOR_DATA'

const ADD_BENEFICIARY = 'ADD_BENEFICIARY'
const REMOVE_BENEFICIARY = 'REMOVE_BENEFICIARY'
const SET_BENEFICIARY = 'SET_BENEFICIARY'
const SAVE_BENEFICIARY = 'SAVE_BENEFICIARY'
const LOADING_BENEFICIARY_DATA = 'LOADING_BENEFICIARY_DATA'

const BACKUP_QUESTIONS = 'BACKUP_QUESTIONS'
const SET_BACKUP_QUESTIONS_LIST = 'SET_BACKUP_QUESTIONS_LIST'
const FILL_QUESTIONS_LIST = 'FILL_QUESTIONS_LIST'
const FILL_SUGGESTED_DIAGNOSTICS = 'FILL_SUGGESTED_DIAGNOSTICS'
const SET_NEXT_QUESTION_TO_ANSWER = 'SET_NEXT_QUESTION_TO_ANSWER'
const SET_NO_ANSWER = 'SET_NO_ANSWER'
const SET_YES_ANSWER = 'SET_YES_ANSWER'
const SET_FORM_YES_ANSWER = 'SET_FORM_YES_ANSWER'
const ADD_DIAGNOSTIC = 'ADD_DIAGNOSTIC'
const REMOVE_DIAGNOSTIC = 'REMOVE_DIAGNOSTIC'
const SET_DATA_DIAGNOSTIC = 'SET_DATA_DIAGNOSTIC'
const FILL_QUESTIONS_ANSWERED = 'FILL_QUESTIONS_ANSWERED'

const SET_PATPAC_SIGN = 'SET_PATPAC_SIGN'

const SET_STATUS = 'SET_STATUS'

const SET_ACTUAL_STEP = 'SET_ACTUAL_STEP'
const SET_SUBSTEP = 'SET_SUBSTEP'


const SET_BACKGROUD_CONTRACTUAL_DOCUMENTS = 'SET_BACKGROUD_CONTRACTUAL_DOCUMENTS'
const SET_REGIONS = 'SET_REGIONS'
const SET_CITIES = 'SET_CITIES'
const SET_COMMUNES = 'SET_COMMUNES'
const SET_AFPS = 'SET_AFPS'
const SET_DATA_BACKGROUND_CONTRACTUAL_DOCUMENTS = 'SET_DATA_BACKGROUND_CONTRACTUAL_DOCUMENTS'
const SET_DATA_PAID_ENTITY = 'SET_DATA_PAID_ENTITY'

function addSteps(steps) {
  return {type: ADD_STEPS,steps}
}
function setMixedData(data) {
  return {type: SET_MIXED_DATA, data,}
}
function setCompleteSubStep(step) {
  return {type: COMPLETE_SUB_STEP, step,}
}
function refreshProgress() {
  return {type: REFRESH_PROGRESS,}
}
function addWizard(wizard) {
  return {type: ADD_WIZARD,  wizard,}
}
function reset() {
  return {type: RESET,}
}
function addStep(step) {
  return { type: ADD_STEP , step,}
}
function setURL(url) {
  return { type: SET_URL_ISAPRE , url,}
}
function setAuthenticationPage(authPage) {
  return {
    type: SET_AUTHENTICATION_PAGE,
    authPage,
  }
}
function setIsTimeOut(payload) {
  return {
    type: SET_IS_TIME_OUT,
    payload,
  }
}

// CONTRIBUTOR ACTIONS
function setContributor(contributor) {
  return { type: SET_CONTRIBUTOR, contributor }
}
function saveContributor(contributor) {
  return { type: SAVE_CONTRIBUTOR, contributor }
}
function setContributorLoading(flag) {
  return {type: LOADING_CONTRIBUTOR_DATA,flag,}
}

// BENEFICIARIES ACTIONS
function setBeneficiary(beneficiary) {
  return { type: SET_BENEFICIARY, beneficiary }
}
function addBeneficiary() {
  return {type: ADD_BENEFICIARY}
}
function removeBeneficiary(benefID) {
  return {type: REMOVE_BENEFICIARY, benefID}
}
function saveBeneficiary(beneficiary) {
  return { type: SAVE_BENEFICIARY, beneficiary }
}
function setBeneficiaryLoading(flag) {
  return {type: LOADING_BENEFICIARY_DATA,flag,}
}

// HEALTH STATEMENT ACTIONS
function backupQuestions() {
  return { type: BACKUP_QUESTIONS }
}
function setBackupQuestionsList(collection) {
  return { type: SET_BACKUP_QUESTIONS_LIST, collection }
}
function setQuestionsList(collection) {
  return { type: FILL_QUESTIONS_LIST, collection }
}
function setNoAnswer(numQuestion) {
  return { type: SET_NO_ANSWER, numQuestion}
}
function setNextQuestionToAnswer(nextQtoA) {
  return { type: SET_NEXT_QUESTION_TO_ANSWER, nextQtoA }
}
function setYesAnswer(numQuestion) {
  return { type: SET_YES_ANSWER, numQuestion}
}
function setFormYesAnswer(answer) {
  return { type: SET_FORM_YES_ANSWER, answer}
}
function setSuggestedDiagnostics(collection) {
  return { type: FILL_SUGGESTED_DIAGNOSTICS, collection }
}
function addDiagnostic(numQuestion) {
  return { type: ADD_DIAGNOSTIC, numQuestion }
}
function removeDiagnostic(numQuestion, indexRemove){
  return { type: REMOVE_DIAGNOSTIC, data: { numQuestion, indexRemove } }
}
function setDiagnosticData(numQuestion, diagnostic, index) {
  return { type: SET_DATA_DIAGNOSTIC, data: { numQuestion, diagnostic, index } }
}
function setQuestionsAnswered(collection) {
  return { type: FILL_QUESTIONS_ANSWERED, data: { collection } }
}

function setPatPacSign(data) {
  return { type: SET_PATPAC_SIGN, data }
}

function setStatus(payload) {
  return { type: SET_STATUS, payload }
}

function setActualStep(payload) {
  return { type: SET_ACTUAL_STEP, payload }
}

function setSubStep(payload) {
  return { type: SET_SUBSTEP, payload }
}

// BAKCGROUND CONTRACTUAL DOCUMENTS

function setBackgroundContractualDocuments(payload) {
  return { type: SET_BACKGROUD_CONTRACTUAL_DOCUMENTS, payload }
}

function setRegions(payload) {
  return { type: SET_REGIONS, payload }
}

function setCities(payload) {
  return { type: SET_CITIES, payload }
}

function setCommunes(payload) {
  return { type: SET_COMMUNES, payload }
}

function setAFPs(payload) {
  return { type: SET_AFPS, payload }
}

function setDataBackgroudContractualDocuments(payload) {
  return { type: SET_DATA_BACKGROUND_CONTRACTUAL_DOCUMENTS, payload }
}

function setDataPaidEntity(payload) {
  return { type: SET_DATA_PAID_ENTITY, payload }
}

export {
  COMPLETE_SUB_STEP,
  ADD_STEPS,
  REFRESH_PROGRESS,
  ADD_WIZARD,
  SET_MIXED_DATA,
  RESET,
  ADD_STEP,
  SET_URL_ISAPRE,
  SET_AUTHENTICATION_PAGE,
  SET_IS_TIME_OUT,
  addWizard,
  addSteps,
  setCompleteSubStep,
  refreshProgress,
  setMixedData,
  reset,
  addStep,
  setURL,
  setAuthenticationPage,
  setIsTimeOut,

  SET_CONTRIBUTOR,
  SAVE_CONTRIBUTOR,
  LOADING_CONTRIBUTOR_DATA,
  setContributor,
  saveContributor,
  setContributorLoading,

  ADD_BENEFICIARY,
  REMOVE_BENEFICIARY,
  SET_BENEFICIARY,
  SAVE_BENEFICIARY,
  LOADING_BENEFICIARY_DATA,
  addBeneficiary,
  removeBeneficiary,
  setBeneficiary,
  saveBeneficiary,
  setBeneficiaryLoading,

  BACKUP_QUESTIONS,
  SET_BACKUP_QUESTIONS_LIST,
  FILL_QUESTIONS_LIST,
  FILL_SUGGESTED_DIAGNOSTICS,
  SET_NO_ANSWER,
  SET_NEXT_QUESTION_TO_ANSWER,
  SET_YES_ANSWER,
  SET_FORM_YES_ANSWER,
  ADD_DIAGNOSTIC,
  REMOVE_DIAGNOSTIC,
  SET_DATA_DIAGNOSTIC,
  FILL_QUESTIONS_ANSWERED,
  backupQuestions,
  setBackupQuestionsList,
  setQuestionsList,
  setSuggestedDiagnostics,
  setNextQuestionToAnswer,
  setNoAnswer,
  setYesAnswer,
  setFormYesAnswer,
  addDiagnostic,
  removeDiagnostic,
  setDiagnosticData,
  setQuestionsAnswered,

  SET_PATPAC_SIGN,
  setPatPacSign,

  SET_STATUS,
  setStatus,

  SET_ACTUAL_STEP,
  setActualStep,
  SET_SUBSTEP,
  setSubStep,

  SET_BACKGROUD_CONTRACTUAL_DOCUMENTS,
  setBackgroundContractualDocuments,
  SET_REGIONS,
  setRegions,
  SET_CITIES,
  setCities,
  SET_COMMUNES,
  setCommunes,
  SET_AFPS,
  setAFPs,
  SET_DATA_BACKGROUND_CONTRACTUAL_DOCUMENTS,
  setDataBackgroudContractualDocuments,
  SET_DATA_PAID_ENTITY,
  setDataPaidEntity,
}
