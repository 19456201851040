const COMPLETE_STEP = 'COMPLETE_STEP'
const ADD_STEPS = 'ADD_STEPS'
const START_PROCESS = 'START_PROCESS'
const SET_RESULT = 'SET_RESULT'
const SET_STEP = 'SET_STEP'
const SET_STAGE = 'SET_STAGE'
const SET_FLUX_ORIGIN = 'SET_FLUX_ORIGIN'
const SET_ORIGIN_INVITATION = 'SET_ORIGIN_INVITATION'


function setCompleteStep(step) {
  return {
    type: COMPLETE_STEP,
    step,
  }
}
function addSteps(steps) {
  return {
    type: ADD_STEPS,
    steps,
  }
}

function startProcess(userInformation) {
  localStorage.setItem('userInformation', JSON.stringify(userInformation))
  return {
    type: START_PROCESS,
    userInformation,
  }
}

function setResult(payload) {
  return { type: SET_RESULT, payload }
}

function setStep(payload) {
  return { type: SET_STEP, payload }
}

function setStage(payload) {
  return { type: SET_STAGE, payload }
}

function setFluxOrigin(payload) {
  localStorage.setItem('isAsisted', JSON.stringify(payload))
  return { type: SET_FLUX_ORIGIN, payload }
}

function setOriginInvitation(payload) {
  localStorage.setItem('originInvitation', JSON.stringify(payload))
  return { type: SET_ORIGIN_INVITATION, payload }
}

export {
  COMPLETE_STEP,
  ADD_STEPS,
  START_PROCESS,
  SET_RESULT,
  SET_STEP,
  SET_STAGE,
  SET_FLUX_ORIGIN,
  SET_ORIGIN_INVITATION,
  addSteps,
  setCompleteStep,
  startProcess,
  setResult,
  setStep,
  setStage,
  setFluxOrigin,
  setOriginInvitation,
}
