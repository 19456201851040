import React, { useEffect, useState } from 'react'
import TextGroup from '@commons/inputs/text-group'
import Button from '@commons/buttons/button'
import ConfirmMessage from '@commons/messages/confirm/confirm'
import ErrorMessage from '@commons/messages/error/error-message'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { fieldsErrorsMessages, rutSchema } from '@utils/fields-validators'
import Logo from '@assets/images/logo-nueva-masvida.svg'
import { StepperStepsService } from '@commons/steppers/steps'
import ReCAPTCHA from 'react-google-recaptcha'
import _ from 'lodash'
import { format } from 'rut.js'

const RecoverPassword = () => {
  // const params = new URLSearchParams(location.search)
  const [token, setToken] = useState('')

  const [disabledButton, setDisabledButton] = useState(true)
  const schema = yup.object().shape({
    rut: rutSchema,
    email: yup.string().matches(/([a-zA-Z0-9]+)([_.\-{1}])?([a-zA-Z0-9]+)@([a-zA-Z0-9.]+)([.])(cl|org|es|net|com|CL|ORG|NET|ES|COM+)$/g, { message: 'Formato inválido (Dominios aceptados: .cl .com .org .es .net)', excludeEmptyString: true }).required('Debe ingresar su correo electrónico'),
  })
  const [statePasswordSent, setStatePasswordSent] = useState(false)
  const [showError, setShowError] = useState(false)
  const { getValues, register, handleSubmit, errors, triggerValidation } = useForm({
    validationSchema: schema,
  })

  /** SideEffects */
  useEffect(() => {
    fieldsErrorsMessages()
  }, [])

  useEffect(() => {
    if (token && !_.size(errors)) {
      setDisabledButton(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, errors, getValues()])


  /**
   * @description Handler send email to email
   */
  function handleSendEmail() {
    const body = {
      rut: getValues().rut,
      correo: getValues().email,
    }
    StepperStepsService.recoverPassword(body).then((response) => {
      setStatePasswordSent(response.httpCode === 200)
      setShowError(response.httpCode !== 200)
    }).catch(() => {
      setShowError(true)
    })
  }
  /**
   * @description Handler close message
   */
  function handleCloseMessage() {
    setStatePasswordSent(false)
  }

  /**
   * @description Handler close error message
   */
  function handleCloseErrorMessage() {
    setShowError(false)
  }

  /**
   * @description Handler Verification Boot
   */
  function handleVerificationBoot(value) {
    triggerValidation()
    setToken(value)
  }

  return (
    <div className="recover-pass">
      <div className="recover-pass__logo">
        <img src={Logo} alt="" />
      </div>
      <h2 className="recover-pass__title">
        Bienvenido a nuestra Plataforma de Suscripción Electrónica
      </h2>
      <div className="recover-pass__message">
        Para retomar el proceso debes ingresar tu{' '}
        <span className="recover-pass__rut">RUT</span>, enviaremos un link de acceso a tu{' '}
        <span className="recover-pass__rut">email</span> y una nueva clave por{' '} 
        <span className="recover-pass__rut">SMS</span> a tú celular y a tu Correo. Si aún no has iniciado un proceso de suscripción
        llámanos al{' '}<span className="recover-pass__cellphone">600 6000 262</span>
      </div>
      <div className="recover-pass__form-wrapper">
        <div className="form-group">
          <TextGroup
            register={register}
            error={errors.rut}
            name="rut"
            label="RUT"
            value={format(getValues().rut)}
            setText={() => triggerValidation()}
          />
        </div>
        <div className="form-group">
          <TextGroup
            register={register}
            error={errors.email}
            name="email"
            label="Email"
            value={getValues().email}
            setText={() => triggerValidation()}
          />
        </div>
        <div className="form-group">
          <ReCAPTCHA
            sitekey={
              process.env.REACT_APP_SITE_KEY
                ? process.env.REACT_APP_SITE_KEY
                : ''
            }
            onChange={handleVerificationBoot}
          />
        </div>
        <div className="recover-pass__actions">
          <Button
            action={handleSubmit(handleSendEmail)}
            disabled={disabledButton}
            text="Enviar contraseña"
          />
        </div>
      </div>
      <ConfirmMessage
        cancel="Cerrar"
        isShowing={statePasswordSent}
        cancelAction={handleCloseMessage}
        iconColor="blue"
      >
        <div className="recover-pass__alert">
          Hemos enviado un link de acceso a tu<br/>
          <strong>email</strong> y una nueva contraseña a tu celular y a tu Correo.<br/>
          Revisa tu bandeja de entrada y tus <br/>
          mensajes <strong>SMS</strong> para continuar el proceso de <br/>
          <strong>Suscripción Electrónica</strong>
        </div>
      </ConfirmMessage>
      <ErrorMessage isShowing={showError} action={handleCloseErrorMessage}>
        <div className="recover-pass__alert">
          No se ha podido enviar la contraseña, porfavor, comunicate con tu
          asesor
        </div>
      </ErrorMessage>
    </div>
  )
}

export default RecoverPassword
