import './confirm.scss'
import React from 'react'
import Button from '@commons/buttons/button'
import PropTypes from 'prop-types'

const ConfirmMessage = ({
  children,
  confirm,
  cancel,
  isShowing,
  confirmAction,
  cancelAction,
  iconColor,
}) => {
  return (
    <>
    <div
      className={`confirm-message confirm-message--${
        isShowing ? 'show' : 'hide'
      }`}
    >
      <div className="confirm-message__container">
        <div
          className={`confirm-message__icon confirm-message__icon--cl-${iconColor}`}
        >
          <em className="icon-alerta" />
        </div>
        <div className="">{children}</div>
        <div className="confirm-message__buttons">
          {confirm ? (
            <Button
              text={confirm}
              action={confirmAction}
              cssClass="confirm-message__confirm"
            />
          ) : null}
          <Button text={cancel} action={cancelAction} />
        </div>
      </div>
    </div>
    {isShowing && <div className="confirm-message__content-locked"></div>}
    </>
  )
}
ConfirmMessage.propTypes = {
  children: PropTypes.node.isRequired,
  confirm: PropTypes.string,
  cancel: PropTypes.string.isRequired,
  isShowing: PropTypes.bool.isRequired,
  confirmAction: PropTypes.func,
  cancelAction: PropTypes.func.isRequired,
  iconColor: PropTypes.string,
}

ConfirmMessage.defaultProps = {
  iconColor: 'orange',
  confirm: undefined,
  confirmAction: () => {},
}

export default ConfirmMessage
