const FILL_DOCUMENT_LIST = 'FILL_DOCUMENT_LIST'
const SET_ALL_AS_READ = 'SET_ALL_AS_READ'
const SET_ALL_AS_SIGN = 'SET_ALL_AS_SIGN'


function setDocumentList(collection) {
  return { type: FILL_DOCUMENT_LIST, collection }
}

function setReadAll(allRead){
  return { type: SET_ALL_AS_READ, allRead}
}

function setSignAll(){
  return { type: SET_ALL_AS_SIGN}
}

export {
  FILL_DOCUMENT_LIST,
  SET_ALL_AS_READ,
  SET_ALL_AS_SIGN,
  setDocumentList,
  setReadAll,
  setSignAll,
}