import RequestService from '@core/request-service'

export default class WizardService extends RequestService {
  // api/v1/obtenerantecedentescotizante
  static getContributorBackground(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Obtenerantecedentescotizante' :
       'https://run.mocky.io/v3/310b3a3d-5885-43e5-91fa-da6d2c68de2e',
      // 'https://run.mocky.io/v3/dd370231-7de5-4e73-b0de-2625e177d004',
      // 'https://run.mocky.io/v3/24782f2f-6ea0-49c0-9c5f-db2924f17bf8',
      payload
    )
  }

  // api/guardarantecedentescotizante
  static saveContributorBackground(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Guardarantecedentescotizante' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }
  
  // api/v1/ObtenerBeneficiarios
  static getBeneficiaries(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/ObtenerBeneficiarios' :
      // 'https://run.mocky.io/v3/e7a25eed-64b2-4267-87b8-8494efa35c2b', // afiliacion asistida
      // 'https://run.mocky.io/v3/03bf7efe-83cf-4148-8eee-6bb4c2dd5fc9', // dashboard venta electronica asistida
       'https://run.mocky.io/v3/d2fea2cb-bf79-4d26-89ba-a6deaee2d72e', // cotizador no asitida
      payload
    )
  }

  static saveBeneficiariesCompensated(payload){
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v4/guardarBeneficiarioCompensado' :
      // 'https://run.mocky.io/v3/e7a25eed-64b2-4267-87b8-8494efa35c2b', // afiliacion asistida
      // 'https://run.mocky.io/v3/03bf7efe-83cf-4148-8eee-6bb4c2dd5fc9', // dashboard venta electronica asistida
       'https://run.mocky.io/v3/d2fea2cb-bf79-4d26-89ba-a6deaee2d72e', // cotizador no asitida
      payload
    )
  }

  // api/validarbeneficarios [OK RESPONSE]
  static verifyBeneficiaries(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/ValidarBeneficiarios' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  // api/validarbeneficarios [ERROR RESPONSE]
  // static verifyBeneficiaries(payload) {
  //   return super.post(
  //     'https://run.mocky.io/v3/af36f927-1f01-4690-84e0-963e50750eea',
  //     payload
  //   )
  // }

  // api/guardarbeneficarios
  static saveBeneficiaries(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/GuardarBeneficiario' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  static getBeneficiariesCompensated(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v4/obtenerDatosBeneficiarioCompensados' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }  

  // api/obtenerds
  static getDSQuestions(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/ObtenerDS' :
      'https://run.mocky.io/v3/7457c66b-56dc-49e2-aeb2-3b58cd2b9bc9',
      payload
    )
  }

  
  static saveDS(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/Guardardeclaracionsalud' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

 
  static getSummaryDSQuestions(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API )? '/api/v1/ObtenerDS' :
      'https://run.mocky.io/v3/7457c66b-56dc-49e2-aeb2-3b58cd2b9bc9',
      payload
    )
  }

  // api/diagnosticos
  
  static getSuggestedDiagnostics() {
    return super.get(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/diagnosticos' :
      'https://run.mocky.io/v3/1cfeb23d-e7a2-45df-a213-931f55368faf'
    )
  }

  // api/guardards
  static saveDSQuestionsAnswers(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/GuardarDS' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  // api/obtenerantecedentesdocumentoscontractuales
  static getBackgroundContractualDocuments(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/ObtenerAntecedentesDocumentosContractuales' :
      'https://run.mocky.io/v3/6d5f7549-a132-4de0-bc32-77d00e883c5a', // con datos
      // 'https://run.mocky.io/v3/47f3502f-635a-4b6a-aac4-8fd825123333', // sin datos
      payload
    )
  }

  // api/regiones
  static getRegions() {
    
    return super.get(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Regiones' :
      'https://run.mocky.io/v3/8cb8add7-57e7-4b52-bd52-b4d0c5cf8d68'
    )
  }

  // api/ciudades
  static getCities() {
    return super.get(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Ciudades' :
      'https://run.mocky.io/v3/fd280950-7837-4d88-9549-fc9bd2799af5'
    )
  }

  // api/comunas
  static getCommunes() {
    return super.get(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Comunas' :
      'https://run.mocky.io/v3/7ac08da5-6b9f-456b-96e6-7b1102d34e2d'
    )
  }

  // api/comunas
  static getAFPs() {
    return super.get(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/Afps' :
      'https://run.mocky.io/v3/6a2b7f83-6de6-41f0-b568-ae12f8a96469'
    )
  }

  static saveBackgroundContractualDocuments(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/GuardarAntecedentesDocumentosContractuales' :
      'https://run.mocky.io/v3/6a2b7f83-6de6-41f0-b568-ae12f8a96469',
      payload
    )
  }

  static verificationLogin(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/sysasapFront/v2/VerificacionLogin' :
      'https://run.mocky.io/v3/50103687-e013-4463-808e-8c6e2941f47d', //ok
      //  'https://run.mocky.io/v3/86b19d91-4402-4af0-9708-145dd32aaf17', //500
      // 'https://run.mocky.io/v3/b468ba9c-1c54-4847-a4c1-301177a3a16d', //400
      payload
    )
  }

  static confirmarConyugeCircular(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/confirmarConyugeCircular' :
      'https://www.mocky.io/v2/5eda7795330000181a79ec46',
      payload
    )
  }

  static obtenerConyugeCircular(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/obtenerConyugeCircular' :
      'https://run.mocky.io/v3/6227adfc-bebd-4cc6-aecc-745c81ceab37', //true
      //'https://run.mocky.io/v3/bee55389-9b15-491b-b3c2-c7e3be655df5', //false
      payload
    )
  }

  static obtenerDocsCargasMedicas(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v1/generaDocumentosCargaMedica' :
      'https://run.mocky.io/v3/6227adfc-bebd-4cc6-aecc-745c81ceab37', //true
      //'https://run.mocky.io/v3/bee55389-9b15-491b-b3c2-c7e3be655df5', //false
      payload
    )
  }

  static generarDocCargaMedica(payload) {
    return super.get(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? `/api/v1/generaDocumentosCargaMedica/${payload}` :
      'https://run.mocky.io/v3/1cfeb23d-e7a2-45df-a213-931f55368faf'
    )
  }

  static esCompensado(payload) {
    return super.post(
      ( process.env.NODE_ENV === 'production'  || process.env.REACT_APP_ENABLE_API ) ? '/api/v2/validarCompensado' :
      'https://run.mocky.io/v3/6227adfc-bebd-4cc6-aecc-745c81ceab37', //true
      //'https://run.mocky.io/v3/bee55389-9b15-491b-b3c2-c7e3be655df5', //false
      payload
    )
  }

}