import React, { useState, useContext, useEffect } from "react";
import Button from "@commons/buttons/button";
import SpinnerLoader from "@commons/loaders/spinner-loader";
import MessageManager from "@commons/message-manager/elements/message-manager";
import { WizardService } from "@commons/steppers/wizard";
import { StepperStepsContext } from "@commons/steppers/steps";
import './window-open.scss'

const WindowOpen = ({
  textButton,
  customActionClose,
  customActionOpen,
  urlIsapre,
  proceso,
  actionOtherFlux,
  messageOtherFlux,
  textOtherFlux,
  preLoadRetryCount,
  preLoadError,
  skipToFinal
}) => {
  const { stepperStepsData } = useContext(StepperStepsContext);
  const [error, setError] = useState(false);
  const [errorRetry, setErrorRetry] = useState(false)
  const [showButton, setShowButton] = useState(true);
  const [retryCount, setRetryCount] = useState(1)

  const handleAuthentication = () => {
    setError(false)
    var popup = window.open(`${urlIsapre}&proceso=${proceso}`);
    setShowButton(false)
    customActionOpen()
    var popupTick = setInterval(function () {
      if (popup.closed) {
        clearInterval(popupTick);

        const body = {
          identificador: stepperStepsData.userInformation.identificador,
          rut: stepperStepsData.userInformation.rut,
          proceso,
        };
        WizardService.verificationLogin(body).then((response) => {
          if (response.httpCode === 200) {
            
            if (response.data === undefined) {
              customActionClose(true);
            } else if (response.data.codigoError === "400") {
              customActionClose(false);
              setError(true);
              if (retryCount === 3) {
                setErrorRetry(true)
              }
            } else if (response.data.codigoError === "500") {
              customActionClose(false);
              setError(true);
              if (retryCount === 3) {
                setErrorRetry(true)
              }
            }
          } else {
            customActionClose(false);
            setError(true);
            if (retryCount === 3) {
              setErrorRetry(true)
            }
          }
        }).catch(() => {
          customActionClose(false);
          setError(true);
          if (retryCount === 3) {
            setErrorRetry(true)
          }
        });
      }
    }, 500);
  };

  const handleRetryAuthentication = () => {
    handleAuthentication();
    setRetryCount(retryCount + 1)
  };

  const handleOtherFlux = () => {
    actionOtherFlux()
  }

  useEffect(()=>{
    if(preLoadRetryCount > 0 && preLoadRetryCount !== 3){
      setError(true)
    }else if (preLoadRetryCount<= 0) {
      setErrorRetry(true)
      setError(true)
    }

  },[preLoadRetryCount])

  const errorMessage = () => (
    <div className="window-open__error">
      <MessageManager
        action={() => handleRetryAuthentication()}
        messages={
          <div className="window-open__message">
            Ha ocurrido un inconveniente con la autenticación de tu actual
            Isapre. 
            <div className="window-open__message--bold">Por favor reinténtalo</div>
            Tienes {preLoadError && preLoadRetryCount > 0 ? preLoadRetryCount : 4 - retryCount} intentos.
          </div>
        }
        textButton="Reintentar Autenticación"
        isShowing={error}
        iconColor="orange"
      />
    </div>
  )

  const errorRetryMessage = () => (
    <div className="window-open__error">
      <MessageManager
        action={() => handleOtherFlux()}
        messages={
          <div>
            {messageOtherFlux}
          </div>
        }
        textButton={textOtherFlux}
        isShowing={error}
        iconColor="orange"
      />
    </div>
  )

  return (
    <>
      {error ? (
       errorRetry ? errorRetryMessage() : errorMessage()
      ) : showButton ? (
        <Button text={textButton} action={skipToFinal ? handleOtherFlux : handleAuthentication} />
      ) : (
        <SpinnerLoader show={true} text="Esperando Autenticación" />
      )}
    </>
  );
};

export default WindowOpen;
