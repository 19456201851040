/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { WizardContext } from '@commons/steppers/wizard'
import Button from '@commons/buttons/button'

const SignDocumentsSuccess = () => {
  const history = useHistory()
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const { signPatPac } = wizardData

  /**
   * @description handle onClick for next step
   */
  function handleNextStep() {
    WizardDispatch(WizardActions.reset())
    history.push('/finalizar-proceso')
  }

  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])


  return (
    <div className="sign-docs-success">
      <h2 className="sign-docs-success__title">Firma de Documentos</h2>

          <div className="sign-docs-success__message">
            Tus <span className="sign-docs-success__highlight">Documentos</span> han sido<br/>firmados exitosamente
          </div>

          { signPatPac && 
            <div className="sign-docs-success__text">
              Informaste ser <b>independiente o voluntario</b>, por lo tanto<br/> 
              debes dirigirte a una de nuestras oficinas para firmar el<br/> 
              <b>mandato PAC o PAT</b> con el fin de que tu cotización sea<br/> 
              descontada a través de uno de estos medios.
            </div>
          }

          <div className="sign-docs-success__button">
            <Button
              text="Continuar"
              action={handleNextStep}
              cssClass="button__continue"
            />
          </div>

    </div>
  )
}
export default SignDocumentsSuccess
