import './input-password.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const InputPassword = ({
  name,
  value,
  placeholder,
  register,
  setPassword,
  error,
}) => {
  const [show, setShow] = useState(false)

  /**
   * Show/Hide password content
   */
  function togglePasswordHandler() {
    setShow(!show)
  }

  return (
    <>
      <div className="ns-password">
        <input
          placeholder={placeholder}
          type={`${show ? 'text' : 'password'}`}
          className="form-control ns-password__field"
          ref={register}
          value={value}
          name={name}
          onChange={(e) => setPassword(e.target.value)}
          required
        />
        <em
          className={`ns-password__toggle icon-${
            show ? 'ocultar' : 'mostrar'
          }-pass`}
          onClick={togglePasswordHandler}
          onKeyPress={togglePasswordHandler}
          role="button"
          tabIndex="0"
        />
      </div>
      {error && (
        <div className="ns-password__invalid-feedback">{error.message}</div>
      )}
    </>
  )
}

InputPassword.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setPassword: PropTypes.func,
  register: PropTypes.func.isRequired,
  error: PropTypes.shape({ message: PropTypes.string }),
}
InputPassword.defaultProps = {
  name: '',
  error: null,
  value: '',
  placeholder: 'Ingresa tu clave',
  setPassword: () => {},
}

export default InputPassword
