import './button.scss'
import React from 'react'
import PropTypes from 'prop-types'

const Button = ({ text, action, cssClass, disabled }) => (
  <button
    type="button"
    onClick={action}
    className={`ns-btn ${cssClass}`.trim()}
    disabled={disabled}
  >
    {text}
  </button>
)

Button.propTypes = {
  text: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  cssClass: PropTypes.string,
  disabled: PropTypes.bool,
}
Button.defaultProps = {
  cssClass: '',
  disabled: false,
}

export default Button
