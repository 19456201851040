import { isNumber } from "lodash"

export default function convertComma(cadena) {
    if (isNumber(cadena)) {
      if (cadena > 1) {
        return cadena.toString().replace('.',',')
      } else {
        return cadena.toString()
      }
    } else {
      return null
    }
  }