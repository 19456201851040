import {
  COMPLETE_STEP,
  ADD_STEPS,
  START_PROCESS,
  SET_RESULT,
  SET_STEP,
  SET_STAGE,
  SET_FLUX_ORIGIN,
  SET_ORIGIN_INVITATION,
} from './stepper-steps-actions'

export default function StepperStepsReducer(state, action) {
  switch (action.type) {
    case COMPLETE_STEP:
      return {
        ...state,
        step: action.step,
      }
    case ADD_STEPS:
      return {
        ...state,
        steps: action.steps,
      }
    case START_PROCESS:
      return {
        ...state,
        userInformation: action.userInformation,
      }
    case SET_RESULT:
      return {
        ...state,
        result: action.payload,
      }
    case SET_STAGE:
      return {
        ...state,
        stage: action.payload,
      }
    case SET_FLUX_ORIGIN:
      return {
        ...state,
        isAsisted: action.payload,
      }
    case SET_STEP:
      let auxSteps = state.steps.map((step,index) => {
        let response = JSON.parse(JSON.stringify(step))
        if (action.payload.index === index) {
          response.title = action.payload.title
          return response
        }
        return step
      })
      return {
        ...state,
        steps: auxSteps,
      }
    case SET_ORIGIN_INVITATION:
      return {
        ...state,
        originInvitation: action.payload,
      }
    default:
      return state
  }
}
