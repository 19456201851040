import React, { useReducer } from 'react'
import PropTypes from 'prop-types'
// import { WizardContext } from '@commons/steppers/wizard'
// import SignHealthStatementService from './sign-health-statement-service'
import SignHealthStatementReducer from './sign-health-statement-reducer'

import * as SignHealthStatementActions from './sign-health-statement-actions'

export const SignHealthStatementContext = React.createContext()

export const SignHealthStatementContextProvider = ({ children, wizard }) => {
  const [signHealthStatementData, SignHealthStatementDispatch] = useReducer(
    SignHealthStatementReducer,
    {}
  )

  return (
    <SignHealthStatementContext.Provider
      value={{
        wizard,
        signHealthStatementData,
        SignHealthStatementDispatch,
        SignHealthStatementActions,
      }}
    >
      {children}
    </SignHealthStatementContext.Provider>
  )
}

SignHealthStatementContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
