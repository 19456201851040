import './progress-bar.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ProgressBar from '@ramonak/react-progress-bar'

const NSProgressBar = ({ percent }) => (
  <div className="ns-progress-bar">
    <ProgressBar completed={percent} height="7px" />
  </div>
)
NSProgressBar.propTypes = {
  percent: PropTypes.number,
}
NSProgressBar.defaultProps = {
  percent: 0,
}
export default NSProgressBar
