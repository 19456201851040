import './questionnaire.scss'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Question from '@components/commons/boxes/question/question'
import Button from '@commons/buttons/button'
import SpinnerLoader from '@commons/loaders/spinner-loader'

const Questionnaire = ({ data, action, setSending }) => {
  const [answers, setAnswers] = useState(
    data.map((item) => ({ q: item.id, a: '' }))
  )

  const handleAnswers = (q, ans) => {
    const newAnswers = answers.map((item) =>
      item.q === q ? { ...item, a: ans } : item
    )
    setAnswers(newAnswers)
  }

  return (
    <>
      <div className="questionnaire row mb-4">
        {data.map((e, i) => (
          <div className="col-sm-12 col-lg-6 d-flex justify-content-center" key={e.id}>
            <Question
              num={i + 1}
              id={e.id}
              title={e.title}
              options={e.ans}
              setAnswer={handleAnswers}
            />
          </div>
        ))}
      </div>
      <div className="validate-identity__container">
        { setSending ? 
          <SpinnerLoader show={setSending} />
        :
          <Button
            text="Responder"
            action={() => action(answers)}
            cssClass={
              answers.filter((item) => item.a === '').length === 0
                ? 'questionnaire__button'
                : 'questionnaire__button-disabled'
            }
            disabled={answers.filter((item) => item.a === '').length !== 0}
          />
        }
      </div>
    </>
  )
}

Questionnaire.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      ans: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  action: PropTypes.func.isRequired,
}

export default Questionnaire
