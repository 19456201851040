import React, { useState, useContext } from "react";
import "./result-rejected.scss";
import Button from "@commons/buttons/button";
import ErrorMessage from '@commons/messages/error/error-message'
import { HealthStatementSentService } from "@components/health-statement-sent";
import { StepperStepsContext } from '@commons/steppers/steps'

const ResultRejected = () => {
  const [message, setMessage] = useState("");
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const { stepperStepsData } = useContext(StepperStepsContext)

  /**
   * @description Handle close sesion
   */
  function handleCloseSesion() {
    const body = {
      identificador: stepperStepsData.userInformation.identificador,
      rut: stepperStepsData.userInformation.rut,
      resultado: 4,
      pin: null,
      acepta: null,
    }
    HealthStatementSentService.finishDS(body)
      .then((response) => {
        if (response.httpCode === 200) {
          window.location.assign("https://www.nuevamasvida.cl/")
        } else {
          setShowErrorMessage(true);
          setMessage(
            "No se ha podido finalizar el proceso, vuelve a intentarlo."
          );
        }
      })
      .catch(() => {
        setShowErrorMessage(true);
        setMessage(
          "No se ha podido finalizar el proceso, vuelve a intentarlo."
        );
      });
  }

  return (
    <div className="result-rejected">
      <div className="result-rejected__message">
        Tu{" "}
        <span className="result-rejected__message--bold">
          Declaración de Salud
        </span>{" "}
        ha sido{" "}
        <span className="result-rejected__message--status">Rechazada</span>,
      </div>
      <div className="result-rejected__statement">
        Tu Declaración de Salud ha sido Rechazada En caso de rechazo, con la
        finalidad de ser aceptado en la Institución de Salud Previsional, el
        interesado podrá solicitar por escrito, con copia a la Intendencia de
        Fondos y Seguros Previsionales de Salud, que la ISAPRE le otorgue para
        las enfermedades preexistentes declaradas, por 18 meses más, la
        cobertura mínima legal. No obstante, tal solicitud, la ISAPRE igualmente
        puede mantener su negativa de aceptación.
      </div>
      <div className="result-rejected__buttons">
        <Button text="Finalizar Proceso" action={handleCloseSesion} />
      </div>
      <ErrorMessage
        isShowing={showErrorMessage}
        action={() => setShowErrorMessage(false)}
      >
        {message}
      </ErrorMessage>
    </div>
  );
};

export default ResultRejected;
