import React, { useEffect, useContext, useState} from "react";
import SignForm from "@components/sign-health-statement/elements/sign-form";
import { WizardContext } from '@commons/steppers/wizard'

const SignHealthStatement = () => {
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))
  const [loadView, setLoadView] = useState(true)
  const [textTitulo, setTextTitulo] = useState('Resumen Declaración de Salud')

  if((esCargaTitular) && (!tieneCargas) && (loadView)){
    setTextTitulo('Resumen Declaración de Renta')
    setLoadView(false)   
  }


  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  return (
    <div className="sign-health-statement">
      <div className="sign-health-statement__title">
        {textTitulo}
      </div>
      <SignForm />
    </div>
  )
}

export default SignHealthStatement;
