/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react'
import IdentityForm from '@components/validate-identity/elements/identity-form'
import Questionnaire from '@commons/boxes/questionnaire/questionnaire'
import {
  ValidateIdentityContext,
  ValidateIdentityService,
} from '@components/validate-identity/'
import { WizardContext } from '@commons/steppers/wizard'
import { StepperStepsContext } from '@commons/steppers/steps'
import ErrorMessage from '@commons/messages/error/error-message'
import Button from '@commons/buttons/button'
import InfoMessage from '@commons/messages/info/info-message'
import './questions-auth.scss'

const QuestionsAuth = () => {
  const { WizardDispatch, WizardActions } = useContext(WizardContext)
  const { stepperStepsData: {userInformation: {rut,identificador}} } = useContext(StepperStepsContext)

  const {
    validateIdentityData,
    ValidateIdentityDispatch,
    ValidateIdentityActions,
  } = useContext(ValidateIdentityContext)

  const { questions, interactiveQueryId, transactionKey } = validateIdentityData
  const [okQuestionnaire, setOk] = useState(false)
  const [messages, setMessages] = useState([])
  const [textButtonMessage, setTextButtonMessage] = useState('')
  const [actionMessage, setActionMessage] = useState()
  const [showMessageError, setShowMessageError] = useState(false)
  const [showError, setShowError] = useState(false)
  const [sending, setSending] = useState(false)

  /**
   * @description Handle submit Questionnaire event
   */
  function AnswerQuestionnaireHandler(answers) {
    const answersBody = answers.map((item) => {
      return {
        idPregunta: item.q,
        idRespuesta: item.a,
      }
    })
    const body = {
      preguntas: answersBody,
      interactiveQueryId,
      transactionKey,
      rut,
      identificador,
    }
    setSending(true)
    ValidateIdentityService.sendAnswers(body).then((response) => {
        if (response.httpCode === 200) {
          if (!response.data) {
            setOk(true)
            ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(2))
            ValidateIdentityDispatch(ValidateIdentityActions.setStateAuthentication(true))
            setSending(false)
          } else {
            if (!response.data.codigoError) {
              setOk(true)
              ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(2))
              ValidateIdentityDispatch(ValidateIdentityActions.setStateAuthentication(true))
              setSending(false)
            } else if (response.data.codigoError === "400") {
              setShowMessageError(true)
              ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(5))
              setSending(false)
            } else if (response.data.codigoError === "500") {
              setOk(true)
              ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(2))
              ValidateIdentityDispatch(ValidateIdentityActions.setStateAuthentication(true))
              setSending(false)
            } else {
              setShowMessageError(true)
              ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(5))
              setSending(false)
            }
          }
          
         
        } else {
          setShowMessageError(true)
          ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(5))
          setSending(false)
        }
    }).catch(() => {
      setShowError(true)
      setSending(false)
    })
  }

  /**
   * Side Effects
   */
  useEffect(() => {
    ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(0))
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  /**
   * @description Handler onClick clear message error and set initial message form
   */
  function returnForm() {
    setShowMessageError(false)
    ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(1))
    setOk(false)
  }

  /**
   * @description Handler onClick redirect to page isapre
   */
  function handleRetry() {
    setShowMessageError(false)
    ValidateIdentityDispatch(ValidateIdentityActions.setQuestionnaire({question: []}))
  }

  /**
   * @description Handler close error message
   */
  function closeMsg() {
    setShowError(false)
  }

  return !showMessageError ? (
    <div className="questions-auth">
      <div className="questions-auth__form-wrapper">
        <ErrorMessage isShowing={showError} action={closeMsg}>
          No hemos podido autenticar tu informacion. Vuelve a intentarlo.
        </ErrorMessage>
        {!validateIdentityData.loading && (
          <>
            {!questions || questions.length === 0  ? (
              <div className="questions-auth__container">
                <IdentityForm />
              </div>
            ):null}
            {questions && questions.length > 0 && !okQuestionnaire ? (
              <div className="questions-auth__container">
                <Questionnaire
                  data={questions}
                  action={AnswerQuestionnaireHandler}
                  setSending={sending}
                />
              </div>
            ):null}
          </>
        )}
      </div>
    </div>
  ) : (
    <div className="questions-auth">
      <div className="questions-auth__error">
        <InfoMessage isShowing={true} iconColor="orange" floating={false} locked={false}>
          <div className="questions-auth__statement">Lamentablemente tus respuestas son incorrectas.</div>
        </InfoMessage>
      </div>
      <div className="questions-auth__button">
          <Button text="Volver a intentar" action={handleRetry} />
        </div>
    </div>
  )
}
export default QuestionsAuth
