const steps = [
  '/informacion-usuario',
  '/declaracion-salud',
  '/firmar-documentos',
  '/finalizar-proceso',
]

const inicioSuscripcion = {
  url: `${steps[0]}/inicio-suscripcion`,
  substep: '',
}
const verificacionIdentidad = {
  url: `${steps[0]}/verificacion-identidad`,
  substep: '',
}
const renta = {
  url: `${steps[0]}/declaracion-renta`,
  substep: 'validacion',
}
const consideraciones = {
  url: `${steps[1]}/consideraciones-previas`,
  substep: 'renta',
}
const cotizante = {
  url: `${steps[1]}/antecedentes-cotizante`,
  substep: 'consideraciones',
}
const beneficiarios = {
  url: `${steps[1]}/identificacion-beneficiarios`,
  substep: 'cotizante',
}
const preguntas = {
  url: `${steps[1]}/preguntas`,
  substep: 'beneficiarios',
}
const documentosDs = {
  url: `${steps[1]}/documentos-cotizante`,
  substep: 'preguntas',
}
const resumenAntecedentes = {
  url: `${steps[1]}/resumen-antecedentes-beneficiarios`,
  substep: 'preguntas',
}
const resumenDeclaracion = {
  url: `${steps[1]}/resumen-preguntas`,
  substep: 'resumenAntecedentes',
}
const firma = {
  url: `${steps[1]}/firmar-declaracion-salud`,
  substep: 'resumenDeclaracion',
}
const resultado = {
  url: `${steps[1]}/resultado-declaracion-salud`,
  substep: 'firma',
}
const documentosContraactuales = {
  url: `${steps[1]}/documentos-contractuales`,
  substep: 'resultado',
}
const resumenCotizacion = {
  url: `${steps[1]}/resumen-cotizacion`,
  substep: 'documentosContractuales',
}
const firmaDocumentos = {
  url: `${steps[2]}/documentos`,
  substep: '',
}
const desafiliarIsapre = {
  url: `${steps[3]}/isapre`,
}
const desafiliarRevision = {
  url: `${steps[3]}/revision`,
  substep: 'firmaDocumentos',
}
const finalizarDesafiliacion = {
  url: `${steps[3]}/terminar-proceso-desafiliacion`,
}
const finalizar = {
  url: `${steps[3]}/terminar-proceso`,
}
export default {
  inicio: inicioSuscripcion,
  autenticacionManual: verificacionIdentidad,
  autenticacionRechazada: verificacionIdentidad,
  validacion: renta,
  renta: consideraciones,
  consideraciones: cotizante,
  cotizante: beneficiarios,
  beneficiarios: preguntas,
  preguntas: resumenAntecedentes,
  documentosDs: documentosDs,
  resumenAntecedentes: resumenDeclaracion,
  resumenDeclaracion: firma,
  firma: resultado,
  resultado: resultado,
  rechazado: resultado,
  rechazadoRestricciones: resultado,
  rechazadoAreaComercial: resultado,
  dsFinalizada: {
    asistida: firmaDocumentos,
    noAsistida: documentosContraactuales,
  },
  documentosContractuales: resumenCotizacion,
  resumenCotizacion: firmaDocumentos,
  firmaDocumentos: desafiliarIsapre,
  firmaIsapreRevision: desafiliarRevision,
  desafiliacionSysasap: finalizarDesafiliacion,
  firmaIsapre: finalizar,
};
