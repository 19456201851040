import './question-message.scss'
import React from 'react'
import Button from '@commons/buttons/button'
import PropTypes from 'prop-types'

const QuestionMessage = ({
  children,
  buttonText,
  action,
  isShowing,
  floating,
  cancelAction,
}) => (
  <>
  <div
    className={`question-message question-message--${
      isShowing ? 'show' : 'hide'
    } question-message--${floating ? 'floating' : 'not-floating'}`}
  >
    <div className="question-message__icon">
      <img
          alt="Solicitar Asistencia"
          src={`${process.env.PUBLIC_URL}/assets/images/asistencia-2.svg`}
      />
    </div>
    <div className="question-message__content">{children}</div>
    {buttonText ? (
      <div className="question-message__actions">
        { cancelAction ? <Button text="Cancelar" action={cancelAction} cssClass="ns-btn--inverted"/> : null }
        <Button text={buttonText} action={action}/>
      </div>
    ) : null}
  </div>
  {isShowing && <div className="question-message__content-locked"></div>}
  </>
)

QuestionMessage.propTypes = {
  children: PropTypes.node.isRequired,
  buttonText: PropTypes.string.isRequired,
  action: PropTypes.func.isRequired,
  isShowing: PropTypes.bool.isRequired,
  floating: PropTypes.bool,
  iconColor: PropTypes.string,
  disabled: PropTypes.bool,
}

QuestionMessage.defaultProps = {
  floating: true,
  iconColor: 'orange',
}

export default QuestionMessage
