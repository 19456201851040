/* eslint-disable no-nested-ternary */
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

// Contexts
import { WizardContext } from '@commons/steppers/wizard'
import { StepperStepsContext } from '@commons/steppers/steps'
import { ValidateIdentityContext } from '@components/validate-identity'

// Commons
import SpinnerLoader from '@commons/loaders/spinner-loader'
import Button from '@commons/buttons/button'

// Components
import AuthenticationPage from '@components/validate-identity/elements/authentication-page'
import QuestionsAuth from '@components/validate-identity/elements/questions-auth'
import CallCenterAuth from '@components/validate-identity/elements/call-center-auth'
import CallCenterMessage from '@components/validate-identity/elements/call-center-message'
import CallCenterMessageReject from '@components/validate-identity/elements/call-center-message-reject';

// Utils
import Stages from '@utils/stages';

const ValidateIdentity = () => {

  const history = useHistory()

  const { stepperStepsData } = useContext(StepperStepsContext)
  const { wizardData, WizardDispatch, WizardActions } = useContext(WizardContext)
  const { wizard, validateIdentityData, ValidateIdentityDispatch, ValidateIdentityActions } = useContext(ValidateIdentityContext)
  
  const {
    loading,
    isOkAuthentication,
    message,
    okQuestionnaire,
    questions,
  } = validateIdentityData;

  const { authenticationPage } = wizardData
  const { stage } = stepperStepsData;
  /**
   * Side Effects
   */
  useEffect(() => {
    WizardDispatch(WizardActions.refreshProgress())
  }, [WizardActions, WizardDispatch])

  useEffect(() => {

    if ( !isOkAuthentication && !loading && authenticationPage === '' ) {

      if ( stage === 'inicio' ) { 

        ValidateIdentityDispatch(ValidateIdentityActions.setNextStepMessage(8));

      } if ( stage === 'autenticacionManual' ) {

        WizardDispatch(WizardActions.setAuthenticationPage('callcenter-message'))

      } else if ( stage === 'autenticacionRechazada' ) {

        WizardDispatch(WizardActions.setAuthenticationPage('callcenter-rechazado'))
      }
    }
  }, [ stage ])

  /**
   * @description handle onClick for next step
   */
  function handleNextStep() {
    wizard.next()
  }

  /**
   * @description handle onClick for next step
   */
  function handleBackStartSuscription() {
    history.push(Stages['inicio'].url)
  }
  
  return (
    <div>
      <div className={`validate-identity ${questions && !okQuestionnaire && questions.length > 0 ? 'validate-identity--question-mode':''}`.trim()}>
        <h2 className="validate-identity__title">
          Autenticación {isOkAuthentication && 'Exitosa'}
        </h2>
        <div className="validate-identity__message">{message}</div>
        <div className="validate-identity__spinner">
          <SpinnerLoader show={loading} />
        </div>
        {!isOkAuthentication && !loading && authenticationPage !== '' ? (
          {
            
              'sysasap': <AuthenticationPage />,
              'previred-equifax': <QuestionsAuth />,
              'callcenter': <CallCenterAuth />,
              'callcenter-message': <CallCenterMessage />,
              'callcenter-rechazado': <CallCenterMessageReject />,

          }[ authenticationPage ]
        ) : !isOkAuthentication && stage === 'inicio' ?
          <div className="validate-identity__container">
            <Button text="Volver a Intentar" action={handleBackStartSuscription} />
          </div>
        :null}
      </div>
      {isOkAuthentication && (
        <div className="validate-identity__container">
          <Button text="Continuar" action={handleNextStep} />
        </div>
      )}
    </div>
  )
}

export default ValidateIdentity
