import React, { useReducer, useEffect, useContext } from 'react'
import PropTypes from 'prop-types'
import HealthStatementSentReducer from './health-statement-sent-reducer'
import { StepperStepsContext } from '@commons/steppers/steps'
import HealthStatementSentService from './health-statement-sent-service'
import * as HealthStatementSentActions from './health-statement-sent-actions'

export const HealthStatementSentContext = React.createContext()

export const HealthStatementSentContextProvider = ({ children, wizard }) => {
  const { stepperStepsData,stepperStepsDispatch, StepperStepsActions } = useContext(
    StepperStepsContext
  )
  const { userInformation: {correo} } = stepperStepsData


  const esCargaTitular = JSON.parse(localStorage.getItem('esCargaTitular'))
  const tieneCargas = JSON.parse(localStorage.getItem('tieneCargas'))
  let textTitulo = 'Resultado Evaluación de Declaración de Salud'
  let textTituloSend = 'Envío de Declaración de Salud'
  let textTituloEvaluacion = 'Salud'

  if((esCargaTitular) && (!tieneCargas)){
    textTitulo = 'Resultado Evaluación de Declaración de Renta'
    textTituloSend = 'Envío de Declaración de Renta'
    textTituloEvaluacion = 'Renta'
  }

  const [healthStatementSentData, HealthStatementSentDispatch] = useReducer(
    HealthStatementSentReducer,
    {
      changedEmail: false,
      email: correo,
      titles: [
        textTituloSend,
        textTitulo,
      ],
      title: '',
      status: 'respond',
      resultData: {},
      dsData: {},
      loading: true,
      error: false,
    }
  )

  useEffect(() => {
    if (healthStatementSentData.status === 'respond') {
      HealthStatementSentDispatch(HealthStatementSentActions.setTitle(1))
      const body = {
        identificador: stepperStepsData.userInformation.identificador,
        rut: stepperStepsData.userInformation.rut,
      }
      HealthStatementSentService.resultDS(body).then((response) => {
        if (response.httpCode === 200) {
          const { resultado } = response.data
          let result = ''
          let title = ''
          if (resultado === 0) {
            result = 'evaluation'
            title = 'D. de '+{textTituloEvaluacion}+' En Evaluación'
          } else if (resultado === 1) {
            result = 'authorized'
            title = 'D. de '+{textTituloEvaluacion}+' Autorizada'
          } else if (resultado === 2) {
            result = 'restricted'
            title = 'D. de '+{textTituloEvaluacion}+' Con Restricciones'
          } else if (resultado === 3) {
            result = 'revision'
            title = 'D. de '+{textTituloEvaluacion}+' En Revisión'
          } else if (resultado === 4) {
            result = 'rejected'
            title = 'D. de '+{textTituloEvaluacion}+' Rechazada'
          } else if (resultado === 5) {
            result = 'canceled'
            title = 'D. de '+{textTituloEvaluacion}+' Cancelada'
          } else if (resultado === 6) {
            result = 'financialEvaluation'
            title = 'D. de '+{textTituloEvaluacion}+' en Evaluación Financiera'
          } else if (resultado === 7) {
            result = 'financialRevision'
            title = 'D. de '+{textTituloEvaluacion}+' En Revisión Financiera'
          } else if (resultado === 8) {
            result = 'insufficientIncome'
            title = 'D. de '+{textTituloEvaluacion}+' Rechazada'
          }
          
          
          stepperStepsDispatch(StepperStepsActions.setResult(result))
          stepperStepsDispatch(StepperStepsActions.setStep({index:0, title}))
          HealthStatementSentDispatch(HealthStatementSentActions.setResultData(response.data))
          HealthStatementSentDispatch(HealthStatementSentActions.setLoading(false))
        } else {
          HealthStatementSentDispatch(HealthStatementSentActions.setError(true))
          HealthStatementSentDispatch(HealthStatementSentActions.setLoading(false))
        }
      }).catch(() => {
        HealthStatementSentDispatch(HealthStatementSentActions.setError(true))
        HealthStatementSentDispatch(HealthStatementSentActions.setLoading(false))
      })
    } else {
      HealthStatementSentDispatch(HealthStatementSentActions.setTitle(0))
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [healthStatementSentData.status])

  return (
    <HealthStatementSentContext.Provider
      value={{
        wizard,
        healthStatementSentData,
        HealthStatementSentDispatch,
        HealthStatementSentActions,
      }}
    >
      {children}
    </HealthStatementSentContext.Provider>
  )
}

HealthStatementSentContextProvider.propTypes = {
  children: PropTypes.element.isRequired,
  wizard: PropTypes.shape({}).isRequired,
}
