import './previous-consideration-item.scss'
import React from 'react'
import PropTypes from 'prop-types'
import ConsiderationNumber from './consideration-number'

const PreviousConsiderationItem = ({ num, consideration }) => {

  return (
    <div className="consideration__item">
      <ConsiderationNumber number={num} />
      <div className="consideration__text">{consideration}</div>
    </div>
  )
}
PreviousConsiderationItem.propTypes = {
  num: PropTypes.number.isRequired,
  consideration: PropTypes.string.isRequired
}
export default PreviousConsiderationItem