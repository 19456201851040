import Button from "@components/commons/buttons/button";
import Checkbox from "@components/commons/inputs/checkbox";
import SpinnerLoader from "@components/commons/loaders/spinner-loader";
import SimpleModal from "@components/commons/messages/simple-modal/modal";
import { WizardService } from "@components/commons/steppers/wizard";

import React, { useCallback, useState } from "react";
import "./ds-doc-view.scss";

const modalStyle = {
  modalBody: {
    position: "fixed",
    top: "50%",
    left: "50%",
    //transform: "translate(-50%, -50%)",
    width: "auto",
    backgroundColor: "#FFFFFF",
    border: "1px solid #B7B7B7",
    borderRadius: "16px",
    zIndex: "999"
  },
  /* closeBtn: {
    position: "absolute !important",
    transform: "translate(985px, -15px)"
  }, */
  modalContainer: {
    margin: "50px 14px 14px",
    textAlign: "center",
    fontFamily: "Raleway",
    fontWeight: "500",
    fontSize: "16px",
    width: "80vw"
  }
};

const DSDocView = ({ file, beneficiariesCM, setBeneficiariesCM }) => {
  const [showError, setShowError] = useState({ show: false, msg: "" });
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [disabledButtonsCircular, setDisabledButtonCircular] = useState(true);
  const [generatedFile, setGeneratedFile] = useState(null);

  const handleConfirmCircular = (e) => {
    setDisabledButtonCircular(e);
  };

  const getDSFile = useCallback(async () => {
    setLoading(true);
    setShowModal(true);
    await WizardService.generarDocCargaMedica(file.id)
      .then((res) => {
        if (res.httpCode === 200) {
          setGeneratedFile(res.data);
        } else {
          setShowError({
            show: true,
            msg: "No se ha podido generar el documento. Intentalo nuevamente."
          });
        }
      })
      .catch(() => {
        setShowError({
          show: true,
          msg: "No se ha podido generar el documento. Intentalo nuevamente."
        });
      });
    setLoading(false);
  }, [file]);

  const acceptDocument = () => {
    if (!disabledButtonsCircular) {
      setBeneficiariesCM(
        beneficiariesCM.map((item) =>
          item.idArchivo === file.id ? { ...item, accepted: true } : item
        )
      );
    } else {
      setBeneficiariesCM(
        beneficiariesCM.map((item) =>
          item.idArchivo === file.id ? { ...item, accepted: false } : item
        )
      );
    }
    setShowModal(false);
  };

  console.log("disabledButtonsCircular", disabledButtonsCircular);

  return (
    <>
      <div className="form-group form-group--large with-icon">
        <div className="midiv">
          <button
            type="button"
            className="decla-btn"
            onClick={() => getDSFile(true)}
          >
            Declaración Jurada
          </button>
          {beneficiariesCM.find((x) => x.idArchivo === file.id).accepted ? (
            <em className="icon-check yes" />
          ) : (
            <em className="icon-x no" />
          )}
        </div>
      </div>

      <SimpleModal
        isShowing={showModal}
        action={acceptDocument}
        styles={modalStyle}
      >
        <div className="prospect-bg__margin-title">
          <div className="prospect-bg__margin-title">
            {/* <embed src={`data:application/pdf;base64,${generatedFile?.archivo}`}/> */}
            <div className="container" style={{ height: "70vh" }}>
              {loading ? (
                <SpinnerLoader show={loading} />
              ) : (
                <>
                  {generatedFile ? (
                    <iframe
                      width="90%"
                      height="90%"
                      src={`data:application/pdf;base64,${generatedFile?.archivo}`}
                    ></iframe>
                  ) : (
                    <span>
                      No se ha podido generar el archivo, intentalo mas tarde
                    </span>
                  )}
                </>
              )}
            </div>
            <div className="row mb-5">
              <div className="col-4"></div>
              <div className="col-4">
                <Checkbox
                  name="Acepto"
                  text={"Acepto Declaración Jurada"}
                  value={
                    beneficiariesCM.find((x) => x.idArchivo === file.id)
                      .accepted
                  }
                  clickMethod={() =>
                    handleConfirmCircular(!disabledButtonsCircular)
                  }
                />
              </div>
              <div className="col-4"></div>
            </div>
          </div>
          <div className="row">
            {/* <div className="col-6">
              <PrintItem
                text={"Descargar Archivo"}
                fileUrl={generatedFile?.archivo}
              />
            </div> */}
            <div className="col-12">
              <Button
                text="Cerrar"
                action={() => acceptDocument()}
                disabled={disabledButtonsCircular}
              />
            </div>
          </div>
        </div>
      </SimpleModal>
    </>
  );
};

export default DSDocView;
