import './health-statement-wizard.scss'
import React, { useContext, useEffect, useCallback } from 'react'
import { Wizard, Steps, Step } from 'react-albus'
import NSProgressBar from '@commons/progress-bar/progress-bar'
import PreviousConsiderations from '@pages/previous-considerations'
import HealthStatement from '@pages/health-statement'
import HealthStatementDocuments from '@pages/health-statement-documents'
import QuoteSummary from '@pages/quote-summary'
import ContributorBackground from '@pages/contributor-background'
import BenefIdentification from '@pages/beneficiary-identification'
import SummaryHealthStatement from '@pages/summary-health-statement'
import SignHealthStatement from '@pages/sign-health-statement'
import SummaryBackgroundStatement from '@pages/summary-background-statement'
import HealthStatementSent from '@pages/health-statement-sent'

import { WizardContext } from '@commons/steppers/wizard'
import { HealthStatementContextProvider } from '@components/health-statement'
import { HealthStatementDocumentsContextProvider } from '@components/health-statement-documents'
import { PreviousConsiderationsContextProvider } from '@components/previous-considerations'
import { BenefIdentContextProvider } from '@components/beneficiary-identification'
import { ContributorBackgroundContextProvider } from '@components/contributor-background'
import { SummaryHealthStatementContextProvider } from '@components/summary-health-statement'
import { SummaryBackgroundStatementContextProvider } from '@components/summary-background-statement'
import { SignHealthStatementContextProvider } from '@components/sign-health-statement'
import { HealthStatementSentContextProvider } from '@components/health-statement-sent'
import { QuoteSummaryContextProvider } from '@components/quote-summary'
import { ManagerRouteContextProvider } from '@commons/steppers/manager-route/manager-route-context'
import { StepperStepsContext } from '@commons/steppers/steps'

const HealthStatementWizard = ({ history, route }) => {
  const { wizardData, WizardDispatch, WizardActions } = useContext(
    WizardContext
  )
  const { stepperStepsData } = useContext(StepperStepsContext)
  const { isAsisted } = stepperStepsData
  const addSteps = useCallback(() => {
    WizardDispatch(
      WizardActions.addSteps([
        {
          name: 'consideraciones',
          completed: false,
        },
        {
          name: 'cotizante',
          completed: false,
        },
        {
          name: 'beneficiarios',
          completed: false,
        },
        {
          name: 'preguntas',
          completed: false,
        },
        {
          name: 'documentosDS',
          completed: false,
        },
        {
          name: 'resumenAntecedentes',
          completed: false,
        },
        {
          name: 'resumenDeclaracion',
          completed: false,
        },
        {
          name: 'firma',
          completed: false,
        },
        {
          name: 'resultado',
          completed: false,
        },
      ])
    )

    if (!isAsisted) {
      WizardDispatch(
        WizardActions.addStep({
          name: 'documentosContractuales',
          completed: false,
        })
      )
      WizardDispatch(
        WizardActions.addStep({
          name: 'resumenCotizacion',
          completed: false,
        })
      )
    }
  }, [WizardActions, WizardDispatch])

  /**
   * Side Effects
   */
  useEffect(() => addSteps(), [addSteps])

  return (
    <div className="wizard health-statement">
      <div className="wizard__progress">
        <NSProgressBar percent={wizardData.progress} />
      </div>
      <div className="wizard__wrapper">
        <Wizard history={history} basename={route}>
          <Steps>
            <Step
              id="consideraciones-previas"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <PreviousConsiderationsContextProvider wizard={wizard}>
                    <PreviousConsiderations />
                  </PreviousConsiderationsContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="antecedentes-cotizante"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <ContributorBackgroundContextProvider wizard={wizard}>
                    <ContributorBackground />
                  </ContributorBackgroundContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="identificacion-beneficiarios"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <BenefIdentContextProvider wizard={wizard}>
                    <BenefIdentification />
                  </BenefIdentContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="preguntas"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <HealthStatementContextProvider wizard={wizard}>
                    <HealthStatement />
                  </HealthStatementContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="resumen-antecedentes-beneficiarios"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <SummaryBackgroundStatementContextProvider wizard={wizard}>
                    <SummaryBackgroundStatement />
                  </SummaryBackgroundStatementContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="resumen-preguntas"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <SummaryHealthStatementContextProvider wizard={wizard}>
                    <SummaryHealthStatement />
                  </SummaryHealthStatementContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="firmar-declaracion-salud"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <SignHealthStatementContextProvider wizard={wizard}>
                    <SignHealthStatement />
                  </SignHealthStatementContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="resultado-declaracion-salud"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <HealthStatementSentContextProvider wizard={wizard}>
                    <HealthStatementSent />
                  </HealthStatementSentContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="documentos-contractuales"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <HealthStatementDocumentsContextProvider wizard={wizard}>
                    <HealthStatementDocuments />
                  </HealthStatementDocumentsContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
            <Step
              id="resumen-cotizacion"
              render={(wizard) => (
                <ManagerRouteContextProvider>
                  <QuoteSummaryContextProvider wizard={wizard}>
                    <QuoteSummary />
                  </QuoteSummaryContextProvider>
                </ManagerRouteContextProvider>
              )}
            />
          </Steps>
        </Wizard>
      </div>
    </div>
  );
}

export default HealthStatementWizard
